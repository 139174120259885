import { CREDITLIST } from '../constants/api';
import fetchClient from '../utils/fetch-client';

export const getCreditlist = ({
  page,
  limit,
  searchText,
  showTransferedToWorkshop,
}: {
  page: number;
  limit: number;
  searchText: string;
  showTransferedToWorkshop: boolean;
}) => {
  return fetchClient.post(CREDITLIST.GET_ALL, {
    page,
    limit,
    searchText,
    showTransferedToWorkshop,
  });
};

export const transferCreditUserToWorkshop = ({
  note,
  workshopSlug,
  creditListId,
}: {
  note: string;
  workshopSlug: string;
  creditListId: string;
}) => {
  return fetchClient.post(`${CREDITLIST.ADD_TO_WORKSHOP}/${creditListId}`, {
    note,
    workshopSlug,
  });
};

export const updateCreditNote = ({
  note,
  creditListId,
}: {
  note: string;
  creditListId: string;
}) => {
  return fetchClient.put(`${CREDITLIST.UPDATE_NOTE}/${creditListId}`, {
    note,
  });
};
