import * as yup from 'yup';

export default yup.object().shape({
  isPublished: yup.boolean().required('isPublished is required'),
  isClassVideo: yup.boolean(),
  sequence: yup
    .number()
    .typeError('Enter numbers only')
    .required('Sequence is required'),
  language: yup.string().when('isPublished', {
    is: true,
    then: (schema) => schema.required('Language is required1'),
    otherwise: (schema) => schema,
  }),
  videoName: yup.string().when('isPublished', {
    is: true,
    then: (schema) => schema.required('Video name is required'),
    otherwise: (schema) => schema,
  }),
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),

  // difficultyLevel: yup.string().when('isPublished', {
  //   is: true,
  //   then: (schema) => schema.required('Difficulty level is required'),
  //   otherwise: yup.string(),
  // }),

  categories: yup
    .array()
    .of(yup.string())
    .when('isPublished', {
      is: true,
      then: (schema) => schema.required('categories is required'),
    }),

  description: yup.object().when('isPublished', {
    is: true,
    then: (schema) =>
      schema.shape({
        forStudent: yup.string().required('Student description is required'),
        forTeacher: yup.string().required('Teacher description is required'),
      }),
    otherwise: (schema) =>
      schema.shape({
        forStudent: yup.string(),
        forTeacher: yup.string(),
      }),
  }),

  faqs: yup.array().when('isPublished', {
    is: true,
    then: (schema) =>
      schema
        .of(
          yup.object().shape({
            question: yup.string().required('Question is required'),
            answer: yup.string().required('Answer is required'),
            forMemberType: yup.string().required('Member type is required'),
          })
        )
        .required('Faqs are required')
        .min(1, 'Minimum one faq are required'),
    otherwise: (schema) =>
      schema.of(
        yup.object().shape({
          question: yup.string(),
          answer: yup.string(),
          forMemberType: yup.string(),
        })
      ),
  }),

  equipments: yup.array().when('isPublished', {
    is: true,
    then: (schema) => schema.of(yup.string()).required('Equipment is required'),
    otherwise: (schema) => schema.of(yup.string()),
  }),
  musicUsed: yup.string().when('isPublished', {
    is: true,
    then: (schema) => schema.required('Music Playlist is required'),
    otherwise: (schema) => schema,
  }),
  mediaId: yup.string().when('isPublished', {
    is: true,
    then: (schema) => schema.required('Video is required'),
    otherwise: (schema) => schema,
  }),
  exerciseStartSeconds: yup.number().when(['isPublished', 'isClassVideo'], {
    is: (isPublished: boolean, isClassVideo: boolean) =>
      isPublished && !isClassVideo,
    then: (schema) => schema.required('Exercise Start Seconds is required'),
    otherwise: (schema) => schema,
  }),
  difficultyLevel: yup.number().when(['isPublished', 'isClassVideo'], {
    is: (isPublished: boolean, isClassVideo: boolean) =>
      isPublished && !isClassVideo,
    then: (schema) => schema.required('Difficulty Level is required'),
    otherwise: (schema) => schema,
  }),
  customThumb: yup.string(),
});
