import * as yup from 'yup';

export default yup.object().shape({
  level: yup.number().required('Please select level'),
  sequence: yup.number().required('Please input sequence'),
  name: yup.object().shape({
    en: yup.string().required('Please input name in english'),
    pt: yup.string().required('Please input name in portugese'),
    it: yup.string().required('Please input name in italian'),
    es: yup.string().required('Please input name in spanish'),
    de: yup.string().required('Please input name in german'),
    zh: yup.string().required('Please input name in mandrin'),
  }),
  logo: yup.string().required('Please upload logo'),
  exerciseName: yup.object().shape({
    en: yup.string().required('Please input exercise name in english'),
    pt: yup.string().required('Please input exercise name in portugese'),
    it: yup.string().required('Please input exercise name in italian'),
    es: yup.string().required('Please input exercise name in spanish'),
    de: yup.string().required('Please input exercise name in german'),
    zh: yup.string().required('Please input exercise name in mandrin'),
  }),
});
