import { CERTIFICATIONS_RENEWAL } from '../constants/api';
import fetchClient from '../utils/fetch-client';

interface addReivewProps {
  responseId: string;
  isApproved: boolean;
  remarks: string;
}

export const getCertificationRenewalResponses = ({
  page = 1,
  limit = 10,
  approvedResponses,
}: {
  page: number;
  limit?: number;
  approvedResponses?: boolean;
}) => {
  return fetchClient.get(
    `${CERTIFICATIONS_RENEWAL.RESPONSE}/all?page=${page}&limit=${limit}&approvedResponses=${approvedResponses}`
  );
};

export const addCertificationRenewalResponseReview = (
  payload: addReivewProps
) => {
  return fetchClient.post(
    `${CERTIFICATIONS_RENEWAL.RESPONSE}/add-review`,
    payload
  );
};

export const getAssignees = () => {
  return fetchClient.get(`${CERTIFICATIONS_RENEWAL.RESPONSE}/assignees`);
};
export const addAssignee = (payload: {
  responseId: string;
  assigneeId: string;
}) => {
  return fetchClient.post(
    `${CERTIFICATIONS_RENEWAL.RESPONSE}/add-assignee`,
    payload
  );
};
