/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, message } from 'antd';
import { FC, memo, useEffect, useState } from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import { TechniqueDetail } from '../../../../types/technique';
import { TUser } from '../../../../services/users-service';
import { getTechniques } from '../../../../services/technique-service';
import FormattedDate from '../../../date/FormattedDate';

import AddCertification from './add-certifications';
// eslint-disable-next-line import/order
import EditCertification from './edit-certifications';

import './index.scss';
import DeleteCertification from './delete-certification';

interface CertificationsProps {
  user: TUser;
  successCallback: () => void;
}

const Certifications: FC<CertificationsProps> = ({ user, successCallback }) => {
  const [techniques, setTechniquess] = useState<TechniqueDetail[]>([]);

  const loadTechniques = async () => {
    try {
      const res = await getTechniques();
      setTechniquess(res.data.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadTechniques();
  }, []);

  return (
    <Card title="Certifications" className="info-container">
      <span className="info-heading">
        <AddCertification
          userId={user?._id || ''}
          techniques={techniques}
          successCallback={() => {
            if (successCallback) {
              successCallback();
              message.success('Added certification successfully.');
            }
          }}
          errorCallback={(e) => {
            message.error(e);
          }}
        >
          {({ onClick }) => (
            <PlusOutlined onClick={onClick} style={{ float: 'right' }} />
          )}
        </AddCertification>
      </span>

      {user?.certifications?.map((certification) => {
        const { code, level, certifiedOn, revokedOn, renewalDates } =
          certification;

        function getLatestDate(dates: Date[]): Date {
          return dates.reduce((latestDate, currentDate) => {
            return new Date(currentDate) > new Date(latestDate)
              ? currentDate
              : latestDate;
          });
        }

        return (
          <div key={certification?.code} className="certificationcard">
            <div className="certificationcard-left">
              <span style={{ fontWeight: 600 }}>{code?.toUpperCase()}</span>
              {certification?.revokedOn ? (
                <span>
                  Revoked On{' '}
                  <FormattedDate date={revokedOn} className="pbt-pl" />
                </span>
              ) : (
                <span>
                  Certified on{' '}
                  <FormattedDate date={certifiedOn} className="pbt-pl" />
                  {level ? `, Level ${level}` : ''}
                  {renewalDates?.length ? (
                    <>
                      {`, last renewed on `}
                      <FormattedDate
                        date={getLatestDate(renewalDates)}
                        className="pbt-pl"
                      />
                    </>
                  ) : null}
                </span>
              )}
            </div>
            <div className="certification-action">
              <EditCertification
                userId={user?._id || ''}
                successCallback={() => {
                  if (successCallback) {
                    successCallback();
                    message.success('Added certification successfully.');
                  }
                }}
                errorCallback={(e) => {
                  message.error(e);
                }}
                techniques={techniques}
                payload={certification}
              >
                {({ onClick }) => (
                  <EditOutlined
                    onClick={onClick}
                    className="action-buttons-edit"
                  />
                )}
              </EditCertification>
              <DeleteCertification
                userId={user?._id || ''}
                certification={certification}
                successCallback={() => {
                  if (successCallback) {
                    successCallback();
                  }
                }}
                errorCallback={(e) => {
                  message.error(e);
                }}
              />
            </div>
          </div>
        );
      })}
    </Card>
  );
};

export default memo(Certifications);
