import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { InputField, SelectField } from '../common/form';
import { flagListItemType, IFlagListFormField } from '../../types/flag-list';

interface FlagListFormProps {
  form: UseFormReturn<IFlagListFormField, object>;
}

const FlagListForm = ({ form }: FlagListFormProps) => {
  return (
    <>
      <Form layout="vertical">
        <SelectField
          label="Type"
          required
          name="type"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: Object.entries(flagListItemType)?.map(([key, value]) => ({
              label: key,
              value,
            })),
            placeholder: 'Select Type',
            onChange: (v) => form.setValue('type', v),
          }}
        />
        <InputField
          label="Value"
          name="value"
          required
          inputProps={{
            placeholder: 'Value',
          }}
          form={form}
        />
        <InputField
          label="Reason"
          name="reason"
          inputProps={{
            placeholder: 'Reason',
          }}
          form={form}
        />
      </Form>
    </>
  );
};

export default FlagListForm;
