import { Typography } from 'antd';

import CertificateForm from '../../components/create-certificate';

const CreateCertificate = () => {
  return (
    <div>
      <Typography.Title level={3}>Create Certificate</Typography.Title>
      <CertificateForm />
    </div>
  );
};

export default CreateCertificate;
