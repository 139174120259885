/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Drawer, message } from 'antd';
import * as yup from 'yup';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import { IUserPages } from '../../../types/user-pages';
import UserPageForm from '../form';
import { editUserPage } from '../../../services/user-pages-service';
import { FaqCategory } from '../../../types/faq';

interface IEditUserPageProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  payload: IUserPages;
  pageId: string;
  children: FC<{
    onClick: () => void;
  }>;
  isEditable: boolean;
  faqCategories: FaqCategory[];
}

const EditUserPage: FC<IEditUserPageProps> = ({
  children,
  payload,
  isEditable,
  successCallback,
  errorCallback,
  faqCategories,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<yup.InferType<typeof validationSchema>>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };
  const onSubmitHandler = async (
    values: yup.InferType<typeof validationSchema>
  ) => {
    setRequesting(true);
    try {
      await editUserPage({
        ...values,
        videos: values.videos,
        faqCategories:
          values?.faqCategories?.map((item, index) => ({
            slug: item,
            sequence: index + 1,
          })) || [],
      });
      setDrawerVisible(false);
      message.success('Updated successfully');
      if (successCallback) {
        successCallback();
      }
    } catch (err: any) {
      if (errorCallback) {
        errorCallback(err?.response?.data?.MESSAGE_KEY || err?.message);
      }
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    form.reset({
      slug: payload?.slug,
      name: payload?.name,
      faqCategories: payload?.faqCategories?.map((i) => i?.slug),
      videos: payload?.videos,
    } as any);
  }, [payload]);

  return (
    <>
      <Drawer
        title="Update Page"
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <UserPageForm
            isEditable={isEditable}
            form={form}
            faqCategories={faqCategories}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditUserPage;
