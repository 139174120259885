import {
  Button,
  Dropdown,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import { getDeals } from '../../services/deals';
import AddDeal from '../../components/deals/add-deal';
import EditDeal from '../../components/deals/edit-deal';
import DeleteDeal from '../../components/deals/delete-deal';
import { IDeals, IDealsStatus } from '../../types/deals';
import { handleError } from '../../utils/common';
import './styles.scss';
import ManageDealStatus from '../../components/deals/manage-status';

import columns from './columns';

const Deals = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadDeals, setReloadDeals] = useState(false);
  const [deals, setDeals] = useState<IDeals[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const statusParam = queryParams.get('status');

  const status: IDealsStatus =
    statusParam &&
    Object.values(IDealsStatus)?.includes(statusParam as IDealsStatus)
      ? (statusParam as IDealsStatus)
      : IDealsStatus.upcoming;

  const loadDeals = async () => {
    setRequesting(true);
    try {
      const response = await getDeals({ page, limit, status });
      const data = response?.data?.data;
      setTotalPages(data?.totalPages ?? 0);
      setDeals(data?.deals || []);
    } catch (e) {
      handleError(e);
    } finally {
      setReloadDeals(false);
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadDeals) {
      loadDeals();
    }
  }, [reloadDeals, requesting]);

  useEffect(() => {
    loadDeals();
  }, [page, limit, status]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: IDeals) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'edit-deal',
                  icon: <EditOutlined />,
                  label: (
                    <EditDeal
                      dealId={record?._id}
                      successCallback={() => setReloadDeals(true)}
                    >
                      {({ onClick }) => <div onClick={onClick}>Edit</div>}
                    </EditDeal>
                  ),
                },
                {
                  key: 'send-welcome-mail',
                  icon: <EyeOutlined />,
                  label: (
                    <ManageDealStatus
                      dealId={record._id}
                      successCallback={() => setReloadDeals(true)}
                      currentStatus={record?.status}
                    >
                      {({ onClick }) => (
                        <div onClick={onClick}>Manage Status</div>
                      )}
                    </ManageDealStatus>
                  ),
                },
                {
                  type: 'divider',
                },

                {
                  key: 'delete-deal',
                  danger: true,
                  icon: <DeleteOutlined />,
                  label: (
                    <DeleteDeal
                      _id={record._id}
                      successCallback={() => setReloadDeals(true)}
                    >
                      {({ onClick }) => <div onClick={onClick}>Delete</div>}
                    </DeleteDeal>
                  ),
                },
              ],
            }}
            arrow
            trigger={['click']}
            placement="bottomRight"
            className="workshop-dropdown"
            overlayClassName="workshop-dropdown-overlay"
          >
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <div className="deal-container">
        {/* <div className="manage-deal-container">s</div> */}
        <div className="deal-table">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography.Title level={3}>Deals</Typography.Title>
            <Space>
              <Tooltip title="Refresh" placement="left">
                <Button
                  type="ghost"
                  icon={<ReloadOutlined />}
                  onClick={() => setReloadDeals(true)}
                />
              </Tooltip>
              <Select
                value={status}
                style={{ width: 150 }}
                onChange={(status) =>
                  navigate({
                    pathname: location.pathname,
                    search: `?status=${status}`,
                  })
                }
                options={[
                  { label: 'Active', value: IDealsStatus.active },
                  { label: 'Expired', value: IDealsStatus.expired },
                  { label: 'Upcoming', value: IDealsStatus.upcoming },
                ]}
              />

              <AddDeal
                successCallback={() => setReloadDeals(true)}
                errorCallback={handleError}
              >
                {({ onClick }) => (
                  <Button
                    onClick={onClick}
                    type="primary"
                    icon={<PlusCircleOutlined />}
                  >
                    Add Deal
                  </Button>
                )}
              </AddDeal>
            </Space>
          </div>

          <Table
            dataSource={deals}
            columns={columnsWithActions}
            rowKey="_id"
            pagination={{
              pageSize: limit,
              current: page,
              onChange: (page: number, limit) => {
                setPage(page);
                setLimit(limit);
              },
              total: (totalPages || 1) * 10,
            }}
          />
        </div>
      </div>
    </Spin>
  );
};

export default Deals;
