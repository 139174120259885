import * as yup from 'yup';

const languages = ['en', 'es', 'it', 'de', 'pt', 'zh', 'fr', 'zh-HK'];

const MultiLangOptSchemaProp = yup.object().shape({
  en: yup.string(),
  es: yup.string(),
  it: yup.string(),
  de: yup.string(),
  pt: yup.string(),
  zh: yup.string(),
  fr: yup.string(),
  'zh-HK': yup.string(),
});

const EmailAttachmentValidationSchema = yup.object().shape({
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
  name: yup.string().min(1).required(),
  description: yup.string(),
  attachments: yup.array().of(
    yup
      .object()
      .shape({
        fileName: MultiLangOptSchemaProp,
        fileUrl: MultiLangOptSchemaProp,
      })
      .test(
        'fileName-fileUrl-check',
        'For each language, both fileName and fileUrl must be present or neither.',
        function (value) {
          return languages.every((lang) => {
            const hasFileName = !!value.fileName[lang];
            const hasFileUrl = !!value.fileUrl[lang];
            return hasFileName === hasFileUrl;
          });
        }
      )
  ),
});

export default EmailAttachmentValidationSchema;
