// import { useState } from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
// import { MailOutlined, IdcardOutlined, SwapOutlined } from '@ant-design/icons';
// import { Link } from 'react-router-dom';

// import { AttendeeWorkshops } from '@custom-types/workshop';
// import { sendWelcomeEmail } from '@services/workshop-service';

// import TransferWorkshop from '../TransferWorkshop';

import { AttendeeWorkshops } from '../../../../types/workshop';
import { CLIENT_FRONTEND_URL } from '../../../../constants/api';
// import { sendWelcomeEmail } from '../../../../services/workshop-service';

export interface IWorkshopList {
  filteredWorkshops: AttendeeWorkshops[];
  requesting: boolean;
  transferSuccessCallback: () => void;
  userId?: string;
}
const WorkshopsList = ({
  filteredWorkshops,
  transferSuccessCallback,
  userId,
}: IWorkshopList) => {
  // const [requesting, setRequesting] = useState(false);
  // const [instructor, setInstructor] = useState('');
  // const [OpenTransferWorkshopTable, setOpenTransferWorkshopTable] =    useState(false);
  // const [oldWorkshopSlug, setOldWorkshopSlug] = useState('');
  // const requestWelcomeEmail = async (
  //   attendeeId: string,
  //   attendeeEmail: string
  // ) => {
  //   try {
  //     setRequesting(true);
  //     await sendWelcomeEmail(attendeeId, { attendeeEmail });
  //     message.success('Mail sent successfully');
  //   } catch (err) {
  //     if (err instanceof Error) message.error(err.message);
  //   } finally {
  //     setRequesting(false);
  //   }
  // };
  const attendeeWorkshopsColumns = [
    {
      title: 'Name On Certificate',
      render: (value: string, record: AttendeeWorkshops) => (
        <Link
          to={{
            pathname: `/workshop-attendees/${record?.workshopSlug}?attendeeID=${record?.email}`,
          }}
          target="_blank"
        >
          {record.attendeeDetails.nameAsOnCertificate}
        </Link>
      ),
      key: 'name',
      width: 250,
    },
    {
      title: 'Workshop Title',
      render: (value: string, record: AttendeeWorkshops) => (
        <Link
          to={{
            pathname: `${CLIENT_FRONTEND_URL}/workshops/${record?.workshops?.techniqueSlug}/${record?.workshopSlug}`,
          }}
          target="_blank"
        >
          {record?.workshops?.title}
        </Link>
      ),
      key: 'workshopTitle',
      width: 250,
    },
    {
      title: 'Level',
      render: (value: string, record: AttendeeWorkshops) =>
        record.workshops.level,
      key: 'level',
      width: 100,
    },
    {
      title: 'Starts Date',
      render: (value: string, record: AttendeeWorkshops) =>
        moment(record?.workshops?.startDate).format('DD/MM/YYYY'),
      key: 'startDate',
      width: 200,
    },
    {
      title: 'Ends Date',
      render: (value: string, record: AttendeeWorkshops) =>
        moment(record.workshops.endDate).format('DD/MM/YYYY'),
      key: 'endDate',
      width: 200,
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   render: (value: string, record: AttendeeWorkshops) => {
    //     return (
    //       <div style={{ display: 'flex', gap: '5px' }}>
    //         {/* Ticket Mail */}
    //         {moment(record?.workshops?.startDate).valueOf() > moment.now() && (
    //           <Popover content={<span>Workshop Ticket Mail</span>}>
    //             <Button type="text">
    //               <MailOutlined
    //                 onClick={() =>
    //                   requestWelcomeEmail(
    //                     record?._id,
    //                     record?.attendeeDetails?.email
    //                   )
    //                 }
    //               />
    //             </Button>
    //           </Popover>
    //         )}

    //         {/* Transfer Request */}
    //         {record?.isPaymentDone &&
    //           record.refundStatus === 'NOT_REQUESTED' &&
    //           moment(record?.workshops?.startDate).valueOf() > moment.now() && (
    //             <Popover
    //               content={
    //                 <span>
    //                   {record.transferStatus === 'REQUESTED'
    //                     ? 'Transfer Requested'
    //                     : 'Request Transfer'}
    //                 </span>
    //               }
    //             >
    //               {
    //                 <Button
    //                   type="text"
    //                   disabled={record.transferStatus === 'REQUESTED'}
    //                 >
    //                   <SwapOutlined
    //                     onClick={() => {
    //                       if (record.transferStatus === 'NOT_REQUESTED') {
    //                         setInstructor(record?.workshops?.workshopOwner);
    //                         setOldWorkshopSlug(record.workshopSlug);
    //                         setOpenTransferWorkshopTable(true);
    //                       }
    //                     }}
    //                   />
    //                 </Button>
    //               }
    //             </Popover>
    //           )}

    //         {/* Download Certificate */}
    //         {record.isPaymentDone &&
    //           record?.certificateLink &&
    //           record?.isAttendanceMarked &&
    //           moment(record?.workshops?.endDate).valueOf() < moment.now() && (
    //             <Popover content={<span>Download Certificate</span>}>
    //               <Button type="text">
    //                 <Link to={record?.certificateLink} target="_blank">
    //                   <IdcardOutlined />
    //                 </Link>
    //               </Button>
    //             </Popover>
    //           )}
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <>
      {/* <TransferWorkshop
        instructor={instructor}
        openTransferWorkshopTable={OpenTransferWorkshopTable}
        oldWorkshopSlug={oldWorkshopSlug}
        setOpenTransferWorkshopTable={setOpenTransferWorkshopTable}
        transferSuccessCallback={transferSuccessCallback}
        userId={userId}
      /> */}
      <Table
        // loading={requesting}
        dataSource={filteredWorkshops}
        columns={attendeeWorkshopsColumns}
      />
    </>
  );
};

export default WorkshopsList;
