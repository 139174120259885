import { Button, Drawer, message } from 'antd';
import { createElement, FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import { Blog } from '../../../types/blog';
import BlogForm from '../form';
import { addBlog } from '../../../services/blog-service';
import { BlogCategory } from '../../../types/blog-categories';

interface IAddTBlogProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  categories: BlogCategory[];
  children: FC<{
    onClick: () => void;
  }>;
}

const AddBlog: FC<IAddTBlogProps> = ({
  children,
  successCallback,
  errorCallback,
  categories,
}) => {
  const refChildren = useRef(children);
  const [requestingPublish, setRequestingPublish] = useState(false);
  const [requestingDraft, setRequestingDraft] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<Blog>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const handleOnDraft = async (values: Blog) => {
    setRequestingDraft(true);

    await addBlog({ ...values, isPublished: false })
      .then(() => {
        setDrawerVisible(false);
        message.success('Blog saved as draft');
        form.reset();
        setRequestingDraft(false);
        if (successCallback) {
          successCallback();
        }
      })
      .catch((error) => {
        setRequestingDraft(false);

        if (error.response) {
          message.error(error.response.data.MESSAGE_KEY);
        } else {
          message.error('Unknown Error');
        }

        if (errorCallback) {
          errorCallback(error as Error);
        }
      });
  };

  const handleOnPublish = async (values: Blog) => {
    setRequestingPublish(true);

    await addBlog({ ...values, isPublished: true })
      .then(() => {
        setDrawerVisible(false);
        message.success('Blog published successfully');
        form.reset();
        setRequestingPublish(false);
        if (successCallback) {
          successCallback();
        }
      })
      .catch((error) => {
        setRequestingPublish(false);

        if (error.response) {
          message.error(error.response.data.MESSAGE_KEY);
        } else {
          message.error('Unknown Error');
        }

        if (errorCallback) {
          errorCallback(error as Error);
        }
      });
  };

  return (
    <>
      <Drawer
        title="Add Blog"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={1200}
        footer={
          <div style={{ display: 'flex', gap: '1rem', marginTop: '0.5rem' }}>
            <Button
              block
              loading={requestingDraft}
              onClick={form.handleSubmit(handleOnDraft)}
            >
              Save as Draft
            </Button>
            <Button
              block
              type="primary"
              loading={requestingPublish}
              onClick={form.handleSubmit(handleOnPublish)}
            >
              Publish
            </Button>
          </div>
        }
      >
        <div style={{ padding: 16 }}>
          <BlogForm
            category=""
            categories={categories}
            isEditable={false}
            form={form}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddBlog;
