import { useForm } from 'react-hook-form';
import { Button, message, Drawer } from 'antd';
import { useState, useRef, FC, createElement, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  EmailAttendees,
  IAttendeesEmail,
  WorkshopAttendeeWithUserDetail,
} from '../../../types/workshop';
import {
  createEmailAttendees,
  updateEmailAttendees,
} from '../../../services/workshop-service';
import { handleError } from '../../../utils/common';

import EmailAttendeesForm from './form';
import validationSchema from './validation-schema';
import SavedCommunication from './saved-communication';

interface EmailAttendeesProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
  attendees: WorkshopAttendeeWithUserDetail[];
  workshopSlug?: string;
  workshopTimeZone?: string;
  workshopId?: string;
}

const EmailSentAttendees: FC<EmailAttendeesProps> = ({
  children,
  successCallback,
  attendees,
  workshopSlug,
  workshopTimeZone,
  workshopId,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [mailToUpdate, setMailToUpdate] = useState<IAttendeesEmail | null>(
    null
  );

  const form = useForm<EmailAttendees>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: EmailAttendees) => {
    setRequesting(true);
    try {
      if (mailToUpdate) {
        await updateEmailAttendees({
          mailId: mailToUpdate?._id?.toString(),
          payload: {
            body: values?.body,
            recipients: values?.recipients,
            subject: values?.subject,
            emailScheduleDate: values?.emailScheduleDate,
            isScheduled: values?.isScheduled,
          },
        });

        message.success('Email Updated Successfully');
      } else {
        await createEmailAttendees(values);
        if (!values?.isScheduled) {
          message.success('Email Sent Successfully');
        } else {
          message.success('Email Scheduled Successfully');
        }
      }
      setMailToUpdate(null);
      form.reset({});
      setDrawerVisible(false);
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    return () => {
      form.reset({});
    };
  }, []);

  return (
    <>
      <Drawer
        title="Email Attendees"
        onClose={() => {
          handleDrawerVisibility();
          setMailToUpdate(null);
          form.reset({});
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={900}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={(e) => {
                if (!mailToUpdate) {
                  form.setValue('workshopSlug', workshopSlug);
                }
                form.handleSubmit(onSubmitHandler)(e);
              }}
            >
              Submit
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <EmailAttendeesForm
            attendees={attendees}
            isEditable={!!mailToUpdate}
            form={form}
            workshopTimeZone={workshopTimeZone}
          />
          {!mailToUpdate && (
            <SavedCommunication
              workshopId={workshopId ?? ''}
              form={form}
              workshopTimeZone={workshopTimeZone || ''}
              attendees={attendees}
              drawerVisible={drawerVisible}
              setMailToUpdate={setMailToUpdate}
            />
          )}
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EmailSentAttendees;
