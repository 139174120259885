export enum Files {
  'banner' = 'banner',
  'note' = 'note',
  'ne_note' = 'ne_note',
  'equipment' = 'equipment',
  'ne_equipment' = 'ne_equipment',
}

export interface FileDetail {
  name: string;
  type: Files;
  desc: string;
  file: string;
  createdAt?: Date;
  _id?: string;
}
