// import { CATEGORIES } from '../constants/api';
import { ASSESSMENT_CATEGORIES } from '../constants/api';
import { AssessmentCategory } from '../types/assessment-categories';
import fetchClient from '../utils/fetch-client';

export const getAssessmentCategories = () => {
  return fetchClient.get(`${ASSESSMENT_CATEGORIES.ASSESSMENT_CATEGORIES}`);
};
export const addAssessmentCategory = (payload: AssessmentCategory) => {
  return fetchClient.post(
    `${ASSESSMENT_CATEGORIES.ASSESSMENT_CATEGORIES}`,
    payload
  );
};

export const updateAssessmentCategory = (
  payload: AssessmentCategory,
  id: string
) => {
  return fetchClient.put(
    `${ASSESSMENT_CATEGORIES.ASSESSMENT_CATEGORIES}/${id}`,
    payload
  );
};

export const deleteAssessmentCategory = (id?: string) => {
  return fetchClient.delete(
    `${ASSESSMENT_CATEGORIES.ASSESSMENT_CATEGORIES}/${id}`
  );
};

export const updateBulkAssessmentCategories = (
  payload: {
    _id: string;
    sequence: number;
  }[]
) => {
  return fetchClient.post(
    `${ASSESSMENT_CATEGORIES.ASSESSMENT_CATEGORIES}/update-all`,
    { categories: payload }
  );
};
