import { Card, Descriptions } from 'antd';
import { FC } from 'react';

import { TUser } from '../../../../services/users-service';
import { NOT_AVAILABLE } from '../../../../constants/common';
import FormattedDate from '../../../date/FormattedDate';

interface IDiscountDetails {
  user: TUser;
}

const DiscountDetails: FC<IDiscountDetails> = ({ user }) => {
  return (
    <Card title="Active Discount On Subscription" className="info-container">
      <Descriptions
        layout="vertical"
        column={2}
        labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
      >
        <Descriptions.Item label="CHECKOUT SESSION" className="info-item">
          {user.subscription?.discount?.checkout_session || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="COUPON ID" className="info-item">
          {user.subscription?.discount?.coupon?.id || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="COUPON NAME" className="info-item">
          {user.subscription?.discount?.coupon?.name || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="COUPON DURATION" className="info-item">
          {user.subscription?.discount?.coupon?.duration || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="COUPON PERCENT OFF" className="info-item">
          {user.subscription?.discount?.coupon?.percent_off
            ? `${user.subscription?.discount?.coupon?.percent_off} %`
            : NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item
          label="COUPON DISCOUNT ENDS ON"
          className="info-item"
        >
          {user.subscription?.discount?.end ? (
            <FormattedDate
              date={user.subscription?.discount?.end}
              fromNow={false}
            />
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item label="COUPON APPLIED ON" className="info-item">
          {user.subscription?.discount?.start ? (
            <FormattedDate
              date={user.subscription?.discount?.start}
              fromNow={false}
            />
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label="SUBSCRIPTION ID THE COUPON IS APPLIED TO"
          className="info-item"
        >
          {user.subscription?.id || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="PROMO CODE ID" className="info-item">
          {user.subscription?.discount?.promotion_code?.id || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="PROMO CODE" className="info-item">
          {user.subscription?.discount?.promotion_code?.code || NOT_AVAILABLE}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default DiscountDetails;
