import axios from 'axios';

import { getUserFromStorage } from '../services/auth-service';

const fetchClient = () => {
  const defaultOptions = {};

  // create instance
  const axiosClient = axios.create(defaultOptions);

  // set the auth token for any request
  axiosClient.interceptors.request.use((config) => {
    const user = getUserFromStorage();
    config.headers = {
      ...config.headers,
      ...(user && { 'auth-token': user ? user?.accessToken : '' }),
    };
    return config;
  });

  return axiosClient;
};

export default fetchClient();
