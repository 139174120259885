import { TECHNIQUE } from '../constants/api';
import { TechniqueDetail } from '../types/technique';
import fetchClient from '../utils/fetch-client';

export const getTechniques = () => {
  return fetchClient.get(`${TECHNIQUE.GET_TECHNIQUES}`);
};
export const addTechnique = (payload: TechniqueDetail) => {
  return fetchClient.post(`${TECHNIQUE.TECHNIQUE}`, payload);
};

export const updateTechnique = (payload: TechniqueDetail, id: string) => {
  return fetchClient.put(`${TECHNIQUE.TECHNIQUE}/${id}`, payload);
};

export const updateBulkTechnique = (
  payload: {
    _id: string;
    sequence: number;
  }[]
) => {
  return fetchClient.post(`${TECHNIQUE.TECHNIQUE}/update-all`, {
    techniques: payload,
  });
};

export const deleteTechnique = (id?: string) => {
  return fetchClient.delete(`${TECHNIQUE.TECHNIQUE}/${id}`);
};

export const getWorkshopInstructors = () => {
  return fetchClient.get(TECHNIQUE.INSTRUCTOR);
};
