import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { getFaqCategories } from '../../../services/faq-services';
import AddFaqCategory from '../../../components/faq/faq-category/create-faq-category';
import EditFaqCategory from '../../../components/faq/faq-category/edit-faq-category';
import { FaqCategory } from '../../../types/faq';
import DeleteFaqCategory from '../../../components/faq/faq-category/delete-faqcategory';

import columns from './columns';

const FaqCategry = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadFaqCategories, setReloadFaqCategories] = useState(true);
  const [faqCategories, setFaqCategories] = useState<FaqCategory[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [edit, setEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const dataSourceToShow = faqCategories.slice(startIndex, endIndex);

  const loadFaqCategories = async () => {
    setRequesting(true);
    try {
      const response = await getFaqCategories();
      setFaqCategories(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadFaqCategories(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadFaqCategories) {
      loadFaqCategories();
    }
  }, [reloadFaqCategories]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: FaqCategory) => {
        return (
          <Space size="large">
            <EditFaqCategory
              faqCategoryId={record._id}
              payload={record}
              setEdit={setEdit}
              edit={edit}
              successCallback={() => setReloadFaqCategories(true)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditFaqCategory>
            <DeleteFaqCategory
              faqCategoryId={record._id}
              successCallback={() => setReloadFaqCategories(true)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteFaqCategory>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={dataSourceToShow}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: faqCategories.length,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
        }}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Faq Categories</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadFaqCategories(true)}
                  />
                </Tooltip>
                <AddFaqCategory
                  successCallback={() => setReloadFaqCategories(true)}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Faq Category
                    </Button>
                  )}
                </AddFaqCategory>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default FaqCategry;
