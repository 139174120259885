import moment from 'moment';
import { FC } from 'react';

interface IFormatedDate {
  date?: number | Date;
  fromNow?: boolean;
  ShowTime?: boolean;
  addON?: boolean;
  className?: string;
}

const FormattedDate: FC<IFormatedDate> = ({
  date,
  fromNow,
  ShowTime,
  addON,
  className,
}) => {
  const formatDate = (timestamp: Date | number) => {
    let updatedTimestamp = timestamp;
    if (typeof timestamp === 'number') {
      if (`${timestamp}`.length === 10) {
        updatedTimestamp = timestamp * 1000;
      }
    }
    const momentDate = moment(updatedTimestamp);
    const FromNow = moment(updatedTimestamp).fromNow();
    return `${addON ? 'ON' : ''} 
      ${
        ShowTime
          ? `${momentDate.format('YYYY-MM-DD h:mm a')}`
          : `${momentDate.format('YYYY-MM-DD')}`
      }
       ${fromNow ? `( ${FromNow} )` : ''} `;
  };

  return <span className={className}>{date && formatDate(date)}</span>;
};

export default FormattedDate;
