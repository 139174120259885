import { Col, Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { TPayload } from '../../services/equipment-service';
import { FileUploadButton, InputField } from '../common/form';
import SlugNote from '../common/slug-note';

export type Locale = {
  en?: string;
  pt?: string;
  it?: string;
  es?: string;
  de?: string;
  zh?: string;
};

export type TFieldValues = {
  _id?: string;
  slno?: number;
  product?: Locale;
  website?: string;
};
export const MULTILANGUAGE = {
  en: '',
  pt: '',
  it: '',
  es: '',
  de: '',
  zh: '',
};

export const defaultValues: TPayload = {
  slug: '',
  slno: undefined,
  product: MULTILANGUAGE,
  website: '',
  imageUrl: '',
};

interface IAddEquipmentFormProps {
  form: UseFormReturn<TPayload, object>;
  isEditable: boolean;
}

const AddEquipmentForm = ({ form, isEditable }: IAddEquipmentFormProps) => {
  const values = form.getValues();
  return (
    <>
      <Form layout="vertical">
        <Col>
          <InputField
            label="Slug"
            name="slug"
            required
            inputProps={{
              placeholder: 'Slug : eg(equipment-slug)',
            }}
            form={form}
            disabled={isEditable}
            style={{ marginBottom: '4px' }}
          />

          <SlugNote />
        </Col>

        <InputField
          label="Sequence No."
          name="slno"
          required
          inputProps={{
            placeholder: 'Sequence number',
          }}
          form={form}
        />

        <InputField
          label="Product EN"
          name="product.en"
          required
          inputProps={{
            placeholder: 'Product (English)',
          }}
          form={form}
        />

        <InputField
          label="Product ES"
          name="product.es"
          required
          inputProps={{
            placeholder: 'Product (Spanish)',
          }}
          form={form}
        />

        <InputField
          label="Product IT"
          name="product.it"
          required
          inputProps={{
            placeholder: 'Product (Italian)',
          }}
          form={form}
        />

        <InputField
          label="Product DE"
          name="product.de"
          required
          inputProps={{
            placeholder: 'Product (German)',
          }}
          form={form}
        />

        <InputField
          label="Product PT"
          name="product.pt"
          required
          inputProps={{
            placeholder: 'Product (Portuguese)',
          }}
          form={form}
        />

        <InputField
          label="Product ZH"
          name="product.zh"
          required
          inputProps={{
            placeholder: 'Product (Mandarin)',
          }}
          form={form}
        />

        <InputField
          label="Website"
          name="website"
          required
          inputProps={{
            placeholder: 'Website',
          }}
          form={form}
        />
        <FileUploadButton
          form={form}
          defaultFileList={
            isEditable
              ? [
                  {
                    uid: '-1',
                    name: `${values?.product?.en}.png`,
                    status: 'done',
                    url: values?.imageUrl,
                  },
                ]
              : []
          }
          label="Equipment Image"
          required
          name="imageUrl"
          buttonLabel="Upload Image"
        />
      </Form>
    </>
  );
};

export default AddEquipmentForm;
