import { getCountryByCode } from '../../constants/countries';
import { TUser } from '../../services/users-service';

const columns = [
  {
    title: 'SNo.',
    render: (value: string, record: TUser, index: number) => index + 1,
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: TUser) => <>{record?.fullName}</>,
    width: 250,
  },
  {
    title: 'Role',
    render: (value: string, record: TUser) => <>{record?.role}</>,
    width: 250,
  },
  {
    title: 'Member Type',
    render: (value: string, record: TUser) => <>{record?.memberType}</>,
    width: 250,
  },
  {
    title: 'Email',
    render: (value: string, record: TUser) => <>{record?.email}</>,
    width: 250,
  },
  {
    title: 'Phone',
    render: (value: string, record: TUser) => <>{record?.mobileNumber}</>,
    width: 250,
  },
  {
    title: 'Country',
    render: (value: string, record: TUser) => (
      <>
        {record.locations?.[0]?.country
          ? getCountryByCode(record.locations?.[0]?.country).name
          : ''}
      </>
    ),
    width: 250,
  },
  {
    title: 'School Teaching At',
    render: (value: string, record: TUser) => (
      <>{record?.teachingInstituteOrQualification}</>
    ),
    width: 250,
  },
  {
    title: 'Teaching Years',
    render: (value: string, record: TUser) => <>{record?.teachingYears}</>,
    width: 250,
  },

  {
    title: 'Dance Type',
    render: (value: string, record: TUser) => <>{record?.teachingDanceType}</>,
    width: 250,
  },
  {
    title: 'WeChat Id',
    render: (value: string, record: TUser) => <>{record?.wechatId}</>,
    width: 250,
  },
  {
    title: 'Why PBT Workshop',
    render: (value: string, record: TUser) => <>{record?.whyPbtWorkshop}</>,
    width: 250,
  },
];

export default columns;
