import * as yup from 'yup';

export default yup.object().shape({
  address1: yup.string().required('Required'),
  address2: yup.string(),
  city: yup.string().required('Required'),
  region: yup.string().required('Required'),
  country: yup.string().required('Required'),
  zip: yup.string().required('Zip Code Required'),
});
