/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, FC, useRef, useState } from 'react';
import { Button, Drawer, message } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import AddProductForm from '../form';
import { createGiftVoucherProduct } from '../../../../services/gift-voucher';

interface IAddProductProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const AddProduct: FC<IAddProductProps> = ({
  children,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (data: any) => {
    setRequesting(true);
    try {
      const payload = {
        name: data?.name,
        months: Number(data?.months),
        memberType: data?.memberType,
        defaultCurrency: data?.defaultCurrency,
        defaultPrice: Number(data?.defaultPrice),
        discountInPercent: Number(data?.discountInPercent),
        imageUrl: data?.imageUrl,
        currencyOptionsArray: data?.currencyOptionsArray?.map(
          (currency: string) => {
            return {
              currency: currency,
              unit_amount: Number(
                data?.currencyOptions?.[currency]?.unit_amount
              ),
            };
          }
        ),
      };

      await createGiftVoucherProduct(payload);
      message.success('Successfull');

      handleDrawerVisibility();
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (e: any) {
      message.success(e?.MESSAGE_KEY || e.message);
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Add Gift Voucher Product"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddProductForm isEditable={false} form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddProduct;
