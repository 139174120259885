import { Col, Form } from 'antd';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { MuscleDetail, MuscleCategoryDetail } from '../../../types/muscle';
import { getMuscleCategories } from '../../../services/muscle-service';
import { InputField, SelectField, FileUploadButton } from '../../common/form';
import SlugNote from '../../common/slug-note';

export type MuscResArr = {
  value: string;
  label: string;
};
interface IMuscleFormProps {
  form: UseFormReturn<MuscleDetail, object>;
  isEditable: boolean;
}
const MuscleForm = ({ form, isEditable }: IMuscleFormProps) => {
  const [categories, setCategories] = useState<MuscResArr[]>();

  const getMusclesList = async () => {
    const response = await getMuscleCategories();
    const responseArr: MuscResArr[] = [];
    response?.data?.data.map((category: MuscleCategoryDetail) => {
      return responseArr.push({
        value: category?._id,
        label: category?.name?.en,
      });
    });
    setCategories(responseArr);
  };
  const values = form.getValues();
  useEffect(() => {
    getMusclesList();
  }, []);
  return (
    <>
      <Form layout="vertical">
        <Col>
          <InputField
            label="Slug"
            name="slug"
            required
            inputProps={{
              placeholder: 'Slug : eg( upper-arm)',
              disabled: isEditable === true,
            }}
            form={form}
            style={{ marginBottom: '4px' }}
          />
          <SlugNote />
        </Col>
        <SelectField
          label="Muscle Category"
          required
          name="category"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: categories,
            placeholder: 'Select Muscle Category',
            disabled: isEditable === true,
            onChange: (v) => form.setValue('category', v),
          }}
        />
        <InputField
          label="Muscle Name (EN)"
          name="muscleName.en"
          required
          inputProps={{
            placeholder: 'Muscle Name (EN)',
          }}
          form={form}
        />
        <InputField
          label="Muscle Name (ES)"
          name="muscleName.es"
          required
          inputProps={{
            placeholder: 'Muscle Name (ES)',
          }}
          form={form}
        />
        <InputField
          label="Muscle Name (IT)"
          name="muscleName.it"
          required
          inputProps={{
            placeholder: 'Muscle Name (IT)',
          }}
          form={form}
        />
        <InputField
          label="Muscle Name (DE)"
          name="muscleName.de"
          required
          inputProps={{
            placeholder: 'Muscle Name (DE)',
          }}
          form={form}
        />
        <InputField
          label="Muscle Name (PT)"
          name="muscleName.pt"
          required
          inputProps={{
            placeholder: 'Muscle Name (PT)',
          }}
          form={form}
        />
        <InputField
          label="Muscle Name (ZH)"
          name="muscleName.zh"
          required
          inputProps={{
            placeholder: 'Muscle Name (ZH)',
          }}
          form={form}
        />
        <>
          <FileUploadButton
            form={form}
            triggerValidate={() =>
              form.trigger(['pic1', 'pic2', 'pic3', 'pic4'])
            }
            defaultFileList={
              isEditable
                ? [
                    {
                      uid: '-1',
                      name: `${values.muscleName.en}.png`,
                      status: 'done',
                      url: values.url1 as string,
                    },
                  ]
                : []
            }
            label="Muscle file1"
            name="pic1"
            buttonLabel="Click to Upload Pic"
          />
          <FileUploadButton
            form={form}
            triggerValidate={() =>
              form.trigger(['pic1', 'pic2', 'pic3', 'pic4'])
            }
            defaultFileList={
              isEditable
                ? [
                    {
                      uid: '-1',
                      name: `${values.muscleName.en}.png`,
                      status: 'done',
                      url: values.url2 as string,
                    },
                  ]
                : []
            }
            label="Muscle file2"
            name="pic2"
            buttonLabel="Click to Upload Pic"
          />
          <FileUploadButton
            form={form}
            triggerValidate={() =>
              form.trigger(['pic1', 'pic2', 'pic3', 'pic4'])
            }
            defaultFileList={
              isEditable
                ? [
                    {
                      uid: '-1',
                      name: `${values.muscleName.en}.png`,
                      status: 'done',
                      url: values.url3 as string,
                    },
                  ]
                : []
            }
            label="Muscle file3"
            name="pic3"
            buttonLabel="Click to Upload Pic"
          />
          <FileUploadButton
            form={form}
            triggerValidate={() =>
              form.trigger(['pic1', 'pic2', 'pic3', 'pic4'])
            }
            defaultFileList={
              isEditable
                ? [
                    {
                      uid: '-1',
                      name: `${values.muscleName.en}.png`,
                      status: 'done',
                      url: values.url4 as string,
                    },
                  ]
                : []
            }
            label="Muscle file4"
            name="pic4"
            buttonLabel="Click to Upload Pic"
          />
        </>
      </Form>
    </>
  );
};

export default MuscleForm;
