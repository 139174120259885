import { Tag } from 'antd';

import { NOT_AVAILABLE } from '../../constants/common';
import { TUser } from '../../services/users-service';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: TUser) => record?.fullName || NOT_AVAILABLE,
    width: 200,
  },
  {
    title: 'Email',
    render: (value: string, record: TUser) => record?.email || NOT_AVAILABLE,
    width: 200,
  },
  {
    title: 'Allowed Sections',
    render: (value: string, record: TUser) => {
      return record?.allowedSections && record?.allowedSections?.length
        ? record?.allowedSections?.flatMap((section) => {
            return section?.subSections?.map((subSection) => {
              return (
                <Tag style={{ marginBottom: '8px' }}>{subSection?.label}</Tag>
              );
            });
          })
        : NOT_AVAILABLE;
    },
    width: 350,
  },
];

export default columns;
