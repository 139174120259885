export const flagListItemType = {
  text: 'text',
  domain: 'domain',
  email: 'email',
};

export enum flagListItemTypeEnum {
  text = 'text',
  domain = 'domain',
  email = 'email',
}

export interface IFlagList {
  _id: string;
  type: flagListItemTypeEnum;
  value: string;
  createdAt: Date;
  createdBy: string;
  reason: string;
  isDeleted: boolean;
}

export interface IFlagListFormField {
  type: flagListItemTypeEnum;
  value: string;
  reason: string;
}
