import { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { CheckCircleTwoTone } from '@ant-design/icons';

import { Workshop } from '../../../types/workshop';
import { CLIENT_FRONTEND_URL } from '../../../constants/api';

interface IViewWorkshopProps {
  workshop?: Workshop;
}

const ViewWorkshop: FC<IViewWorkshopProps> = ({ workshop }) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setOpen(!open);
  }, []);

  const handlePopVisibility = () => {
    setOpen(!open);
    navigate('/workshops');
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={handlePopVisibility}
        width={400}
        destroyOnClose
        footer={false}
      >
        <div
          className=""
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            fontSize: '1.2rem',
          }}
        >
          <span>Workshop Launched</span>
          <CheckCircleTwoTone style={{ fontSize: '3rem' }} />
          <Link
            to={{
              pathname: `${CLIENT_FRONTEND_URL}/workshops/${workshop?.techniqueSlug}/${workshop?.slug}`,
            }}
          >
            {workshop?.title}
            <span> </span>
            {workshop?.level && <small> L{workshop?.level}</small>}
          </Link>
          <Button type="primary">
            <Link
              to={{
                pathname: `${CLIENT_FRONTEND_URL}/workshops/${workshop?.techniqueSlug}/${workshop?.slug}`,
              }}
              target="_blank"
            >
              View Workshop
            </Link>
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ViewWorkshop;
