/* eslint-disable @typescript-eslint/no-explicit-any */

import { message } from 'antd';

import fetchClient from '../../utils/fetch-client';
import { S3_URL } from '../../constants/api';

//handling image upload from editor----
function uploadAdapter(loader: any) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        loader.file.then((file: File) => {
          const fileType = file.type;
          const fileName = file.name;
          const fileDetails = {
            fileName,
            fileType,
          };

          fetchClient
            .post(S3_URL.PRESIGNED_URL, {
              fileDetails,
            })
            .then((response) => {
              const returnData = response.data.data;
              const signedRequest = returnData.signedUrl;
              const key = returnData.key;
              const API_URL = 'https://pbt-website-images.s3.amazonaws.com';
              fetchClient
                .put(signedRequest, file)
                .then((result) => {
                  message.success(`${fileName} Successfully Uploaded!`);
                  resolve({
                    default: `${API_URL}/${key}`,
                  });
                })

                .catch((error) => {
                  // onError(error);
                  reject(error);
                });
            })
            .catch((error) => {
              alert(JSON.stringify(error));
            });
        });
      });
    },
  };
}

function uploadPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return uploadAdapter(loader);
  };
}

export default uploadPlugin;
