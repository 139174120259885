import { FC, MouseEventHandler, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import { PlanSimplified } from '../../../../../constants/stripe';
import { SelectField } from '../../../../common/form';
import { getUserPlans } from '../../../../../services/users-service';
import { convertStripeAmount } from '../../../../../utils/common';

export type TChangeBillingCycle = {
  billingCycle?: string;
};

export const getValidationSchema = () => {
  return yup.object().shape({
    billingCycle: yup.string().required('Billing Cycle Required'),
  });
};

export const defaultValues: TChangeBillingCycle = {
  billingCycle: '',
};

interface IChangeBillingCycleFormProps {
  form: UseFormReturn<TChangeBillingCycle, object>;
  onSubmit: MouseEventHandler<HTMLElement>;
  loading: boolean;
  currency?: string;
  memberType?: string;
}
const ChangeBillingCycleForm: FC<IChangeBillingCycleFormProps> = ({
  form,
  onSubmit,
  loading,
  currency,
  memberType,
}) => {
  const [requesting, setRequesting] = useState(true);
  const [planData, setPlanData] = useState<PlanSimplified[]>([]);

  const getPlans = async () => {
    setRequesting(true);
    const response = await getUserPlans(currency);
    setPlanData(response.data?.data?.plans || []);
    setRequesting(false);
  };
  useEffect(() => {
    getPlans();
  }, []);
  return (
    <Form layout="vertical">
      <Row>
        <Col span={24}>
          <SelectField
            label={'Select Billing Cycle'}
            name="billingCycle"
            required
            form={form}
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: [
                ...planData
                  .filter((plans) => {
                    return form.getValues()?.billingCycle
                      ? plans.name.includes(
                          `${form.getValues()?.billingCycle?.split(' ')[0]}`
                        )
                      : memberType
                      ? plans.name.toLowerCase().includes(memberType as string)
                      : true;
                  })
                  .map((item) => {
                    return {
                      value: `${item.id}`,
                      label: `${item.name} ( ${
                        item.currency
                      } ${convertStripeAmount(item.amount, item.currency)} )`,
                    };
                  }),
              ],
              placeholder: 'Select Billing cycle',
              onChange: (v) => form.setValue('billingCycle', v),
              loading: requesting,
            }}
          />
        </Col>
      </Row>
      <Form.Item className="pbt-pt">
        <Button type="primary" loading={loading} block onClick={onSubmit}>
          {'SUBMIT'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangeBillingCycleForm;
