import * as yup from 'yup';

export default yup.object().shape({
  serialno: yup.number().required('Serial Number is required'),
  category: yup.object().shape({
    en: yup.string().required('Please enter Category name in english'),
    pt: yup.string().required('Please enter Category name in portugese'),
    it: yup.string().required('Please enter Category name in italian'),
    es: yup.string().required('Please enter Category name in spanish'),
    de: yup.string().required('Please enter Category name in german'),
    zh: yup.string().required('Please enter Category name in mandrin'),
  }),
});
