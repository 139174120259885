/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Button, Form, message, Upload, UploadFile, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';

import fetchClient from '../../utils/fetch-client';
import { getUrlToUploadUserProfilePic } from '../../services/users-service';

interface FileUploadButtonProps {
  label?: string;
  required?: boolean;
  name: string;
  form: UseFormReturn<any, any>;
  buttonLabel: string;
  disabled?: boolean;
  defaultFileList?: Array<UploadFile>;
  allowedFileTypes?: string[];
  triggerValidate?: () => Promise<boolean>;
  className?: string;
}

interface upload {
  signedUrl: string;
}

const ProfilePicUploadButton: FC<FileUploadButtonProps> = ({
  form: {
    formState: { errors },
    control,
    setValue,
  },
  label,
  required,
  name,
  disabled = false,
  buttonLabel,
  defaultFileList = [],
  allowedFileTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
  ],
  triggerValidate,
  className,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const errorMessages = _.get(errors, name);
  const hasError = !!(errors && errorMessages);

  const props: UploadProps = {
    beforeUpload: (file) => {
      const isMatches = allowedFileTypes.includes(file.type);

      if (!isMatches) {
        message.error(`${file.name} is not allowed`);
      }
      return isMatches || Upload.LIST_IGNORE;
    },
  };

  function resizeImage(file: File, wantedWidth: number): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const imgEl: HTMLImageElement = document.createElement('img');
      imgEl.onload = () => {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

        canvas.width = wantedWidth;
        canvas.height = wantedWidth;

        if (!ctx) {
          reject(new Error('Failed to get canvas context.'));
          return;
        }

        ctx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Failed to create blob from canvas.'));
            return;
          }

          const resizedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: Date.now(),
          });
          resolve(resizedFile);
        }, file.type);
      };

      imgEl.src = URL.createObjectURL(file);
    });
  }

  const customRequest = async ({
    file,
    onError,
    onSuccess,
    onProgress,
  }: any) => {
    const fileType = file.type;
    const fileName = file.name;
    const fileDetails = {
      fileName,
      fileType,
    };

    getUrlToUploadUserProfilePic(fileDetails)
      .then(async (response) => {
        const uploadUrls = [...response.data.data];
        const imageUrls: File[] = [file];

        await Promise.all(
          [100, 35].map(async (size) => {
            const resizedImage = await resizeImage(file, size);
            imageUrls.push(resizedImage);
          })
        );
        const pic = {
          pic: uploadUrls[0].key,
          pic100: uploadUrls[1].key,
          pic35: uploadUrls[2].key,
        };

        const options = {
          onUploadProgress: (event: ProgressEvent) => {
            const { loaded, total } = event;
            onProgress(
              {
                percent: Math.round((loaded / total) * 100),
              },
              file
            );
          },
          headers: {
            'Content-Type': fileType,
          },
        };

        await Promise.all(
          uploadUrls.map(async (uploadUrl: upload, index: number) => {
            await fetchClient.put(
              uploadUrl.signedUrl,
              imageUrls[index],
              options
            );
          })
        )
          .then((result) => {
            setValue(name, pic);
            if (triggerValidate) {
              triggerValidate();
            }
            setLoading(false);
            onSuccess(result, file);
            message.success(`${fileName} Successfully Uploaded!`);
          })
          .catch((error) => {
            onError(error);
            alert('ERROR ' + JSON.stringify(error));
          });
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  const handleFileRemove = () => {
    setValue(name, undefined);
    if (triggerValidate) {
      triggerValidate();
    }
  };

  return (
    <Form.Item
      label={label}
      help={errorMessages?.message}
      validateStatus={hasError ? 'error' : 'success'}
      required={required}
      className={className || ''}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Upload
            {...props}
            disabled={loading}
            customRequest={customRequest}
            defaultFileList={defaultFileList}
            listType="picture"
            maxCount={1}
            onRemove={handleFileRemove}
          >
            <Button loading={loading} disabled={disabled}>
              <UploadOutlined /> {buttonLabel}
            </Button>
          </Upload>
        )}
      />
    </Form.Item>
  );
};

export default ProfilePicUploadButton;
