/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { Form, Checkbox } from 'antd';
import { Controller, UseFormReturn } from 'react-hook-form';
import _ from 'lodash';

interface CheckboxFieldProps {
  label?: string;
  required?: boolean;
  name: string;
  defaultChecked: boolean;
  form: UseFormReturn<any, any>;
  message?: string;
  style?: any;
}

const CheckboxField: FC<CheckboxFieldProps> = ({
  form: {
    formState: { errors },
    control,
  },
  label,
  required,
  defaultChecked,
  name,
  message,
  style,
}) => {
  const errorMessages = _.get(errors, name);
  const hasError = !!(errors && errorMessages);
  return (
    <Form.Item
      label={label}
      required={required}
      style={style}
      help={errorMessages?.message}
      validateStatus={hasError ? 'error' : 'success'}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }): React.ReactElement => (
          <Checkbox
            style={{ width: '100%', paddingLeft: '10px' }}
            defaultChecked={defaultChecked}
            {...field}
          >
            {message || ''}
          </Checkbox>
        )}
      />
    </Form.Item>
  );
};

export default CheckboxField;
