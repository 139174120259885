export const workshopTechniquesMarketingStrategy = [
  {
    name: 'pbt',
    isMultiLevel: true,
    maxLevel: 3,
    workshopAdmin: {
      expenseConsentRequired: true,
      expenseConsent: [
        // In this index acts as level of workshop
        {
          percent: 12,
          defaultAmount: 0,
          currency: 'workshopCurrency',
          question:
            'Do you agree to spend 12% of the gross revenue on marketing expenses?',
        },
        {
          percent: 0,
          defaultAmount: 200,
          currency: 'usd',
          question:
            'Do you agree to spend a USD $200 or equivalent in local currency in advertising?',
        },
        {
          percent: 0,
          defaultAmount: 200,
          currency: 'usd',
          question:
            'Do you agree to spend a USD $200 or equivalent in local currency in advertising?',
        },
      ],
    },
  },
  {
    name: 'pct',
    isMultiLevel: false,
    maxLevel: 1,
    workshopAdmin: {
      expenseConsentRequired: true,
      expenseConsent: [
        {
          percent: 12,
          defaultAmount: 0,
          currency: 'workshopCurrency',
          question:
            'Do you agree to spend 12% of the gross revenue on marketing expenses?',
        },
      ],
    },
  },
  {
    name: 'pbt-chinese',
    isMultiLevel: false,
    maxLevel: 1,
    workshopAdmin: {
      expenseConsentRequired: false,
      expenseConsent: [
        {
          percent: 0,
          defaultAmount: 0,
          currency: '',
          question:
            'Do you agree to spend 0% of the gross revenue on marketing expenses?',
        },
      ],
    },
  },
];
