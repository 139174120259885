import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { IBlockedTeacherContactEmails } from '../../types/blocked-teacher-contact-emails';
// import CKEditorWrapper from '../../components/ckeditor';
import ReactQuillField from '../../components/common/form/react-quill-fields';
import { reviewBlockedEmail } from '../../services/blocked-teacher-contat-emails';

interface IViewEmailProps {
  successCallback?: () => void;
  errorCallback?: (e: unknown) => void;
  children: FC<{
    onClick: () => void;
  }>;
  payload: IBlockedTeacherContactEmails;
}

const ViewEmail: FC<IViewEmailProps> = ({
  children,
  successCallback,
  errorCallback,
  payload,
}) => {
  const refChildren = useRef(children);
  const [requestingRejected, setRequestingRejected] = useState(false);
  const [requestingApproved, setRequestingApproved] = useState(false);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm({});

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (value: boolean) => {
    if (value) {
      setRequestingApproved(true);
    } else {
      setRequestingRejected(true);
    }
    try {
      await reviewBlockedEmail(value, payload?._id);
      setDrawerVisible(false);
      message.success((value ? 'Approved' : 'Rejected') + ' successfully');
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      if (errorCallback) errorCallback(err);
    } finally {
      setRequestingApproved(false);
      setRequestingRejected(false);
    }
  };

  useEffect(() => {
    form.reset({ message: payload?.body });
  }, [payload]);

  return (
    <>
      <Drawer
        title="Review Email"
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="default"
              loading={requestingRejected}
              onClick={() => onSubmitHandler(false)}
              style={{ marginBottom: '10px' }}
            >
              Reject
            </Button>
            <Button
              block
              type="primary"
              loading={requestingApproved}
              onClick={() => onSubmitHandler(true)}
            >
              Approve
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <p>
            <strong>From :</strong> {payload?.replyTo}{' '}
          </p>
          <p>
            <strong>To :</strong> {payload?.recipientEmail}{' '}
          </p>
          <p>
            <strong>Message :</strong>
          </p>
          <ReactQuillField
            readOnly
            name="message"
            form={form}
            showToolBar={false}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default ViewEmail;
