import moment from 'moment';

import { NOT_AVAILABLE } from '../../constants/common';
import { TUser } from '../../services/users-service';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 200,
  },
  {
    title: 'Email',
    render: (value: string, record: TUser) => record?.email || NOT_AVAILABLE,
    key: 'email',
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: TUser) => record?.fullName || NOT_AVAILABLE,
    key: 'name',
    width: 200,
  },
  {
    title: 'Role',
    render: (value: string, record: TUser) => record?.role || NOT_AVAILABLE,
    key: 'role',
    width: 200,
  },
  {
    title: 'Status',
    render: (value: string, record: TUser) =>
      record?.subscription?.status
        ? record?.subscription?.status
        : !record?.subscription?.status && record.isDeleted
        ? 'Deleted'
        : NOT_AVAILABLE,
    key: 'status',
    width: 200,
  },
  {
    title: 'SignUp',
    render: (value: moment.MomentInput, record: TUser) =>
      moment(record?.createdAt).format('MMMM Do YYYY, h:mm a') || NOT_AVAILABLE,

    key: 'signup',
    width: 400,
  },
];

export default columns;
