/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Drawer, message, Row } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ExerciseForm from '../form';
import validationSchema from '../validation-schema';
import { updateExercise } from '../../../services/exercises';
import { ExerciseVideo, VideoFormData } from '../../../types/exercises';
import { handleError } from '../../../utils/common';

interface IEditExerciseProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  payload: ExerciseVideo;
  exerciseId: string;
  isPublished: boolean;
  isClassVideo: boolean;
  isAddingTranslation?: boolean;
  videoFormData: VideoFormData;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditExercise: FC<IEditExerciseProps> = ({
  children,
  payload,
  exerciseId,
  videoFormData,
  isPublished,
  isClassVideo,
  isAddingTranslation,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<ExerciseVideo>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: ExerciseVideo) => {
    if (values?.isPublished) {
      setRequesting(true);
    } else {
      setSaving(true);
    }
    try {
      await updateExercise(values, exerciseId);
      setDrawerVisible(false);
      values?.isPublished
        ? message.success('Exercise updated successfully')
        : message.success('Exercise updated as draft');
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
      setSaving(false);
    }
  };

  useEffect(() => {
    const updatedPayload = {
      ...payload,
      difficultyLevel: payload.difficultyLevel || 0,
      isClassVideo,
      haveMediaId: !!payload?.mediaId,
      categories: payload?.categories?.map((item) => JSON.stringify(item)),
      equipments: payload?.equipments?.map((item: any) =>
        JSON.stringify({
          name: item?.name,
          url: item?.url,
        })
      ),
    };
    form.reset(updatedPayload);

    return () => {
      form.reset({});
    };
  }, [payload]);

  return (
    <>
      <Drawer
        title={
          isAddingTranslation
            ? 'Adding Translation'
            : isClassVideo
            ? 'Update Class Video'
            : 'Update Exercise'
        }
        placement="bottom"
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width="100%"
        height="100%"
        footer={
          <>
            <Row>
              {!isPublished && (
                <Col span={12} style={{ padding: '10px' }}>
                  <Button
                    danger
                    block
                    type="primary"
                    loading={saving}
                    onClick={(e) => {
                      form.setValue('isPublished', false);
                      form.handleSubmit(onSubmitHandler)(e);
                    }}
                  >
                    Save As Draft
                  </Button>
                </Col>
              )}
              <Col span={!isPublished ? 12 : 24} style={{ padding: '10px' }}>
                <Button
                  block
                  type="primary"
                  loading={requesting}
                  onClick={(e) => {
                    form.setValue('isPublished', true);
                    form.handleSubmit(onSubmitHandler)(e);
                  }}
                >
                  {!isPublished ? 'Publish' : 'Update'}
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <ExerciseForm
            videoFormData={videoFormData}
            isEditable={true}
            form={form}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditExercise;
