import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

import { getExerciseEquipments } from '../../services/exercise-equipment-service';
import AddExerciseEquipment from '../../components/exercise-equipments/add-exercise-equipment';
import EditExerciseEquipment from '../../components/exercise-equipments/edit-exercise-equipment';
import DeleteExerciseEquipment from '../../components/exercise-equipments/delete-exercise-equipment';
import { ExerciseEquipment } from '../../types/exercise-equipment';

import columns from './columns';

const ExerciseEquipments = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadExerciseEquipments, setReloadExerciseEquipments] =
    useState(false);
  const [exerciseEquipments, setExerciseEquipments] = useState<
    ExerciseEquipment[]
  >([]);

  const loadExerciseEquipments = async () => {
    setRequesting(true);
    try {
      const response = await getExerciseEquipments();
      setExerciseEquipments(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadExerciseEquipments(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadExerciseEquipments) {
      loadExerciseEquipments();
    }
  }, [reloadExerciseEquipments, requesting]);

  useEffect(() => {
    loadExerciseEquipments();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      render: (value: string, record: ExerciseEquipment) => {
        return (
          <Space size="large">
            <EditExerciseEquipment
              equipmentId={record._id as string}
              payload={record}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditExerciseEquipment>
            <DeleteExerciseEquipment
              equipmentId={record._id as string}
              successCallback={() => setReloadExerciseEquipments(true)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteExerciseEquipment>
          </Space>
        );
      },
      key: 'actions',
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={exerciseEquipments}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Exercise Equipment</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadExerciseEquipments(true)}
                  />
                </Tooltip>
                <AddExerciseEquipment
                  successCallback={() => setReloadExerciseEquipments(true)}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Exercise Equipment
                    </Button>
                  )}
                </AddExerciseEquipment>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};

export default ExerciseEquipments;
