import * as yup from 'yup';

export default yup.object().shape({
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
  questions: yup
    .array()
    .of(
      yup.object().shape({
        en: yup
          .string()
          .required('Please input assessment question in english'),
        pt: yup
          .string()
          .required('Please input assessment question in portugese'),
        it: yup
          .string()
          .required('Please input assessment question in italian'),
        es: yup
          .string()
          .required('Please input assessment question in spanish'),
        de: yup.string().required('Please input assessment question in german'),
        zh: yup
          .string()
          .required('Please input assessment question in mandrin'),
      })
    )
    .required('Please input assessment question')
    .min(1, 'Minimum one field is required'),
});
