export type TQuestionLocale = {
  en?: string;
};
export type TQuestion = {
  _id: string;
  slno: string;
  maxmarks?: number;
  question?: TQuestionLocale;
};

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 70,
  },
  {
    title: 'Question',
    render: (value: string, record: TQuestion) => record.question?.en,
    key: 'question',
    width: 900,
  },
  {
    title: 'Max marks',
    dataIndex: 'maxmarks',
    key: 'maxmarks',
  },
];

export default columns;
