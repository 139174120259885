/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode } from 'react';
import _ from 'lodash';
import { Form, Select, SelectProps } from 'antd';
import { Controller, UseFormReturn } from 'react-hook-form';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';

const { Option } = Select;

interface ISelectFieldProps {
  label?: string;
  required?: boolean;
  name: string;

  defaultValue?: string;
  valueKey?: string;
  labelKey?: string;
  form: UseFormReturn<any, any>;
  selectFieldProps: SelectProps<any, any>;
  mode?: string;
  tooltip?: LabelTooltipType;
  renderValue?: (record: any) => ReactNode;
  alignVertical?: boolean;
}

const SelectField: FC<ISelectFieldProps> = ({
  form: {
    formState: { errors },
    control,
  },
  label,
  required,
  name,
  defaultValue,
  valueKey = 'value',
  labelKey = 'name',
  selectFieldProps,
  tooltip,
  renderValue,
  alignVertical,
}) => {
  const errorMessages = _.get(errors, name);
  const hasError = !!(errors && errorMessages);
  const { options, ...restSelectFieldProps } = selectFieldProps || {};
  return (
    <Form.Item
      label={label}
      help={errorMessages?.message}
      validateStatus={hasError ? 'error' : 'success'}
      required={required}
      tooltip={tooltip}
      labelCol={alignVertical ? { span: 24 } : undefined}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <Select
              {...field}
              {...restSelectFieldProps}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.children as unknown as string)
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {(options || []).map((o: any, i: number) => {
                const value = _.get(o, valueKey);
                const label = _.get(o, labelKey);
                return (
                  <Option
                    key={`${i}#${value}`}
                    value={value}
                    disabled={o?.disabled}
                  >
                    {renderValue ? renderValue(o) : label}
                  </Option>
                );
              })}
            </Select>
          );
        }}
      />
    </Form.Item>
  );
};

export default SelectField;
