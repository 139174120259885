import * as yup from 'yup';

export default yup.object().shape({
  category: yup.string().required('Muscle category is required'),
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
  muscleName: yup.object().shape({
    en: yup.string().required('Please input muscle name in english'),
    pt: yup.string().required('Please input muscle name in portugese'),
    it: yup.string().required('Please input muscle name in italian'),
    es: yup.string().required('Please input muscle name in spanish'),
    de: yup.string().required('Please input muscle name in german'),
    zh: yup.string().required('Please input muscle name in mandrin'),
  }),
  pic1: yup
    .mixed()
    .test(
      'oneOfRequired',
      'Atleast one muscle image is required',
      (item, picTestContext) => {
        return (
          !!picTestContext.parent.pic1 ||
          !!picTestContext.parent.pic2 ||
          !!picTestContext.parent.pic3 ||
          !!picTestContext.parent.pic4
        );
      }
    ),
  pic2: yup
    .mixed()
    .test(
      'oneOfRequired',
      'Atleast one muscle image is required',
      (item, picTestContext) => {
        const test =
          !!picTestContext.parent.pic1 ||
          !!picTestContext.parent.pic2 ||
          !!picTestContext.parent.pic3 ||
          !!picTestContext.parent.pic4;
        console.log('test', test);
        return test;
      }
    ),
  pic3: yup
    .mixed()
    .test(
      'oneOfRequired',
      'Atleast one muscle image is required',
      (item, picTestContext) => {
        return (
          !!picTestContext.parent.pic1 ||
          !!picTestContext.parent.pic2 ||
          !!picTestContext.parent.pic3 ||
          !!picTestContext.parent.pic4
        );
      }
    ),
  pic4: yup
    .mixed()
    .test(
      'oneOfRequired',
      'Atleast one muscle image is required',
      (item, picTestContext) => {
        return (
          !!picTestContext.parent.pic1 ||
          !!picTestContext.parent.pic2 ||
          !!picTestContext.parent.pic3 ||
          !!picTestContext.parent.pic4
        );
      }
    ),
});
