import { Button, Drawer, Spin, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AddBlogForm from '../form';
import validationSchema from '../validation-schema';
import {
  getBlogBySlug,
  updateBlog,
  updateBlogStatus,
} from '../../../services/blog-service';
import { Blog } from '../../../types/blog';
import { BlogCategory } from '../../../types/blog-categories';
import { handleError } from '../../../utils/common';

interface IEditBlogProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  payload: Blog;
  categories: BlogCategory[];
  _id: string;
  slug: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditBlog: FC<IEditBlogProps> = ({
  children,
  payload,
  _id,
  slug,
  categories,
  successCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [requestingEditBlog, setRequestingEditBlog] = useState(false);
  const [requestingStatus, setRequestingStatus] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<Blog>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const loadBlog = async () => {
    setRequestingEditBlog(true);
    try {
      const response = await getBlogBySlug(slug);
      const data = response?.data?.data;
      form.reset(data);
    } catch (e) {
      handleError(e);
    } finally {
      setRequestingEditBlog(false);
    }
  };

  useEffect(() => {
    if (drawerVisible) loadBlog();
  }, [drawerVisible]);

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: Blog) => {
    setRequesting(true);
    try {
      await updateBlog({ ...values }, _id);
      setDrawerVisible(false);
      message.success('Blog Updated successfully');
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  const handleBlogStatus = async (isPublished: boolean) => {
    setRequestingStatus(true);

    try {
      await updateBlogStatus({ isPublished: isPublished }, _id);
      setDrawerVisible(false);
      message.success(
        `Blog ${isPublished ? 'Published' : 'moved to Drafts'} successfully`
      );
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRequestingStatus(false);
    }
  };

  return (
    <>
      <Drawer
        title="Update Blog"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={1200}
        footer={
          <div style={{ display: 'flex', gap: '1rem', marginTop: '0.5rem' }}>
            {payload.isPublished ? (
              <Button
                block
                loading={requestingStatus}
                onClick={() => handleBlogStatus(false)}
              >
                Move to Drafts
              </Button>
            ) : (
              <Button
                block
                type="primary"
                loading={requestingStatus}
                onClick={() => handleBlogStatus(true)}
              >
                Publish
              </Button>
            )}
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </div>
        }
      >
        <Spin spinning={requestingEditBlog}>
          <div style={{ padding: 16 }}>
            <AddBlogForm
              categories={categories}
              category={payload.category}
              isEditable={true}
              form={form}
            />
          </div>
        </Spin>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditBlog;
