import { Col, Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';
import { useMemo, useCallback } from 'react';

import { FileUploadButton, InputField, SelectField } from '../common/form';
import {
  IDealsActionDisplayType,
  IDealsActionType,
  IDealsDiscountedPlans,
  IDealsFormField,
  IDealsTargetAudience,
  IDealsType,
} from '../../types/deals';
import { countrieskeyVlaue } from '../../constants/countries';

interface IDealFormProps {
  form: UseFormReturn<IDealsFormField, object>;
  isEditable: boolean;
}

const getActionTypeOptions = (type: IDealsType | undefined) => [
  { label: IDealsActionType.redirect, value: IDealsActionType.redirect },
  {
    label: IDealsActionType.signup,
    value: IDealsActionType.signup,
    disabled: type === IDealsType.notification_bar,
  },
];

const DealForm = ({ form, isEditable }: IDealFormProps) => {
  const type = form.watch('type');
  const actionType = form.watch('action.type');

  const actionTypeOptions = useMemo(() => getActionTypeOptions(type), [type]);

  const resetActionFields = useCallback(() => {
    form.setValue('action.actionDisplayType', null);
    form.setValue('action.actionLabel', null);
    form.setValue('action.discount', null);
    form.setValue('action.discountedPlans', null);
    form.setValue('action.url', null);
    form.setValue('action.mailchimpTag', null);
  }, [form]);

  const handleTypeChange = useCallback(
    (e) => {
      form.setValue('type', e);
      form.setValue('action.type', null);
    },
    [form]
  );

  const handleActionTypeChange = useCallback(
    (e) => {
      resetActionFields();
      form.setValue('action.type', e);
    },
    [form, resetActionFields]
  );

  const renderPopupFields = () => (
    <Col>
      <FileUploadButton
        name="bannerUrl"
        label="Banner"
        form={form}
        required
        buttonLabel="Click to Upload"
        defaultFileList={
          isEditable && form.watch('bannerUrl')
            ? [
                {
                  uid: '-1',
                  name: 'banner',
                  status: 'done',
                  url: form.watch('bannerUrl') as string,
                },
              ]
            : []
        }
      />
    </Col>
  );

  const renderNotificationBarFields = () => (
    <Col>
      <InputField
        label="Headline"
        name="headLine"
        required
        inputProps={{ placeholder: 'Headline' }}
        form={form}
      />
    </Col>
  );

  return (
    <Form layout="vertical">
      <InputField
        label="Deal Name"
        name="name"
        required
        inputProps={{ placeholder: 'Deal name' }}
        form={form}
      />

      <SelectField
        label="Deal Type"
        name="type"
        required
        labelKey="label"
        valueKey="value"
        selectFieldProps={{
          placeholder: 'Select Deal Type',
          options: [
            { label: IDealsType.popup, value: IDealsType.popup },
            {
              label: IDealsType.notification_bar,
              value: IDealsType.notification_bar,
            },
          ],
          onChange: handleTypeChange,
        }}
        form={form}
      />

      {type === IDealsType.popup && renderPopupFields()}
      {type === IDealsType.notification_bar && renderNotificationBarFields()}

      <SelectField
        label="Action Type"
        name="action.type"
        required
        labelKey="label"
        valueKey="value"
        selectFieldProps={{
          placeholder: 'Select Action Type',
          options: actionTypeOptions,
          onChange: handleActionTypeChange,
        }}
        form={form}
      />

      {actionType === IDealsActionType.signup && (
        <>
          <InputField
            label="Discount on Signup"
            name="action.discount"
            required
            inputProps={{ placeholder: 'Discount in percent' }}
            form={form}
          />
          <SelectField
            label="Discounted Plans"
            name="action.discountedPlans"
            required
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              placeholder: 'Select Discounted Plans',
              options: [
                {
                  label: IDealsDiscountedPlans.all,
                  value: IDealsDiscountedPlans.all,
                },
                {
                  label: IDealsDiscountedPlans.monthly,
                  value: IDealsDiscountedPlans.monthly,
                },
                {
                  label: IDealsDiscountedPlans.yearly,
                  value: IDealsDiscountedPlans.yearly,
                },
              ],
            }}
            form={form}
          />
          <InputField
            label="MailChimp Tag"
            name="action.mailchimpTag"
            required
            inputProps={{ placeholder: 'MailChimp Tag' }}
            form={form}
          />
        </>
      )}

      {actionType === IDealsActionType.redirect && (
        <>
          <Col>
            <InputField
              label="Redirect URL"
              name="action.url"
              required
              inputProps={{ placeholder: 'Redirect URL' }}
              form={form}
            />
          </Col>
          <Col>
            <InputField
              label="Action Label"
              name="action.actionLabel"
              required
              inputProps={{ placeholder: 'Action Label' }}
              form={form}
            />
          </Col>
          <Col>
            <SelectField
              label="Action Display Type"
              name="action.actionDisplayType"
              required
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                placeholder: 'Action Display Type',
                options: [
                  {
                    label: IDealsActionDisplayType.button,
                    value: IDealsActionDisplayType.button,
                  },
                  {
                    label: IDealsActionDisplayType.link,
                    value: IDealsActionDisplayType.link,
                  },
                ],
              }}
              form={form}
            />
          </Col>
        </>
      )}

      <Col>
        <SelectField
          label="Target Audience"
          name="targetAudience"
          required
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            placeholder: 'Select Target Audience',
            options: [
              { label: 'Members', value: IDealsTargetAudience.members },
              {
                label: 'Non Members',
                value: IDealsTargetAudience['non-members'],
              },
            ],
            mode: 'multiple',
          }}
          form={form}
        />
      </Col>
      <Col>
        <SelectField
          label="Target Countries"
          name="targetCountry"
          required
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            placeholder: 'Select Target Country',
            options: countrieskeyVlaue(),
            mode: 'multiple',
          }}
          form={form}
        />
      </Col>
    </Form>
  );
};

export default DealForm;
