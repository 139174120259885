import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { InputField, FileUploadButton, SelectField } from '../common/form';

interface IAddAssessmentCategoryFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
  isEditable: boolean;
}
const AssessmentCategoryForm = ({
  form,
  isEditable,
}: IAddAssessmentCategoryFormProps) => {
  const values = form.getValues();
  return (
    <>
      <Form layout="vertical">
        <SelectField
          label="Level"
          name="level"
          required
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: [
              { label: 'One', value: 1 },
              { label: 'Two', value: 2 },
              { label: 'Three', value: 3 },
            ],
            placeholder: 'Select level',
          }}
          form={form}
        />
        <InputField
          label="Sequence"
          name="sequence"
          required
          inputProps={{
            placeholder: 'Sequence eg. 1',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Name (EN)"
          name="name.en"
          required
          inputProps={{
            placeholder: 'Name (EN)',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Name (ES)"
          name="name.es"
          required
          inputProps={{
            placeholder: 'Name (ES)',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Name (DE)"
          name="name.de"
          required
          inputProps={{
            placeholder: 'Name (DE)',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Name (IT)"
          name="name.it"
          required
          inputProps={{
            placeholder: 'Name (IT)',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Name (PT)"
          name="name.pt"
          required
          inputProps={{
            placeholder: 'Name (PT)',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Name (ZH)"
          name="name.zh"
          required
          inputProps={{
            placeholder: 'Name (ZH)',
            type: 'text',
          }}
          form={form}
        />

        <FileUploadButton
          form={form}
          defaultFileList={
            isEditable
              ? [
                  {
                    uid: '-1',
                    name: `${values.name?.en}.png`,
                    status: 'done',
                    url: values.logo as string,
                  },
                ]
              : []
          }
          label="Logo"
          required
          name="logo"
          buttonLabel="Click to Upload Pic"
        />
        <br />
        <InputField
          label="Exercise Name (EN)"
          name="exerciseName.en"
          required
          inputProps={{
            placeholder: 'Exercise Name (EN)',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Exercise Name (ES)"
          name="exerciseName.es"
          required
          inputProps={{
            placeholder: 'Exercise Name (ES)',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Exercise Name (DE)"
          name="exerciseName.de"
          required
          inputProps={{
            placeholder: 'Exercise Name (DE)',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Exercise Name (IT)"
          name="exerciseName.it"
          required
          inputProps={{
            placeholder: 'Exercise Name (IT)',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Exercise Name (PT)"
          name="exerciseName.pt"
          required
          inputProps={{
            placeholder: 'Exercise Name (PT)',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Exercise Name (ZH)"
          name="exerciseName.zh"
          required
          inputProps={{
            placeholder: 'Exercise Name (ZH)',
            type: 'text',
          }}
          form={form}
        />
      </Form>
    </>
  );
};

export default AssessmentCategoryForm;
