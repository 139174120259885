import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import moment from 'moment';

import validationSchema from '../validation-schema';
import AddCouponForm from '../form';
import { IWorkshopCoupon, Workshop } from '../../../../types/workshop';
import { handleError } from '../../../../utils/common';
import {
  addWorkshopCoupon,
  getWorkshopsForTransfer,
} from '../../../../services/workshop-service';

interface IAddCouponProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  workshopSlug?: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const AddCoupon: FC<IAddCouponProps> = ({
  children,
  successCallback,
  errorCallback,
  workshopSlug,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [workshops, setWorkshops] = useState<Workshop[]>([]);

  const form = useForm<IWorkshopCoupon>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const handleAddCoupon = async (values: IWorkshopCoupon) => {
    setRequesting(true);
    try {
      await addWorkshopCoupon(workshopSlug, {
        ...values,
        redeemBy: new Date(values.redeemBy).getTime(),
      });
      setDrawerVisible(false);
      message.success('Coupon added successfully');
      form.reset();
      setRequesting(false);
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  const loadWorkshops = async () => {
    setRequesting(true);
    try {
      const response = await getWorkshopsForTransfer();
      setWorkshops(response?.data?.data);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (drawerVisible) loadWorkshops();
  }, [drawerVisible]);

  return (
    <>
      <Drawer
        title="Add Coupon"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={500}
        footer={
          <div style={{ display: 'flex', gap: '1rem', marginTop: '0.5rem' }}>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(handleAddCoupon)}
            >
              Add
            </Button>
          </div>
        }
      >
        <div style={{ padding: 16 }}>
          <AddCouponForm form={form} workshops={workshops} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddCoupon;
