import { SENT_EMAIL_RECORDS } from '../constants/api';
import { SentEmailStatus } from '../types/email-records';
import fetchClient from '../utils/fetch-client';

export const getSentEmailsByRecipientEmail = ({
  userId,
  page,
  limit,
  status = SentEmailStatus.SENT,
}: {
  userId: string;
  page: number;
  limit?: number;
  status?: SentEmailStatus;
}) => {
  return fetchClient.get(
    `${SENT_EMAIL_RECORDS.GET}/${userId}?page=${page || 1}&limit=${
      limit || 10
    }&status=${status}`
  );
};
