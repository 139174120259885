import { FC, useState } from 'react';
import { Button, Card, Descriptions, Modal, Typography, message } from 'antd';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { TUser, deleteLocation } from '../../../../services/users-service';
import { locationLimit, NOT_AVAILABLE } from '../../../../constants/common';

import '../../../../containers/users/style.scss';
import EditLocation from './edit-location';
import AddLocation from './add-location';

interface TLocationField {
  user: TUser;
  successCallback: (e: boolean) => void;
}
export interface ILocationField {
  _id?: string | undefined;
  address1?: string | undefined;
  address2?: string | undefined;
  city?: string | undefined;
  region?: string | undefined;
  country?: string | undefined;
  zip?: number | undefined;
}

const Location: FC<TLocationField> = ({ user, successCallback }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState<string>('');
  const [loadingDelete, setLoadingDelete] = useState(false);

  const deleteLocationHandler = async () => {
    setLoadingDelete(true);
    try {
      const payload = {
        locationId: locationToDelete,
      };
      await deleteLocation(payload, `${user?._id}`);
      message.success('Deleted Successfully');
      setLoadingDelete(false);
      setPreviewVisible((prev) => !prev);
      successCallback(true);
    } catch (error) {
      message.error(error);
      setLoadingDelete(false);
      setPreviewVisible((prev) => !prev);
    }
  };

  const handlePreview = () => {
    setPreviewVisible((prev) => !prev);
  };

  return (
    <>
      <Modal
        title="Remove Location"
        open={previewVisible}
        onCancel={handlePreview}
        footer={[
          <Button key="Cancel" onClick={handlePreview}>
            Cancel
          </Button>,
          <Button
            key="delete"
            type="primary"
            loading={loadingDelete}
            onClick={deleteLocationHandler}
          >
            Remove
          </Button>,
        ]}
      >
        <p>Are You Sure ?</p>
      </Modal>
      <Card title="Locations" className="info-container">
        {user?.locations && user?.locations?.length < locationLimit && (
          <span className="info-heading">
            <AddLocation userId={user?._id} successCallback={successCallback}>
              {({ onClick }) => (
                <PlusOutlined onClick={onClick} style={{ float: 'right' }} />
              )}
            </AddLocation>
          </span>
        )}
        <Descriptions
          layout="vertical"
          column={1}
          labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
        >
          {user.locations?.length ? (
            user?.locations?.map((location, index) => {
              return (
                <Descriptions.Item
                  label={`${location.city?.toUpperCase()}`}
                  className="info-item"
                  key={location._id}
                >
                  {location
                    ? [
                        location?.address1,
                        location?.address2,
                        location?.city,
                        location?.region,
                        location?.country,
                        location?.zip,
                      ]
                        ?.filter(Boolean)
                        ?.join(', ')
                    : NOT_AVAILABLE}
                  <span
                    className="info-heading icon-list"
                    style={{ top: '-15px' }}
                  >
                    <EditLocation
                      userId={user?._id}
                      location={{ ...location }}
                      successCallback={successCallback}
                    >
                      {({ onClick }) => (
                        <>
                          <EditOutlined
                            onClick={onClick}
                            style={{ float: 'right', cursor: 'pointer' }}
                          />
                          <DeleteOutlined
                            onClick={() => {
                              setLocationToDelete(`${location._id}`);
                              handlePreview();
                            }}
                            style={{
                              float: 'right',
                              cursor: 'pointer',
                              color: 'red',
                            }}
                          />
                        </>
                      )}
                    </EditLocation>
                  </span>
                </Descriptions.Item>
              );
            })
          ) : (
            <Typography>No Locations.</Typography>
          )}
        </Descriptions>
      </Card>
    </>
  );
};

export default Location;
