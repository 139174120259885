import { Card, Descriptions, Image } from 'antd';
import { FC, useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';

import { AdminType, TUser } from '../../../../services/users-service';
import { NOT_AVAILABLE } from '../../../../constants/common';
import EditInstructor from '../../../instructors/edit-instructor';
import { getInstructors } from '../../../../services/instructor-service';
import { WorkshopInstructor } from '../../../../types/instructor';
import { handleError } from '../../../../utils/common';

interface TInstructorProfile {
  user: TUser;
  successCallback: (e: boolean) => void;
}

const InstructorProfile: FC<TInstructorProfile> = ({
  user,
  successCallback,
}) => {
  const [instructors, setInstructors] = useState<WorkshopInstructor[]>();

  useEffect(() => {
    const loadInstructors = async () => {
      try {
        const response = await getInstructors();
        setInstructors(response?.data?.data);
      } catch (e) {
        handleError(e);
      }
    };
    loadInstructors();
  }, []);

  return (
    <>
      {
        <Card title="Instructor Profile" className="info-container">
          {user?.role === 'admin' &&
            (user?.adminType === AdminType.workshop ||
              user?.adminType === AdminType.instructor) && (
              <span className="info-heading">
                <EditInstructor
                  instructorId={user?._id || ''}
                  payload={instructors?.find((i) => i?._id === user?._id)}
                  instructors={instructors || []}
                  successCallback={() => successCallback(true)}
                  errorCallback={handleError}
                >
                  {({ onClick }) => <EditOutlined onClick={onClick} />}
                </EditInstructor>
              </span>
            )}
          {user?.role === 'admin' &&
          (user?.adminType === AdminType.workshop ||
            user?.adminType === AdminType.instructor) ? (
            <Descriptions
              layout="vertical"
              column={2}
              labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
            >
              <Descriptions.Item label="DESIGNATION" className="info-item">
                {user?.designation?.en || NOT_AVAILABLE}
              </Descriptions.Item>
              <Descriptions.Item label="CREDENTIALS" className="info-item">
                {user?.credentials?.en || NOT_AVAILABLE}
              </Descriptions.Item>
              <Descriptions.Item
                label="CONENCT ACCOUNT ID"
                className="info-item"
              >
                {user?.stripeConenct || NOT_AVAILABLE}
              </Descriptions.Item>
              <Descriptions.Item
                label="VERIFIED BY STRIPE"
                className="info-item"
              >
                {user?.stripeConnectVerified ? 'YES' : 'NO'}
              </Descriptions.Item>
              <Descriptions.Item label="SIGNATURE" className="info-item">
                <Image src={user?.signature} height={'30px'} />
              </Descriptions.Item>
              <Descriptions.Item
                label="ASSISTANT INSTRUCTOR'S ID"
                className="info-item"
              >
                {user?.assistantInstructor || NOT_AVAILABLE}
              </Descriptions.Item>
              <Descriptions.Item label="LOGIN ALLOWED" className="info-item">
                {user?.isAllowInstructorSignIn ? 'YES' : 'NO'}
              </Descriptions.Item>
              <Descriptions.Item
                label="NEW WORKSHOPS ALLOWED"
                className="info-item"
              >
                {user?.isAllowInstructorCreateEvent ? 'YES' : 'NO'}
              </Descriptions.Item>
              <Descriptions.Item label="PAYOUTS ALLOWED" className="info-item">
                {user?.isAllowInstructorReviewPay ? 'YES' : 'NO'}
              </Descriptions.Item>
              <Descriptions.Item
                label="TECHNIQUES ALLOWED"
                className="info-item"
              >
                {user?.techniquesAllowed?.join(', ')}
              </Descriptions.Item>
              <Descriptions.Item
                label="ADVANCE LAUNCH DAYS"
                className="info-item"
              >
                {user?.advanceLaunchDays || NOT_AVAILABLE}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            'Not A Instructor'
          )}
        </Card>
      }
    </>
  );
};

export default InstructorProfile;
