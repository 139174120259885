import { FC, createElement, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Drawer, Popconfirm, message } from 'antd';

import AssessmentReviewForm from '../form';
import { IAssessmentResponse } from '../../../types/assesment-response';
import { addAssessmentReivew } from '../../../services/assessment-response-service';

interface IAddTAssessmentReivewProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  payload: IAssessmentResponse;
  activeResponseId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const AddAssessmentReivew: FC<IAddTAssessmentReivewProps> = ({
  children,
  payload,
  activeResponseId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requestingApprove, setRequestingApprove] = useState(false);
  const [requestingReject, setRequestingReject] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const handleRejectPopConfirm = () => {
    setOpenReject((prev) => !prev);
  };

  const form = useForm<{ remarks: string }>({
    defaultValues: { remarks: payload?.remarks },
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
    setOpenReject(false);
  };

  const onSubmitHandler = async (values: {
    isApproved: boolean;
    remarks: string;
  }) => {
    if (values?.isApproved) {
      setRequestingApprove(true);
    } else setRequestingReject(true);
    try {
      await addAssessmentReivew({
        ...values,
        assessmentResponseId: payload?._id,
      });
      setDrawerVisible(false);

      message.success('Assessment Reivew added successfully');

      setOpenReject(false);
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequestingApprove(false);
      setRequestingReject(false);
    }
  };

  useEffect(() => {
    if (
      activeResponseId &&
      activeResponseId === payload?._id &&
      drawerVisible !== true
    ) {
      setDrawerVisible(true);
    }
  }, []);

  return (
    <>
      <Drawer
        title="Submit Assessment Reivew"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requestingApprove}
              onClick={form.handleSubmit((v) =>
                onSubmitHandler({ ...v, isApproved: true })
              )}
              style={{ marginBottom: '10px' }}
              disabled={payload?.isReviewed}
            >
              Approve
            </Button>
            <Popconfirm
              title="Are You Sure ?"
              open={openReject}
              onConfirm={form.handleSubmit((v) =>
                onSubmitHandler({ ...v, isApproved: false })
              )}
              onCancel={handleRejectPopConfirm}
            >
              <Button
                block
                type="ghost"
                loading={requestingReject}
                onClick={() => setOpenReject(true)}
                disabled={payload?.isReviewed}
              >
                Reject
              </Button>
            </Popconfirm>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <div>
            {payload?.exercisesResponse?.map((response, index) => {
              return (
                <div key={response?.questionId}>
                  <h2>{response?.exerciseName}</h2>
                  {response?.answers.map((answer, index) => {
                    return (
                      <div key={index} style={{ marginBottom: '20px' }}>
                        <div style={{ fontWeight: 600, marginBottom: '5px' }}>
                          <span>Q.{index + 1}) </span>
                          {answer?.question}
                        </div>
                        <div>
                          <span>Ans. </span>
                          {answer?.answer}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: '30px' }}>
            <span>Exercise Video Uploaded by User : </span>
            {payload?.isResponseSubmitted ? (
              <a href={payload?.userVideoUrl} target="_blank" rel="noreferrer">
                <Button type="primary" style={{ paddingLeft: '10px' }}>
                  Download Video
                </Button>
              </a>
            ) : (
              ' Video is not uploaded'
            )}
          </div>
          <div style={{ marginTop: '30px' }}>
            <AssessmentReviewForm form={form} />
          </div>
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddAssessmentReivew;
