// import { RcFile } from 'antd/lib/upload';
import { Moment } from 'moment';

import { USERS } from '../constants/api';
import fetchClient from '../utils/fetch-client';

export interface UserAward {
  logoUrl: string;
  nameEn: string;
  categoryId: string;
}

export enum Techniques {
  'pbt' = 'pbt',
  'pct' = 'pct',
  'pbt-chinese' = 'pbt-chinese',
  'pgt' = 'pgt',
}

export interface UserCertification {
  code: Techniques;
  name: string;
  isListedInDirectory: boolean;
  level: number;
  certifiedOn?: Date;
  nextRenewalDueOn?: Date;
  certificateUrl?: string;
  revokedOn?: Date;
  renewalDates?: Date[];
}

export enum Language {
  'en' = 'en',
  'es' = 'es',
  'zh' = 'zh',
}

export enum MemberType {
  'student' = 'student',
  'teacher' = 'teacher',
}

export enum UserRole {
  'admin' = 'admin',
  'member' = 'member',
}

export interface FileState {
  base64: string | null;
  filename: string | null;
}

export interface Pause {
  _id: string;
  subscriptionId: string;
  purpose: string;
  behavior: string;
  requestedPauseMonths: number;
  actualPausedDays?: number;
  remainingSubscriptionDaysAtPause: number;
  createdAt: Date;
}

export type TUser = {
  _id?: string;
  email?: string;
  emailVerified?: boolean;
  freeMonthsTaken?: number;
  fullName?: string;
  displayName?: string;
  mobileNumber?: string;
  emergencyContactName?: string;
  emergencyContactNumber?: string;
  profession?: string;
  nameAsOnCertificate?: string;
  slug?: string;
  dob?: Moment;
  website?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  linkedin?: string;
  pic?: string;
  pic100?: string;
  pic35?: string;
  isDeleted?: boolean;
  isSignupComplete?: boolean;
  tosAndPrivacyPolicyAccepted?: boolean;
  tosAndPrivacyPolicyAcceptedIp?: string;
  tosAndPrivacyPolicyAcceptedUa?: string;
  tosAndPrivacyPolicyAcceptedOn?: Date;
  paymentConsentToSAccepted?: boolean;
  paymentConsentToSAcceptedIp?: string;
  paymentConsentToSAcceptedUa?: string;
  paymentConsentToSAcceptedOn?: Date;
  l1Awards?: Array<UserAward>;
  l2Awards?: Array<UserAward>;
  l3Awards?: Array<UserAward>;
  certifications?: Array<UserCertification>;
  language?: Language;
  lastLogin?: string;
  monthsFreeGiven?: string;
  oldStripeCustomerId?: Array<string>;
  referrer?: string;
  stripeCustomerId?: string;
  amountPaidToReferrer?: number;
  role?: UserRole;
  adminType?: AdminType;
  memberType?: MemberType;
  allowedSections?: [
    {
      key: string;
      subSections: [{ key: string; label: string }];
    }
  ];
  designation?: TLocale;
  credentials?: OptLocale;
  multiLangBio?: OptLocale;
  stripeConenct?: string;
  stripeConnectVerified?: boolean;
  signature?: string;
  isAllowInstructorSignIn?: boolean;
  isAllowInstructorReviewPay?: boolean;
  isAllowInstructorCreateEvent?: boolean;
  paymentProcessor?: string;
  stripeAccountId?: string;
  paypalEmailId?: string;
  techniquesAllowed?: string[];
  advanceLaunchDays?: number;
  assistantInstructor?: string[];
  signupCompletedOn?: Date;
  trialConverted?: boolean;
  trialConvertedOn?: Date;
  previousStatus?: Status;

  currentStatus?: Status;

  statusChangedOn?: Date;

  pauses?: Pause[];

  subscription?: {
    id: string;
    status: string;
    pause_collection: {
      behavior: string;
      resumes_at: number;
    };
    current_period_start: number;
    current_period_end: number;
    billing_cycle_anchor: number;
    start_date: number;
    ended_at: string;
    cancel_at: number;
    currency: string;
    customer?: string;
    cancel_at_period_end: boolean;
    items?: {
      data: Array<{
        price: {
          id: string;
          active: boolean;
          unit_amount: number;
          currency: string;
          nickname: string;
          currency_options: {
            [key: string]: {
              unit_amount: number;
            };
          };
          created: string;
          recurring: {
            interval: string;
            interval_count: number;
          };
        };
      }>;
    };
    collection_method: string;
    default_payment_method: string;
    default_source: string;
    discount: {
      id: string;
      checkout_session: string;
      coupon: {
        id: string;
        name: string;
        duration: string;
        percent_off: string;
      };
      promotion_code: {
        id: string;
        code: string;
      };
      end: number;
      start: number;
    };
    latest_invoice: {
      id: string;
      number: string;
      currency: string;
      unit_amount: number;
      account_name: string;
      amount_due: number;
      amount_paid: number;
      amount_remaining: number;
      attempt_count: number;
      attempted: boolean;
      next_payment_attempt: number;
      auto_advance: boolean;
      billing_reason: string;
      charge: string;
      collection_method: string;
      default_payment_method: string;
      description: string;
      due_date: number;
      hosted_invoice_url: string;
      invoice_pdf: string;
      paid_out_of_band: boolean;
      payment_intent: string;
      status: string;
      status_transitions: {
        finalized_at: number;
        marked_uncollectible_at: number;
        paid_at: number;
        voided_at: number;
      };
      discount: {
        id: string;
        checkout_session: string;
        subscription: string;
        coupon: {
          id: string;
          name: string;
          duration: string;
          percent_off: string;
        };
        promotion_code: {
          id: string;
          code: string;
        };
        end: number;
        start: number;
      };
    };

    created?: number;
  };
  locations?: Array<TLocationField>;
  schools?: Array<UserSchool>;
  invoiceName?: string;

  invoiceAddress?: string;

  invoiceCountry?: string;
  invoicePostalCode?: string;

  businessName?: string;

  businessAddress?: string;

  taxIds?: { taxIdName: string; taxIdNumber: string }[];

  createdAt?: Date;

  isUsingDefaultTechniqueShares?: boolean;
  customPaymentShares?: {
    techniqueSlug?: string;
    ownerShare?: number;
    hplShare?: number;
    instructorShare?: number;
  }[];
  teachingInstituteOrQualification?: string;
  teachingYears?: number;
  teachingDanceType?: string;
  wechatId?: string;
  whyPbtWorkshop?: string;
  assignedInstructor?: string[];
  deletedOn?: string;
};

export type Status =
  | 'active'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'trialing'
  | 'unpaid';

export type UserSchool = {
  _id?: string;
  name: string;
  website?: string;
  twitter?: string;
  facebook?: string;
  instagram?: string;
};

export interface TLocale {
  en: string;
  es: string;
  de: string;
  it: string;
  pt: string;
  zh: string;
}

export type OptLocale = {
  en?: string;
  es?: string;
  de?: string;
  it?: string;
  pt?: string;
  zh?: string;
};

export enum AdminType {
  'workshop' = 'workshop',
  // they will teach
  'instructor' = 'instructor',
  'assessment' = 'assessment',
  'general' = 'general',
  'root' = 'root',
}

type TData = {
  _id?: string;
  user: TUser;
};
type TDeleteUserPayload = {
  _id?: string;
  feedback?: string;
  otherReason?: string;
};
type TGetUser = {
  _id?: string | undefined;
};
type TChangeEmailInput = {
  newEmail: string | undefined;
  password: string | undefined;
};

type TChangeBillingCycle = {
  newPlanId?: string;
  userId?: string;
};

type TSendPasswordResetLink = {
  email: string;
  returnUrl: string;
};

type TSendPasswordMail = {
  email: string;
  userId: string;
};

export type TLocationField = {
  _id?: string;
  address1?: string;
  address2?: string;
  city?: string;
  region?: string;
  country?: string;
  zip?: number;
};

export type TGetUrl = { fileName?: string; fileType: string };

type TGetUsers = {
  searchText?: string;
  page?: number;
  limit?: number;
};

export const getUsers = ({ searchText, page, limit }: TGetUsers) => {
  return fetchClient.get(
    `${USERS.GET_USERS}/?searchText=${searchText ?? ''}&page=${
      page ?? ''
    }&limit=${limit ?? ''}`
  );
};
export const updateUser = (data: TData) => {
  return fetchClient.put(`${USERS.UPDATE_USER}/${data._id}`, data.user);
};

export const deleteUser = (payload: TDeleteUserPayload) => {
  return fetchClient.delete(USERS.DELETE_USER + '/' + payload._id);
};

export const getUser = (data: TGetUser) => {
  // return fetchClient.get(USERS.GET_USER);
  return fetchClient.get(`${USERS.GET_USER}/${data._id}`);
};
export const getCurrentUser = () => {
  // return fetchClient.get(USERS.GET_USER);
  return fetchClient.get(`${USERS.GET_USER}`);
};

export const getUserPlans = (currecy?: string) => {
  return fetchClient.get(`${USERS.GET_USER}/plans?currency=${currecy ?? ''}`);
};

export const changeEmail = (payload: TChangeEmailInput) => {
  return fetchClient.put(USERS.CHANGE_EMAIL, payload);
};

export const removeUserProfilePic = (data: TGetUser) => {
  return fetchClient.delete(`${USERS.REMOVE_PROFILE_PIC}/${data._id}`);
};
export const getUrlToUploadUserProfilePic = (data: TGetUrl) => {
  return fetchClient.post(`${USERS.GET_URL_TO_UPlOAD_USER_PROFILE_PIC}`, data);
};

export const sendPasswordResetLink = (payload: TSendPasswordResetLink) => {
  return fetchClient.post(USERS.FORGOT_PASSWORD, payload);
};

export const sendPasswordMail = (payload: TSendPasswordMail) => {
  return fetchClient.post(USERS.SEND_PASSWORD, payload);
};

export const changeBillingCycle = (payload: TChangeBillingCycle) => {
  return fetchClient.post(USERS.CHANGE_SUBSCRIPTION, payload);
};

export const addLocation = (payload: TLocationField, userId: string) => {
  return fetchClient.post(`${USERS.LOCAION}/${userId}`, payload);
};

export const updateLocation = (
  payload: TLocationField,
  userId: string,
  locationId: string
) => {
  return fetchClient.put(`${USERS.LOCAION}/${userId}`, {
    ...payload,
    locationId,
  });
};

export const deleteLocation = (
  payload: { locationId: string },
  userId: string
) => {
  return fetchClient.delete(`${USERS.LOCAION}/${userId}`, {
    data: payload,
  });
};

export const addSchool = (payload: UserSchool, userId: string) => {
  return fetchClient.post(`${USERS.SCHOOL}/${userId}`, payload);
};
export const editSchool = (payload: UserSchool, userId: string) => {
  return fetchClient.put(`${USERS.SCHOOL}/${userId}`, payload);
};
export const removeSchool = (payload: { schoolId: string }, userId: string) => {
  return fetchClient.delete(`${USERS.SCHOOL}/${userId}`, { data: payload });
};

export const updateUserAwards = (userId: string, awards: string[]) => {
  return fetchClient.post(`${USERS.UPDATE_USER}/awards/${userId}`, { awards });
};
export const updateUserCertification = (
  userId: string,
  certifications: {
    code: string;
    level: number;
    nextRenewalDueOn: Date;
  }
) => {
  return fetchClient.put(
    `${USERS.UPDATE_USER}/certifications/${userId}`,
    certifications
  );
};

export const addUserCertification = (
  userId: string,
  certifications: {
    code: string;
    level: number;
    certificationDate: Date;
  }
) => {
  return fetchClient.post(
    `${USERS.UPDATE_USER}/certifications/${userId}`,
    certifications
  );
};

export const deleteUserCertification = (
  userId: string,
  code: string,
  action: string
) => {
  return fetchClient.delete(
    `${USERS.UPDATE_USER}/certifications/${userId}/${code}/${action}`
  );
};

export interface DataFilter {
  countries: string[];
  subscriptionStatus: string[];
  membership: string;
  pbtCertification: boolean;
  pctCertification: boolean;
}

export const getFilteredUsers = (filter: DataFilter) => {
  return fetchClient.post(`${USERS.GET_FILTERED_USERS}/search`, filter);
};

export const updateMemberType = (payload: {
  memberType: MemberType;
  userId: string;
}) => {
  return fetchClient.put(`${USERS.UPDATE_MEMBER_TYPE}/${payload.userId}`, {
    memberType: payload?.memberType,
  });
};
