import { Button, Drawer, message, Spin } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AddSectionsForm from '../form';
import validationSchema from '../validation-schema';
import { SectionDetail } from '../../../../types/sections';
import { updateSection } from '../../../../services/sections-service';
import { TechniqueDetail } from '../../../../types/technique';

interface EditSectionProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  payload: SectionDetail;
  sectionId: string;
  techniques: TechniqueDetail[];
  children: FC<{
    onClick: () => void;
  }>;
}

const EditSection: FC<EditSectionProps> = ({
  children,
  payload,
  sectionId,
  successCallback,
  errorCallback,
  techniques,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<SectionDetail>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  useEffect(() => {
    form.reset({
      name: payload?.name,
      ageGroup: payload?.ageGroup,
      classPlanPic: payload?.classPlanPicUrl,
      tutorialPic: payload?.tutorialPicUrl,
      sequence: payload?.sequence,
      slug: payload?.slug,
      isClassToo: payload?.isClassToo,
      techniqueId: payload?.techniqueId,
    });

    return () => {
      form.reset({});
    };
  }, [payload]);

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };
  const onSubmitHandler = async (values: SectionDetail) => {
    try {
      setRequesting(true);

      await updateSection(values, sectionId);
      setDrawerVisible(false);
      setRequesting(false);
      message.success('Section Updated successfully');
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Spin spinning={requesting}>
      <Drawer
        title="Update Section"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddSectionsForm
            techniques={techniques}
            isEditable={true}
            form={form}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </Spin>
  );
};

export default EditSection;
