import { useForm } from 'react-hook-form';
import { Button, Divider, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { Workshop, WorkshopPhotos } from '../../../types/workshop';
import { updateWorkshopPhotos } from '../../../services/workshop-service';

import WorkshopPhotosForm from './form';
import validationSchema from './validation-schema';
import WorkshopProfile from './workshop-profile';

interface UploadWorkshopPhotosProps {
  workshop?: Workshop & WorkshopPhotos;
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const UploadWorkshopPhotos: FC<UploadWorkshopPhotosProps> = ({
  children,
  workshop,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<WorkshopPhotos>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: WorkshopPhotos) => {
    const { pic1, pic2, pic3, pic4, pic5, pic6 } = values;
    const payload = {
      ...(pic1 && { pic1 }),
      ...(pic2 && { pic2 }),
      ...(pic3 && { pic3 }),
      ...(pic4 && { pic4 }),
      ...(pic5 && { pic5 }),
      ...(pic6 && { pic6 }),
    };
    setRequesting(true);
    try {
      if (workshop?.slug) {
        await updateWorkshopPhotos(payload, workshop?.slug);
      }
      setDrawerVisible(false);
      message.success('Workshop Photos Updated Successfully');
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    form.reset({
      pic1: workshop?.pic1,
      pic2: workshop?.pic2,
      pic3: workshop?.pic3,
      pic4: workshop?.pic4,
      pic5: workshop?.pic5,
      pic6: workshop?.pic6,
    });
  }, [workshop]);

  return (
    <>
      <Drawer
        title="Update Workshop Photos"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={500}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Update
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <WorkshopProfile workshop={workshop} />
          <Divider />
          <WorkshopPhotosForm form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default UploadWorkshopPhotos;
