import { createElement, FC, useRef, useState } from 'react';
import { message, Popconfirm } from 'antd';

import { deleteExerciseEquipment } from '../../../services/exercise-equipment-service';
interface IDeleteExerciseEquipmentProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  equipmentId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const DeleteExerciseEquipment: FC<IDeleteExerciseEquipmentProps> = ({
  children,
  equipmentId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [popVisible, setPopVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const onDelete = async () => {
    setRequesting(true);
    try {
      await deleteExerciseEquipment({ equipmentId });
      message.success('Exercise equipment deleted successfully');
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  const handlePopVisibility = () => {
    setPopVisible(!popVisible);
  };
  return (
    <Popconfirm
      open={popVisible}
      onVisibleChange={handlePopVisibility}
      title="Are you sure to remove this exercise equipment?"
      okButtonProps={{ loading: requesting }}
      onConfirm={onDelete}
      onCancel={() => console.log('clicked cancel')}
      okText="Yes"
      cancelText="No"
    >
      {createElement(refChildren.current, { onClick: handlePopVisibility })}
    </Popconfirm>
  );
};

export default DeleteExerciseEquipment;
