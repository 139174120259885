import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AddCategoryForm from '../form';
import validationSchema from '../validation-schema';
// import { SectionDetail } from '../../../types/sections';
// import { TechniqueDetail } from '../../../types/technique';
import { updateBlogCategory } from '../../../services/blog-category-service';
import { BlogCategory } from '../../../types/blog-categories';
import { handleError } from '../../../utils/common';

interface IEditCategoryProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  payload: BlogCategory;
  _id: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditBlogCategory: FC<IEditCategoryProps> = ({
  children,
  payload,
  _id,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<BlogCategory>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  useEffect(() => {
    const updatedPayload = {
      ...payload,
    };

    form.reset(updatedPayload);
  }, [payload]);

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };
  const onSubmitHandler = async (values: BlogCategory) => {
    setRequesting(true);
    try {
      await updateBlogCategory(values, _id);
      setDrawerVisible(false);
      message.success('Blog category Updated successfully');
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Update Blog Category"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddCategoryForm isEditable={true} form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditBlogCategory;
