import { Button, message, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
  DragOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { ICertificationRenewalQuestion } from '../../types/CertificationRenewalQuestion';
import {
  getCertificationRenewalQuestions,
  updateBulkCertificationRenewalQuestions,
} from '../../services/certification-renewal-question-service';
import AddCertificationRenewalQuestion from '../../components/certification-renewal-questions/add-question';
import EditCertificationRenewalQuestion from '../../components/certification-renewal-questions/edit-question';
import DeleteCertificationRenewalQuestion from '../../components/certification-renewal-questions/delete-question';

import columns from './columns';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: transform ? `translateY(${transform.y}px)` : undefined,
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

interface DraggableTableProps {
  certificationQuestions: ICertificationRenewalQuestion[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnsWithActions: any[]; // You should replace 'any[]' with the actual type for your columns
  onDragEnd: (event: DragEndEvent) => void;
}

const DraggableTable = ({
  certificationQuestions,
  columnsWithActions,
  onDragEnd,
}: DraggableTableProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={certificationQuestions.map((i) => i._id)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          dataSource={certificationQuestions}
          columns={columnsWithActions}
          pagination={false}
          rowKey="_id"
          components={{
            body: {
              row: Row,
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

const CertificationQuestions = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadCertificationQuestions, setReloadCertificationQuestions] =
    useState(true);
  const [certificationQuestions, setCertificationQuestions] = useState<
    ICertificationRenewalQuestion[]
  >([]);
  const [dragAndDropEnabled, setDragAndDropEnabled] = useState(false);
  const [sequenceChanging, setSequenceChanging] = useState(false);
  const [originalCertificationQuestions, setOriginalCertificationQuestions] =
    useState<ICertificationRenewalQuestion[]>([]);

  const loadCertificationRenewalQuestions = async () => {
    setRequesting(true);
    try {
      const response = await getCertificationRenewalQuestions();
      setCertificationQuestions(response?.data?.data);
      setOriginalCertificationQuestions(response?.data?.data);
    } catch (e) {
      message.error('Something went wrong');
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadCertificationQuestions(false);
    }
  };
  useEffect(() => {
    if (!requesting && reloadCertificationQuestions) {
      loadCertificationRenewalQuestions();
    }
  }, [reloadCertificationQuestions, requesting]);

  const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setCertificationQuestions((previous) => {
        const activeIndex = previous.findIndex((i) => i._id === active.id);
        const overIndex = previous.findIndex((i) => i._id === over?.id);

        const newQuestions = [...previous];

        // Remove the dragged question from the array
        const [draggedQuestion] = newQuestions.splice(activeIndex, 1);

        // Insert the dragged question back to the array at the target position
        newQuestions.splice(overIndex, 0, draggedQuestion);

        // Update the sequence order for all questions based on their index
        newQuestions.forEach((item, index) => {
          item.sequence = index + 1;
        });

        return newQuestions;
      });
    }
  }, []);

  const toggleDragAndDrop = useCallback(() => {
    setDragAndDropEnabled((prevState) => !prevState);
  }, []);

  const saveOrderHandler = async () => {
    setSequenceChanging(true);
    try {
      if (certificationQuestions === originalCertificationQuestions) {
        message.info('No Changes Found');
        setDragAndDropEnabled(false);
      } else {
        await updateBulkCertificationRenewalQuestions(
          certificationQuestions.map((question) => ({
            _id: question._id,
            sequence: question.sequence,
          }))
        );

        message.success('Sequence Order Updated Successfully');
        setOriginalCertificationQuestions(certificationQuestions);
        setDragAndDropEnabled(false);
      }
    } catch (error) {
      message.error('Error occurred while updating the order');
      console.log(error);
    } finally {
      setSequenceChanging(false);
    }
  };

  const resetSequenceOrder = useCallback(() => {
    setCertificationQuestions(originalCertificationQuestions);
    setDragAndDropEnabled(false);
  }, [originalCertificationQuestions]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: ICertificationRenewalQuestion) => {
        return (
          <Space size="large">
            <EditCertificationRenewalQuestion
              questionId={record._id}
              payload={record}
              successCallback={() => setReloadCertificationQuestions(true)}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditCertificationRenewalQuestion>
            <DeleteCertificationRenewalQuestion
              questionId={record._id}
              successCallback={() => setReloadCertificationQuestions(true)}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteCertificationRenewalQuestion>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={3}>
            Certification Renewal Questions
          </Typography.Title>
          <Space>
            <Tooltip title="Refresh" placement="left">
              <Button
                type="ghost"
                icon={<ReloadOutlined />}
                onClick={() => setReloadCertificationQuestions(true)}
              />
            </Tooltip>
            <AddCertificationRenewalQuestion
              successCallback={() => setReloadCertificationQuestions(true)}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => (
                <Button
                  onClick={onClick}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add Question
                </Button>
              )}
            </AddCertificationRenewalQuestion>
            {/* Button to toggle drag and drop */}
            {!dragAndDropEnabled && (
              <Button
                onClick={toggleDragAndDrop}
                type={'default'}
                icon={<DragOutlined />}
              >
                Edit Sequence Order
              </Button>
            )}
            {dragAndDropEnabled && (
              <Button
                onClick={saveOrderHandler}
                type={'primary'}
                icon={<CheckOutlined />}
                loading={sequenceChanging}
              >
                Save
              </Button>
            )}
            {dragAndDropEnabled && (
              <Button onClick={resetSequenceOrder} type="dashed">
                Cancel
              </Button>
            )}
          </Space>
        </div>

        {/* Conditionally render the draggable table if dragAndDropEnabled is true */}
        {dragAndDropEnabled ? (
          <DraggableTable
            certificationQuestions={certificationQuestions}
            columnsWithActions={columnsWithActions}
            onDragEnd={onDragEnd}
          />
        ) : (
          // Render a regular table without drag and drop functionality
          <Table
            dataSource={certificationQuestions}
            columns={columnsWithActions}
            pagination={false}
            rowKey="_id"
          />
        )}
      </div>
    </Spin>
  );
};

export default CertificationQuestions;
