import { Button, message, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import type { PaginationProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  getAssessmentResponses,
  getAssignees,
} from '../../services/assessment-response-service';
import { IAssessmentResponse, IAssignee } from '../../types/assesment-response';
import AddAssessmentReivew from '../../components/assesment-response/review-assessment';
import { handleError } from '../../utils/common';

import columns from './columns';
import AssigneeModel from './assignee-model';

interface assessmentResponsesProps {
  totalDocuments: number;
  totalPages: number;
  currentPage: string;
  assessmentResponse: IAssessmentResponse[];
}

const AssessmentResponses = () => {
  const [requesting, setRequesting] = useState(false);
  const [loadingAssignees, setLoadingAssignees] = useState(false);
  const [assignees, setAssignees] = useState<IAssignee[]>();
  const [reloadAssessmentResponses, setReloadAssessmentResponses] =
    useState(true);
  const [assessmentResponses, setAssessmentResponses] =
    useState<assessmentResponsesProps>({
      totalDocuments: 0,
      totalPages: 0,
      currentPage: '1',
      assessmentResponse: [],
    });
  const [activeResponseId, setActiveResponseId] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const currentURL = location?.pathname || '';

  const page = queryParams.get('page')
    ? parseInt(queryParams.get('page') || '')
    : 1;
  const limit = queryParams.get('limit')
    ? parseInt(queryParams.get('limit') || '')
    : 10;

  const loadAssessmentResponses = async () => {
    setRequesting(true);
    try {
      const response = await getAssessmentResponses({
        isLevelOne: currentURL?.includes('certification'),
        page: page,
        ...(limit && { limit }),
        approvedResponses: currentURL?.includes('approved'),
      });
      setAssessmentResponses(response?.data?.data);
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
      setReloadAssessmentResponses(false);
    }
  };

  const removeQueryParam = () => {
    setActiveResponseId('');
    navigate({
      pathname: location.pathname,
    });
  };

  const loadAssignees = async () => {
    setLoadingAssignees(true);
    try {
      const response = await getAssignees();
      setAssignees(response.data?.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingAssignees(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadAssessmentResponses) {
      loadAssessmentResponses();
    }
  }, [reloadAssessmentResponses, requesting]);

  useEffect(() => {
    setReloadAssessmentResponses(true);
  }, [page, limit, location.pathname]);

  useEffect(() => {
    const activeResponseId = queryParams.get('id');
    if (activeResponseId) setActiveResponseId(activeResponseId as string);
  }, []);

  useEffect(() => {
    loadAssignees();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Assignee',
      render: (value: string, record: IAssessmentResponse) => {
        return (
          <AssigneeModel
            assignees={assignees}
            loadingAssignees={loadingAssignees}
            response={record}
            successCallback={() => setReloadAssessmentResponses(true)}
          />
        );
      },
      width: 200,
    },
    {
      title: 'Action',
      render: (value: string, record: IAssessmentResponse) => {
        return (
          <>
            <AddAssessmentReivew
              payload={record}
              activeResponseId={activeResponseId}
              successCallback={() => {
                removeQueryParam();
                setReloadAssessmentResponses(true);
              }}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </AddAssessmentReivew>{' '}
          </>
        );
      },
      width: 200,
    },
  ];

  const handleTableChange: PaginationProps['onChange'] = (pagination, size) => {
    setReloadAssessmentResponses(true);
    navigate({
      pathname: location.pathname,
      search: `?page=${pagination}&limit=${size}`,
    });
  };

  return (
    <Spin spinning={requesting}>
      <Table
        key={JSON.stringify(assessmentResponses?.assessmentResponse)}
        dataSource={assessmentResponses?.assessmentResponse}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={{
          position: ['bottomRight'],
          total: assessmentResponses?.totalDocuments,
          defaultCurrent: page,
          defaultPageSize: limit,
          onChange: handleTableChange,
          showSizeChanger: true,
        }}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>
                {currentURL?.includes('approved') && 'Approved '}
                {currentURL?.includes('certification')
                  ? 'Certification Responses'
                  : 'Badge Responses'}
              </Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadAssessmentResponses(true)}
                  />
                </Tooltip>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};

export default AssessmentResponses;
