import { createElement, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Drawer, message } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TUser, updateUser } from '../../../../../services/users-service';

import EditBusinessDetailsForm from './form';
import validationSchema from './validation-schema';

export interface TFieldValues {
  invoiceName?: string;
  invoiceAddress?: string;
  invoiceCountry?: string;
  businessName?: string;
  invoicePostalCode?: string;
  taxIdName: string[];
  taxIdNumber: string[];
}

interface IEditUserProps {
  userId?: string | undefined;
  user: TUser;
  children: FC<{ onClick: () => void }>;
  errorCallback?: (e: Error) => void;
  successCallback?: (e: boolean) => void;
}

const EditBusinessDetails: FC<IEditUserProps> = ({
  children,
  user,
  userId,
  errorCallback,
  successCallback,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const refChildren = useRef(children);

  const getProfileToEdit = (userProfile: TUser) => {
    return {
      ...(userProfile?.invoiceName && { invoiceName: userProfile.invoiceName }),
      ...(userProfile?.invoiceAddress && {
        invoiceAddress: userProfile.invoiceAddress,
      }),
      ...(userProfile?.invoiceCountry && {
        invoiceCountry: userProfile.invoiceCountry,
      }),
      ...(userProfile?.businessName && {
        businessName: userProfile.businessName,
      }),
      ...(userProfile?.invoicePostalCode && {
        invoicePostalCode: userProfile.invoicePostalCode,
      }),
      ...(userProfile?.taxIds && {
        taxIdName: userProfile.taxIds.map((p) => p.taxIdName),
      }),
      ...(userProfile?.taxIds && {
        taxIdNumber: userProfile.taxIds.map((p) => p.taxIdNumber),
      }),
    };
  };
  const form = useForm<TFieldValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: useMemo(() => {
      return getProfileToEdit(user);
    }, [user]),
  });

  useEffect(() => {
    form.reset(getProfileToEdit(user));
  }, [form, user]);

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (data: TFieldValues) => {
    const updatedTaxId = data.taxIdName.map((name, index) => {
      return { taxIdName: name, taxIdNumber: data.taxIdNumber[index] };
    });

    const user = {
      invoiceName: data.invoiceName,
      invoiceAddress: data.invoiceAddress,
      invoiceCountry: data.invoiceCountry,
      businessName: data.businessName,
      invoicePostalCode: data.invoicePostalCode,
      taxIds: updatedTaxId,
    };
    setRequesting(true);
    try {
      await updateUser({
        _id: userId,
        user,
      })
        .then((response) => {
          if (response.status === 200) {
            message.success(' Updated Successfully');
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
      handleDrawerVisibility();
      form.reset();
      if (successCallback) {
        successCallback(true);
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Edit Business Details"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
          >
            Update
          </Button>
        }
      >
        <div style={{ padding: 16 }}>
          <EditBusinessDetailsForm form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditBusinessDetails;
