import { CerificationCategory } from '../../types/certification-categories';

const columns = [
  {
    title: 'Sl No',
    render: (value: number, record: CerificationCategory) => (
      <>{record?.serialno}</>
    ),
    key: 'slno',
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: CerificationCategory) => (
      <>{record?.category.en}</>
    ),
    width: 250,
  },
];

export default columns;
