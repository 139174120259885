import {
  Button,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
  DragOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  getCategoriesBySection,
  updateBulkCategories,
  updateSectionCategory,
} from '../../services/exercise-categories-service';
import {
  ExerciseCategory,
  ExerciseCategoryDisplay,
} from '../../types/exercise-categories';
import AddCategory from '../../components/exercise-categories/create-category';
import EditCategory from '../../components/exercise-categories/edit-category';
import DeleteCategory from '../../components/exercise-categories/delete-category';
import { getTechniques } from '../../services/technique-service';
import { TechniqueDetail } from '../../types/technique';
import { SectionDetail } from '../../types/sections';
import { getSections } from '../../services/sections-service';

import columns from './columns';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

interface DraggableTableProps {
  categories: ExerciseCategory[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnsWithActions: any[]; // You should replace 'any[]' with the actual type for your columns
  onDragEnd: (event: DragEndEvent) => void;
}

const DraggableTable = ({
  categories,
  columnsWithActions,
  onDragEnd,
}: DraggableTableProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={categories.map((i) => i._id)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          dataSource={categories}
          columns={columnsWithActions}
          rowKey="_id"
          pagination={false}
          components={{
            body: {
              row: Row,
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

const ExerciseCategories = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadCategories, setReloadCategories] = useState(false);
  const [sections, setSections] = useState<SectionDetail[]>([]);
  const [categories, setCategories] = useState<ExerciseCategory[]>([]);
  const [techniques, setTechniques] = useState<TechniqueDetail[]>([]);
  const [dragAndDropEnabled, setDragAndDropEnabled] = useState(false);
  const [originalCategories, setOriginalCategories] = useState<
    ExerciseCategory[]
  >([]);

  const loadSections = async () => {
    setRequesting(true);
    try {
      const response = await getSections();
      setSections(response?.data?.data || []);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
    }
  };

  const loadTechniques = async () => {
    setRequesting(true);
    try {
      const response = await getTechniques();
      setTechniques(response?.data?.data || []);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
    }
  };

  const loadCategories = async () => {
    setRequesting(true);
    try {
      const response = await getCategoriesBySection({ isClassVideo: false });
      setCategories(response?.data?.data || []);
      setOriginalCategories(response?.data?.data || []);
    } catch (e) {
      console.log(e);
    } finally {
      setReloadCategories(false);
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadCategories) {
      loadCategories();
    }
  }, [reloadCategories, requesting]);

  useEffect(() => {
    loadCategories();
    loadTechniques();
    loadSections();
  }, []);

  const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setCategories((previous) => {
        const activeIndex = previous.findIndex((i) => i._id === active.id);
        const overIndex = previous.findIndex((i) => i._id === over?.id);

        const newCategories = [...previous];

        // Remove the dragged category from the array
        const [draggedCategory] = newCategories.splice(activeIndex, 1);

        // Insert the dragged category back to the array at the target position
        newCategories.splice(overIndex, 0, draggedCategory);

        // Update the sequence values based on the new order
        newCategories.forEach((category, index) => {
          category.sequence = index + 1;
        });

        return newCategories;
      });
    }
  }, []);

  const handleTeacherOnly = async (value: boolean, id: string) => {
    setRequesting(true);
    try {
      await updateSectionCategory({ isTeacherOnly: value }, id);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      message.error(e?.response?.data?.MESSAGE_KEY || e?.message);
    } finally {
      setRequesting(false);
      setReloadCategories(true);
    }
  };

  const columnsWithActions = [
    ...columns,
    {
      title: "Teacher's Only",
      key: 'isTeacherOnly',
      width: 200,
      render: (value: string, record: ExerciseCategory) => {
        return (
          <Tooltip
            title="Would you like to make this category teacher's only?"
            placement="right"
          >
            <Switch
              size="small"
              defaultChecked={record?.isTeacherOnly || false}
              onChange={async (e) => {
                await handleTeacherOnly(e, record?._id);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: ExerciseCategory) => {
        return (
          <Space size="large">
            <EditCategory
              _id={record._id}
              payload={record as unknown as ExerciseCategoryDisplay}
              sections={sections}
              techniques={techniques}
              successCallback={() => setReloadCategories(true)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditCategory>
            <DeleteCategory
              _id={record._id}
              successCallback={() => setReloadCategories(true)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteCategory>
          </Space>
        );
      },
    },
  ];

  // Function to toggle the drag and drop feature
  const toggleDragAndDrop = useCallback(() => {
    setDragAndDropEnabled((prevState) => !prevState);
  }, []);

  const saveOrderHandler = async () => {
    try {
      if (categories === originalCategories) {
        message.info('No Changes Found');
        setDragAndDropEnabled(false);
      } else {
        await updateBulkCategories(
          categories.map((category) => ({
            _id: category._id,
            sequence: category.sequence,
          }))
        );
        message.success('Updated Successfully');
        setDragAndDropEnabled(false);
        setReloadCategories(true);
      }
    } catch (error) {
      message.error('Network Error');
      console.log(error);
    }
  };

  // Function to reset order to its original state
  const resetSequenceOrder = useCallback(() => {
    setDragAndDropEnabled(false);
    setCategories(
      originalCategories.map((category, index) => {
        category.sequence = index + 1;
        return category;
      })
    );
  }, [originalCategories]);

  const paginationConfig = {
    pageSize: 10,
    total: categories.length,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
  };

  return (
    <Spin spinning={requesting}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={3}>Exercise Categories</Typography.Title>
          <Space>
            <Tooltip title="Refresh" placement="left">
              <Button
                type="ghost"
                icon={<ReloadOutlined />}
                onClick={() => setReloadCategories(true)}
              />
            </Tooltip>
            <AddCategory
              techniques={techniques}
              sections={sections}
              successCallback={() => setReloadCategories(true)}
            >
              {({ onClick }) => (
                <Button
                  onClick={onClick}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add Exercise Category
                </Button>
              )}
            </AddCategory>
            {/* Button to toggle drag and drop */}
            {!dragAndDropEnabled && (
              <Button
                onClick={toggleDragAndDrop}
                type={'default'}
                icon={<DragOutlined />}
              >
                Edit Sequence Order
              </Button>
            )}
            {dragAndDropEnabled && (
              <Button
                onClick={saveOrderHandler}
                type={'primary'}
                icon={<CheckOutlined />}
              >
                Save
              </Button>
            )}
            {dragAndDropEnabled && (
              <Button onClick={resetSequenceOrder} type="dashed">
                Cancel
              </Button>
            )}
          </Space>
        </div>

        {/* Conditionally render the draggable table if dragAndDropEnabled is true */}
        {dragAndDropEnabled ? (
          <DraggableTable
            categories={categories}
            columnsWithActions={columnsWithActions}
            onDragEnd={onDragEnd}
          />
        ) : (
          // Render a regular table without drag and drop functionality
          <Table
            dataSource={categories}
            columns={columnsWithActions}
            rowKey="_id"
            pagination={paginationConfig}
          />
        )}
      </div>
    </Spin>
  );
};

export default ExerciseCategories;
