/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode } from 'react';
import _ from 'lodash';
import { Form, Radio, RadioGroupProps } from 'antd';
import { Controller, UseFormReturn } from 'react-hook-form';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';

interface IRadioFieldProps {
  label?: string;
  required?: boolean;
  name: string;
  defaultValue?: string;
  valueKey?: string;
  labelKey?: string;
  form: UseFormReturn<any, any>;
  radioFieldProps: RadioGroupProps;
  tooltip?: LabelTooltipType;
  renderValue?: (value: any) => ReactNode;
}

const RadioField: FC<IRadioFieldProps> = ({
  form: {
    formState: { errors },
    control,
  },
  label,
  required,
  name,
  defaultValue,
  valueKey = 'value',
  labelKey = 'label',
  radioFieldProps,
  renderValue,
}) => {
  const errorMessages = _.get(errors, name);
  const hasError = !!(errors && errorMessages);
  const { options, ...restRadioFieldProps } = radioFieldProps || {};
  return (
    <Form.Item
      label={label}
      help={errorMessages?.message}
      validateStatus={hasError ? 'error' : 'success'}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <Radio.Group
              {...field}
              {...restRadioFieldProps}
              defaultValue={defaultValue}
            >
              {(options || []).map((o: any, i: number) => (
                <Radio value={o[valueKey]} key={`${i}#${o[valueKey]}`}>
                  {renderValue ? renderValue(o) : o[labelKey]}
                </Radio>
              ))}
            </Radio.Group>
          );
        }}
      />
    </Form.Item>
  );
};

export default RadioField;
