import { Button, message, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { getMuscleCategories } from '../../../services/muscle-service';
import { MuscleCategoryDetail } from '../../../types/muscle';
import AddMuscleCategory from '../../../components/muscle/muscle-category/create-muscle-category';
import EditMuscleCategory from '../../../components/muscle/muscle-category/edit-musclecategory';
import DeleteMuscleCategory from '../../../components/muscle/muscle-category/delete-muscle-category';

import columns from './columns';

const MuscleCategory = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadMuscleCategory, setReloadMuscleCategory] = useState(false);
  const [muscleCategories, setMuscleCategories] = useState<
    MuscleCategoryDetail[]
  >([]);

  const loadMuscleCategories = async () => {
    setRequesting(true);
    try {
      const response = await getMuscleCategories();
      setMuscleCategories(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadMuscleCategory(false);
    }
  };
  useEffect(() => {
    if (!requesting && reloadMuscleCategory) {
      loadMuscleCategories();
    }
  }, [reloadMuscleCategory, requesting]);

  useEffect(() => {
    loadMuscleCategories();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: MuscleCategoryDetail) => {
        return (
          <Space size="large">
            <EditMuscleCategory
              muscleCategoryId={record._id}
              payload={record}
              successCallback={() => setReloadMuscleCategory(true)}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditMuscleCategory>
            <DeleteMuscleCategory
              muscleCategoryId={record._id}
              successCallback={() => setReloadMuscleCategory(true)}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteMuscleCategory>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={muscleCategories}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Muscle Categories</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadMuscleCategory(true)}
                  />
                </Tooltip>
                <AddMuscleCategory
                  successCallback={() => setReloadMuscleCategory(true)}
                  errorCallback={(e) => message.error(e.message)}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Muscle Category
                    </Button>
                  )}
                </AddMuscleCategory>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default MuscleCategory;
