/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dropdown,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  ReloadOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { AdminType, TUser } from '../../services/users-service';
import AddAdmin from '../../components/admins/add-admin';
import { getAdmins } from '../../services/admin-service';
import AddInstructorPaymentProcessor from '../../components/instructor-paymentprocessor/update-payment-processor';
import { handleError } from '../../utils/common';
import EditAdmin from '../../components/admins/edit-admin';
import { getInstructors } from '../../services/instructor-service';
import { WorkshopInstructor } from '../../types/instructor';

import columns from './columns';

const Admins = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadAdmins, setReloadAdmins] = useState(true);
  const [admins, setAdmins] = useState<TUser[]>([]);
  const navigate = useNavigate();

  const [instructor, setInstructors] = useState<WorkshopInstructor[]>([]);

  const allowedAdminType = [AdminType.root, AdminType.workshop];

  const loadAdmins = async () => {
    setRequesting(true);
    try {
      const response = await getAdmins();
      setAdmins(response?.data?.data);
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
      setReloadAdmins(false);
    }
  };

  const loadInstructors = useCallback(async () => {
    setRequesting(true);
    try {
      const response = await getInstructors();
      setInstructors(response?.data?.data);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  }, []);

  useEffect(() => {
    if (!requesting && reloadAdmins) {
      loadAdmins();
    }
  }, [reloadAdmins, requesting]);

  useEffect(() => {
    loadInstructors();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: TUser) => {
        return (
          <Space size="large">
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'admin-profile',
                    label: <p className="no-margin">View profile</p>,
                    onClick: () => navigate(`/userdetails/${record._id}`),
                  },
                  {
                    key: 'edit-admin',
                    label: (
                      <EditAdmin
                        successCallback={() => setReloadAdmins(true)}
                        errorCallback={handleError}
                        payload={record}
                        instructor={instructor}
                      >
                        {({ onClick }) => (
                          <p className="no-margin" onClick={onClick}>
                            Edit profile
                          </p>
                        )}
                      </EditAdmin>
                    ),
                  },
                  {
                    type: 'divider',
                  },
                  {
                    key: 'add-payment-processor',
                    label: (
                      <AddInstructorPaymentProcessor
                        payload={{
                          paymentProcessor: record?.paymentProcessor,
                          paypalEmailId: record?.paypalEmailId,
                          stripeConenct: record?.stripeConenct,
                          adminType: record?.adminType,
                        }}
                        userId={record?._id}
                        allowedAdminType={[AdminType.root, AdminType.workshop]}
                        successCallback={() => setReloadAdmins(true)}
                        errorCallback={handleError}
                      >
                        {({ onClick }) => (
                          <p
                            className="no-margin"
                            onClick={() => {
                              if (
                                record?.adminType &&
                                allowedAdminType?.includes(record?.adminType)
                              ) {
                                onClick();
                              }
                            }}
                          >
                            Add payment processor
                          </p>
                        )}
                      </AddInstructorPaymentProcessor>
                    ),
                    disabled: !(
                      record?.adminType &&
                      allowedAdminType?.includes(record?.adminType)
                    ),
                  },
                ],
              }}
              arrow
              trigger={['click']}
              placement="bottomRight"
              className="workshop-dropdown"
              overlayClassName="workshop-dropdown-overlay"
            >
              <EllipsisOutlined />
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={admins}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Admins</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadAdmins(true)}
                  />
                </Tooltip>
                <AddAdmin
                  successCallback={() => setReloadAdmins(true)}
                  instructor={instructor}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Admin
                    </Button>
                  )}
                </AddAdmin>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default Admins;
