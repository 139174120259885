/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, message, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

import { getEquipments, TPayload } from '../../services/equipment-service';
import AddEquipment from '../../components/equipment/add-equipment';
import DeleteEquipment from '../../components/equipment/delete-equipment';
import EditEquipment from '../../components/equipment/edit-equipment';

import columns from './columns';

const Equipments = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadEquipments, setReloadEquipments] = useState(false);
  const [equipments, setEqipments] = useState<TPayload[]>([]);

  const loadEquipments = async () => {
    setRequesting(true);
    try {
      const response = await getEquipments();
      setEqipments(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadEquipments(false);
    }
  };
  useEffect(() => {
    if (!requesting && reloadEquipments) {
      loadEquipments();
    }
  }, [reloadEquipments, requesting]);

  useEffect(() => {
    loadEquipments();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: unknown, record: TPayload) => {
        return (
          <Space size="large">
            {record._id && (
              <EditEquipment
                equipmentId={record._id}
                payload={record}
                successCallback={() => setReloadEquipments(true)}
                errorCallback={(e: any) =>
                  message.error(e?.response?.data?.MESSAGE_KEY || e?.message)
                }
              >
                {({ onClick }) => <EditOutlined onClick={onClick} />}
              </EditEquipment>
            )}
            <DeleteEquipment
              equipmentId={record._id}
              successCallback={() => setReloadEquipments(true)}
              errorCallback={(e: any) =>
                message.error(e?.response?.data?.MESSAGE_KEY || e?.message)
              }
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteEquipment>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={equipments}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Equipment</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadEquipments(true)}
                  />
                </Tooltip>
                <AddEquipment
                  successCallback={() => setReloadEquipments(true)}
                  errorCallback={(e: any) =>
                    message.error(e?.response?.data?.MESSAGE_KEY || e?.message)
                  }
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Equipment
                    </Button>
                  )}
                </AddEquipment>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default Equipments;
