import { NOT_AVAILABLE } from '../../constants/common';
import { IBlockedTeacherContactEmails } from '../../types/blocked-teacher-contact-emails';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 200,
  },
  {
    title: 'from',
    render: (value: string, record: IBlockedTeacherContactEmails) =>
      record?.replyTo || NOT_AVAILABLE,
    width: 800,
  },
  {
    title: 'to',
    render: (value: string, record: IBlockedTeacherContactEmails) =>
      record?.recipientEmail || NOT_AVAILABLE,
    width: 800,
  },
  {
    title: 'Reason for blocking',
    render: (value: string, record: IBlockedTeacherContactEmails) =>
      record?.reason || NOT_AVAILABLE,
    width: 800,
  },
];

export default columns;
