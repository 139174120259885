import { createElement, FC, useRef } from 'react';
import { message } from 'antd';

import { mailWorkshop } from '../../../services/workshop-service';

interface EmailWorkshopAttendeeProps {
  attendeeId: string;
  setRequesting: (value: boolean) => void;
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const EmailWorkshopAttendee: FC<EmailWorkshopAttendeeProps> = ({
  attendeeId,
  setRequesting,
  successCallback,
  errorCallback,
  children,
}) => {
  const refChildren = useRef(children);

  const sendMail = async () => {
    try {
      setRequesting(true);
      await mailWorkshop(attendeeId);
      message.success('Mail has been sent successfully to workshop attendee');
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  return <>{createElement(refChildren.current, { onClick: sendMail })}</>;
};

export default EmailWorkshopAttendee;
