import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import { TechniqueDetail } from '../../../types/technique';
import { WorkshopInstructor } from '../../../types/instructor';
import { updateTechnique } from '../../../services/technique-service';
import TechniqueForm from '../form';
import { isHttp } from '../../../utils/common';

interface EditTechniqueProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  payload: TechniqueDetail;
  techniqueId: string;
  instructors: WorkshopInstructor[];
  children: FC<{
    onClick: () => void;
  }>;
}

const EditTechnique: FC<EditTechniqueProps> = ({
  children,
  payload,
  techniqueId,
  successCallback,
  errorCallback,
  instructors,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<TechniqueDetail>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  useEffect(() => {
    form.reset(payload);
  }, [payload]);

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };
  const onSubmitHandler = async (values: TechniqueDetail) => {
    const {
      logoSmallForLightBg,
      logoMediumForLightBg,
      logoSmallForDarkBg,
      logoMediumForDarkBg,
      ...rest
    } = values;
    const payload = {
      ...rest,
      logoSmallForLightBg: isHttp(logoSmallForLightBg)
        ? ''
        : logoSmallForLightBg,
      logoMediumForLightBg: isHttp(logoMediumForLightBg)
        ? ''
        : logoMediumForLightBg,
      logoSmallForDarkBg: isHttp(logoSmallForDarkBg) ? '' : logoSmallForDarkBg,
      logoMediumForDarkBg: isHttp(logoMediumForDarkBg)
        ? ''
        : logoMediumForDarkBg,
    };
    try {
      setRequesting(true);
      await updateTechnique(payload, techniqueId);
      setDrawerVisible(false);
      message.success('Technique Updated successfully');
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Update Technique"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={800}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <TechniqueForm instructors={instructors} isEditable form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditTechnique;
