import * as yup from 'yup';

export default yup.object().shape({
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
  slno: yup
    .number()
    .typeError('Sequence number should ne number.')
    .required('Sequence number is required.'),
  product: yup.object().shape({
    en: yup.string().required('Please input product in english'),
    pt: yup.string().required('Please input product in portugese'),
    it: yup.string().required('Please input product in italian'),
    es: yup.string().required('Please input product in spanish'),
    de: yup.string().required('Please input product in german'),
    zh: yup.string().required('Please input product in mandrin'),
  }),
  website: yup
    .string()
    .matches(
      /^(https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
      'Please Enter a valid website(eg: https://example.com)'
    )
    .required('Please input website'),
  imageUrl: yup.string().required('Product image is required'),
});
