import { AssessmentCategory } from '../../types/assessment-categories';

const columns = [
  {
    title: 'Sequence',
    render: (value: string, record: AssessmentCategory) => record?.sequence,
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: AssessmentCategory) => record?.name?.en,
    width: 400,
  },
  {
    title: 'Level',
    render: (value: string, record: AssessmentCategory) => record.level,
    width: 400,
  },
];

export default columns;
