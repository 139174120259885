import { CERTIFICATIONS_RENEWAL } from '../constants/api';
import { ICertificationRenewalQuestion } from '../types/CertificationRenewalQuestion';
import fetchClient from '../utils/fetch-client';

export const getCertificationRenewalQuestions = () => {
  return fetchClient.get(`${CERTIFICATIONS_RENEWAL.QUESTION}`);
};
export const addCertificationRenewalQuestion = (
  payload: ICertificationRenewalQuestion
) => {
  return fetchClient.post(`${CERTIFICATIONS_RENEWAL.QUESTION}`, payload);
};

export const updateCertificationRenewalQuestion = (
  payload: ICertificationRenewalQuestion,
  id: string
) => {
  return fetchClient.put(`${CERTIFICATIONS_RENEWAL.QUESTION}/${id}`, payload);
};

export const deleteCertificationRenewalQuestion = (id?: string) => {
  return fetchClient.delete(`${CERTIFICATIONS_RENEWAL.QUESTION}/${id}`);
};

export const updateBulkCertificationRenewalQuestions = (
  payload: {
    _id: string;
    sequence: number;
  }[]
) => {
  return fetchClient.post(`${CERTIFICATIONS_RENEWAL.QUESTION}/update-all`, {
    questions: payload,
  });
};
