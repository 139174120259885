import * as yup from 'yup';

export default yup.object().shape({
  pic1: yup
    .mixed()
    .test(
      'oneOfRequired',
      'Atleast one photo is required',
      (item, picTestContext) => {
        return !!picTestContext.parent.pic1;
      }
    ),
});
