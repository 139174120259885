import * as yup from 'yup';

const schema = yup.object().shape({
  reason: yup.string(),
  type: yup.string().required('Type is required'),
  value: yup
    .string()
    .required('Value is required')
    .when('type', {
      is: 'email',
      then: yup.string().email('Please enter a valid email'),
      otherwise: yup.string().when('type', {
        is: 'domain',
        then: yup
          .string()
          .matches(
            /^(?:https?:\/\/)?(?:[\w-]+\.)+[a-z]{2,}$/i,
            'Please enter a valid domain or URL'
          ),
        otherwise: yup.string().when('type', {
          is: 'text',
          then: yup.string().min(2, 'Please enter a word or sentence'),
        }),
      }),
    }),
});

export default schema;
