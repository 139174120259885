import { createElement, FC, useRef, useState } from 'react';
import { Popconfirm } from 'antd';

import { deleteBlogCategory } from '../../../services/blog-category-service';

interface IDeleteCategoryProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  _id?: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const DeleteBlogCategory: FC<IDeleteCategoryProps> = ({
  children,
  _id,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [popVisible, setPopVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const onDelete = async () => {
    setRequesting(true);
    try {
      await deleteBlogCategory(_id);
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  const handlePopVisibility = () => {
    setPopVisible(!popVisible);
  };
  return (
    <Popconfirm
      open={popVisible}
      onVisibleChange={handlePopVisibility}
      title="Are you sure to remove this blog category ?"
      okButtonProps={{ loading: requesting }}
      onConfirm={onDelete}
      onCancel={() => console.log('clicked cancel')}
      okText="Yes"
      cancelText="No"
    >
      {createElement(refChildren.current, { onClick: handlePopVisibility })}
    </Popconfirm>
  );
};

export default DeleteBlogCategory;
