import { FC, createElement, useRef, useState } from 'react';
import { Button, Form, Modal, Popover, message } from 'antd';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { WorkshopAttendeeWithUserDetail } from '../../types/workshop';
import {
  aprroveRefundRequest,
  rejectRefundRequest,
} from '../../services/workshop-service';
import { handleError } from '../../utils/common';
import { InputField } from '../../components/common/form';

interface RefundModalProps {
  attendee: WorkshopAttendeeWithUserDetail;
  setReloadWorkshopAttendees: React.Dispatch<React.SetStateAction<boolean>>;
  children: FC<{
    onClick: () => void;
  }>;
}

const RefundState = {
  NOT_REQUESTED: 'Not Requested',
  REQUESTED: 'Requested',
  REQUEST_ACCEPTED_PENDING: 'Request Pending',
  REQUEST_ACCEPTED_COMPLETED: 'Request Completed',
  REQUEST_DECLINED: 'Request Declined',
};

const validationSchema = yup.object().shape({
  reason: yup.string().required('required'),
});

const RefundModal: FC<RefundModalProps> = ({
  attendee,
  setReloadWorkshopAttendees,
  children,
}) => {
  const refChildren = useRef(children);

  const [open, setOpen] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const form = useForm<{ reason: string }>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const approveRefund = async (attendeeId?: string) => {
    setRequesting(true);
    try {
      await aprroveRefundRequest(attendeeId);
      message.success('Refund Request Processed Successfully');
      setReloadWorkshopAttendees(true);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  const rejectRefund = async (attendeeId: string, reason: string) => {
    setRejecting(true);
    try {
      await rejectRefundRequest(attendeeId, reason);
      message.success('Refund Rejected Successfully');
      setReloadWorkshopAttendees(true);
    } catch (err) {
      handleError(err);
    } finally {
      setRejecting(false);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
        destroyOnClose
        title="Manage Refund"
      >
        <div style={{ padding: '10px' }}>
          {attendee && (
            <div
              style={{ fontSize: '14px', color: '#888', marginBottom: '20px' }}
            >
              <p>
                <strong>Attendee:</strong> {attendee.fullName}
              </p>
              <p>
                <strong>Email:</strong> {attendee.email}
              </p>
              <p>
                <strong>Current Status:</strong>{' '}
                <strong style={{ color: '#000' }}>
                  {RefundState[attendee?.refundStatus]}
                </strong>
              </p>
            </div>
          )}

          <div className="refund-request-button" style={{ textAlign: 'end' }}>
            {attendee?.refundStatus === 'REQUESTED' && (
              <Popover
                trigger="click"
                content={
                  <div>
                    <Form layout="vertical">
                      <InputField
                        label="Enter reason to decline"
                        name="reason"
                        form={form}
                        inputProps={{ placeholder: 'reason' }}
                        required
                      />
                    </Form>
                    <div className="refund-request-button">
                      <Button
                        type="primary"
                        loading={rejecting}
                        onClick={form.handleSubmit((value) =>
                          rejectRefund(attendee?._id, value?.reason)
                        )}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button type="default">Reject Refund</Button>
              </Popover>
            )}
            <Button
              type="primary"
              onClick={() => approveRefund(attendee?._id)}
              loading={requesting}
              disabled={
                attendee?.refundStatus === 'REQUEST_DECLINED' ||
                attendee?.refundStatus === 'REQUEST_ACCEPTED_COMPLETED' ||
                attendee?.refundStatus === 'REQUEST_ACCEPTED_PENDING'
              }
            >
              Process Refund
            </Button>
          </div>
        </div>
      </Modal>

      {createElement(refChildren.current, { onClick: () => setOpen(true) })}
    </>
  );
};

export default RefundModal;
