import { createElement, FC, useEffect, useRef, useState } from 'react';
import { Button, Drawer } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import EditEquipmentForm from '../form';
import validationSchema from '../validation-schema';
import { TPayload, updateEquipment } from '../../../services/equipment-service';

interface IEditEquipmentProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  payload: TPayload;
  equipmentId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditEquipment: FC<IEditEquipmentProps> = ({
  children,
  payload,
  equipmentId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<TPayload>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (data: TPayload) => {
    const payload = {
      _id: equipmentId,
      ...data,
    };

    setRequesting(true);
    try {
      await updateEquipment(payload);
      handleDrawerVisibility();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    form.reset(payload);
  }, [payload]);

  return (
    <>
      <Drawer
        title="Edit Equipment"
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
          >
            Update
          </Button>
        }
      >
        <div style={{ padding: 16 }}>
          <EditEquipmentForm isEditable form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditEquipment;
