import { SectionDetail } from '../../../types/sections';

const columns = [
  {
    title: 'Sequence',
    render: (value: string, record: SectionDetail) => record?.sequence,
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: SectionDetail) => <>{record?.name?.en}</>,
    width: 250,
  },
  {
    title: 'Age Group',
    render: (value: string, record: SectionDetail) => (
      <div>
        {'Min-' + record?.ageGroup?.min}
        {' , Max-' + record?.ageGroup?.max}
      </div>
    ),
    width: 200,
  },
];

export default columns;
