import { Col, Form, Row } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { InputField, SelectField } from '../../common/form';
import ReactQuillField from '../../common/form/react-quill-fields';
interface IAddUserFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
}
const EditUserForm = ({ form }: IAddUserFormProps) => {
  return (
    <Form layout="vertical">
      <Row>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'Full name'}
            name="fullName"
            required
            form={form}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'Display Name'}
            name="displayName"
            required
            form={form}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'Name As On Certificate'}
            name="nameAsOnCertificate"
            form={form}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'Emergency Contact Name'}
            name="emergencyContactName"
            form={form}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'Emergency Contact Number'}
            name="emergencyContactNumber"
            form={form}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <SelectField
            label={'Language'}
            name="language"
            form={form}
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: [
                { value: 'en', label: 'English' },
                { value: 'es', label: 'Spanish' },
                { value: 'zh', label: 'Chinese' },
              ],
              placeholder: 'Select Language',
              onChange: (v) => form.setValue('language', v),
            }}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField label={'Profession'} name="profession" form={form} />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField label={'Website'} name="website" form={form} />
        </Col>
        <Col span={12}>
          <InputField label={'Facebook'} name="facebook" form={form} />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField label={'Instagram'} name="instagram" form={form} />
        </Col>
        <Col span={12}>
          <InputField label={'Twitter'} name="twitter" form={form} />
        </Col>
        <Col span={12}>
          <InputField label={'Linkedin'} name="linkedin" form={form} />
        </Col>
        <ReactQuillField
          name="multiLangBio.en"
          label="Bio (EN)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <ReactQuillField
          name="multiLangBio.es"
          label="Bio (ES)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <ReactQuillField
          name="multiLangBio.de"
          label="Bio (DE)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <ReactQuillField
          name="multiLangBio.it"
          label="Bio (IT)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <ReactQuillField
          name="multiLangBio.pt"
          label="Bio (PT)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <ReactQuillField
          name="multiLangBio.zh"
          label="Bio (ZH)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />{' '}
      </Row>
    </Form>
  );
};

export default EditUserForm;
