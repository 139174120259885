/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'antd';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { InputField, RadioField, SelectField } from '../common/form';
import {
  stripeAvailablityCountries,
  stripeCurrency,
} from '../../constants/stripeCountry';
import { AdminType } from '../../services/users-service';

interface IAddInstructorPaymentProcessorFormProps {
  form: UseFormReturn<any, any>;
  allowedAdminType: AdminType[];
}
const AddInstructorPaymentProcessorForm = ({
  form,
  allowedAdminType,
}: IAddInstructorPaymentProcessorFormProps) => {
  const values = form.getValues();

  const adminType = form.watch('adminType');
  const paymentProcessor = form.watch('paymentProcessor');
  const haveStripeId = form.watch('haveStripeId');

  useEffect(() => {
    haveStripeId
      ? form.setValue('stripeId', values?.stripeConenct)
      : form.setValue('stripeId', undefined);
    form.setValue('accountHolderName', undefined);
    form.setValue('accountNumber', undefined);
    form.setValue('routingNumber', undefined);
    form.setValue('accountHolderType', undefined);
    form.setValue('currency', undefined);
    form.setValue('country', undefined);
  }, [haveStripeId]);

  return (
    <>
      <Form layout="vertical">
        {allowedAdminType.includes(adminType) && (
          <>
            <SelectField
              label="Select payment processor"
              required
              name="paymentProcessor"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: [
                  { label: 'Stripe', value: 'stripe' },
                  { label: 'Paypal', value: 'paypal' },
                  { label: 'Ebanx', value: 'ebanx' },
                ],
                placeholder: 'Select payment processor',
                onChange: (v) => {
                  form.setValue('paymentProcessor', v);
                  form.setValue('stripeId', values?.stripeConenct);
                  form.setValue('accountHolderName', undefined);
                  form.setValue('accountNumber', undefined);
                  form.setValue('routingNumber', undefined);
                  form.setValue('accountHolderType', undefined);
                  form.setValue('currency', undefined);
                  form.setValue('country', undefined);
                  form.setValue('paypalEmailId', undefined);
                  form.setValue('haveStripeId', !!values?.stripeConenct);
                },
              }}
            />

            {paymentProcessor === 'stripe' && (
              <>
                <RadioField
                  label="Do you have stripe account Id ?"
                  name="haveStripeId"
                  form={form}
                  radioFieldProps={{
                    options: [
                      {
                        label: 'Yes',
                        value: true,
                      },
                      {
                        label: 'No',
                        value: false,
                      },
                    ],
                  }}
                />
                {haveStripeId ? (
                  <InputField
                    label="Stripe Account Id"
                    name="stripeId"
                    inputProps={{
                      placeholder: 'Stripe account id',
                      type: 'text',
                    }}
                    form={form}
                  />
                ) : (
                  <>
                    <InputField
                      label="Account Holder Name"
                      name="accountHolderName"
                      required
                      inputProps={{
                        placeholder: 'Account holder name',
                        type: 'text',
                      }}
                      form={form}
                    />
                    <InputField
                      label="Account Number"
                      name="accountNumber"
                      required
                      inputProps={{
                        placeholder: 'Bank Account Number',
                        type: 'text',
                      }}
                      form={form}
                    />

                    <InputField
                      label="Routing Number"
                      name="routingNumber"
                      inputProps={{
                        placeholder: 'Router Number',
                        type: 'text',
                      }}
                      form={form}
                    />

                    <SelectField
                      label="Account Type"
                      required
                      name="accountHolderType"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: [
                          {
                            lable: 'individual',
                            value: 'individual',
                          },
                          { lable: 'company', value: 'company' },
                        ],
                        placeholder: 'Select Account Type',
                        onChange: (v) => form.setValue('accountHolderType', v),
                      }}
                    />
                    <SelectField
                      label="Stripe A/C Currency"
                      required
                      name="currency"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: stripeCurrency,
                        placeholder: 'select currency',
                        onChange: (v) => form.setValue('currency', v),
                      }}
                    />
                    <SelectField
                      label="Country"
                      required
                      name="country"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: stripeAvailablityCountries,
                        placeholder: 'select country',
                        onChange: (v) => form.setValue('country', v),
                      }}
                    />
                  </>
                )}
              </>
            )}
            {paymentProcessor === 'paypal' && (
              <>
                <InputField
                  label="Paypal Email Id"
                  required
                  name="paypalEmailId"
                  inputProps={{
                    placeholder: 'Paypal Email Id',
                    type: 'text',
                  }}
                  form={form}
                />
              </>
            )}
          </>
        )}

        <br />
      </Form>
    </>
  );
};

export default AddInstructorPaymentProcessorForm;
