import { Button, Col, Row, Typography } from 'antd';
import { useState } from 'react';

import Login from '../../components/authentication/login';
import ForgotPassword from '../../components/authentication/forgot-password';
import { useAuthState } from '../../context/auth-state';
import './style.scss';

const { Title } = Typography;

const Auth = () => {
  const [login, setLogin] = useState(true);
  const { session } = useAuthState();

  // useEffect(() => {
  //   if (session) {
  //     // navigate('/all-users');
  //   }
  // }, [session, navigate]);

  return (
    <>
      {!session && (
        <Row>
          <Col span={8} offset={8}>
            <div className="login">
              <Title className="heading" level={3}>
                {login ? 'Login into your account' : 'Forgot Password?'}
              </Title>
              {login ? <Login /> : <ForgotPassword />}
              <Button className="login_form_forgot" type="link" block>
                <span onClick={() => setLogin(!login)}>
                  {login ? 'Forgot password?' : 'Log in'}
                </span>
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
export default Auth;
