/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Modal, Button, message } from 'antd';

import { onBoradingCall } from '../../services/stripe';
import { useAuthState } from '../../context/auth-state';

const AlertComp: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { user } = useAuthState();

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      const { data } = await onBoradingCall(window.location.href);

      window.location.href = data?.data.url;
      setOpen(false);
    } catch (error: any) {
      message.error(error?.response?.data?.MESSAGE_KEY || error?.message);
      console.log(error?.response || error);
    } finally {
      setConfirmLoading(false);
    }
  };

  useEffect(() => {
    if (
      user &&
      user?.paymentProcessor === 'stripe' &&
      !user?.stripeConnectVerified
    ) {
      setOpen(true);
    }
  }, [user]);

  return (
    <>
      <Modal
        title="Complete the Verification"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        footer={[
          <Button
            key="onBoard"
            type="primary"
            loading={confirmLoading}
            onClick={handleOk}
          >
            OK
          </Button>,
        ]}
      >
        <p>
          Please Complete your on boarding process to create your workshop .It
          can take some time.
        </p>
      </Modal>
    </>
  );
};

export default AlertComp;
