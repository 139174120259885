import { Button, Col, Drawer, Row, Spin, Table, message } from 'antd';
import { createElement, FC, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  IExpenseFieldValue,
  Workshop,
  WorkshopAttendee,
} from '../../../types/workshop';
import {
  addExpense,
  getWorkshopAttendees,
} from '../../../services/workshop-service';
import {
  convertStripeAmountWithoutDecimal,
  handleError,
} from '../../../utils/common';
import { InputField } from '../../common/form';

import validationSchema from './validation-schema';
// import paymentRouter from '../../../../../pbt-backend-sls-ts/dist/src/modules/payment/paymentRouter';
// import { transferCreditUserToWorkshop } from '../../../services/credit-list';

interface IWorkshopIncomeProps {
  children: FC<{
    onClick: () => void;
  }>;
  workshopSlug: string;
}

const incomeColumns = [
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: 'Event currency',
    dataIndex: 'eventcur',
    key: 'eventcur',
  },
  {
    title: 'Pymt amt',
    dataIndex: 'paymtAmt',
    key: 'paymtAmt',
  },
  {
    title: 'Off',
    dataIndex: 'off',
    key: 'off',
  },
  {
    title: 'Adjust',
    dataIndex: 'adjust',
    key: 'adjust',
  },
  {
    title: 'Pgwy Fee',
    dataIndex: 'pgwyFee',
    key: 'pgwyFee',
  },
  {
    title: 'PBT Fee',
    dataIndex: 'pbtFee',
    key: 'pbtFee',
  },
  {
    title: 'Admin Income',
    dataIndex: 'adminIncome',
    key: 'adminIncome',
  },
  {
    title: 'Admin Income ( in her currency )',
    dataIndex: 'adminIncomeInHerCur',
    key: 'adminIncomeInHerCur',
    width: 150,
  },
];

const expensesColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
];

const WorkshopIncome: FC<IWorkshopIncomeProps> = ({
  children,
  workshopSlug,
}) => {
  const refChildren = useRef(children);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workshop, setWorkshop] = useState<Workshop>();
  const [attendeesList, setAttendeesList] = useState<WorkshopAttendee[]>([]);

  const form = useForm<IExpenseFieldValue>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const incomeData = useMemo(() => {
    if (!attendeesList || attendeesList?.length === 0 || !workshop) return [];

    const attendees = attendeesList?.filter((i) => !i?.isUnpaid);

    const paymentCurrency = workshop?.currency?.toUpperCase();

    const attendeesWithSameCur = attendees.filter(
      (attendee) =>
        attendee.paymentCurrency.toLowerCase() ===
        workshop.currency.toLowerCase()
    );

    const attendeesWithDifferentCur = attendees.filter(
      (attendee) =>
        attendee.paymentCurrency.toLowerCase() !==
        workshop.currency.toLowerCase()
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const aggAtt = attendeesWithDifferentCur.reduce((map: any, attendee) => {
      if (map[attendee?.paymentCurrency]) {
        map[attendee?.paymentCurrency].push(attendee);
      } else {
        map[attendee?.paymentCurrency] = [attendee];
      }
      return map;
    }, {});

    const incomes = [
      {
        count: attendeesWithSameCur.length,
        eventcur: paymentCurrency.toUpperCase(),
        paymtAmt: convertStripeAmountWithoutDecimal(
          attendeesWithSameCur.reduce(
            (acc, current) =>
              acc +
              (current?.HPLShareInPaymentCurrency || 0) +
              (current?.instructorShareInPaymentCurrency || 0) +
              (current?.techniqueOwnerShareInPaymentCurrency || 0) +
              (current?.gatewayFeeInPaymentCurrency || 0),
            0
          ),
          workshop?.currency
        ),
        off: 0,
        adjust: convertStripeAmountWithoutDecimal(
          attendeesWithSameCur?.reduce(
            (acc, current) => acc + (current?.expenseCollected || 0),
            0
          ),
          workshop?.currency
        ),
        pgwyFee: convertStripeAmountWithoutDecimal(
          attendeesWithSameCur.reduce(
            (acc, current) => acc + (current?.gatewayFeeInPaymentCurrency || 0),
            0
          ),
          workshop?.currency
        ),
        pbtFee: convertStripeAmountWithoutDecimal(
          attendeesWithSameCur.reduce(
            (acc, current) => acc + (current?.HPLShareInPaymentCurrency || 0),
            0
          ),
          workshop?.currency
        ),
        adminIncome: convertStripeAmountWithoutDecimal(
          attendeesWithSameCur.reduce(
            (acc, current) =>
              acc + (current?.instructorShareInPaymentCurrency || 0),
            0
          ),
          workshop?.currency
        ),
        adminIncomeInHerCur: (
          <p style={{ marginBottom: 0 }}>
            {convertStripeAmountWithoutDecimal(
              attendeesWithSameCur.reduce(
                (acc, current) =>
                  acc + (current?.instructorShareInTransferCurrency || 0),
                0
              ),
              workshop?.currency
            )}
          </p>
        ),
      },
      ...(
        Object.entries(aggAtt) as unknown as [string, WorkshopAttendee[]][]
      ).map(([key, attendeeArr]: [string, WorkshopAttendee[]]) => {
        // Your logic here
        return {
          count: attendeeArr.length,
          eventcur: attendeeArr?.[0]?.paymentCurrency.toUpperCase(),
          paymtAmt: convertStripeAmountWithoutDecimal(
            attendeeArr.reduce(
              (acc, current) =>
                acc +
                (current?.HPLShareInPaymentCurrency || 0) +
                (current?.instructorShareInPaymentCurrency || 0) +
                (current?.techniqueOwnerShareInPaymentCurrency || 0) +
                (current?.gatewayFeeInPaymentCurrency || 0),
              0
            ),
            key
          ),
          off: 0,
          adjust: convertStripeAmountWithoutDecimal(
            attendeeArr?.reduce(
              (acc, current) => acc + (current?.expenseCollected || 0),
              0
            ),
            key
          ),
          pgwyFee: convertStripeAmountWithoutDecimal(
            attendeeArr.reduce(
              (acc, current) =>
                acc + (current?.gatewayFeeInPaymentCurrency || 0),
              0
            ),
            key
          ),
          pbtFee: convertStripeAmountWithoutDecimal(
            attendeeArr.reduce(
              (acc, current) => acc + (current?.HPLShareInPaymentCurrency || 0),
              0
            ),
            key
          ),
          adminIncome: convertStripeAmountWithoutDecimal(
            attendeeArr.reduce(
              (acc, current) =>
                acc + (current?.instructorShareInPaymentCurrency || 0),
              0
            ),
            key
          ),
          adminIncomeInHerCur: (
            <p style={{ marginBottom: 0 }}>
              {convertStripeAmountWithoutDecimal(
                attendeeArr.reduce(
                  (acc, current) =>
                    acc + (current?.instructorShareInTransferCurrency || 0),
                  0
                ),
                key
              )}
            </p>
          ),
        };
      }),
    ];

    return incomes;
  }, [attendeesList, workshop]);

  const totalExpense = useMemo(() => {
    if (!workshop) return 0;

    const totalAmount = workshop?.expenses?.reduce((acc, cur) => {
      if (cur?.amount) acc += cur.amount;
      return acc;
    }, 0);

    return convertStripeAmountWithoutDecimal(totalAmount, workshop?.currency);
  }, [workshop]);

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const submitHandler = async (formData: IExpenseFieldValue) => {
    setRequesting(true);
    try {
      if (workshop) {
        await addExpense({
          payload: formData,
          workshopId: workshop?._id,
        });
        await getWorkshopAteendeesData(workshopSlug);
      }
      message.success('Successfull');
      form.reset({});
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  const getWorkshopAteendeesData = async (workshopSlug: string) => {
    setLoading(true);
    try {
      const response = await getWorkshopAttendees(workshopSlug);
      setWorkshop(response?.data?.data);
      setAttendeesList(response?.data?.data?.attendees || []);
      console.log(response?.data?.data);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (drawerVisible) {
      getWorkshopAteendeesData(workshopSlug);
    }
  }, [drawerVisible]);

  return (
    <>
      <Drawer
        title="Event Income Details"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={900}
        footer={false}
      >
        <Spin spinning={loading}>
          <div style={{ padding: 11 }}>
            <div>
              <h2 style={{ fontWeight: '600', marginBottom: '0px' }}>
                {workshop?.title}
              </h2>
              <p style={{ marginBottom: '2px' }}>
                <span>
                  {workshop?.workshopMode === 'virtual'
                    ? 'Online'
                    : workshop?.city}
                </span>
                <span>{`, ` + workshop?.country}</span>
              </p>
              <p style={{ marginBottom: '10px' }}>
                <span>
                  {moment(workshop?.startDate)
                    .tz(workshop?.timezone || 'UTC')
                    .format('MMM DD, YYYY h:mm a')}
                </span>
                <span> ( {workshop?.timezone} )</span>
              </p>
              <hr />
              <div className="workshop-income-views-container">
                <p className="workshop-income-views-container-title">
                  Total Page Views :
                </p>
                <p className="workshop-income-views-container-value">
                  {workshop?.eventPageVisitCount ?? 0}
                </p>
              </div>
              <h2 style={{ fontWeight: '600' }}>Income</h2>
              <div>
                <Table
                  dataSource={incomeData}
                  columns={incomeColumns}
                  pagination={false}
                />
              </div>

              <div style={{ padding: 11 }}>
                <h2 style={{ fontWeight: '600' }}>Expenses</h2>
                <div>
                  <Table
                    dataSource={
                      workshop && workshop?.expenses
                        ? workshop?.expenses?.map((i) => ({
                            name: i?.name,
                            amount: convertStripeAmountWithoutDecimal(
                              Number(i?.amount),
                              workshop?.currency
                            ),
                          }))
                        : []
                    }
                    columns={expensesColumns}
                    pagination={false}
                  />
                  <p style={{ fontWeight: '600', marginTop: '10px' }}>
                    Total Expenses : {totalExpense}
                  </p>
                </div>
              </div>
              <div>
                <h2 style={{ fontWeight: '600', marginTop: '10px' }}>
                  Add Expense
                </h2>

                <Row style={{ marginBottom: '0px' }}>
                  <Col span={7}>
                    <InputField
                      name="name"
                      form={form}
                      inputProps={{
                        placeholder: 'Expense Name ( e.g. Facebook Ad )',
                      }}
                    />
                  </Col>
                  <Col span={7}>
                    <InputField
                      name="amount"
                      form={form}
                      inputProps={{
                        placeholder: 'Amount ( e.g. 250)',
                      }}
                    />{' '}
                  </Col>
                  <Col span={3}>
                    <Button
                      type="primary"
                      onClick={form.handleSubmit(submitHandler)}
                      loading={requesting}
                    >
                      ADD
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Spin>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default WorkshopIncome;
