import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { SelectField, TextAreaField } from '../../common/form';
import { TechniqueDetail } from '../../../types/technique';

interface IReivewFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, object>;
  techniques: TechniqueDetail[];
}
const ReviewForm = ({ form, techniques }: IReivewFormProps) => {
  const assignedCertificationCode = form.watch('assignedCertificationCode');

  return (
    <>
      <Form layout="vertical">
        <SelectField
          label="Technique"
          name="assignedCertificationCode"
          form={form}
          labelKey="name.en"
          valueKey="slug"
          selectFieldProps={{
            options: techniques,
            placeholder: 'Select technique',
            onChange: (v) => form.setValue('assignedCertificationCode', v),
            mode: 'multiple',
            allowClear: true,
          }}
        />

        {assignedCertificationCode &&
          assignedCertificationCode?.map((i: string) => {
            const currentTechnique = techniques?.find(
              (item) => item?.slug === i
            );
            return (
              <SelectField
                key={i}
                label={`${i} Certification Level to assign`}
                name={`assignedCertificationLevel.${i}`}
                form={form}
                required
                labelKey="label"
                valueKey="value"
                selectFieldProps={{
                  options: currentTechnique?.isCertificationMultiLevel
                    ? [
                        { label: 'One', value: 1 },
                        { label: 'Two', value: 2 },
                        { label: 'Three', value: 3 },
                      ]
                    : [{ label: 'One', value: 1 }],
                  placeholder: 'Select Certification Category',
                  onChange: (v) =>
                    form.setValue(`assignedCertificationLevel.${i}`, v),
                }}
              />
            );
          })}
        <TextAreaField
          label="Remarks"
          name="remarks"
          textAreaProps={{
            placeholder: 'Type Remarks',
            rows: 5,
          }}
          form={form}
        />
      </Form>
    </>
  );
};

export default ReviewForm;
