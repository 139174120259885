import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { getTechniques } from '../../services/technique-service';
import { WorkshopInstructor } from '../../types/instructor';
import { TechniqueDetail } from '../../types/technique';
import {
  FileUploadButton,
  InputField,
  RadioField,
  SelectField,
} from '../common/form';
import ReactQuillField from '../common/form/react-quill-fields';
import ProfilePicUploadButton from '../upload-user-profile-pic';
import { handleError } from '../../utils/common';
import { AdminType } from '../../services/users-service';
// import { useAuthState } from '../../context/auth-state';

interface IAddInstructorFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
  isEditable: boolean;
  instructors?: WorkshopInstructor[];
  readOnly: boolean;
}
const AddInstructorForm = ({
  form,
  isEditable,
  instructors,
  readOnly,
}: IAddInstructorFormProps) => {
  const values = form.getValues();
  // const { user } = useAuthState();

  const adminType = form.watch('adminType');
  const techniquesAllowed = form.watch('techniquesAllowed');
  const isUsingDefaultTechniqueShares = form.watch(
    'isUsingDefaultTechniqueShares'
  );

  const [techniques, setTechniques] = useState<TechniqueDetail[]>([]);

  const getAllTechnique = async () => {
    try {
      const response = await getTechniques();
      if (response) {
        setTechniques(response?.data?.data);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getAllTechnique();
  }, []);

  return (
    <>
      <Form layout="vertical">
        <InputField
          label="Sequence no."
          name="instructorSequenceNo"
          required
          inputProps={{
            placeholder: 'Sequence number',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Name"
          name="fullName"
          required
          inputProps={{
            placeholder: 'Name',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />

        <InputField
          label="Name as under signature"
          required
          name="nameAsUnderSignature"
          inputProps={{
            placeholder: 'Name as under signature',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Email"
          name="email"
          required
          inputProps={{
            placeholder: 'Name',
            type: 'email',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Contact Email"
          name="contactEmail"
          required
          inputProps={{
            placeholder: 'Name',
            type: 'email',
          }}
          form={form}
          disabled={readOnly}
        />
        <SelectField
          label="Admin Type"
          required
          name="adminType"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: [
              { label: 'Workshop Admin', value: AdminType.workshop },
              { label: 'Workshop Instructor', value: AdminType.instructor },
            ],
            placeholder: 'Select admin type',
            onChange: (v) => {
              form.setValue('adminType', v);
            },
            disabled: readOnly,
          }}
        />
        <InputField
          label="Designation (EN)"
          name="designation.en"
          required
          inputProps={{
            placeholder: 'Designation (EN)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Designation (ES)"
          name="designation.es"
          required
          inputProps={{
            placeholder: 'Designation (ES)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Designation (DE)"
          name="designation.de"
          required
          inputProps={{
            placeholder: 'Designation (DE)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Designation (IT)"
          name="designation.it"
          required
          inputProps={{
            placeholder: 'Designation (IT)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Designation (PT)"
          name="designation.pt"
          required
          inputProps={{
            placeholder: 'Designation (PT)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Designation (ZH)"
          name="designation.zh"
          required
          inputProps={{
            placeholder: 'Designation (ZH)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />

        <InputField
          label="Credentials (EN)"
          name="credentials.en"
          inputProps={{
            placeholder: 'credentials (EN)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Credentials (ES)"
          name="credentials.es"
          inputProps={{
            placeholder: 'credentials (ES)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Credentials (DE)"
          name="credentials.de"
          inputProps={{
            placeholder: 'credentials (DE)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Credentials (IT)"
          name="credentials.it"
          inputProps={{
            placeholder: 'credentials (IT)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Credentials (PT)"
          name="credentials.pt"
          inputProps={{
            placeholder: 'credentials (PT)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Credentials (ZH)"
          name="credentials.zh"
          inputProps={{
            placeholder: 'credentials (ZH)',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Facebook"
          name="facebook"
          inputProps={{
            placeholder: 'Facebook',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Twitter"
          name="twitter"
          inputProps={{
            placeholder: 'Twitter',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="LinkedIn"
          name="linkedin"
          inputProps={{
            placeholder: 'LinkedIn',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <InputField
          label="Instagram"
          name="instagram"
          inputProps={{
            placeholder: 'Instagram',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />
        <ReactQuillField
          name="multiLangBio.en"
          label="Bio (EN)"
          required
          form={form}
          readOnly={readOnly}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <ReactQuillField
          name="multiLangBio.es"
          label="Bio (ES)"
          required
          form={form}
          readOnly={readOnly}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <ReactQuillField
          name="multiLangBio.de"
          label="Bio (DE)"
          required
          form={form}
          readOnly={readOnly}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <ReactQuillField
          name="multiLangBio.it"
          label="Bio (IT)"
          required
          form={form}
          readOnly={readOnly}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <ReactQuillField
          name="multiLangBio.pt"
          label="Bio (PT)"
          required
          form={form}
          readOnly={readOnly}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <ReactQuillField
          name="multiLangBio.zh"
          label="Bio (ZH)"
          required
          form={form}
          readOnly={readOnly}
          reactQuillProps={{
            theme: 'snow',
          }}
        />

        <InputField
          label="Advance Launch Days"
          name="advanceLaunchDays"
          inputProps={{
            placeholder: 'Minimum number of days',
            type: 'text',
          }}
          form={form}
          disabled={readOnly}
        />

        <SelectField
          label="Assistant instructor"
          name="assistantInstructor"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: instructors?.map((option) => ({
              label: `${option?.fullName}/${option?.email}`,
              value: option?._id,
            })),
            placeholder: 'Assistant instructor',
            onChange: (v) => form.setValue('assistantInstructor', v),
            disabled: readOnly,
          }}
        />

        <SelectField
          label="Techniques allowed"
          name="techniquesAllowed"
          form={form}
          labelKey="label"
          valueKey="value"
          mode="multiple"
          selectFieldProps={{
            options: techniques.map((option) => ({
              label: option?.name?.en,
              value: option?.slug,
            })),
            placeholder: 'Select technique',
            onChange: (v) => {
              form.setValue('techniquesAllowed', v);
              form.setValue('customPaymentShares', undefined);
              form.setValue('isUsingDefaultTechniqueShares', undefined);
            },
            mode: 'multiple',
            allowClear: true,
            disabled: readOnly,
          }}
        />

        {adminType === AdminType.workshop ? (
          <SelectField
            label="Assign Instructors"
            name="assignedInstructor"
            form={form}
            labelKey="label"
            valueKey="value"
            mode="multiple"
            selectFieldProps={{
              options: [
                ...(instructors || [])
                  // ?.filter((i) => i?._id?.toString() !== user?._id?.toString())
                  ?.map((i) => ({
                    label: `${i?.fullName} (${i?.email})`,
                    value: i?._id,
                  })),
              ],
              placeholder: 'Assign Instructor',
              onChange: (v) => {
                form.setValue('assignedInstructor', v);
              },
              mode: 'multiple',
              allowClear: true,
              disabled: readOnly,
            }}
          />
        ) : null}

        {techniquesAllowed?.length && adminType === AdminType.workshop ? (
          <RadioField
            label="Do you want to use default technique's payment shares ?"
            name="isUsingDefaultTechniqueShares"
            form={form}
            radioFieldProps={{
              options: [
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ],
            }}
          />
        ) : null}

        {!isUsingDefaultTechniqueShares &&
        adminType === AdminType.workshop &&
        techniquesAllowed?.length
          ? techniquesAllowed.map((techiqueSlug: string) => {
              const technique = techiqueSlug?.toUpperCase();
              return (
                <div>
                  <InputField
                    label={`Owner share (${technique})`}
                    name={`customPaymentShares.${techiqueSlug}.ownerShare`}
                    inputProps={{
                      placeholder: 'Owner share',
                      type: 'text',
                    }}
                    form={form}
                    disabled={readOnly}
                    required
                  />
                  <InputField
                    label={`HPL share (${technique})`}
                    name={`customPaymentShares.${techiqueSlug}.hplShare`}
                    inputProps={{
                      placeholder: 'HPL share',
                      type: 'text',
                    }}
                    form={form}
                    disabled={readOnly}
                    required
                  />
                  <InputField
                    label={`Instructor share (${technique})`}
                    name={`customPaymentShares.${techiqueSlug}.instructorShare`}
                    inputProps={{
                      placeholder: 'Instructor share',
                      type: 'text',
                    }}
                    form={form}
                    disabled={readOnly}
                    required
                  />
                </div>
              );
            })
          : null}

        <ProfilePicUploadButton
          form={form}
          defaultFileList={
            isEditable
              ? values.pic.pic
                ? [
                    {
                      uid: '-1',
                      name: `${values.fullName}.png`,
                      status: 'done',
                      url: values.pic.pic as string,
                    },
                  ]
                : []
              : []
          }
          label="Upload Image"
          name="pic"
          buttonLabel="Click to Upload Pic"
          disabled={readOnly}
        />
        <FileUploadButton
          form={form}
          defaultFileList={
            isEditable
              ? values.signature
                ? [
                    {
                      uid: '-1',
                      name: `${values.fullName}-signature.png`,
                      status: 'done',
                      url: values.signature,
                    },
                  ]
                : []
              : []
          }
          label="Upload Signature Image"
          name="signature"
          buttonLabel="Click to Upload Signature Image"
          disabled={readOnly}
        />
        <br />
      </Form>
    </>
  );
};

export default AddInstructorForm;
