import { NOT_AVAILABLE } from '../../constants/common';
import { IDeals } from '../../types/deals';

const columns = [
  {
    title: 'SNo.',
    render: (value: string, record: IDeals, index: number) => index + 1,
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: IDeals) => record?.name || NOT_AVAILABLE,
    width: 400,
  },
  {
    title: 'Type',
    render: (value: string, record: IDeals) => record?.type || NOT_AVAILABLE,
    width: 400,
  },
  {
    title: 'Action',
    render: (value: string, record: IDeals) =>
      record?.action?.type || NOT_AVAILABLE,
    width: 400,
  },
];

export default columns;
