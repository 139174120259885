import {
  Button,
  PaginationProps,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { ReloadOutlined, LinkOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { getUnansweredComments } from '../../services/exercises';
import { IUnansweredComments } from '../../types/MultiLingual-ExerciseVideo';

import columns from './columns';

interface unansweredCommentsResponsesProps {
  totalDocuments: number;
  totalPages: number;
  currentPage: string;
  unansweredComments: IUnansweredComments[];
}

const UnansweredComments = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadComments, setReloadComments] = useState(false);
  const [unansweredCommentsResponses, setUnansweredCommentsResponses] =
    useState<unansweredCommentsResponsesProps>({
      totalDocuments: 0,
      totalPages: 0,
      currentPage: '1',
      unansweredComments: [],
    });

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const page = queryParams.get('page')
    ? parseInt(queryParams.get('page') || '')
    : 1;
  const limit = queryParams.get('limit')
    ? parseInt(queryParams.get('limit') || '')
    : 10;

  const loadUnansweredComments = async () => {
    setRequesting(true);
    try {
      const response = await getUnansweredComments({
        page: page,
        ...(limit && { limit }),
      });
      setUnansweredCommentsResponses(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadComments(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadComments) {
      loadUnansweredComments();
    }
  }, [reloadComments, requesting]);

  useEffect(() => {
    setReloadComments(true);
  }, [page, limit, location.pathname]);

  useEffect(() => {
    loadUnansweredComments();
  }, []);

  const videoUrl = (record: IUnansweredComments) => {
    const videoType = record?.isClassVideo ? 'classes' : 'tutorials';

    return `${process.env.REACT_APP_FRONT_END_URL}/library/${record?.firstCategory?.techniqueSlug}/${record?.firstCategory?.sectionSlug}/${videoType}/${record?.firstCategory?.categorySlug}/${record?.slug}`;
  };
  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      render: (value: string, record: IUnansweredComments) => {
        return (
          <Space size="large">
            <Tooltip title="Reply">
              <Link to={videoUrl(record)} target="_blank">
                <LinkOutlined
                  style={{ cursor: 'pointer', fontSize: '1.2rem' }}
                />
              </Link>
            </Tooltip>
          </Space>
        );
      },
      key: 'actions',
      width: 200,
    },
  ];

  const handleTableChange: PaginationProps['onChange'] = (pagination, size) => {
    setReloadComments(true);
    navigate({
      pathname: location.pathname,
      search: `?page=${pagination}&limit=${size}`,
    });
  };

  return (
    <Spin spinning={requesting}>
      <Table
        key={JSON.stringify(unansweredCommentsResponses?.unansweredComments)}
        dataSource={unansweredCommentsResponses?.unansweredComments}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={{
          position: ['bottomRight'],
          total: unansweredCommentsResponses?.totalDocuments,
          defaultCurrent: page,
          defaultPageSize: limit,
          onChange: handleTableChange,
          showSizeChanger: true,
        }}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Unanswered Comments</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadComments(true)}
                  />
                </Tooltip>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};

export default UnansweredComments;
