import { Button, Drawer } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import { SectionDetail } from '../../../../types/sections';

import ViewForm from './viewForm';

interface IViewSectionProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  payload: SectionDetail;
  sectionId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const ViewSection: FC<IViewSectionProps> = ({
  children,
  payload,
  sectionId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<SectionDetail>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  useEffect(() => {
    form.reset(payload);
  }, [payload]);

  return (
    <>
      <Drawer
        title="Section Details"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={handleDrawerVisibility}
            >
              Done
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <ViewForm isEditable={true} form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default ViewSection;
