import * as yup from 'yup';

export default yup.object().shape({
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
  name: yup.object().shape({
    en: yup.string().required('Please input muscle category name in english'),
    pt: yup.string().required('Please input muscle category name in portugese'),
    it: yup.string().required('Please input muscle category name in italian'),
    es: yup.string().required('Please input muscle category name in spanish'),
    de: yup.string().required('Please input muscle category name in german'),
    zh: yup.string().required('Please input muscle category name in mandrin'),
  }),
});
