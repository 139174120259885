/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Row, Upload } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import { UseFormReturn } from 'react-hook-form';

import {
  getTencentSignature,
  getTencentVideoData,
} from '../../../services/exercises';
import { getCoverFile } from '../../../utils/common';
import './tencent.scss';
import Tcplayer from '../tencent-player';

interface TencentVideoUploaderProps {
  form: UseFormReturn<any, any>;
}

const TencentVideoUploader: React.FC<TencentVideoUploaderProps> = ({
  form,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploaderInstance, setUploaderInstance] = useState<any>(null);

  const fileID = form.watch('mediaId');

  const handleFileChange = async ({ file, onProgress, onSuccess }: any) => {
    setIsUploading(true);

    try {
      const {
        data: {
          data: { signature },
        },
      } = await getTencentSignature();

      const tcVod = new (window as any).TcVod.default({
        getSignature: () => Promise.resolve(signature),
      });

      const coverFile = await getCoverFile(file, 5);

      const uploader = tcVod.upload({
        mediaFile: file,
        coverFile,
      });

      setUploaderInstance(uploader);

      uploader.on('media_progress', (info: any) => {
        onProgress({ percent: Math.round(info.percent * 100) });
      });

      const { fileId } = await uploader.done();
      const {
        data: { data: videoData },
      } = await getTencentVideoData(fileId);

      ['videoUrl', 'thumb', 'mediaId', 'duration'].forEach((key) => {
        form.setValue(key, videoData?.[key], { shouldValidate: true });
      });

      onSuccess(fileId, file);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleUploadCancel = () => {
    if (uploaderInstance) {
      uploaderInstance.cancel();
    }
    ['videoUrl', 'thumb', 'mediaId', 'duration'].forEach((key) => {
      form.setValue(key, undefined);
    });
    setIsUploading(false);
  };

  const uploadButton = (
    <div className="tencent-upload-button">
      <VideoCameraOutlined style={{ fontSize: '35px' }} />
      <div>Drag & Drop or browse</div>
    </div>
  );

  return (
    <>
      <Upload
        accept="video/*"
        customRequest={handleFileChange}
        listType="picture"
        maxCount={1}
        showUploadList={{ showPreviewIcon: false }}
        className="assessment-video-uploader"
        onRemove={handleUploadCancel}
      >
        {isUploading || form.watch('mediaId') ? null : uploadButton}
      </Upload>
      <br />
      <Row key={fileID}>{fileID && <Tcplayer fileId={fileID} />}</Row>
    </>
  );
};

export default TencentVideoUploader;
