import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { Files, FileDetail } from '../../types/file';
import { FileUploadButton, InputField, SelectField } from '../common/form';

interface FileFormProps {
  form: UseFormReturn<FileDetail, object>;
}

const FILE_TYPES = [
  {
    value: Files.banner,
    label: 'Workshop Banners',
  },
  {
    value: Files.note,
    label: 'English Notes',
  },
  {
    value: Files.equipment,
    label: 'English Equipments',
  },
  {
    value: Files.ne_note,
    label: 'Non-English Notes',
  },
  {
    value: Files.ne_equipment,
    label: 'Non-English Equipments',
  },
];

const FileForm = ({ form }: FileFormProps) => {
  const values = form.getValues();
  return (
    <>
      <Form layout="vertical">
        <InputField
          label="File Name"
          name="name"
          required
          inputProps={{
            placeholder: 'Enter file name',
          }}
          form={form}
        />
        <InputField
          label="Description"
          name="desc"
          inputProps={{
            placeholder: 'Enter file description',
          }}
          form={form}
        />
        <SelectField
          label="File Type"
          required
          name="type"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: FILE_TYPES,
            placeholder: 'Select File Type',
            onChange: (v) => form.setValue('type', v),
          }}
        />

        <FileUploadButton
          form={form}
          triggerValidate={() => form.trigger(['file'])}
          defaultFileList={
            values.file
              ? [
                  {
                    uid: '-1',
                    name: `${values.name}.png`,
                    status: 'done',
                    url: values.file as string,
                  },
                ]
              : []
          }
          label="File"
          name="file"
          buttonLabel="Upload File"
          allowedFileTypes={[
            'image/png',
            'image/webp',
            'image/jpg',
            'image/jpeg',
            'application/pdf',
          ]}
        />
      </Form>
    </>
  );
};

export default FileForm;
