import { Col, Form, TreeSelect } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { SectionDetail } from '../../types/sections';
import { InputField } from '../common/form';
import { TechniqueDetail } from '../../types/technique';
import TreeSelectField from '../common/form/tree-select';
import { ExerciseClasses } from '../../types/exercise-classes';
import SlugNote from '../common/slug-note';

export type ClassesResArr = {
  value: string;
  label: string;
};
interface IClassesFormProps {
  form: UseFormReturn<ExerciseClasses, object>;
  isEditable: boolean;
  sections: SectionDetail[];
  techniques: TechniqueDetail[];
}
const ClassesForm = ({
  form,
  sections,
  techniques,
  isEditable,
}: IClassesFormProps) => {
  return (
    <>
      <Form layout="vertical">
        <Col>
          <InputField
            label="Slug"
            name="slug"
            required
            disabled={isEditable === true ? true : false}
            inputProps={{
              placeholder: 'Slug : eg(Classes-slug)',
            }}
            form={form}
            style={{ marginBottom: '4px' }}
          />
          <SlugNote />
        </Col>

        <TreeSelectField
          name="sections"
          label="Sections"
          form={form}
          required
          treeSelectFieldProps={{
            treeData: techniques.map((technique, index) => {
              return {
                label: technique.slug,
                value: JSON.stringify(
                  sections
                    .filter((item) => item.techniqueId.includes(technique.slug))
                    .map((section) => {
                      return {
                        techniqueSlug: technique.slug,
                        sectionSlug: section.slug,
                      };
                    })
                ),
                children: sections
                  .filter((item) => item.techniqueId.includes(technique.slug))
                  .map((section) => {
                    return {
                      label: `${section.slug}`,
                      value: JSON.stringify({
                        techniqueSlug: technique.slug,
                        sectionSlug: section.slug,
                      }),
                    };
                  }),
              };
            }),
            placeholder: 'Please Select Sections',
            style: {
              width: '100%',
            },
            treeCheckable: true,
            showCheckedStrategy: TreeSelect.SHOW_PARENT,
          }}
        />

        <InputField
          label="Name EN"
          name="name.en"
          required
          inputProps={{
            placeholder: 'WARM UP',
          }}
          form={form}
        />
        <InputField
          label="Name PT"
          name="name.pt"
          required
          inputProps={{
            placeholder: 'AQUECER',
          }}
          form={form}
        />
        <InputField
          label="Name IT"
          name="name.it"
          required
          inputProps={{
            placeholder: 'RISCALDARE',
          }}
          form={form}
        />
        <InputField
          label="Name ES"
          name="name.es"
          required
          inputProps={{
            placeholder: 'CALENTAMIENTO',
          }}
          form={form}
        />
        <InputField
          label="Name DE"
          name="name.de"
          required
          inputProps={{
            placeholder: 'AUFWARMEN',
          }}
          form={form}
        />
        <InputField
          label="Name ZH"
          name="name.zh"
          required
          inputProps={{
            placeholder: '暖身',
          }}
          form={form}
        />
        <InputField
          label="Sequence"
          name="sequence"
          required
          inputProps={{
            placeholder: '1',
          }}
          form={form}
        />
      </Form>
    </>
  );
};

export default ClassesForm;
