/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Form, Tooltip, Typography } from 'antd';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { QuestionCircleOutlined, DeleteFilled } from '@ant-design/icons';
import { useEffect } from 'react';

import { InputField, SelectField } from '../common/form';
import { FaqCategory } from '../../types/faq';
import SlugNote from '../common/slug-note';

import SortableList from './dragable';

interface IUserPageFormProps {
  form: UseFormReturn<any, object>;
  isEditable: boolean;
  faqCategories: FaqCategory[];
}
const UserPageForm = ({
  form,
  isEditable,
  faqCategories,
}: IUserPageFormProps) => {
  const { control } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'videos',
  });

  useEffect(() => {
    if (!isEditable) {
      append({});
    }
  }, []);
  return (
    <>
      <Form layout="vertical">
        <Col>
          <InputField
            label="Slug"
            name="slug"
            required
            inputProps={{
              placeholder: 'Slug : eg(template-slug)',
              disabled: isEditable,
            }}
            form={form}
            disabled={isEditable}
            style={{ marginBottom: '4px' }}
          />
          <SlugNote />
        </Col>
        <InputField
          label="Page Name"
          name="name"
          required
          inputProps={{
            placeholder: 'Page Name',
          }}
          form={form}
        />
        <SelectField
          label="Faq Categories"
          name="faqCategories"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: faqCategories?.map((category) => {
              return {
                label: category?.category_name?.en,
                value: category?.slug,
              };
            }),
            placeholder: 'Select Faq Category',
            onChange: (v) => form.setValue('faqCategories', v),
            mode: 'multiple',
            allowClear: true,
          }}
        />
        {form.watch('faqCategories')?.length ? (
          <Col>
            <h3>
              Faq Categories Sequence{' '}
              <Tooltip
                placement="top"
                title={'Drag and Drop to change sequence'}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </h3>
            <SortableList form={form} faqCategories={faqCategories} />
          </Col>
        ) : null}

        <Col>
          <p style={{ paddingTop: '20px' }}>Add Videos</p>
        </Col>
        {fields.map(({ id }, index) => {
          return (
            <div key={id} style={{ paddingBottom: '20px' }}>
              <Typography.Title level={5}>Video {index + 1}</Typography.Title>
              <InputField
                label="Media ID (EN)"
                name={`videos[${index}].en`}
                required
                inputProps={{
                  placeholder: 'Media ID (English)',
                }}
                form={form}
              />
              <InputField
                label="Media ID (ES)"
                name={`videos[${index}].es`}
                required
                inputProps={{
                  placeholder: 'Media ID (Spanish)',
                }}
                form={form}
              />
              <InputField
                label="Media ID (IT)"
                name={`videos[${index}].it`}
                required
                inputProps={{
                  placeholder: 'Media ID (Italian)',
                }}
                form={form}
              />
              <InputField
                label="Media ID (DE)"
                name={`videos[${index}].de`}
                required
                inputProps={{
                  placeholder: 'Media ID (German)',
                }}
                form={form}
              />
              <InputField
                label="Media ID (PT)"
                name={`videos[${index}].pt`}
                required
                inputProps={{
                  placeholder: 'Media ID (Portuguese)',
                }}
                form={form}
              />
              <InputField
                label="Media ID (ZH)"
                name={`videos[${index}].zh`}
                required
                inputProps={{
                  placeholder: 'Media ID (Mandarin)',
                }}
                form={form}
              />
              {fields.length > 1 && (
                <Button
                  style={{ float: 'right' }}
                  danger
                  onClick={() => remove(index)}
                >
                  <DeleteFilled />
                </Button>
              )}
            </div>
          );
        })}
        <Button type="primary" onClick={() => append({})}>
          +
        </Button>
      </Form>
    </>
  );
};

export default UserPageForm;
