import { BLOG } from '../constants/api';
import { Blog } from '../types/blog';
import fetchClient from '../utils/fetch-client';

export const getBlog = () => {
  return fetchClient.get(`${BLOG}`);
};
export const getBlogPerPage = (
  category?: string,
  page?: number,
  limit?: string
) => {
  return fetchClient.get(
    `${BLOG}/blogs?category=${category ?? ''}&page=${page ?? 1}&limit=${
      limit || 10
    }`
  );
};
export const getBlogBySlug = (slug: string) => {
  return fetchClient.get(`${BLOG}/blog/${slug}`);
};
export const addBlog = (payload: Blog) => {
  return fetchClient.post(`${BLOG}`, payload);
};

export const updateBlog = (payload: Blog, id: string) => {
  return fetchClient.put(`${BLOG}/${id}`, payload);
};
export const updateBlogStatus = (payload: object, id: string) => {
  return fetchClient.put(`${BLOG}/status/${id}`, payload);
};

export const deleteBlog = (id?: string) => {
  return fetchClient.delete(`${BLOG}/${id}`);
};
