/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UseFormReturn } from 'react-hook-form';

import { FaqCategory } from '../../types/faq';

function SortableList({
  form,
  faqCategories,
}: {
  form: UseFormReturn<any, any>;
  faqCategories: FaqCategory[];
}) {
  const items: string[] = form.watch('faqCategories');

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item === active.id);
      const newIndex = items.findIndex((item) => item === over.id);

      form.setValue('faqCategories', arrayMove(items, oldIndex, newIndex));
    }
  };

  return (
    <div
      style={{ background: '#F6F6F6', padding: '15px', borderRadius: '5px' }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={items.map((item) => item)}
          strategy={verticalListSortingStrategy}
        >
          {items.map((item, index) => (
            <SortableItem
              key={item}
              id={item}
              index={index}
              faqCategories={faqCategories}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
}

function SortableItem({
  id,
  index,
  faqCategories,
}: {
  id: string;
  index: number;
  faqCategories: FaqCategory[];
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    background: '#FFFFFF',
    padding: 10,
    marginBottom: '8px',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes} {...listeners}>
      {index + 1}.{' '}
      {faqCategories?.find((i) => i?.slug === id)?.category_name?.en}
    </div>
  );
}

export default SortableList;
