import { DEALS } from '../constants/api';
import { IDealsFormField, IDealsStatus } from '../types/deals';
import fetchClient from '../utils/fetch-client';

export const getDeals = ({
  page = 1,
  limit = 10,
  status = IDealsStatus.active,
}: {
  page: number;
  limit: number;
  status: IDealsStatus;
}) => {
  return fetchClient.get(
    `${DEALS.GET_ALL}?page=${page}&limit=${limit}&status=${status}`
  );
};

export const getDeal = ({ dealId }: { dealId: string }) => {
  return fetchClient.get(`${DEALS.GET}/${dealId}`);
};

export const addDeal = ({ payload }: { payload: IDealsFormField }) => {
  return fetchClient.post(`${DEALS.ADD}`, payload);
};

export const updateDeal = ({
  payload,
  dealId,
}: {
  payload: IDealsFormField;
  dealId: string;
}) => {
  return fetchClient.put(`${DEALS.ADD}/${dealId}`, payload);
};

export const deleteDeal = ({ dealId }: { dealId: string }) => {
  return fetchClient.delete(`${DEALS.DELETE}/${dealId}`);
};

export const updateDealStatus = ({
  dealId,
  status,
}: {
  dealId: string;
  status: string;
}) => {
  return fetchClient.put(`${DEALS.UPDATE_STATUS}/${dealId}`, {
    status: status,
  });
};
