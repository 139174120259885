import { FLAG_LIST } from '../constants/api';
import fetchClient from '../utils/fetch-client';

export const getFlagList = () => {
  return fetchClient.get(`${FLAG_LIST.GET}`);
};

export const addFlagList = (payload: {
  type: string;
  value: string;
  reason: string;
}) => {
  return fetchClient.post(`${FLAG_LIST.GET}`, payload);
};

export const updateFlagList = (
  flagListId: string,
  payload: {
    type: string;
    value: string;
    reason: string;
  }
) => {
  return fetchClient.put(`${FLAG_LIST.GET}/${flagListId}`, payload);
};

export const deleteFlagList = (flagListId: string) => {
  return fetchClient.delete(`${FLAG_LIST.GET}/${flagListId}`);
};
