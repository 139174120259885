import { FC, useState } from 'react';
import { Image, Modal, Upload, Button, message } from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import {
  TUser,
  updateUser,
  removeUserProfilePic,
  getUrlToUploadUserProfilePic,
} from '../../../services/users-service';
import fetchClient from '../../../utils/fetch-client';
import './userProfilePicture.scss';
import { handleError } from '../../../utils/common';

interface ProfilePicUrl {
  imageUrl?: string;
  width: string;
  user: TUser;
  setReloadUsers: (e: boolean) => void;
}

interface upload {
  signedUrl: string;
}

const UserProfilePicture: FC<ProfilePicUrl> = ({
  imageUrl,
  width,
  user,
  setReloadUsers,
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [updateloading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [file, setFile] = useState<File>();

  const handlePreview = () => {
    setPreviewVisible(true);
  };

  const handleDelete = () => {
    setDeleteVisible(true);
  };

  const handleUpdate = () => {
    setUpdateVisible(true);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
    setDeleteVisible(false);
    setUpdateVisible(false);
  };

  const handleDeleteConfirm = async () => {
    if (user.pic) {
      setDeleteLoading(true);

      await removeUserProfilePic({ _id: user?._id })
        .then((res) => {
          if (res.status === 200) {
            message.success('Image Deleted Succesfully');
            setDeleteLoading(false);
            setDeleteVisible(false);
            setReloadUsers(true);
          }
        })
        .catch((e) => {
          setDeleteLoading(false);
          message.error(e.message);
          setDeleteVisible(false);
        });
    } else {
      message.info('No Image to Delete');
      setDeleteVisible(false);
    }
  };

  const handleUpload = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFile(file);
    };
  };

  function resizeImage(file: File, wantedWidth: number): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const imgEl: HTMLImageElement = document.createElement('img');
      imgEl.onload = () => {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

        canvas.width = wantedWidth;
        canvas.height = wantedWidth;

        if (!ctx) {
          reject(new Error('Failed to get canvas context.'));
          return;
        }

        ctx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Failed to create blob from canvas.'));
            return;
          }

          const resizedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: Date.now(),
          });
          resolve(resizedFile);
        }, file.type);
      };

      imgEl.src = URL.createObjectURL(file);
    });
  }

  const handleUpdateConfirm = async () => {
    if (!file) {
      return;
    }

    setUpdateLoading(true);
    const id = user?._id;

    try {
      const fileType = file.type;
      const fileName = file.name;
      const fileDetails = {
        fileName,
        fileType,
      };
      const getUploadUrls = await getUrlToUploadUserProfilePic(fileDetails);

      const uploadUrls = [...getUploadUrls.data.data];

      const imageUrls: File[] = [file];

      await Promise.all(
        [100, 35].map(async (size) => {
          const resizedImage = await resizeImage(file, size);
          imageUrls.push(resizedImage);
        })
      );

      const { ...user } = {
        pic: uploadUrls[0].key,
        pic100: uploadUrls[1].key,
        pic35: uploadUrls[2].key,
      };

      const options = {
        headers: {
          'Content-Type': fileType,
        },
      };
      await Promise.all(
        uploadUrls.map(async (uploadUrl: upload, index: number) => {
          await fetchClient.put(uploadUrl.signedUrl, imageUrls[index], options);
        })
      );
      await updateUser({ _id: id, user }).then((res) => {
        message.success('Photo Updated Succesfully');
        setReloadUsers(true);
        setUpdateLoading(false);
        setUpdateVisible(false);
      });
    } catch (error) {
      handleError(error);
      setUpdateLoading(false);
    }
  };

  return (
    <div>
      <Image
        src={imageUrl || '/profile-demo.jpg'}
        style={{
          width,
          height: width,
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        preview={{
          visible: false,
          mask: (
            <div
              className="image-preview-options"
              style={{
                display: 'flex',
                width: '100%',
                gap: '10px',
                justifyContent: 'center',
              }}
            >
              {imageUrl && <EyeOutlined onClick={handlePreview} />}
              {imageUrl && (
                <DeleteOutlined
                  onClick={handleDelete}
                  style={{ color: 'red' }}
                />
              )}
              <EditOutlined onClick={handleUpdate} />
            </div>
          ),
        }}
      />
      <Modal
        open={previewVisible}
        footer={null}
        onCancel={handleCancel}
        className="profilePicModal"
      >
        <Image
          src={imageUrl}
          preview={false}
          className="user-profile-picture"
        />
      </Modal>
      <Modal
        open={deleteVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="delete"
            type="primary"
            loading={deleteLoading}
            onClick={handleDeleteConfirm}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete this image?</p>
      </Modal>
      <Modal
        open={updateVisible}
        title="Select Image To Upload"
        onCancel={handleCancel}
        footer={[
          <Button key="cancle" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="update"
            type="primary"
            loading={updateloading}
            onClick={handleUpdateConfirm}
          >
            Update
          </Button>,
        ]}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ImgCrop showGrid rotationSlider aspectSlider showReset>
            <Upload.Dragger
              beforeUpload={(file) => {
                handleUpload(file);
                return false;
              }}
              style={{ padding: '20px' }}
              maxCount={1}
              listType="picture"
              accept=".png, .jpeg, .jpg"
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload.Dragger>
          </ImgCrop>
        </div>
      </Modal>
    </div>
  );
};

export default UserProfilePicture;
