import { Col, Row } from 'antd';
import { FC } from 'react';

import { WorkshopAttendeeWithUserDetail } from '../../../types/workshop';
import './index.scss';
import { getCountryByCode } from '../../../constants/countries';
import { NOT_AVAILABLE } from '../../../constants/common';

interface WorkshopDetails {
  title?: string;
  state?: string;
  country?: string;
  startDate?: string;
  timezone?: string;
  city?: string;
  workshopMode?: string;
}
interface WorkshopAttendees {
  payload: WorkshopAttendeeWithUserDetail;
  workshopDetails: WorkshopDetails;
}
const AttendeeDetails: FC<WorkshopAttendees> = ({
  payload,
  workshopDetails,
}) => {
  return (
    <div>
      <Row>
        <Col span={12}>
          <h1>{workshopDetails?.title}</h1>
          <h4>
            {workshopDetails?.workshopMode === 'virtual'
              ? 'Online'
              : workshopDetails?.city}
            {workshopDetails.country &&
              `, ${getCountryByCode(workshopDetails?.country)?.name}`}{' '}
          </h4>
          <small>
            {workshopDetails?.startDate} , {workshopDetails?.timezone}
          </small>
        </Col>
      </Row>
      <div style={{ paddingTop: '20px' }}>
        <Row className="detail-row">
          <Col span={6}>
            <b>Name:</b>
          </Col>
          <Col span={12}>
            {payload?.attendeesUserDetail?.fullName ?? payload?.fullName}
          </Col>
        </Row>
        <Row className="detail-row-bg">
          <Col span={6}>
            <b>Email:</b>
          </Col>
          <Col span={12}>
            {payload?.attendeesUserDetail?.email ?? payload?.email}
          </Col>
        </Row>
        <Row className="detail-row">
          <Col span={6}>
            <b>Mobile:</b>
          </Col>
          <Col span={12}>{payload?.attendeesUserDetail?.mobileNumber}</Col>
        </Row>
        <Row className="detail-row-bg">
          <Col span={6}>
            <b>Country:</b>
          </Col>
          <Col span={6}>
            {payload?.attendeesUserDetail?.locations?.[0]?.country}
          </Col>
          <Col span={6}>
            <b>State:</b>
          </Col>
          <Col span={6}>
            {payload?.attendeesUserDetail?.locations?.[0]?.region}
          </Col>
        </Row>
        <Row className="detail-row">
          <Col span={6}>
            <b>Address 1:</b>
          </Col>
          <Col span={12}>
            {payload?.attendeesUserDetail?.locations?.[0]?.address1}
          </Col>
        </Row>
        <Row className="detail-row-bg">
          <Col span={12}>
            <b>Address2:</b>
          </Col>
          <Col span={12}>
            {payload?.attendeesUserDetail?.locations?.[0]?.address2}
          </Col>
        </Row>
        <Row className="detail-row">
          <Col span={6}>
            <b>City:</b>
          </Col>
          <Col span={6}>
            {payload?.attendeesUserDetail?.locations?.[0]?.city}
          </Col>
          <Col span={6}>
            <b>Zip:</b>
          </Col>
          <Col span={6}>
            {payload?.attendeesUserDetail?.locations?.[0]?.zip}
          </Col>
        </Row>
        <Row className="detail-row-bg">
          <Col span={6}>
            <b>WeChat Id:</b>
          </Col>
          <Col span={12}>{payload?.attendeesUserDetail?.wechatId || 'N/A'}</Col>
        </Row>
        <Row className="detail-row">
          <Col span={12}>
            <b>Name to appear on certificate :</b>
          </Col>
          <Col span={12}>
            {payload?.attendeesUserDetail?.nameAsOnCertificate}
          </Col>
        </Row>

        <Row className="detail-row-bg">
          <Col span={12}>
            <b>Emergency Contact Name :</b>
          </Col>
          <Col span={12}>
            {payload?.attendeesUserDetail?.emergencyContactName}
          </Col>
        </Row>
        <Row className="detail-row">
          <Col span={12}>
            <b>Emergency Contact Number :</b>
          </Col>
          <Col span={12}>
            {payload?.attendeesUserDetail?.emergencyContactNumber}
          </Col>
        </Row>
        <Row className="detail-row-bg">
          <Col span={13}>
            <b>How did you hear about the workshop? :</b>
          </Col>
          <Col span={11}>{payload?.getWorkshopFrom || NOT_AVAILABLE}</Col>
        </Row>
        <Row className="detail-row">
          <Col span={6}>
            <b>Note :</b>
          </Col>
          <Col span={12}>{payload?.note}</Col>
        </Row>
      </div>
    </div>
  );
};

export default AttendeeDetails;
