import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { FileDetail } from '../../types/file';

interface FilesDataTableProps {
  data: FileDetail[];
  columns: ColumnsType<FileDetail>;
}

const FilesDataTable = ({ data, columns }: FilesDataTableProps) => (
  <Table dataSource={data} columns={columns} rowKey="_id" pagination={false} />
);

export default FilesDataTable;
