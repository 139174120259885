import { Form, Tooltip } from 'antd';
import { UseFormReturn } from 'react-hook-form';
import moment from 'moment';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { DatePickerField, InputField, SelectField } from '../../common/form';
import { IWorkshopCoupon, Workshop } from '../../../types/workshop';

export type CouponResArr = {
  value: string;
  label: string;
};

interface ICouponFormProps {
  form: UseFormReturn<IWorkshopCoupon, object>;
  workshops?: Workshop[];
}

const CouponForm = ({ form, workshops }: ICouponFormProps) => {
  return (
    <>
      <Form layout="vertical">
        <InputField
          label={
            <p className="discount-coupon-label">
              Name
              <Tooltip
                title="NOTE: DO NOT USE SPACE OR ANY SPECIAL CHARACTER. USE CAPITAL LETTERS ONLY."
                placement="right"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </p>
          }
          name="name"
          required
          inputProps={{
            placeholder: 'Coupon Name',
          }}
          form={form}
        />

        <InputField
          label="% Off"
          name="discountPercentage"
          required
          inputProps={{
            placeholder: 'Coupon Discount',
          }}
          form={form}
        />
        <InputField
          label="Limit"
          name="limit"
          inputProps={{
            placeholder: 'Available Coupon Quantity',
          }}
          form={form}
        />
        <SelectField
          name="workshopsIds"
          form={form}
          labelKey="label"
          label="Select Workshops"
          valueKey="value"
          mode="multiple"
          required
          selectFieldProps={{
            options: workshops?.map((workshop) => ({
              key: workshop?._id,
              label: (
                <p
                  className="no-margin"
                  style={{ textTransform: 'capitalize' }}
                >
                  {workshop?.title} with{' '}
                  {workshop?.workshopInstructor?.fullname} -{' '}
                  {moment(workshop?.startDate)
                    .tz(workshop?.timezone)
                    .format('ll')}
                </p>
              ),
              value: workshop?._id,
            })),
            mode: 'multiple',
            allowClear: true,
            placeholder: 'Select workshops for this coupon',
          }}
        />
        <DatePickerField
          label="Expiry Date"
          required
          name="redeemBy"
          form={form}
          datePickerProps={{
            placeholder: 'Coupon Expiry Date',
            disabledDate: (current) => {
              return current && current.isSameOrBefore(moment(), 'day');
            },
          }}
        />
      </Form>
    </>
  );
};

export default CouponForm;
