import { Button, Drawer, message } from 'antd';
import { createElement, FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AddAdminForm from '../form';
import validationSchema from '../validation-schema';
import { IAllowedSections, addAdmin } from '../../../services/admin-service';
import { handleError } from '../../../utils/common';
import { WorkshopInstructor } from '../../../types/instructor';

interface IAddAdminProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  children: FC<{
    onClick: () => void;
  }>;
  instructor: WorkshopInstructor[];
}

export interface IAdminFormValue {
  fullName: string;
  email: string;
  adminType?: string;
  allowedSections?: string[];
  techniquesAllowed?: string[];
  isUsingDefaultTechniqueShares?: boolean;
  customPaymentShares?: {
    [key: string]: {
      ownerShare: number;
      hplShare: number;
      instructorShare: number;
    };
  };
  assignedInstructor?: string[];
}

const AddAdmin: FC<IAddAdminProps> = ({
  children,
  successCallback,
  errorCallback,
  instructor,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<IAdminFormValue>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmitHandler = async (values: IAdminFormValue) => {
    setRequesting(true);
    try {
      const allowedSections: IAllowedSections['allowedSections'] = [];

      (values?.allowedSections || []).forEach((i) => {
        const { key, subSection } = JSON.parse(i);
        const index = allowedSections.findIndex((item) => item.key === key);

        if (index !== -1) {
          allowedSections[index].subSections.push({
            key: subSection.key,
            label: subSection?.label,
          });
        } else {
          allowedSections.push({
            key,
            subSections: [{ key: subSection.key, label: subSection?.label }],
          });
        }
      });
      const customPaymentShares = Object?.keys(
        values?.customPaymentShares || {}
      )?.map((i: string) => ({
        techniqueSlug: i,
        ownerShare: values?.customPaymentShares?.[i]?.ownerShare,
        hplShare: values?.customPaymentShares?.[i]?.hplShare,
        instructorShare: values?.customPaymentShares?.[i]?.instructorShare,
      }));
      const payload = {
        ...values,
        allowedSections,
        customPaymentShares,
      };
      await addAdmin({ payload });
      setDrawerVisible(false);
      message.success('Admin added successfully');
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Add Admin"
        onClose={() => {
          handleDrawerVisibility();
          form.reset({});
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddAdminForm form={form} instructor={instructor} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddAdmin;
