import { NOT_AVAILABLE } from '../../constants/common';
import { IEndorsement } from '../../types/endorsements-and-testimonials';

const columns = [
  {
    title: '# No',
    render: (value: number, record: IEndorsement) => record?.sequence,
    key: 'slno',
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: IEndorsement) =>
      record?.name || NOT_AVAILABLE,
    width: 300,
  },
  {
    title: 'Status',
    render: (value: string, record: IEndorsement) =>
      record?.isApproved ? (
        <span style={{ color: 'green' }}>Published </span>
      ) : (
        <span style={{ color: 'red' }}>Not Published</span>
      ),
    width: 200,
  },
];

export default columns;
