import { createElement, FC, useState, useEffect } from 'react';
import { Button, Drawer } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TechniqueDetail } from '../../../../../types/technique';
import { addUserCertification } from '../../../../../services/users-service';
import CertificationForm from '../form';
import validationSchema from '../validation-schema';
import { handleError } from '../../../../../utils/common';

interface IAddCertificationProps {
  userId: string;
  techniques: TechniqueDetail[];
  children: FC<{ onClick: () => void }>;
  errorCallback?: (e: Error) => void;
  successCallback?: () => void;
}

interface IFormData {
  assignedCertificationCode: string;
  assignedCertificationLevel: {
    [key: string]: number;
  };
  certificationDate: Date;
}

const AddCertification: FC<IAddCertificationProps> = ({
  children,
  userId,
  techniques,
  // errorCallback,
  successCallback,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const form = useForm<IFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (data: IFormData) => {
    if (requesting) return;
    try {
      setRequesting(true);

      await addUserCertification(userId, {
        code: data?.assignedCertificationCode,
        level:
          data?.assignedCertificationLevel?.[data?.assignedCertificationCode],
        certificationDate: data?.certificationDate,
      });

      handleDrawerVisibility();
      form.reset({});
      if (successCallback) {
        successCallback();
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    return () => {
      form.reset({});
    };
  }, []);

  return (
    <>
      <Drawer
        title="Add Certification"
        onClose={() => {
          handleDrawerVisibility();
          form.reset({});
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
            disabled={requesting}
          >
            Submit
          </Button>
        }
      >
        <div style={{ padding: 16 }}>
          <CertificationForm form={form} techniques={techniques} />
        </div>
      </Drawer>
      {createElement(children, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddCertification;
