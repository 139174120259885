import { Button, Form, Col, Row, Spin, Typography, message, Table } from 'antd';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import { countrieskeyVlaue, getCountryByCode } from '../../constants/countries';
import { SelectField } from '../../components/common/form';
import { handleError } from '../../utils/common';
import DateRangePickerField from '../../components/common/form/date-range-picker-field';
import { getRegionsKeyValue } from '../../constants/regions';
import {
  WorkshopFilter,
  getFilteredWorkshops,
} from '../../services/workshop-service';
import { Workshop } from '../../types/workshop';

import columns from './columns';

const WorkshopDataDownload = () => {
  const [requesting, setRequesting] = useState(false);
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [workshopsData, setWorkshopsData] = useState<Workshop[]>([]);
  const [focusedField, setFocusedField] = useState<string | null>(null);

  const form = useForm<WorkshopFilter>({
    mode: 'all',
  });
  const watchCountry = form.watch('country');

  const loadWorkshops = async (filter: WorkshopFilter) => {
    setRequesting(true);
    try {
      const response = await getFilteredWorkshops(filter);
      const data = response?.data?.data;
      setWorkshopsData(data);

      const newData: string[][] = [
        [
          'Title',
          'Technique',
          'Mode',
          'Level',
          'Venue',
          'State/Region',
          'Country',
          'Date',
          'Positions Filled',
          'Capacity',
          'Occupancy %',
          'Tutor',
        ],
      ];
      if (data && data.length > 0) {
        data.forEach((workshop: Workshop) => {
          newData.push([
            workshop.title || '',
            workshop.techniqueSlug?.toUpperCase() || '',
            workshop.workshopMode || '',
            workshop.level?.toString() || '',
            workshop.venueName || '',
            workshop.state || '',
            getCountryByCode(workshop.country || '').name || '',
            moment(workshop.startDate)
              .tz(workshop.timezone || 'UTC')
              .format('D MMM YYYY, h:mm a')
              ?.toString() || '',
            workshop.closedTickets?.toString() || '',
            workshop.totalTickets?.toString() || '',
            (workshop.closedTickets === undefined || 0
              ? 0
              : Math.floor(
                  (workshop.closedTickets / workshop.totalTickets) * 100
                )) + '%',
            workshop.workshopInstructor?.fullname || '',
          ]);
        });
        setCsvData(newData);
      } else {
        message.error('No Workshop Found');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (focusedField === 'region') {
      form.setValue('country', undefined);
      form.setValue('state', undefined);
    }
    if (focusedField === 'country') {
      form.setValue('region', undefined);
    }
  }, [focusedField]);

  return (
    <Spin spinning={requesting}>
      <Table
        scroll={{ x: 'max-content' }}
        dataSource={workshopsData}
        columns={columns}
        pagination={false}
        rowKey={'_id'}
        title={() => {
          return (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography.Title level={3}>Workshop Data</Typography.Title>
              </div>
              <Form
                layout="vertical"
                style={{ display: 'grid', gap: '1rem', margin: 0 }}
              >
                <div>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <SelectField
                      name="region"
                      label="Select Region"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        onFocus: () => setFocusedField('region'),
                        options: [
                          { label: 'All Regions', value: 'all' },
                          { label: 'UK / Europe', value: 'uk-and-europe' },
                          { label: 'Latin America', value: 'latin-america' },
                          { label: 'USA / Canada', value: 'usa-and-canada' },
                          { label: 'China', value: 'china' },
                          { label: 'Asia', value: 'asia' },
                          {
                            label: 'Australia / New Zealand',
                            value: 'australia-and-new-zealand',
                          },
                          { label: 'Others', value: 'others' },
                        ],
                        size: 'large',
                        placeholder: 'Region',
                        allowClear: true,
                        style: { width: '96%' },
                      }}
                    />
                  </Col>
                  <p
                    style={{
                      margin: '0 0 1rem 0',
                      fontWeight: 'bold',
                    }}
                  >
                    OR
                  </p>
                  <Row gutter={[24, 10]}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <SelectField
                        name="country"
                        label="Select Country"
                        form={form}
                        labelKey="label"
                        valueKey="value"
                        selectFieldProps={{
                          onFocus: () => setFocusedField('country'),
                          options: countrieskeyVlaue(),
                          size: 'large',
                          placeholder: 'Country',
                          allowClear: true,
                          showSearch: true,
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <SelectField
                        label="Select State"
                        name="state"
                        form={form}
                        labelKey="label"
                        valueKey="value"
                        selectFieldProps={{
                          options: watchCountry
                            ? getRegionsKeyValue(watchCountry)
                            : [],
                          placeholder: 'State',
                          allowClear: true,
                          showSearch: true,
                          size: 'large',
                        }}
                      />
                    </Col>
                  </Row>
                  <hr style={{ width: '100%', opacity: '0.3' }} />
                </div>
                <Row gutter={[24, 10]}>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <SelectField
                      name="workshopStatus"
                      label="Select Workshop Status"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: [
                          { value: true, label: 'Closed' },
                          { value: false, label: 'Open' },
                        ],
                        size: 'large',
                        placeholder: 'Workshop Status',
                        allowClear: true,
                      }}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <SelectField
                      name="technique"
                      label="Select Technique"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: [
                          { value: 'pbt', label: 'PBT' },
                          { value: 'pct', label: 'PCT' },
                        ],
                        size: 'large',
                        placeholder: 'Technique',
                        allowClear: true,
                      }}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <SelectField
                      name="mode"
                      label="Select Mode"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: [
                          { value: 'physical', label: 'Offline' },
                          { value: 'virtual', label: 'Online' },
                        ],
                        size: 'large',
                        placeholder: 'Mode',
                        allowClear: true,
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <DateRangePickerField
                      name="dateRange"
                      label="Select Date Range"
                      form={form}
                      format="YYYY-MM-DD"
                      size="large"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p style={{ lineHeight: '1.15', visibility: 'hidden' }}>
                      Get User Data
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginRight: '0.8rem',
                        gap: '1rem',
                      }}
                    >
                      <Button
                        type="primary"
                        onClick={form.handleSubmit(loadWorkshops)}
                        style={{
                          width: 'fit-content',
                          height: '2.5rem',
                        }}
                      >
                        Search
                      </Button>
                      {workshopsData.length > 0 && (
                        <CSVLink
                          filename={'workshopsData.csv'}
                          data={csvData}
                          target="_blank"
                        >
                          <Button
                            style={{
                              width: 'fit-content',
                              height: '2.5rem',
                            }}
                          >
                            Download Data
                          </Button>
                        </CSVLink>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default WorkshopDataDownload;
