import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { getBlogPerPage } from '../../services/blog-service';
import { Blog } from '../../types/blog';
import AddBlog from '../../components/blog/add-blog';
import EditBlog from '../../components/blog/edit-blog';
import DeleteBlog from '../../components/blog/delete-blog';
import { BlogCategory } from '../../types/blog-categories';
import { getBlogCategory } from '../../services/blog-category-service';
import { handleError } from '../../utils/common';

import columns from './columns';

const Blogs = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadBlogs, setReloadBlogs] = useState(false);
  const [categories, setCategories] = useState<BlogCategory[]>([]);
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(6);

  const loadBlogs = async () => {
    setRequesting(true);
    try {
      const response = await getBlogPerPage('', page);
      const data = response?.data?.data;
      setTotalPages(data?.totalPages ?? '');
      const categoryRes = await getBlogCategory();
      const categoryData = categoryRes?.data?.data;
      setCategories(categoryRes?.data?.data);

      const modifiedData = data.blogs.map((blog: Blog, i: number) => {
        return {
          ...blog,
          status: blog.isPublished ? 'Published' : 'Draft',
          sequenceNo: i + 1,
          categoryTitle: categoryData.filter(
            (category: BlogCategory) => category?.slug === blog?.category
          )[0]?.name.en,
        };
      });

      setBlogs(modifiedData || []);
    } catch (e) {
      handleError(e);
    } finally {
      setReloadBlogs(false);
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadBlogs) {
      loadBlogs();
    }
  }, [reloadBlogs, requesting, categories.length]);

  useEffect(() => {
    loadBlogs();
  }, []);

  useEffect(() => {
    loadBlogs();
  }, [page]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: Blog) => {
        return (
          <Space size="large">
            <EditBlog
              _id={record._id}
              slug={record.slug}
              categories={categories}
              payload={record as unknown as Blog}
              successCallback={() => setReloadBlogs(true)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditBlog>
            <DeleteBlog
              _id={record._id}
              successCallback={() => setReloadBlogs(true)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteBlog>
          </Space>
        );
      },
    },
  ];

  const paginationConfig = {
    pageSize: 10,
    current: page,
    onChange: (curPage: number) => {
      setPage(curPage);
    },
    total: (totalPages || 1) * 10,
  };

  return (
    <Spin spinning={requesting}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={3}>Blogs</Typography.Title>
          <Space>
            <Tooltip title="Refresh" placement="left">
              <Button
                type="ghost"
                icon={<ReloadOutlined />}
                onClick={() => setReloadBlogs(true)}
              />
            </Tooltip>
            <AddBlog
              categories={categories}
              successCallback={() => setReloadBlogs(true)}
            >
              {({ onClick }) => (
                <Button
                  onClick={onClick}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add Blog
                </Button>
              )}
            </AddBlog>
          </Space>
        </div>

        <Table
          dataSource={blogs}
          columns={columnsWithActions}
          rowKey="_id"
          pagination={paginationConfig}
        />
      </div>
    </Spin>
  );
};

export default Blogs;
