import { FC, createElement, useRef, useState } from 'react';
import { Button, Modal, message } from 'antd';

import { WorkshopAttendeeWithUserDetail } from '../../types/workshop';
import {
  confirmPreviousCertification,
  requestPreviousCertification,
} from '../../services/workshop-service';
import { handleError } from '../../utils/common';

interface PreviousCertificationVerificationProps {
  attendee: WorkshopAttendeeWithUserDetail;
  setReloadWorkshopAttendees: React.Dispatch<React.SetStateAction<boolean>>;
  children: FC<{
    onClick: () => void;
  }>;
  workshopLevel: number;
}

const PreviousCertificationVerification: FC<
  PreviousCertificationVerificationProps
> = ({ attendee, setReloadWorkshopAttendees, children, workshopLevel }) => {
  const refChildren = useRef(children);

  const [open, setOpen] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [requestingCertificate, setRequestingCertificate] = useState(false);

  const handleSubmit = async (attendeeId: string) => {
    setRequesting(true);
    try {
      await confirmPreviousCertification(attendeeId);
      message.success('Previous Certificate Verification Updated Successfully');
      setOpen(false);
      setReloadWorkshopAttendees(true);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  const handleRequestCertificate = async (
    attendeeId: string,
    workshopLevel: number
  ) => {
    setRequestingCertificate(true);
    try {
      await requestPreviousCertification(attendeeId, workshopLevel);
      message.success('Request Certificate Email Sent Successfully');
      setOpen(false);
      setReloadWorkshopAttendees(true);
    } catch (err) {
      handleError(err);
    } finally {
      setRequestingCertificate(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="Verify Previous Certification"
        footer={[
          <Button className="modal-action-btn" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            className="modal-action-btn"
            type="primary"
            loading={requesting}
            onClick={() => handleSubmit(attendee?._id)}
          >
            Verify and Confirm
          </Button>,
        ]}
        destroyOnClose
      >
        <div className="modal-content">
          <p className="">
            {`Have you verified ${attendee?.fullName}'s ${
              workshopLevel === 2 ? 'L1' : 'L1 & L2'
            } certification? This action cannot be undone.`}
          </p>
          <Button
            loading={requestingCertificate}
            onClick={() =>
              handleRequestCertificate(attendee?._id, workshopLevel)
            }
          >
            Request Certificate
          </Button>
        </div>
      </Modal>

      {createElement(refChildren.current, { onClick: () => setOpen(true) })}
    </>
  );
};

export default PreviousCertificationVerification;
