/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { Form, TreeSelect, TreeSelectProps } from 'antd';
import { Controller, UseFormReturn } from 'react-hook-form';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';

const TreeSelectField: FC<{
  label?: string;
  required?: boolean;
  name: string;
  defaultValue?: any;
  form: UseFormReturn<any, any>;
  treeSelectFieldProps: TreeSelectProps<any>;
  tooltip?: LabelTooltipType;
}> = ({
  form: {
    formState: { errors },
    control,
  },
  label,
  required,
  name,
  defaultValue,
  treeSelectFieldProps,
  tooltip,
}) => {
  const errorMessages = errors[name];
  const hasError = !!(errors && errorMessages);
  const { treeData, ...restTreeSelectFieldProps } = treeSelectFieldProps || {};

  return (
    <Form.Item
      label={label}
      help={errorMessages?.message}
      validateStatus={hasError ? 'error' : 'success'}
      required={required}
      tooltip={tooltip}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TreeSelect
            {...field}
            {...restTreeSelectFieldProps}
            treeData={treeData}
          />
        )}
      />
    </Form.Item>
  );
};

export default TreeSelectField;
