/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import _ from 'lodash';
import { Form, Input } from 'antd';
import { Controller, UseFormReturn } from 'react-hook-form';
import { TextAreaProps } from 'antd/lib/input';

interface ITextAreaFieldProps {
  label?: string;
  required?: boolean;
  name: string;
  defaultValue?: string;
  form: UseFormReturn<any, any>;
  textAreaProps?: TextAreaProps;
}

const TextAreaField: FC<ITextAreaFieldProps> = ({
  form: {
    formState: { errors },
    control,
  },
  label,
  required,
  name,
  defaultValue,
  textAreaProps,
}) => {
  const errorMessages = _.get(errors, name);
  const hasError = !!(errors && errorMessages);
  return (
    <Form.Item
      label={label}
      help={errorMessages?.message}
      validateStatus={hasError ? 'error' : 'success'}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => <Input.TextArea {...field} {...textAreaProps} />}
      />
    </Form.Item>
  );
};

export default TextAreaField;
