import * as yup from 'yup';

import { AdminType } from '../../services/users-service';

export default yup.object().shape({
  fullName: yup.string().required('required'),
  email: yup.string().email().required('required'),
  adminType: yup.string().required('required'),
  techniquesAllowed: yup.array().of(yup.string()),
  assignedInstructor: yup.array().of(yup.string()),
  isUsingDefaultTechniqueShares: yup
    .boolean()
    .when(['techniquesAllowed', 'adminType'], {
      is: (techniquesAllowed: string[], adminType: string) =>
        adminType === AdminType.root && techniquesAllowed?.length > 0,
      then: (schema) => schema.required('Please choose any one option'),
      otherwise: (schema) => schema,
    }),
  customPaymentShares: yup.object().when(
    ['isUsingDefaultTechniqueShares', 'techniquesAllowed', 'adminType'],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (isUsingDefaultTechniqueShares, techniquesAllowed, adminType) => {
      if (
        adminType !== AdminType.root ||
        isUsingDefaultTechniqueShares ||
        techniquesAllowed?.length === 0
      ) {
        return yup.object().shape({});
      }

      const shape = techniquesAllowed?.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accumulator: any, technique: string) => {
          accumulator[technique] = yup.object().shape({
            ownerShare: yup
              .number()
              .typeError('Please input a number')
              .required(`Owner share (${technique}) is required`),
            hplShare: yup
              .number()
              .typeError('Please input a number')
              .required(`HPL share (${technique}) is required`),
            instructorShare: yup
              .number()
              .typeError('Please input a number')
              .required(`Instructor share (${technique}) is required`),
          });
          return accumulator;
        },
        {}
      );

      return yup.object().shape(shape);
    }
  ),
});
