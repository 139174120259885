import { Button, Drawer, message, Spin } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AddCertificationCategoryForm from '../form';
import validationSchema from '../validation-schema';
import { updateCertificationCategory } from '../../../services/certification-category-service';
import { CerificationCategory } from '../../../types/certification-categories';

interface IEditCertificationCategoryProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  payload: CerificationCategory;
  certificationCategoryId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditCertificationCategory: FC<IEditCertificationCategoryProps> = ({
  children,
  payload,
  certificationCategoryId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<CerificationCategory>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  useEffect(() => {
    form.reset(payload);
  }, [payload]);

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };
  const onSubmitHandler = async (values: CerificationCategory) => {
    try {
      setRequesting(true);
      await updateCertificationCategory(values, certificationCategoryId);
      setDrawerVisible(false);
      setRequesting(false);
      message.success('Certification Category Updated successfully');
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Spin spinning={requesting}>
      <Drawer
        title="Update Certification Category"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        visible={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddCertificationCategoryForm isEditable={true} form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </Spin>
  );
};

export default EditCertificationCategory;
