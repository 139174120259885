import { Table, Typography, Pagination, Input, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { handleError } from '../../../utils/common';
import { searchAttendees } from '../../../services/workshop-service';

import columns from './columns';

export type TAttendanceStatus = 'ATTENDED' | 'NOT_ATTENDED' | 'NOT_CERTIFIED';

export interface ISearchAttendees {
  _id: string;
  workshopSlug: string;
  userId: string;
  fullName: string;
  email: string;
  isPaymentDone: boolean;
  isAttendanceMarked: boolean;
  attendanceStatus: TAttendanceStatus;
  createdAt: Date;
  workshop: {
    _id: string;
    startDate: Date;
    timezone: string;
    country: string;
    title: string;
    slug: string;
    isWorkshopConcluded: boolean;
    owner: {
      email: string;
      fullName: string;
    };
  };
}

const FilteredWorkshopAttendees: React.FC = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const [text, setText] = useState('');
  const [debouncedText, setDebouncedText] = useState('');

  const [attendees, setAttendees] = useState<ISearchAttendees[]>([]);

  const [requesting, setRequesting] = useState(false);
  const [defaultMessage, setDefaultMessage] = useState(
    'Search user by name or email'
  );
  const loadAttendees = async () => {
    setRequesting(true);
    try {
      const response = await searchAttendees(debouncedText, page, limit);
      setAttendees(response?.data?.data?.attendees);

      setTotalPages(response?.data?.data?.totalPages);
      if (!response?.data?.data?.attendees?.length) {
        setDefaultMessage('No Attendee Found');
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      render: (record: ISearchAttendees) => (
        <Link
          to={`/workshop-attendees/${record?.workshop?.slug}?attendeeID=${record?._id}`}
        >
          Quick link
        </Link>
      ),
      key: 'actions',
    },
  ];

  useEffect(() => {
    const id = setTimeout(() => setDebouncedText(text), 500);

    return () => {
      return clearTimeout(id);
    };
  }, [text]);

  useEffect(() => {
    if (debouncedText) {
      loadAttendees();
    }
  }, [debouncedText, page, limit]);

  return (
    <>
      <Table
        dataSource={attendees}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Workshops Attendees</Typography.Title>
              <Input
                value={text}
                onChange={(e) => {
                  setText(e?.target?.value);
                }}
                size="middle"
                style={{ maxWidth: 350 }}
                addonBefore={<SearchOutlined />}
                placeholder="Enter name or email"
              />
            </div>
          );
        }}
        locale={{
          emptyText: !requesting ? (
            defaultMessage
          ) : (
            <Spin spinning={requesting}></Spin>
          ),
        }}
      />
      <Pagination
        style={{ float: 'right' }}
        pageSize={limit}
        current={page}
        onChange={(curPage, curLimit) => {
          setPage(curPage);
          setLimit(curLimit);
        }}
        total={totalPages * limit}
      />
    </>
  );
};

export default FilteredWorkshopAttendees;
