enum ResKey {
  BAD_REQUEST = 'BAD REQUEST OR INCOMPLETE INPUT',
  BAD_SESSION = 'BAD SESSION',
  DELETED = 'DELETED',
  INCORRECT_PASSWORD = 'INCORRECT PASSWORD',
  USER_NOT_FOUND = 'USER NOT FOUND',
  UNKNOWN_ERROR = 'UNKNOWN ERROR',
  ALL_FILEDS_ARE_REQUIRED = 'ALL FILEDS ARE REQUIRED',
}
export default ResKey;
