import { Card, Descriptions, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { FC } from 'react';

import { TUser } from '../../../../services/users-service';
import { NOT_AVAILABLE } from '../../../../constants/common';
import FormattedDate from '../../../date/FormattedDate';
import { convertStripeAmount } from '../../../../utils/common';

import ChangeBillingCycle from './change-billing-cycle';

interface ISubscriptionDetails {
  user: TUser;
  successCallback: (e: boolean) => void;
}

const SubscriptionDetails: FC<ISubscriptionDetails> = ({
  user,
  successCallback,
}) => {
  return (
    <Card title="Subscription Details" className="info-container">
      <span className="info-heading">
        {user ? (
          <ChangeBillingCycle
            billingCycle={{
              billingCycle: user?.subscription?.items?.data[0]?.price?.nickname,
            }}
            user={{ ...user }}
            successCallback={successCallback}
          >
            {({ onClick }) => (
              <EditOutlined onClick={onClick} style={{ float: 'right' }} />
            )}
          </ChangeBillingCycle>
        ) : (
          <EditOutlined />
        )}
      </span>

      <Descriptions
        layout="vertical"
        column={2}
        labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
      >
        <Descriptions.Item label="CREATED ON" className="info-item">
          {user?.subscription?.created ? (
            <FormattedDate
              date={user?.subscription?.created}
              fromNow={true}
              ShowTime={false}
            />
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item label="STARTED ON" className="info-item">
          {user?.subscription ? (
            <FormattedDate
              date={user?.subscription?.start_date}
              fromNow={true}
              ShowTime={false}
            />
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item label="PREVIOUS STATUS" className="info-item">
          {user?.previousStatus || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="CURRENT STATUS" className="info-item">
          {user?.currentStatus ? (
            <span>
              {user?.subscription?.status}
              since
              <FormattedDate
                date={user?.statusChangedOn}
                fromNow={false}
                ShowTime={false}
              />
            </span>
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item label="COLLECTION METHOD" className="info-item">
          {user?.subscription?.collection_method || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="CURRENT PERIOD" className="info-item">
          {user?.subscription?.current_period_start ? (
            <span>
              <FormattedDate
                date={user?.subscription?.current_period_start}
                fromNow={false}
                ShowTime={false}
              />
              {' To '}
              <FormattedDate
                date={user?.subscription?.current_period_end}
                fromNow={false}
                ShowTime={false}
              />
            </span>
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item label="IS SENT TO CANCEL?" className="info-item">
          {user?.subscription?.cancel_at_period_end ? (
            <span>
              Yes at
              <FormattedDate
                date={user?.subscription?.cancel_at}
                ShowTime={false}
                fromNow={false}
              />
            </span>
          ) : (
            'NO'
          )}
        </Descriptions.Item>

        <Descriptions.Item label="BILLING CYCLE" className="info-item">
          {user?.subscription?.currency &&
          user?.subscription?.items?.data?.[0]?.price?.recurring?.interval &&
          user?.subscription?.items?.data?.[0]?.price.currency_options?.[
            user?.subscription?.currency
          ]?.unit_amount ? (
            <span>
              {`${user.subscription.currency} @${convertStripeAmount(
                user.subscription.items.data[0].price.currency_options?.[
                  user.subscription.currency
                ]?.unit_amount,
                user.subscription.currency
              )} per ${
                user.subscription.items.data[0].price.recurring?.interval
              }`}
            </span>
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>

        <Descriptions.Item
          label="BILLING CYCLE ANCHOR (INVOICING DAY)"
          className="info-item"
        >
          {user?.subscription?.billing_cycle_anchor ? (
            <span>
              <FormattedDate
                date={user?.subscription.billing_cycle_anchor}
                fromNow={false}
                ShowTime={false}
              />
            </span>
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item label="CUSTOMER ID" className="info-item">
          {user?.subscription?.customer || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="OLD CUSTOMER IDS" className="info-item">
          {user?.oldStripeCustomerId?.join(', ').toString() || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="SUBSCRIPTION ID" className="info-item">
          {user?.subscription?.id ? (
            <span>
              {user?.subscription?.id || NOT_AVAILABLE}
              <Typography.Text className={user?.subscription?.status || ''}>
                {user?.subscription?.status}
              </Typography.Text>
            </span>
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item label="DEFAULT PAYMENT METHOD" className="info-item">
          {user?.subscription?.default_payment_method || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item
          label="LATEST SOURCE (FOR ALIPAY USERS ONLY)"
          className="info-item"
        >
          {user?.subscription?.default_source ? (
            <span>
              {user?.subscription?.default_source || NOT_AVAILABLE}
              <Typography.Text className={user?.subscription?.status || ''}>
                {user?.subscription?.status}
              </Typography.Text>
            </span>
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label="DEFAULT SOURCE (FOR ALIPAY USERS ONLY)"
          className="info-item"
        >
          {user?.subscription?.default_source || NOT_AVAILABLE}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default SubscriptionDetails;
