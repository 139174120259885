import {
  Button,
  message,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  InfoOutlined,
  EditOutlined,
  ReloadOutlined,
  CreditCardOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import {
  getInstructors,
  allowInstructorFunc,
} from '../../services/instructor-service';
import AddInstructor from '../../components/instructors/create-instructor';
import EditInstructor from '../../components/instructors/edit-instructor';
import DeleteInstructor from '../../components/instructors/delete-instructor';
import {
  AllowInstructorDetail,
  WorkshopInstructor,
} from '../../types/instructor';
import InfoInstructor from '../../components/instructors/info-instructor';
import AddInstructorPaymentProcessor from '../../components/instructor-paymentprocessor/update-payment-processor';
import { AdminType } from '../../services/users-service';
import { handleError } from '../../utils/common';

import columns from './columns';

const Instructor = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadInstructor, setReloadInstructor] = useState(true);
  const [instructors, setInstructors] = useState<WorkshopInstructor[]>([]);

  const loadInstructors = async () => {
    setRequesting(true);
    try {
      const response = await getInstructors();
      setInstructors(response?.data?.data);
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
      setReloadInstructor(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadInstructor) {
      loadInstructors();
    }
  }, [reloadInstructor]);

  const allowInstructorToDo = async (
    flag: AllowInstructorDetail,
    _id?: string
  ) => {
    setRequesting(true);
    try {
      const response = await allowInstructorFunc(flag, _id);
      if (response?.status === 200) {
        message.success('Instructor details have been updated');
      }
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
      setReloadInstructor(true);
    }
  };

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: WorkshopInstructor) => {
        return (
          <Space size="large">
            <Tooltip title="Allow Sign In" placement="left">
              <Switch
                size="small"
                defaultChecked={record?.isAllowInstructorSignIn}
                onChange={(e) =>
                  allowInstructorToDo(
                    { isAllowInstructorSignIn: e },
                    record._id
                  )
                }
              />
            </Tooltip>

            <Tooltip title="Allow Final Pay" placement="left">
              <Switch
                disabled
                size="small"
                defaultChecked={record?.isAllowInstructorReviewPay}
                onChange={(e) =>
                  allowInstructorToDo(
                    { isAllowInstructorReviewPay: e },
                    record._id
                  )
                }
              />
            </Tooltip>
            <Tooltip title="Allow New Events" placement="left">
              <Switch
                size="small"
                defaultChecked={record?.isAllowInstructorCreateEvent}
                onChange={(e) =>
                  allowInstructorToDo(
                    { isAllowInstructorCreateEvent: e },
                    record._id
                  )
                }
              />
            </Tooltip>
            <InfoInstructor
              instructorId={record._id}
              payload={record}
              readOnly={true}
              instructors={instructors}
              successCallback={() => setReloadInstructor(true)}
              errorCallback={handleError}
            >
              {({ onClick }) => <InfoOutlined onClick={onClick} />}
            </InfoInstructor>

            <EditInstructor
              instructorId={record._id}
              instructors={instructors}
              payload={record}
              successCallback={() => setReloadInstructor(true)}
              errorCallback={handleError}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditInstructor>
            <DeleteInstructor
              instructorId={record._id}
              successCallback={() => setReloadInstructor(true)}
              errorCallback={handleError}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteInstructor>
            {record?.adminType === AdminType.workshop && (
              <Tooltip title="Add Payment Processor Details" placement="left">
                <AddInstructorPaymentProcessor
                  payload={{
                    paymentProcessor: record?.paymentProcessor,
                    paypalEmailId: record?.paypalEmailId,
                    stripeConenct: record?.stripeConenct,
                    adminType: record?.adminType,
                  }}
                  userId={record?._id}
                  allowedAdminType={[AdminType.workshop]}
                  successCallback={() => setReloadInstructor(true)}
                  errorCallback={handleError}
                >
                  {({ onClick }) => <CreditCardOutlined onClick={onClick} />}
                </AddInstructorPaymentProcessor>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={instructors}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Instructors</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadInstructor(true)}
                  />
                </Tooltip>
                <AddInstructor
                  successCallback={() => setReloadInstructor(true)}
                  instructors={instructors}
                  errorCallback={handleError}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Instructor
                    </Button>
                  )}
                </AddInstructor>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default Instructor;
