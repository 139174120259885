import { useEffect } from 'react';

import { useAuthState } from '../../context/auth-state';
import useInterval from '../../hooks/use-interval';
import { refreshSession } from '../../services/auth-service';

const SessionRefresher = () => {
  const {
    session,
    loadingSession,
    onUpdateLoadingSession,
    onUpdateSession,
    onLogout,
  } = useAuthState();
  const onRefreshSession = async () => {
    if (loadingSession) {
      return;
    }
    try {
      onUpdateLoadingSession(true);
      const refreshedSession = await refreshSession();
      onUpdateSession(refreshedSession);
    } catch (err) {
      // check and signout user if not authorized
      onLogout();
    } finally {
      onUpdateLoadingSession(false);
    }
  };

  useInterval(
    () => {
      onRefreshSession();
    },
    // Delay in milliseconds or null to stop it
    session?.session_type === 'OK' ? 1000 * 60 * 2 : null
  );

  useEffect(() => {
    if (session?.session_type === 'OK') {
      onRefreshSession();
    }
  }, []);

  return null;
};

export default SessionRefresher;
