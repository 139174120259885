import { Button, Space, Spin, Table, Tooltip, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { IUserPages } from '../../types/user-pages';
import EditUserPage from '../../components/user-pages/edit-page';
import AddUserPage from '../../components/user-pages/add-page';
import { getAllPage } from '../../services/user-pages-service';
import { FaqCategory } from '../../types/faq';
import { getFaqCategories } from '../../services/faq-services';
import DeleteUserPage from '../../components/user-pages/delete-page';

import columns from './column';

const UserPages = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadPages, setReloadPages] = useState(true);
  const [pages, setPages] = useState<IUserPages[]>([]);
  const [faqCategories, setFaqCategories] = useState<FaqCategory[]>([]);

  const loadPages = async () => {
    setRequesting(true);
    try {
      const response = await getAllPage();
      setPages(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadPages(false);
    }
  };

  const loadFaqCategories = async () => {
    setRequesting(true);
    try {
      const response = await getFaqCategories();
      setFaqCategories(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
    }
  };
  useEffect(() => {
    if (!requesting && reloadPages) {
      loadPages();
    }
  }, [reloadPages]);

  useEffect(() => {
    loadFaqCategories();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: IUserPages) => {
        return (
          <Space size="large">
            <EditUserPage
              pageId={record?._id || ''}
              payload={record}
              isEditable={true}
              successCallback={() => setReloadPages(true)}
              errorCallback={(e: string) => {
                message.error(e);
              }}
              faqCategories={faqCategories}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditUserPage>
            <DeleteUserPage
              pageID={record?._id}
              successCallback={() => setReloadPages(true)}
              errorCallback={(e: string) => {
                message.error(e);
              }}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteUserPage>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={pages}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Pages</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadPages(true)}
                  />
                </Tooltip>
                <AddUserPage
                  isEditable={false}
                  successCallback={() => setReloadPages(true)}
                  errorCallback={(e: string) => {
                    message.error(e);
                  }}
                  faqCategories={faqCategories}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Page
                    </Button>
                  )}
                </AddUserPage>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default UserPages;
