import { Image } from 'antd';

import { ExerciseEquipment } from '../../types/exercise-equipment';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 70,
  },
  {
    title: 'Name',
    render: (value: string, record: ExerciseEquipment) => record.name?.en,
    key: 'name',
    width: 900,
  },
  {
    title: 'Image',
    render: (value: string, record: ExerciseEquipment) => (
      <Image width={70} height={70} preview={false} src={record.imageUrl1} />
    ),
    key: 'image',
    width: 900,
  },
];

export default columns;
