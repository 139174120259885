import { ADMIN } from '../constants/api';
import fetchClient from '../utils/fetch-client';

export interface IAllowedSections {
  allowedSections: {
    key: string;
    subSections: { key: string; label: string }[];
  }[];
}

export interface IPayload {
  fullName: string;
  email: string;
  adminType?: string;
  allowedSections?: {
    key: string;
    subSections: { key: string; label: string }[];
  }[];
  techniquesAllowed?: string[];
  isUsingDefaultTechniqueShares?: boolean;
  customPaymentShares?: {
    techniqueSlug?: string;
    ownerShare?: number;
    hplShare?: number;
    instructorShare?: number;
  }[];
}

export const getAdmins = () => {
  return fetchClient.get(`${ADMIN.ADMIN}`);
};

export const updateAllowedSections = ({
  id,
  payload,
}: {
  id: string;
  payload: IAllowedSections;
}) => {
  return fetchClient.post(`${ADMIN.UPDATE_ALLOWED_SECTIONS}/${id}`, payload);
};

export const addAdmin = ({ payload }: { payload: IPayload }) => {
  return fetchClient.post(`${ADMIN.ADMIN}`, payload);
};

export const editAdmin = ({
  userID,
  payload,
}: {
  userID: string;
  payload: IPayload;
}) => {
  return fetchClient.put(`${ADMIN.ADMIN}/${userID}`, payload);
};
