import { Col, Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { InputField } from '../../common/form';
import CKEditorWrapper from '../../ckeditor';
interface EmailAttendeesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
  isEditable: boolean;
}
const WaitlistForm = ({ form }: EmailAttendeesProps) => {
  return (
    <>
      <Form layout="vertical">
        <InputField
          label="Subject"
          name="subject"
          required
          inputProps={{
            placeholder: 'subject',
            type: 'text',
          }}
          form={form}
        />
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <CKEditorWrapper
            name="body"
            label="Email Body"
            required
            form={form}
          />
        </Col>
      </Form>
    </>
  );
};
export default WaitlistForm;
