import { FC, useState } from 'react';
import { Button, Popover, Tooltip, message } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import {
  BuyingFor,
  WorkshopAttendeeWithUserDetail,
} from '../../types/workshop';
import { handleError } from '../../utils/common';
import { CLIENT_FRONTEND_URL } from '../../constants/api';
import { mailSignWorkshopWaiver } from '../../services/workshop-service';

interface WorkshopWaiverProps {
  attendee: WorkshopAttendeeWithUserDetail;
  workshop: {
    techniqueSlug?: string;
    slug?: string;
    language?: string;
  };
}

const WorkshopWaiver: FC<WorkshopWaiverProps> = ({ attendee, workshop }) => {
  const [requesting, setRequesting] = useState(false);

  const signWorkshopWaiver = async (attendeeId?: string) => {
    try {
      setRequesting(true);
      await mailSignWorkshopWaiver(attendeeId);
      message.success('Mail sent successfully.');
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  return attendee?.isRegistrationCompleted ? (
    <Tooltip placement="top" title="Waiver signed">
      <CheckCircleFilled className="waiver-signed" />
    </Tooltip>
  ) : (
    <Popover
      placement="top"
      title={false}
      content={
        <div>
          {attendee?.ticketPurachasedBy === BuyingFor.SELF ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p>Reason: attendee's email is not verified.</p>
              <Button
                type="primary"
                onClick={async () => {
                  await signWorkshopWaiver(attendee?._id);
                }}
                loading={requesting}
              >
                Resend email verification mail
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p>Reason: position not claimed yet.</p>

              <Button
                type="primary"
                onClick={async () => {
                  await signWorkshopWaiver(attendee?._id);
                }}
                loading={requesting}
              >
                Resend claim position email
              </Button>
              <div style={{ marginTop: '10px' }}>or</div>
              <Button
                type="link"
                style={{ padding: '0px' }}
                href={`${CLIENT_FRONTEND_URL}/${workshop?.language}/workshops/${workshop?.techniqueSlug}/${workshop?.slug}?attendeeId=${attendee._id}`}
                target="_blank"
              >
                open claim position link
              </Button>
            </div>
          )}
        </div>
      }
      trigger="click"
    >
      <CloseCircleFilled className="waiver-not-signed" />
    </Popover>
  );
};

export default WorkshopWaiver;
