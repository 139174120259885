import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';
import { useMemo } from 'react';

import { DatePickerField, SelectField } from '../../../common/form';
import { TechniqueDetail } from '../../../../types/technique';

interface ICertificationFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
  techniques: TechniqueDetail[];
  isEditMode?: boolean;
}
const CertificationForm = ({
  form,
  techniques,
  isEditMode = false,
}: ICertificationFormProps) => {
  const assignedCertificationCode = form.watch('assignedCertificationCode');

  const currentTechnique = useMemo(() => {
    return techniques?.find((item) => item?.slug === assignedCertificationCode);
  }, [assignedCertificationCode]);

  return (
    <Form layout="vertical">
      <SelectField
        label="Technique"
        name="assignedCertificationCode"
        form={form}
        labelKey="name.en"
        valueKey="slug"
        required
        selectFieldProps={{
          options: techniques,
          placeholder: 'Select technique',
          onChange: (v) => {
            form.setValue('assignedCertificationCode', v);
            form.setValue('assignedCertificationLevel', undefined);
          },
          allowClear: true,
          disabled: isEditMode,
        }}
      />

      <SelectField
        key={assignedCertificationCode}
        label={`${
          assignedCertificationCode?.toUpperCase() ?? ''
        } Certification Level to assign`}
        name={`assignedCertificationLevel.${assignedCertificationCode}`}
        form={form}
        required
        labelKey="label"
        valueKey="value"
        selectFieldProps={{
          options: currentTechnique?.isCertificationMultiLevel
            ? [
                { label: 'One', value: 1 },
                { label: 'Two', value: 2 },
                { label: 'Three', value: 3 },
              ]
            : [{ label: 'One', value: 1 }],
          placeholder: 'Select Certification Category',
          onChange: (v) =>
            form.setValue(
              `assignedCertificationLevel.${assignedCertificationCode}`,
              v
            ),
        }}
      />
      <DatePickerField
        datePickerProps={{ disabled: isEditMode ? true : false }}
        label={'Certification Date'}
        name="certificationDate"
        required
        form={form}
      />

      {isEditMode && (
        <DatePickerField
          label={'Renewal Due Date'}
          name="nextRenewalDueOn"
          required
          form={form}
        />
      )}
    </Form>
  );
};

export default CertificationForm;
