import { createElement, FC, useRef, useState } from 'react';
import { Popconfirm } from 'antd';

import { deleteSectionCategory } from '../../../services/exercise-categories-service';

interface IDeleteClassesProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  _id?: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const DeleteClasses: FC<IDeleteClassesProps> = ({
  children,
  _id,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [popVisible, setPopVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const onDelete = async () => {
    setRequesting(true);
    try {
      await deleteSectionCategory(_id);
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  const handlePopVisibility = () => {
    setPopVisible(!popVisible);
  };
  return (
    <Popconfirm
      open={popVisible}
      onOpenChange={handlePopVisibility}
      title="Are you sure to remove this exercise class ?"
      okButtonProps={{ loading: requesting }}
      onConfirm={onDelete}
      onCancel={() => setPopVisible((prev) => !prev)}
      okText="Yes"
      cancelText="No"
    >
      {createElement(refChildren.current, { onClick: handlePopVisibility })}
    </Popconfirm>
  );
};

export default DeleteClasses;
