import { Link } from 'react-router-dom';

import { Blog } from '../../types/blog';
import { CLIENT_FRONTEND_URL } from '../../constants/api';
// import { getTechniqueColor } from '../../utils/common';

const columns = [
  {
    title: 'SNo.',
    render: (value: string, record: Blog, index: number) => index + 1,
    width: 200,
  },
  {
    title: 'Title',
    render: (value: string, record: Blog) => (
      <Link
        to={{
          pathname: `${CLIENT_FRONTEND_URL}/blog/${record?.slug}`,
        }}
        target="_blank"
      >
        {record?.title?.en}
      </Link>
    ),
    width: 250,
  },
  {
    title: 'Category',
    render: (value: string, record: Blog) => <>{record?.categoryTitle}</>,
    width: 250,
  },

  {
    title: 'Status',
    render: (value: string, record: Blog) => <>{record?.status}</>,
    width: 250,
  },
];

export default columns;
