import { FileDetail } from '../../types/file';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 100,
  },
  {
    title: 'Name',
    render: (value: string, record: FileDetail) => record.name,
    key: 'name',
    width: 400,
  },
  {
    title: 'Description',
    render: (value: string, record: FileDetail) => record?.desc,
    key: 'description',
  },
];

export default columns;
