import { ENDORSEMENT } from '../constants/api';
import { IEndorsement } from '../types/endorsements-and-testimonials';
import fetchClient from '../utils/fetch-client';

export const getAllEndorsements = () => {
  return fetchClient.get(`${ENDORSEMENT}/all`);
};

export const addEndorsement = (payload: IEndorsement) => {
  return fetchClient.post(`${ENDORSEMENT}`, payload);
};

export const updateEndorsement = (
  payload: IEndorsement,
  endorsementId: string
) => {
  return fetchClient.put(`${ENDORSEMENT}/${endorsementId}`, payload);
};
export const deleteEndorsement = (endorsementId: string) => {
  return fetchClient.delete(`${ENDORSEMENT}/${endorsementId}`);
};
export const updateSliderVisibility = (
  payload: { inSlider: boolean },
  endorsementId: string
) => {
  return fetchClient.put(`${ENDORSEMENT}/slider/${endorsementId}`, payload);
};
