import * as yup from 'yup';

export default yup.object().shape({
  workshopfor: yup
    .string()
    .required('Workshop input is required ')
    .default('teacher'),
  techniqueSlug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
  level: yup
    .number()
    .min(1)
    .max(3)
    .when('isCertificationMultiLevel', {
      is: true,
      then: (schema) => schema.required('Workshop level is required'),
      otherwise: (schema) => schema,
    }),
  workshopMode: yup.string().required('Workshop mode is required'),
  startDate: yup.date().required('Workshop start date is required'),
  endDate: yup.date().required('Workshop end date is required'),
  ticketEndDate: yup.date().required('Ticket end date is required'),
  // city: yup.string().when('workshopMode', {
  //   is: 'physical',
  //   then: (schema) => schema.required('City is required'),
  //   otherwise: (schema) => schema,
  // }),
  country: yup.string().required('Country is required'),
  state: yup.string().when('workshopMode', {
    is: 'physical',
    then: (schema) => schema.required('State is required'),
    otherwise: (schema) => schema,
  }),
  timezone: yup.string().required('Timezone is required'),
  title: yup.string().required('Title is required'),
  language: yup.string().required('Language is required'),
  venueName: yup.string().when('workshopMode', {
    is: 'physical',
    then: (schema) => schema.required('Venue is required'),
    otherwise: (schema) => schema,
  }),
  address1: yup.string().when('workshopMode', {
    is: 'physical',
    then: (schema) => schema.required('Address line one is required'),
    otherwise: (schema) => schema,
  }),
  address2: yup.string(),
  currency: yup.string().required('Currency is required'),
  ticketPrice: yup
    .number()
    .typeError('Should be a  number')
    .required('Ticket price is required'),
  totalTickets: yup
    .number()
    .typeError('Should be a number')
    .required('Total tickets is required'),
  description: yup.string().required('Description is required'),
  workshopTimetable: yup.string().required('Timetable is required'),
  bannerUrl: yup.string().required('Image banner is required'),
  noteUrl: yup.string().required('English note is required'),
  equipmentUrl: yup.string().required('English Language equipment is required'),
  isTeachingSelf: yup.boolean().required('Required'),
  instructor: yup.string().when('isTeachingSelf', {
    is: false,
    then: (schema) => schema.required('Instructor is required'),
    otherwise: (schema) => schema,
  }),
  enquiryEmail: yup.string().required('Enquiry Email is required'),
  zoomDetails: yup.string().when('workshopMode', {
    is: 'virtual',
    then: (schema) => schema.required('Zoom details required'),
    otherwise: (schema) => schema,
  }),
});
