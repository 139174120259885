import { BLOG_CATEGORIES } from '../constants/api';
import { BlogCategory } from '../types/blog-categories';
import fetchClient from '../utils/fetch-client';

export const getBlogCategory = () => {
  return fetchClient.get(`${BLOG_CATEGORIES.BLOG_CATEGORIES}`);
};

export const addBlogCategory = (payload: BlogCategory) => {
  return fetchClient.post(`${BLOG_CATEGORIES.BLOG_CATEGORIES}`, payload);
};

export const updateBlogCategory = (payload: BlogCategory, id: string) => {
  return fetchClient.put(`${BLOG_CATEGORIES.BLOG_CATEGORIES}/${id}`, payload);
};

export const deleteBlogCategory = (id?: string) => {
  return fetchClient.delete(`${BLOG_CATEGORIES.BLOG_CATEGORIES}/${id}`);
};

export const updateBulkCategories = (
  payload: {
    _id: string;
    sequenceNo: number;
  }[]
) => {
  return fetchClient.post(`${BLOG_CATEGORIES.BLOG_CATEGORIES}/update-all`, {
    categories: payload,
  });
};
