import {
  Button,
  Col,
  Dropdown,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import {
  FileTextOutlined,
  MailOutlined,
  PrinterOutlined,
  UserAddOutlined,
  EllipsisOutlined,
  LeftCircleFilled,
  CheckCircleFilled,
  ReloadOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import {
  // generateReceipt,
  getWorkshopAttendees,
  sendCertificationMail,
} from '../../services/workshop-service';
import {
  WorkshopAttendeeList,
  WorkshopAttendeeWithUserDetail,
} from '../../types/workshop';
import EditWorkshopAttendee from '../../components/manage-workshop/edit-attendee';
import ViewWorkshopAttendee from '../../components/manage-workshop/view-attendees';
import EmailWorkshopAttendee from '../../components/manage-workshop/mail-attendee';
import AddWorkshopAttendee from '../../components/manage-workshop/add-attendee';
import WaitlistAttendees from '../../components/workshops/waitlist-attendees';
import EmailSentAttendees from '../../components/workshops/email-attendees';
import './index.scss';
import {
  generateCertificate,
  generateCertificateForAllAttendee,
} from '../../services/create-certificate';
import {
  convertStripeAmount,
  downloadBufferFile,
  handleError,
} from '../../utils/common';

import RefundModal from './refund';
import PreviousCertificationVerification from './previous-certification-verification';
import WorkshopWaiver from './workshop-waiver';
import TransferAttendee from './transfer-attendee';
import RemoveAttendee from './remove-attendee';

const ManageWorkshop = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { workshopSlug } = useParams();

  const [text, setText] = useState('');

  const [requesting, setRequesting] = useState(false);
  const [printCertificateRequesting, setPrintCertificateRequesting] =
    useState(false);
  const [reloadWorkshopAttendees, setReloadWorkshopAttendees] = useState(true);
  const [openPrintCertificateModal, setOpenPrintCertificateModal] =
    useState(false);

  const [attendeesDetail, setAttendeeDetail] = useState<
    WorkshopAttendeeWithUserDetail[]
  >([]);
  const [workshopAttendees, setWorkshopAttendees] =
    useState<WorkshopAttendeeList>();

  const downloadCertificate = async (attendeeId: string) => {
    setRequesting(true);
    try {
      const response = await generateCertificate(attendeeId);
      window.open(response?.data?.data, '_blank');
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
      setReloadWorkshopAttendees(true);
    }
  };

  const sendCertificateMail = async (attendeeId: string) => {
    setRequesting(true);
    try {
      await sendCertificationMail({ attendeeId });
      message.success('Mail Send SuccessFully');
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
      setReloadWorkshopAttendees(true);
    }
  };

  const downloadReceipt = async (attendeeId: string) => {
    setRequesting(true);
    try {
      window.open(
        `${process.env.REACT_APP_FRONT_END_URL}/receipt/${attendeeId}?attendee=true`,
        '_blank'
      );
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
      setReloadWorkshopAttendees(true);
    }
  };

  const downloadAllCertificate = async () => {
    setPrintCertificateRequesting(true);
    try {
      const response = await generateCertificateForAllAttendee(
        workshopAttendees?.slug as string
      );
      downloadBufferFile(
        `${workshopAttendees?.title}.zip`,
        response?.data?.data?.data
      );
      setOpenPrintCertificateModal(false);
    } catch (e) {
      handleError(e);
    } finally {
      setPrintCertificateRequesting(false);
    }
  };

  const printAttendeesList = () => {
    const csvdata = [
      [
        'Workshop',
        'Date',
        'Order Dt',
        'Name',
        'Email',
        'Waiver',
        'Name on Certificate',
        'Mobile',
        'dob',
        'age',
        'Profession',
        'Address',
        'School Teaching At',
        'Teaching Years',
        'Dance Type',
        'WeChat Id',
        'Why PBT Workshop',
        'Currency',
        'Buyer Paid',
        "Payment Gateway's",
        "Course Owner's",
        "PBT's",
        "Your's",
      ],
    ];
    attendeesDetail &&
      attendeesDetail?.length &&
      (attendeesDetail || [])?.forEach((attendee) => {
        if (attendee?.paymentCurrency) {
          const buyerPaid =
            attendee?.HPLShareInPaymentCurrency +
            attendee?.instructorShareInPaymentCurrency +
            attendee?.techniqueOwnerShareInPaymentCurrency +
            attendee?.gatewayFeeInPaymentCurrency;
          csvdata?.push([
            `${workshopAttendees?.title || ''}`,
            `${
              moment(workshopAttendees?.startDate)
                .tz(workshopAttendees?.timezone || 'UTC')
                .format('ll') || ''
            }`,
            `${moment(attendee?.createdAt).format('ll') || ''}`,
            `${attendee?.attendeesUserDetail?.fullName || ''}`,
            `${attendee?.attendeesUserDetail?.email || ''}`,
            `${attendee?.isRegistrationCompleted ? 'Yes' : 'No'}`,
            `${attendee?.attendeesUserDetail?.nameAsOnCertificate || ''}`,
            `${attendee?.attendeesUserDetail?.mobileNumber || ''}`,
            `${
              attendee?.attendeesUserDetail?.dob
                ? moment(attendee?.attendeesUserDetail?.dob).format('ll')
                : ''
            }`,
            `${
              attendee?.attendeesUserDetail?.dob
                ? moment().diff(
                    moment(attendee?.attendeesUserDetail?.dob),
                    'years'
                  )
                : ''
            }`,
            `${attendee.attendeesUserDetail?.profession}`,
            `${attendee?.attendeesUserDetail?.locations?.[0]?.address1 || ''},${
              attendee?.attendeesUserDetail?.locations?.[0]?.address2 || ''
            },${attendee?.attendeesUserDetail?.locations?.[0]?.city || ''},${
              attendee?.attendeesUserDetail?.locations?.[0]?.region || ''
            },${attendee?.attendeesUserDetail?.locations?.[0]?.country || ''}`,
            `${
              attendee?.attendeesUserDetail?.teachingInstituteOrQualification ||
              ''
            }`,
            `${attendee?.attendeesUserDetail?.teachingYears || ''}`,
            `${attendee?.attendeesUserDetail?.teachingDanceType || ''}`,
            `${attendee?.attendeesUserDetail?.wechatId || ''}`,
            `${attendee?.attendeesUserDetail?.whyPbtWorkshop || ''}`,
            `${attendee?.paymentCurrency || ''}`,
            `${convertStripeAmount(buyerPaid, attendee?.paymentCurrency)}`,
            `${convertStripeAmount(
              attendee?.gatewayFeeInPaymentCurrency,
              attendee?.paymentCurrency
            )}`,
            `${convertStripeAmount(
              attendee?.techniqueOwnerShareInPaymentCurrency,
              attendee?.paymentCurrency
            )}`,
            `${convertStripeAmount(
              attendee?.HPLShareInPaymentCurrency,
              attendee?.paymentCurrency
            )}`,
            `${convertStripeAmount(
              attendee?.instructorShareInPaymentCurrency,
              attendee?.paymentCurrency
            )}`,
          ]);
        }
      });
    return csvdata;
  };

  const onSearch = (reg: string) => {
    const filteredData = workshopAttendees?.attendees?.filter((record) => {
      return (
        record?.fullName?.includes(reg) ||
        record?.email?.includes(reg) ||
        record?._id === reg
      );
    });
    if (filteredData) {
      setAttendeeDetail(filteredData);
    }
  };

  const getAttendees = async () => {
    setRequesting(true);

    try {
      if (workshopSlug) {
        const data = await getWorkshopAttendees(workshopSlug);
        if (data) {
          setWorkshopAttendees(data?.data?.data);
          setAttendeeDetail(data?.data?.data?.attendees);
        }
      }
    } catch (err) {
      message.error(err);
    } finally {
      setRequesting(false);
      setReloadWorkshopAttendees(false);
    }
  };

  const columnsWithActions = [
    {
      title: '# No',
      render: (value: number, record: unknown, index: number) => index + 1,
      key: 'slno',
      width: 200,
    },
    {
      title: 'Attendee',
      render: (value: string, record: WorkshopAttendeeWithUserDetail) => (
        <div>
          <h3>
            <span style={{ marginRight: '5px' }}>{record?.fullName}</span>
            <WorkshopWaiver
              attendee={record}
              workshop={{
                techniqueSlug: workshopAttendees?.techniqueSlug,
                slug: workshopAttendees?.slug,
                language: workshopAttendees?.language,
              }}
            />
            {record.isCertificateReceived && (
              <Tooltip title="Certificate Sent">
                <FileDoneOutlined
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                />
              </Tooltip>
            )}
          </h3>
          <h4>{record?.email}</h4>
          <h4>{moment(record?.createdAt).format('ll')}</h4>
        </div>
      ),
    },
    {
      title: 'Buyer',
      render: (value: string, record: WorkshopAttendeeWithUserDetail) => (
        <div>
          {record?.isUnpaid ? (
            <>
              <h3>{'COMPLEMENTRY'}</h3>
            </>
          ) : (
            <>
              <h3>{record?.buyerName}</h3>
              <h4>{record?.buyerEmail}</h4>
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: WorkshopAttendeeWithUserDetail) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'view-attendee',
                  label: (
                    <ViewWorkshopAttendee
                      payload={record}
                      workshopDetails={{
                        title: workshopAttendees?.title,
                        state: workshopAttendees?.state,
                        country: workshopAttendees?.country,
                        startDate: moment(workshopAttendees?.startDate)
                          .tz(workshopAttendees?.timezone || 'UTC')
                          .format('MMMM Do YYYY, h:mm:ss a'),
                        timezone: workshopAttendees?.timezone,
                        city: workshopAttendees?.city,
                        workshopMode: workshopAttendees?.workshopMode,
                      }}
                    >
                      {({ onClick }) => (
                        <p className="no-margin w-full" onClick={onClick}>
                          View Attendee
                        </p>
                      )}
                    </ViewWorkshopAttendee>
                  ),
                  // disabled: !record?.attendeesUserDetail?._id,
                },
                {
                  key: 'edit-attendee',
                  label: (
                    <EditWorkshopAttendee
                      payload={record}
                      attendeeId={record._id as string}
                      successCallback={() => setReloadWorkshopAttendees(true)}
                      errorCallback={(e) => handleError(e)}
                    >
                      {({ onClick }) => (
                        <p className="no-margin w-full" onClick={onClick}>
                          Edit Attendees
                        </p>
                      )}
                    </EditWorkshopAttendee>
                  ),
                  // disabled: !record?.attendeesUserDetail?._id,
                },
                {
                  type: 'divider',
                },
                {
                  key: 'send-welcome-mail',
                  label: (
                    <EmailWorkshopAttendee
                      setRequesting={setRequesting}
                      attendeeId={record?._id as string}
                      successCallback={() => setReloadWorkshopAttendees(true)}
                      errorCallback={(e) => handleError(e)}
                    >
                      {({ onClick }) => (
                        <p className="no-margin w-full" onClick={onClick}>
                          Send Welcome Mail
                        </p>
                      )}
                    </EmailWorkshopAttendee>
                  ),
                  // disabled: !record?.attendeesUserDetail?._id,
                },

                {
                  key: 'send-certification-mail',
                  label: 'Send Certification Mail',
                  onClick: async () => {
                    const isPreviousCertificationVerified =
                      (workshopAttendees?.level || 1) > 1
                        ? record?.isPreviousCertificationVerified
                        : true;
                    if (
                      record?.attendeesUserDetail?._id &&
                      isPreviousCertificationVerified &&
                      workshopAttendees?.isWorkshopConcluded
                    ) {
                      await sendCertificateMail(record._id as string);
                    }
                  },
                  disabled:
                    !workshopAttendees?.isWorkshopConcluded ||
                    !record?.attendeesUserDetail?._id ||
                    ((workshopAttendees?.level || 1) > 1 &&
                      !record?.isPreviousCertificationVerified),
                },
                {
                  type: 'divider',
                },
                {
                  key: 'certificate-download',
                  label: 'Download Certificate',
                  onClick: async () => {
                    await downloadCertificate(record._id as string);
                  },
                  disabled: !record?.attendeesUserDetail?._id,
                },
                {
                  key: 'certificate-receipt',
                  label: 'Download Receipt',
                  onClick: async () => {
                    await downloadReceipt(record._id as string);
                  },
                },
                {
                  type: 'divider',
                },
                {
                  key: 'previous-certification-verification',
                  label: (
                    <PreviousCertificationVerification
                      attendee={record}
                      setReloadWorkshopAttendees={setReloadWorkshopAttendees}
                      workshopLevel={workshopAttendees?.level ?? 1}
                    >
                      {({ onClick }) => (
                        <p
                          className="no-margin  w-full"
                          onClick={() => {
                            if (
                              (workshopAttendees?.level || 1) !== 1 &&
                              !record?.isPreviousCertificationVerified &&
                              record?.attendeesUserDetail?._id
                            )
                              onClick();
                          }}
                        >
                          {record?.isPreviousCertificationVerified ? (
                            <div className="verify-previous-certification">
                              <CheckCircleFilled className="waiver-signed" />
                              <span>Previous certification verified</span>
                            </div>
                          ) : (
                            'Verify previous certification'
                          )}
                        </p>
                      )}
                    </PreviousCertificationVerification>
                  ),
                  disabled:
                    (workshopAttendees?.level || 1) === 1 ||
                    record?.isPreviousCertificationVerified ||
                    !record?.attendeesUserDetail?._id,
                },

                {
                  key: 'refund',
                  label: (
                    <RefundModal
                      attendee={record}
                      setReloadWorkshopAttendees={setReloadWorkshopAttendees}
                    >
                      {({ onClick }) => (
                        <p
                          className="no-margin w-full"
                          onClick={() => {
                            if (!record?.isUnpaid) {
                              onClick();
                            }
                          }}
                        >
                          Manage Refund
                        </p>
                      )}
                    </RefundModal>
                  ),
                  disabled: record?.isUnpaid,
                },
                {
                  key: 'transfer-attendee',
                  label: (
                    <TransferAttendee
                      attendee={record}
                      setReloadWorkshopAttendees={setReloadWorkshopAttendees}
                      workshop={workshopAttendees}
                    >
                      {({ onClick }) => (
                        <p
                          className="no-margin w-full"
                          onClick={() => {
                            if (record?.isRegistrationCompleted) {
                              onClick();
                            }
                          }}
                        >
                          Manage Transfer
                        </p>
                      )}
                    </TransferAttendee>
                  ),
                  disabled: !record?.isRegistrationCompleted,
                },
                {
                  key: 'remove-attendee',
                  label: (
                    <RemoveAttendee
                      attendee={record}
                      successCallback={() => setReloadWorkshopAttendees(true)}
                      errorCallback={handleError}
                    >
                      {({ onClick }) => (
                        <p
                          className="no-margin w-full"
                          onClick={() => {
                            if (record?.isUnpaid) {
                              onClick();
                            }
                          }}
                        >
                          Remove Attendee
                        </p>
                      )}
                    </RemoveAttendee>
                  ),
                  disabled: !record?.isUnpaid,
                },
              ],
            }}
            arrow
            trigger={['click']}
            placement="bottomRight"
            className="workshop-dropdown"
            overlayClassName="workshop-dropdown-overlay"
          >
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    if (reloadWorkshopAttendees) getAttendees();
  }, [workshopSlug, reloadWorkshopAttendees]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const attendeeID = queryParams.get('attendeeID');
    if (attendeeID && workshopAttendees) {
      setText(attendeeID);
    }
  }, [workshopAttendees]);

  useEffect(() => {
    onSearch(text);
  }, [text]);

  return (
    <>
      <Spin spinning={requesting}>
        <div className="attendees">
          <div className="manage-workshop-heading">
            <div>
              <h2
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  fontSize: '28px',
                }}
                className="no-margin"
              >
                <LeftCircleFilled
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                {workshopAttendees?.title}
              </h2>
              <div>
                <p className="no-margin">
                  {workshopAttendees?.city || 'Online'},{' '}
                  {workshopAttendees?.country}
                </p>
                <p className="no-margin">
                  {moment(workshopAttendees?.startDate)
                    .tz(workshopAttendees?.timezone || 'UTC')
                    .format('MMMM Do YYYY, h:mm a')}
                  , {workshopAttendees?.timezone}
                </p>
              </div>
            </div>
            <div className="manage-attendees">
              <EmailSentAttendees
                attendees={attendeesDetail}
                workshopSlug={workshopAttendees?.slug}
                workshopTimeZone={workshopAttendees?.timezone}
                workshopId={workshopAttendees?._id}
                successCallback={() => setReloadWorkshopAttendees(true)}
                errorCallback={(e) => handleError(e)}
              >
                {({ onClick }) => (
                  <div className="manage-workshop-icon" onClick={onClick}>
                    <i>
                      <MailOutlined />
                    </i>
                    <p className="no-margin">Email Attendees</p>
                  </div>
                )}
              </EmailSentAttendees>
              <div
                className="manage-workshop-icon"
                onClick={() => setOpenPrintCertificateModal(true)}
              >
                <i>
                  <FileTextOutlined />
                </i>
                <p className="no-margin">Print off certificates</p>
              </div>
              {/* print certificate modal-- */}
              <Modal
                open={openPrintCertificateModal}
                onCancel={() => setOpenPrintCertificateModal(false)}
                destroyOnClose
                width={680}
                title="Print Off Certificates"
                footer={[
                  <Button onClick={() => setOpenPrintCertificateModal(false)}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={printCertificateRequesting}
                    onClick={downloadAllCertificate}
                  >
                    Print
                  </Button>,
                ]}
              >
                <p>
                  <span style={{ color: 'red' }}>Note</span>: Only certificates
                  of users with registration completed, attendance marked as
                  attended, and previous certification verified for workshops
                  above level 1, are downloaded.
                </p>
              </Modal>
              <CSVLink
                className="manage-workshop-icon"
                filename={'attendeesList.csv'}
                data={printAttendeesList()}
              >
                <i>
                  <PrinterOutlined />
                </i>
                <p className="no-margin" style={{ color: '#000' }}>
                  Print out list
                </p>
              </CSVLink>
              <AddWorkshopAttendee
                workshopSlug={workshopSlug as string}
                successCallback={() => setReloadWorkshopAttendees(true)}
                errorCallback={(e) => handleError(e)}
              >
                {({ onClick }) => (
                  <div className="manage-workshop-icon" onClick={onClick}>
                    <i>
                      <UserAddOutlined />
                    </i>

                    <p className="no-margin">Add attendee</p>
                  </div>
                )}
              </AddWorkshopAttendee>

              <WaitlistAttendees>
                {({ onClick }) => (
                  <div className="manage-workshop-icon" onClick={onClick}>
                    <i>
                      <FileTextOutlined />
                    </i>

                    <p className="no-margin">Waitlist</p>
                  </div>
                )}
              </WaitlistAttendees>
            </div>
          </div>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col span={8}>
              <Input.Search
                onChange={(e) => {
                  setText(e?.target?.value);
                }}
                placeholder="Search Records"
                allowClear
                value={text}
              />
            </Col>
            <Tooltip title="Refresh" placement="bottom">
              <Button
                type="ghost"
                icon={<ReloadOutlined />}
                onClick={() => setReloadWorkshopAttendees(true)}
              />
            </Tooltip>
          </Row>
          <div>
            <Table
              rowKey="_id"
              dataSource={attendeesDetail?.sort((a, b) => {
                return b.isRegistrationCompleted === a.isRegistrationCompleted
                  ? 0
                  : b.isRegistrationCompleted
                  ? -1
                  : 1;
              })}
              columns={columnsWithActions}
            />
          </div>
        </div>
      </Spin>
    </>
  );
};

export default ManageWorkshop;
