import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { handleError } from '../../utils/common';
import { IEmailAttachments } from '../../types/email-attachment';
import EditAttachments from '../../components/email-attachments/edit-attachments';
import DeleteAttachment from '../../components/email-attachments/delete-attachment';
import AddAttachments from '../../components/email-attachments/add-attachments';
import { getAllAttachments } from '../../services/email-attachments';

import columns from './column';

const EmailAttachments = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadAttachments, setReloadAttachments] = useState(true);
  const [attachments, setAttachments] = useState<IEmailAttachments[]>([]);

  const loadPages = async () => {
    setRequesting(true);
    try {
      const response = await getAllAttachments();
      setAttachments(response?.data?.data);
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
      setReloadAttachments(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadAttachments) {
      loadPages();
    }
  }, [reloadAttachments]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: IEmailAttachments) => {
        return (
          <Space size="large">
            <EditAttachments
              slug={record?.slug || ''}
              payload={record}
              successCallback={() => setReloadAttachments(true)}
              errorCallback={handleError}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditAttachments>
            <DeleteAttachment
              id={record?._id}
              successCallback={() => setReloadAttachments(true)}
              errorCallback={handleError}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteAttachment>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={attachments}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>
                Manage Email Attachments
              </Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadAttachments(true)}
                  />
                </Tooltip>
                <AddAttachments
                  successCallback={() => setReloadAttachments(true)}
                  errorCallback={handleError}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Attachments
                    </Button>
                  )}
                </AddAttachments>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default EmailAttachments;
