/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AddFaqCategoryForm from '../form';
import validationSchema from '../validation-schema';
import { updateFaq } from '../../../../services/faq-services';
import { FaqDetail } from '../../../../types/faq';

interface IEditFaqProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  payload: FaqDetail;
  faqId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditFaq: FC<IEditFaqProps> = ({
  children,
  payload,
  faqId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<FaqDetail>({
    resolver: yupResolver(validationSchema),
    defaultValues: payload,
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };
  const onSubmitHandler = async (values: FaqDetail) => {
    setRequesting(true);
    try {
      await updateFaq(values, faqId);
      setDrawerVisible(false);
      message.success('Faq Updated successfully');
      if (successCallback) {
        successCallback();
      }
    } catch (err: any) {
      message.error(err?.response?.data?.MESSAGE_KEY || err.message);
      console.log(err);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    form.reset(payload);
  }, [payload]);

  return (
    <>
      <Drawer
        title="Update Faq Category"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Update
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddFaqCategoryForm form={form} editable={true} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditFaq;
