/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode } from 'react';
import { Form, Input, InputProps } from 'antd';
import _ from 'lodash';
import { Controller, UseFormReturn } from 'react-hook-form';
import Password from 'antd/lib/input/Password';

interface IInputFieldProps {
  label?: string | ReactNode;
  required?: boolean;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  form: UseFormReturn<any, any>;
  inputProps?: InputProps;
  isPassword?: boolean;
  style?: object;
}

const InputField: FC<IInputFieldProps> = ({
  form: {
    formState: { errors },
    control,
  },
  label,
  required,
  name,
  defaultValue,
  disabled = false,
  inputProps,
  isPassword,
  style,
}) => {
  let TextInput: typeof Input | typeof Password = Input;
  if (isPassword) {
    TextInput = Input.Password;
  }
  const errorMessages = _.get(errors, name);
  const hasError = !!(errors && errorMessages);
  return (
    <Form.Item
      label={label}
      help={errorMessages?.message}
      validateStatus={hasError ? 'error' : 'success'}
      required={required}
      style={style ?? {}}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextInput {...field} {...inputProps} disabled={disabled} />
        )}
      />
    </Form.Item>
  );
};

export default InputField;
