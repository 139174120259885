import { ICertificationRenewalResponse } from '../../types/certifciation-renewal-response';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 100,
  },
  {
    title: 'User',
    render: (value: string, record: ICertificationRenewalResponse) => (
      <div>
        {record?.user?.fullName} ({record?.user?.email})
      </div>
    ),
    width: 200,
  },
  {
    title: 'Renewal Year',
    render: (value: string, record: ICertificationRenewalResponse) => (
      <div>{record?.renewalYear}</div>
    ),
    width: 200,
  },

  {
    title: 'Status',
    render: (value: string, record: ICertificationRenewalResponse) => {
      const isReviewd = record?.isReviewed;
      const isApproved = record?.isApproved;
      return (
        <div>
          {isReviewd ? (isApproved ? 'Approved' : 'Rejected') : 'pending'}
        </div>
      );
    },
    width: 150,
  },
];

export default columns;
