import * as yup from 'yup';

// Custom validation method for all uppercase letters and numbers
const allCapsAndNumbers = (value: string | undefined) => {
  if (!value) return false;
  const allCapsAndNumbersRegex = /^[A-Z0-9]+$/;
  return allCapsAndNumbersRegex.test(value);
};

export default yup.object().shape({
  name: yup
    .string()
    .required('Coupon name is required')
    .test(
      'is-all-caps-and-numbers',
      'Coupon name must be in uppercase letters and numbers only',
      (value) => allCapsAndNumbers(value)
    ),
  discountPercentage: yup
    .number()
    .typeError('Coupon % is a number.')
    .required('Coupon % off is required')
    .min(1, 'Coupon % off  must be greater than or equal to 1')
    .max(100, 'Coupon % off must be less than or equal to 100'),
  redeemBy: yup.string().required('Coupon expiry date is required'),
  workshopsIds: yup
    .array()
    .required('Select atleast one workshop')
    .min(1, 'Select atleast one workshop'),
});
