import { Form, message, Modal } from 'antd';
import { createElement, FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  changeBillingCycle,
  getUserPlans,
  MemberType,
  TUser,
  updateMemberType,
  UserRole,
} from '../../../../services/users-service';
import './index.scss';
import { SelectField } from '../../../common/form';
import { NOT_AVAILABLE } from '../../../../constants/common';
import { convertStripeAmount, handleError } from '../../../../utils/common';
import { PlanSimplified } from '../../../../constants/stripe';

const validationSchema = yup.object().shape({
  memberType: yup
    .string()
    .oneOf([MemberType.student, MemberType.teacher])
    .required('required'),
});

const UpdateMemberType = ({
  user,
  setReloadUsers,
}: {
  user: TUser;
  setReloadUsers: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div>
      {user?.role === UserRole.admin ? (
        NOT_AVAILABLE
      ) : user?.memberType ? (
        user?.memberType === MemberType.teacher ? (
          <p className="member-type-label">{user?.memberType}</p>
        ) : (
          <UpdateMemberTypeModal user={user} setReloadUsers={setReloadUsers}>
            {({ onClick }) => (
              <p
                className="member-type-label link-member-type"
                onClick={onClick}
              >
                {user?.memberType}
              </p>
            )}
          </UpdateMemberTypeModal>
        )
      ) : (
        NOT_AVAILABLE
      )}
    </div>
  );
};

const UpdateMemberTypeModal = ({
  user,
  children,
  setReloadUsers,
}: {
  user: TUser;
  children: FC<{
    onClick: () => void;
  }>;
  setReloadUsers: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, seLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [planData, setPlanData] = useState<PlanSimplified[]>([]);

  const form = useForm<{
    memberType: MemberType;
    billingCycle: string;
  }>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    form.reset({});
  }, [form]);

  const handleSubmit = async (values: {
    memberType: MemberType;
    billingCycle: string;
  }) => {
    setRequesting(true);
    try {
      await updateMemberType({
        memberType: values?.memberType,
        userId: user?._id ?? '',
      });
      await changeBillingCycle({
        newPlanId: values.billingCycle,
        userId: user?._id,
      });
      message.success('Successful');
      handleClose();
      setReloadUsers(true);
    } catch (error) {
      handleError(error);
    } finally {
      setRequesting(false);
    }
  };

  const getPlans = async () => {
    seLoading(true);
    try {
      const response = await getUserPlans(user?.subscription?.currency || '');
      setPlanData(response.data?.data?.plans || []);
    } catch (error) {
      handleError(error);
    } finally {
      seLoading(false);
    }
  };

  useEffect(() => {
    if (open) getPlans();
  }, [open]);

  const memberType = form.watch('memberType');

  return (
    <>
      <Modal
        open={open}
        onCancel={handleClose}
        title="Change Member Type"
        bodyStyle={{ paddingTop: '15px', paddingBottom: '15px' }}
        okText="Submit"
        okButtonProps={{
          type: 'primary',
          loading: requesting,
        }}
        onOk={form.handleSubmit(handleSubmit)}
      >
        <Form layout="vertical">
          <SelectField
            required
            label="Select New Member Type"
            name="memberType"
            labelKey="label"
            valueKey="value"
            form={form}
            selectFieldProps={{
              options: [
                {
                  label: 'Teacher',
                  value: 'teacher',
                },
                // {
                //   label: 'Student',
                //   value: 'student',
                // },
              ],
              placeholder: 'Select Member Type',
            }}
          />
          {memberType && (
            <SelectField
              label={'Select New Billing Cycle'}
              name="billingCycle"
              required
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: [
                  ...planData
                    .filter((plans) => {
                      return form.getValues()?.billingCycle
                        ? plans.name.includes(
                            `${form.getValues()?.billingCycle?.split(' ')[0]}`
                          )
                        : memberType
                        ? plans.name
                            .toLowerCase()
                            .includes(memberType as string)
                        : true;
                    })
                    .map((item) => {
                      return {
                        value: `${item.id}`,
                        label: `${item.name} ( ${
                          item.currency
                        } ${convertStripeAmount(item.amount, item.currency)} )`,
                      };
                    }),
                ],
                placeholder: 'Select Billing Cycle',
                onChange: (v) => form.setValue('billingCycle', v),
                loading: loading,
              }}
            />
          )}
          <p className="no-margin">
            <strong>Note:</strong> This is a one-way process: a student can be
            promoted to a teacher, but a teacher cannot be reverted to a
            student.
          </p>
        </Form>
      </Modal>
      {createElement(children, { onClick: handleOpen })}
    </>
  );
};

export default UpdateMemberType;
