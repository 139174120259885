import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { InputField, SelectField, TextAreaField } from '../../common/form';
import { ICategory } from '../types';

export type TFieldValues = {
  category: string;
  en: string;
  pt: string;
  it: string;
  es: string;
  de: string;
  zh: string;
  maxmarks?: number;
};

export const defaultValues: TFieldValues = {
  category: '',
  en: '',
  pt: '',
  it: '',
  es: '',
  de: '',
  zh: '',
};

interface IAddQuestionFormProps {
  form: UseFormReturn<TFieldValues, object>;
  categories: ICategory[];
}

const AddQuestionForm = ({ categories, form }: IAddQuestionFormProps) => {
  return (
    <Form
      {...{
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      }}
    >
      <SelectField
        label="Category"
        required
        name="category"
        valueKey="slno"
        renderValue={(record) => record.name.en}
        form={form}
        selectFieldProps={{
          options: categories,
          placeholder: 'Select category',
          onChange: (v) => form.setValue('category', v),
        }}
      />
      <TextAreaField
        label="Question (en)"
        name="en"
        required
        textAreaProps={{
          rows: 2,
          placeholder: 'English',
        }}
        form={form}
      />
      <TextAreaField
        label="Question (pt)"
        name="pt"
        required
        textAreaProps={{
          rows: 2,
          placeholder: 'Portugese',
        }}
        form={form}
      />
      <TextAreaField
        label="Question (it)"
        name="it"
        required
        textAreaProps={{
          rows: 2,
          placeholder: 'Italian',
        }}
        form={form}
      />
      <TextAreaField
        label="Question (es)"
        name="es"
        required
        textAreaProps={{
          rows: 2,
          placeholder: 'Spanish',
        }}
        form={form}
      />
      <TextAreaField
        label="Question (de)"
        name="de"
        required
        textAreaProps={{
          rows: 2,
          placeholder: 'German',
        }}
        form={form}
      />
      <TextAreaField
        label="Question (zh)"
        name="zh"
        required
        textAreaProps={{
          rows: 2,
          placeholder: 'Mandrin',
        }}
        form={form}
      />
      <InputField
        label="Max Marks"
        name="maxmarks"
        inputProps={{
          type: 'number',
          placeholder: 'Marks',
        }}
        form={form}
      />
    </Form>
  );
};

export default AddQuestionForm;
