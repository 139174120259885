import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { countrieskeyVlaue } from '../../constants/countries';
import { getRegionsKeyValue } from '../../constants/regions';
import { InputField, SelectField } from '../common/form';
import { WorkshopAttendeeWithUserDetail } from '../../types/workshop';

interface AttendeeFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, object>;
  isEditable: boolean;
  payload: WorkshopAttendeeWithUserDetail;
}
const AttendeeForm = ({ form, isEditable, payload }: AttendeeFormProps) => {
  return (
    <>
      <Form layout="vertical">
        <InputField
          label="Name"
          name="fullName"
          required
          inputProps={{
            placeholder: 'Name',
          }}
          form={form}
        />
        <InputField
          label="Email"
          name="email"
          required
          inputProps={{
            placeholder: 'Email',
          }}
          form={form}
        />
        <InputField
          label="Mobile"
          name="mobileNumber"
          required
          inputProps={{
            placeholder: 'mobile number',
          }}
          form={form}
          disabled={!payload?.attendeesUserDetail?._id}
        />
        <SelectField
          label="Country"
          name="country"
          required
          form={form}
          valueKey="value"
          labelKey="label"
          selectFieldProps={{
            options: countrieskeyVlaue(),
            showSearch: true,
            onChange: (v) => {
              form.setValue('country', v);
              form.setValue('state', '');
            },
            placeholder: 'Select country',
            disabled: !payload?.attendeesUserDetail?._id,
          }}
        />
        <SelectField
          label="State"
          name="state"
          required
          form={form}
          valueKey="value"
          labelKey="label"
          selectFieldProps={{
            options: getRegionsKeyValue(form.watch('country')),
            placeholder: 'Select State',
            onChange: (v) => form.setValue('state', v),
            disabled: !payload?.attendeesUserDetail?._id,
          }}
        />
        <InputField
          label="Address1"
          name="address1"
          required
          inputProps={{
            placeholder: 'Address1',
          }}
          disabled={!payload?.attendeesUserDetail?._id}
          form={form}
        />
        <InputField
          label="Address2"
          name="address2"
          required={false}
          inputProps={{
            placeholder: 'Address2',
          }}
          disabled={!payload?.attendeesUserDetail?._id}
          form={form}
        />
        <InputField
          label="City"
          name="city"
          required
          inputProps={{
            placeholder: 'City',
          }}
          disabled={!payload?.attendeesUserDetail?._id}
          form={form}
        />
        <InputField
          label="Zip"
          name="zip"
          required
          inputProps={{
            placeholder: 'Zip',
          }}
          form={form}
          disabled={!payload?.attendeesUserDetail?._id}
        />
        <InputField
          label="WeChat Id"
          name="wechatId"
          required
          inputProps={{
            placeholder: 'WeChat Id',
          }}
          form={form}
          disabled={!payload?.attendeesUserDetail?._id}
        />
        <InputField
          label="Name to appear on certificate"
          name="nameAsOnCertificate"
          required
          inputProps={{
            placeholder: 'Name to appear on certificate',
          }}
          form={form}
          disabled={!payload?.attendeesUserDetail?._id}
        />
        <InputField
          label="Emergency contact name"
          name="emergencyContactName"
          required
          inputProps={{
            placeholder: 'emergency contact  name',
          }}
          form={form}
          disabled={!payload?.attendeesUserDetail?._id}
        />
        <InputField
          label="Emergency contact number"
          name="emergencyContactNumber"
          required
          inputProps={{
            placeholder: 'emergency contact number',
          }}
          form={form}
          disabled={!payload?.attendeesUserDetail?._id}
        />
        <InputField
          label="Note"
          name="note"
          inputProps={{
            placeholder: 'Note',
          }}
          form={form}
        />
      </Form>
    </>
  );
};

export default AttendeeForm;
