import React, { useEffect } from 'react';
import { Button, Drawer, message } from 'antd';
import { createElement, FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import FileForm from '../form';
import { FileDetail } from '../../../types/file';
import {
  editFile,
  uploadFile,
} from '../../../services/files-directory-service';

interface UploadFileProps {
  file?: FileDetail;
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const UploadFile: FC<UploadFileProps> = ({
  file,
  children,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<FileDetail>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: FileDetail) => {
    setRequesting(true);
    try {
      if (!file) {
        await uploadFile(values);
      } else await editFile(values);
      setDrawerVisible(false);
      message.success(`File ${file ? 'updated' : 'uploaded'} successfully`);
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (file) form.reset(file);
  }, [file]);

  return (
    <>
      <Drawer
        title={`${file ? 'Edit file' : 'Upload file in directory'} `}
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={400}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <FileForm form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default UploadFile;
