import { USER_PAGES } from '../constants/api';
import { IUserPages } from '../types/user-pages';
import fetchClient from '../utils/fetch-client';

export const addUserPage = (payload: IUserPages) => {
  return fetchClient.post(`${USER_PAGES.ADD}`, payload);
};
export const getAllPage = () => {
  return fetchClient.get(USER_PAGES.GET_ALL);
};

export const editUserPage = (payload: IUserPages) => {
  return fetchClient.put(`${USER_PAGES.EDIT}`, payload);
};

export const deleteUserPage = (pageID: string) => {
  return fetchClient.delete(`${USER_PAGES.DELETE}/${pageID}`);
};
