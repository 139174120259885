import {
  Button,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { IEndorsement } from '../../types/endorsements-and-testimonials';
import {
  getAllEndorsements,
  updateSliderVisibility,
} from '../../services/endorsements-service';
import AddEndorsement from '../../components/endorsement-and-testimonials/add-endorsement';
import EditEndorsement from '../../components/endorsement-and-testimonials/edit-endorsement';
import DeleteEndorsement from '../../components/endorsement-and-testimonials/delete-endorsement';

import columns from './columns';

const Endorsements = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadEndorsements, setReloadEndorsements] = useState(true);
  const [endorsements, setEndorsements] = useState<IEndorsement[]>([]);

  const loadTemplates = async () => {
    setRequesting(true);
    try {
      const response = await getAllEndorsements();
      setEndorsements(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadEndorsements(false);
    }
  };

  const handleSliderVisivility = async (value: boolean, id: string) => {
    setRequesting(true);
    try {
      await updateSliderVisibility({ inSlider: value }, id);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      message.error(e?.response?.data?.MESSAGE_KEY || e?.message);
    } finally {
      setRequesting(false);
      setReloadEndorsements(true);
    }
  };

  useEffect(() => {
    if (!requesting && reloadEndorsements) {
      loadTemplates();
    }
  }, [reloadEndorsements, requesting]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Slider Visibility',
      key: 'sliderVisibility',
      width: 200,
      render: (value: string, record: IEndorsement) => {
        return (
          <Tooltip
            title="Would you like to include this in the testimonial slider?"
            placement="left"
          >
            <Switch
              size="small"
              defaultChecked={record?.inSlider}
              onChange={async (e) => {
                await handleSliderVisivility(e, record?._id || '');
              }}
              disabled={!record?.isApproved}
            />
          </Tooltip>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: IEndorsement) => {
        return (
          <Space size="large">
            <EditEndorsement
              endorsmentId={record._id || ''}
              payload={record}
              successCallback={() => {
                message.success('Endorsement updated successfully');
                setReloadEndorsements(true);
              }}
              errorCallback={(e) => {
                message.error('error in updating');
              }}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditEndorsement>
            <DeleteEndorsement
              endorsmentId={record._id || ''}
              successCallback={() => {
                message.success('Endorsement deleted successfully');
                setReloadEndorsements(true);
              }}
              errorCallback={(e) => {
                message.error('error in deleting');
              }}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteEndorsement>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={endorsements}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>
                Testimonials & Endorsement
              </Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadEndorsements(true)}
                  />
                </Tooltip>
                <AddEndorsement
                  successCallback={() => {
                    message.success('Endorsement added successfully');
                    setReloadEndorsements(true);
                  }}
                  errorCallback={(e) => {
                    message.error('error in adding');
                  }}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Endorsement
                    </Button>
                  )}
                </AddEndorsement>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default Endorsements;
