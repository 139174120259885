import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AddInstructorForm from '../form';
import validationSchema from '../validation-schema';
import { updateInstructor } from '../../../services/instructor-service';
import {
  InstructorDetail,
  WorkshopInstructor,
} from '../../../types/instructor';

interface IEditInstructorProps {
  successCallback: () => void;
  instructors?: WorkshopInstructor[];
  errorCallback?: (e: unknown) => void;
  payload?: WorkshopInstructor;
  instructorId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditInstructor: FC<IEditInstructorProps> = ({
  children,
  payload,
  instructors,
  instructorId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<InstructorDetail>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: InstructorDetail) => {
    setRequesting(true);

    try {
      const customPaymentShares = Object?.keys(
        values?.customPaymentShares || {}
      )?.map((i: string) => ({
        techniqueSlug: i,
        ownerShare: values?.customPaymentShares?.[i]?.ownerShare,
        hplShare: values?.customPaymentShares?.[i]?.hplShare,
        instructorShare: values?.customPaymentShares?.[i]?.instructorShare,
      }));

      const payload = {
        ...values,
        pic: values?.pic?.pic,
        pic100: values?.pic?.pic100,
        pic35: values?.pic?.pic35,
        customPaymentShares,
      };

      await updateInstructor(payload, instructorId);
      setDrawerVisible(false);
      message.success('Instructor Updated successfully');
      if (successCallback) successCallback();
    } catch (err) {
      if (errorCallback) {
        errorCallback(err);
      }
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    const defaultInstructorValues = {
      ...payload,
      pic: {
        pic: payload?.pic,
        pic100: payload?.pic100,
        pic35: payload?.pic35,
      },
      customPaymentShares: payload?.customPaymentShares?.reduce(
        (
          acc: {
            [key: string]: {
              ownerShare?: number;
              hplShare?: number;
              instructorShare?: number;
            };
          },
          cur
        ) => {
          if (cur?.techniqueSlug) {
            acc[cur.techniqueSlug] = {
              ownerShare: cur?.ownerShare,
              hplShare: cur?.hplShare,
              instructorShare: cur?.instructorShare,
            };
          }

          return acc;
        },
        {}
      ),
    };
    form.reset(defaultInstructorValues);

    return () => {
      form.reset({});
    };
  }, [payload]);

  return (
    <>
      <Drawer
        title="Update Instructor"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddInstructorForm
            isEditable={true}
            instructors={instructors}
            form={form}
            readOnly={false}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditInstructor;
