import { Form, TreeSelect } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { TUser } from '../../../../../services/users-service';
import TreeSelectField from '../../../../common/form/tree-select';
import { MENUS } from '../../../../layout/sidebar';

interface IEditAdminFormProps {
  form: UseFormReturn<TUser, object>;
}
const EditAdminForm = ({ form }: IEditAdminFormProps) => {
  return (
    <>
      <Form layout="vertical">
        <TreeSelectField
          name="allowedSections"
          label="Allowed Sections"
          form={form}
          treeSelectFieldProps={{
            treeData: MENUS?.map((menu) => {
              return {
                label: menu?.label,
                value: menu?.key,
                key: menu?.key,
                children: menu?.submenus?.map((submenu) => {
                  return {
                    label: submenu?.label,
                    value: JSON.stringify({
                      key: menu?.key,
                      subSection: {
                        key: submenu?.path,
                        label: submenu?.label,
                      },
                    }),
                    key: JSON.stringify({
                      key: menu?.key,
                      subSection: {
                        key: submenu?.path,
                        label: submenu?.label,
                      },
                    }),
                  };
                }),
              };
            }),
            placeholder: 'Select Sections',
            style: {
              width: '100%',
            },
            treeCheckable: true,
            showCheckedStrategy: TreeSelect.SHOW_CHILD,
          }}
        />
      </Form>
    </>
  );
};

export default EditAdminForm;
