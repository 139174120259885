export const stripeAvailablityCountries = [
  { label: 'United States', value: 'US' },
  { label: 'Canada', value: 'CA' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Australia', value: 'AU' },
  { label: 'Germany', value: 'DE' },
  { label: 'France', value: 'FR' },
  { label: 'Spain', value: 'ES' },
  { label: 'Italy', value: 'IT' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Norway', value: 'NO' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Finland', value: 'FI' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Austria', value: 'AT' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Japan', value: 'JP' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Chile', value: 'CL' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Peru', value: 'PE' },
  { label: 'India', value: 'IN' },
];
export const stripeCurrency = [
  { label: 'United States Dollar (USD)', value: 'usd' },
  { label: 'Canadian Dollar (CAD)', value: 'cad' },
  { label: 'British Pound (GBP)', value: 'gbp' },
  { label: 'Australian Dollar (AUD)', value: 'aud' },
  { label: 'Euro (EUR)', value: 'eur' },
  { label: 'Swedish Krona (SEK)', value: 'sek' },
  { label: 'Norwegian Krone (NOK)', value: 'nok' },
  { label: 'Danish Krone (DKK)', value: 'dkk' },
  { label: 'Swiss Franc (CHF)', value: 'chf' },
  { label: 'Singapore Dollar (SGD)', value: 'sgd' },
  { label: 'Japanese Yen (JPY)', value: 'jpy' },
  { label: 'Hong Kong Dollar (HKD)', value: 'hkd' },
  { label: 'New Zealand Dollar (NZD)', value: 'nzd' },
  { label: 'Brazilian Real (BRL)', value: 'brl' },
  { label: 'Mexican Peso (MXN)', value: 'mxn' },
  { label: 'Argentine Peso (ARS)', value: 'ars' },
  { label: 'Chilean Peso (CLP)', value: 'clp' },
  { label: 'Colombian Peso (COP)', value: 'cop' },
  { label: 'Peruvian Nuevo Sol (PEN)', value: 'pen' },
  { label: 'Indian Rupee (INR)', value: 'inr' },
];

export const activeEbanxCountries = [
  // 'AR', not using in ebanx now
  'BO',
  'BR',
  'CL',
  'EC',
  'MX',
  'PE',
  'CO',
  'UY',
];
