export const NOT_AVAILABLE = 'N/A';

export const USD_CURRENCY = {
  value: 'USD',
  label: 'US Dollar (USD)',
};

export const CNY_CURRENCY = {
  value: 'CNY',
  label: 'Chinese yuan (CNY)',
};

export const RUB_CURRENCY = {
  value: 'RUB',
  label: 'Russian ruble (RUB)',
};

export const LANGUAGES = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'es',
    label: 'Spanish',
  },
  {
    value: 'de',
    label: 'German',
  },
  {
    value: 'pt',
    label: 'Portugeese',
  },
  {
    value: 'it',
    label: 'Italian',
  },
  {
    value: 'zh',
    label: 'Chinese',
  },
];

export const ROLES = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'student',
    label: 'Students',
  },
  {
    value: 'teacher',
    label: 'Teachers',
  },
];

export const STAGES = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export enum Language {
  'en' = 'en',
  'es' = 'es',
  'zh' = 'zh',
  'pt' = 'pt',
  'it' = 'it',
  'de' = 'de',
}

export type LanguageEnum = 'en' | 'es' | 'zh' | 'it' | 'de' | 'pt';

export enum WorkshopLanguage {
  'en' = 'en',
  'es' = 'es',
  'zh' = 'zh',
  'pt' = 'pt',
  'it' = 'it',
  'de' = 'de',
  'zt' = 'zt',
  'ja' = 'ja',
  'ru' = 'ru',
}

export const PRIMARY_LANGUAGES: { value: Language; label: string }[] = [
  {
    value: Language.en,
    label: 'English',
  },
  {
    value: Language.es,
    label: 'Spanish',
  },
  {
    value: Language.zh,
    label: 'Chinese',
  },
];

export const WORKSHOP_LANGUAGES: { value: WorkshopLanguage; label: string }[] =
  [
    {
      value: WorkshopLanguage.en,
      label: 'English',
    },
    {
      value: WorkshopLanguage.es,
      label: 'Spanish',
    },
    {
      value: WorkshopLanguage.zh,
      label: 'Chinese (Simplified)',
    },
    {
      value: WorkshopLanguage.zt,
      label: 'Chinese (Traditional)',
    },
    {
      value: WorkshopLanguage.pt,
      label: 'Portuguese',
    },
    {
      value: WorkshopLanguage.it,
      label: 'Italian',
    },
    {
      value: WorkshopLanguage.de,
      label: 'German',
    },
    {
      value: WorkshopLanguage.ja,
      label: 'Japanese',
    },
    {
      value: WorkshopLanguage.ru,
      label: 'Russian',
    },
  ];

export const STRIPE_ZERO_DECIMAL_COUNTRIES = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

export const LANGUAGE_FILTER_OPTIONS = [
  {
    value: 'es',
    label: 'No Spanish',
  },
  {
    value: 'de',
    label: 'No German',
  },
  {
    value: 'pt',
    label: 'No Portugeese',
  },
  {
    value: 'it',
    label: 'No Italian',
  },
  {
    value: 'zh',
    label: 'No Chinese',
  },
];

// export const LANGUAGE_FILTER_OPTIONS = [
//   {
//     value: '{language: "es", isFiltered: true}',
//     label: 'Video in Spanish',
//   },
//   {
//     value: '{language: "de", isFiltered: true}',
//     label: 'Video in German',
//   },
//   {
//     value: '{language: "pt", isFiltered: true}',
//     label: 'Video in Portuguese',
//   },
//   {
//     value: '{language: "it", isFiltered: true}',
//     label: 'Video in Italian',
//   },
//   {
//     value: '{language: "zh", isFiltered: true}',
//     label: 'Video in Chinese',
//   },
//   {
//     value: '{language: "es", isFiltered: false}',
//     label: 'Video not in Spanish',
//   },
//   {
//     value: '{language: "de", isFiltered: false}',
//     label: 'Video not in German',
//   },
//   {
//     value: '{language: "pt", isFiltered: false}',
//     label: 'Video not in Portuguese',
//   },
//   {
//     value: '{language: "it", isFiltered: false}',
//     label: 'Video not in Italian',
//   },
//   {
//     value: '{language: "zh", isFiltered: false}',
//     label: 'Video not in Chinese',
//   },
// ];

export const locationLimit = 2;
