import * as yup from 'yup';

export default yup.object().shape({
  // blog: yup.string().required('Section-blog is required'),
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
  title: yup.object().shape({
    en: yup
      .string()
      .required('Please enter blog title in English')
      .min(3, 'Blog title in English must be at least 3 characters')
      .max(100, 'Blog title in English must be at most 100 characters'),

    pt: yup
      .string()
      .required('Please enter blog title in Portugese')
      .min(3, 'Blog title in Portuguese must be at least 3 characters')
      .max(100, 'Blog title in Portuguese must be at most 100 characters'),

    it: yup
      .string()
      .required('Please enter blog title in Italian')
      .min(3, 'Blog title in Italian must be at least 3 characters')
      .max(100, 'Blog title in Italian must be at most 100 characters'),

    es: yup
      .string()
      .required('Please enter blog title in Spanish')
      .min(3, 'Blog title in Spanish must be at least 3 characters')
      .max(100, 'Blog title in Spanish must be at most 100 characters'),

    de: yup
      .string()
      .required('Please enter blog title in German')
      .min(3, 'Blog title in German must be at least 3 characters')
      .max(100, 'Blog title in German must be at most 100 characters'),
    zh: yup
      .string()
      .required('Please enter blog title in Mandrin')
      .max(100, 'Blog title in Mandrin must be at most 100 characters'),
  }),
});
