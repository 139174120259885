import { Locale } from './common';
import { Sections } from './exercise-categories';
export interface ExerciseDetail {
  _id?: string;
  sectionSlug: string;
  categorySlug: string;
  stages: number[];
  masterVideoSlug: string;
  sectionName?: Locale;
}
export type MemberType = {
  students: string;
  teachers: string;
};

export interface ExerciseFaq {
  _id?: string;
  question: string;
  answer: string;
  forMemberType: MemberType;
}

export interface Category {
  techniqueSlug: string;
  sectionSlug: string;
  categorySlug: string;
}

export interface ExerciseVideo {
  _id: string;
  slug: string;
  language: string;
  videoName: string;
  musicUsed: string;
  difficultyLevel: 1 | 2 | 3;
  sequence: number;
  playCount?: number;
  duration?: number;
  mediaId?: string;
  thumb: string;
  customThumb?: string;
  classPlanPDFUrl?: string;
  videoUrl: string;
  description: {
    forStudent: string;
    forTeacher: string;
  };
  exerciseStartSeconds: number;
  faqs: ExerciseFaq[];
  equipments: (
    | string
    | {
        _id: string;
        name: {
          en: string;
          es: string;
          de: string;
          it: string;
          pt: string;
          zh: string;
        };
        url: string;
      }
  )[];
  categories: (string | Category)[];
  draftExerciseId?: string;
  isPublished?: boolean;
  haveMediaId?: boolean;
  isClassVideo?: boolean;
}

export interface VideoFormSections {
  _id: string;
  slug: string;
  name: string;
  techniqueId: string[];
}
export interface VideoFormCategories {
  _id: string;
  slug: string;
  name: string;
  sections: Sections[];
}
export interface VideoFormEquipments {
  _id: string;
  name: {
    en: string;
    es: string;
    de: string;
    it: string;
    pt: string;
    zh: string;
  };
  imageUrl1: string;
}
export interface VideoFormMuscles {
  _id: string;
  muscleName: {
    en: string;
    es: string;
    de: string;
    it: string;
    pt: string;
    zh: string;
  };
  url1: string;
}

export interface VideoFormVideos {
  _id: string;
  slug: string;
  videoName: string;
  mediaId: string;
}
export interface VideoFormTechniques {
  _id: string;
  slug: string;
  name: Locale;
}

export interface VideoFormData {
  sections: VideoFormSections[];
  categories: VideoFormCategories[];
  equipment: VideoFormEquipments[];
  techniques: VideoFormTechniques[];
}

export const defaultVideoFormDataValue = {
  sections: [{ _id: '', slug: '', name: 'All Sections', techniqueId: [] }],
  categories: [{ _id: '', slug: '', name: 'All Categories', sections: [] }],
  equipment: [
    {
      _id: '',
      name: { en: '', pt: '', it: '', es: '', de: '', zh: '' },
      imageUrl1: '',
    },
  ],
  techniques: [
    {
      _id: '',
      slug: '',
      name: { en: '', pt: '', it: '', es: '', de: '', zh: '' },
    },
  ],
};

export const defaultClassVideoFormDataValue = {
  sections: [{ _id: '', slug: '', name: 'All Sections', techniqueId: [] }],
  categories: [{ _id: '', slug: '', name: 'All Classes', sections: [] }],
  equipment: [
    {
      _id: '',
      name: { en: '', pt: '', it: '', es: '', de: '', zh: '' },
      imageUrl1: '',
    },
  ],
  techniques: [
    {
      _id: '',
      slug: '',
      name: { en: '', pt: '', it: '', es: '', de: '', zh: '' },
    },
  ],
};
