import { createElement, FC, useRef, useState } from 'react';
import { Button, Drawer } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { updateQuestion } from '../../../services/questions-service';
import { ICategory, IQuestion } from '../types';

import AddQuestionForm, { TFieldValues } from './form';
import validationSchema from './validation-schema';

interface IAddQuestionProps {
  categories: ICategory[];
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  question: IQuestion;
  questionId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditQuestion: FC<IAddQuestionProps> = ({
  children,
  categories,
  question,
  questionId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<TFieldValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: question,
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (data: TFieldValues) => {
    const { category, maxmarks, ...question } = data;
    setRequesting(true);
    try {
      await updateQuestion({
        category,
        ...(maxmarks && { maxmarks }),
        question,
        _id: questionId,
      });
      handleDrawerVisibility();
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Edit Question"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
          >
            Update
          </Button>
        }
      >
        <div style={{ padding: 16 }}>
          <AddQuestionForm categories={categories} form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditQuestion;
