import { BlogCategory } from '../../types/blog-categories';
// import { getTechniqueColor } from '../../utils/common';

const columns = [
  {
    title: 'Sequence',
    render: (value: string, record: BlogCategory) => record?.sequenceNo,
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: BlogCategory) => <>{record?.name?.en}</>,
    width: 250,
  },
];

export default columns;
