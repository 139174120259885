import { DRAFT_EXERCISES, EXERCISES, TENCENT } from '../constants/api';
import { ExerciseVideo } from '../types/exercises';
import fetchClient from '../utils/fetch-client';

export interface ExerciseFilter {
  language: string;
  section: string;
  category: string;
  technique: string;
  isClassVideo?: boolean;
}

export const getExercises = (
  filter: ExerciseFilter = {
    language: '',
    section: '',
    category: '',
    technique: '',
    isClassVideo: false,
  }
) => {
  return fetchClient.get(
    `${EXERCISES.EXERCISES}/all?language=${filter.language}&section=${filter.section}&category=${filter.category}&isclassvideo=${filter.isClassVideo}&technique=${filter.technique}`
  );
};

export const getDataForExerciseVideoForm = ({
  isClassVideo = false,
}: {
  isClassVideo?: boolean;
}) => {
  return fetchClient.get(
    `${EXERCISES.EXERCISES}/video-form-data?isclassvideo=${isClassVideo}`
  );
};

export const addExercise = ({
  payload,
  isClassVideo,
}: {
  payload: ExerciseVideo;
  isClassVideo?: boolean;
}) => {
  return fetchClient.post(`${EXERCISES.EXERCISES}`, {
    ...payload,
    isClassVideo,
  });
};
export const updateExercise = (payload: ExerciseVideo, exerciseId: string) => {
  return fetchClient.put(`${EXERCISES.EXERCISES}/${exerciseId}`, payload);
};
export const deleteExercise = (exerciseId: string) => {
  return fetchClient.delete(`${EXERCISES.EXERCISES}/${exerciseId}`);
};

export const getDraftExercises = (
  filter: ExerciseFilter = {
    language: '',
    section: '',
    category: '',
    technique: '',
  }
) => {
  return fetchClient.get(`${DRAFT_EXERCISES.DRAFT_EXERCISES}`);
};

export const addDraftExercise = (payload: ExerciseVideo) => {
  return fetchClient.post(`${DRAFT_EXERCISES.DRAFT_EXERCISES}`, payload);
};
export const updateDraftExercise = (
  payload: ExerciseVideo,
  draftExerciseId: string
) => {
  return fetchClient.put(
    `${DRAFT_EXERCISES.DRAFT_EXERCISES}/${draftExerciseId}`,
    payload
  );
};

export async function getTencentSignature() {
  return fetchClient.get(TENCENT.GENERATE_UPLOAD_SIGNATURE);
}

export async function getTencentVideoData(fileId: string) {
  return fetchClient.get(`${TENCENT.GET_TENCENT_VIDEO_DATA}/${fileId}`);
}

export async function getPSign(fileId: string) {
  return fetchClient.get(`${TENCENT.GENERATE_PSIGN}/${fileId}`);
}

export const getUnansweredComments = async ({
  page = 1,
  limit = 10,
}: {
  page: number;
  limit?: number;
}) => {
  return fetchClient.get(
    `${EXERCISES.EXERCISES}/comments?page=${page}&limit=${limit}`
  );
};
