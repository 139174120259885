import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { updateWorkshopAttendee } from '../../../services/workshop-service';
import {
  UpdateAttendee,
  WorkshopAttendeeWithUserDetail,
} from '../../../types/workshop';
import AttendeeForm from '../form';
import validationSchema from '../validationSchema';

interface EditWorkshopAttendeeProps {
  payload: WorkshopAttendeeWithUserDetail;
  attendeeId: string;
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditWorkshopAttendee: FC<EditWorkshopAttendeeProps> = ({
  children,
  payload,
  attendeeId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<UpdateAttendee>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: UpdateAttendee) => {
    setRequesting(true);
    try {
      await updateWorkshopAttendee(attendeeId, values);
      setDrawerVisible(false);
      message.success('Workshop Attendee Updated Successfully');
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    const defaultValues = {
      fullName: payload?.attendeesUserDetail?.fullName || payload?.fullName,
      email: payload?.attendeesUserDetail?.email || payload?.email,
      mobileNumber: payload.attendeesUserDetail?.mobileNumber,
      country: payload?.attendeesUserDetail?.locations?.[0]?.country,
      state: payload?.attendeesUserDetail?.locations?.[0]?.region,
      address1: payload?.attendeesUserDetail?.locations?.[0]?.address1,
      address2: payload?.attendeesUserDetail?.locations?.[0]?.address2,
      city: payload?.attendeesUserDetail?.locations?.[0]?.city,
      zip: payload?.attendeesUserDetail?.locations?.[0]?.zip,
      nameAsOnCertificate: payload?.attendeesUserDetail?.nameAsOnCertificate,
      emergencyContactName: payload?.attendeesUserDetail?.emergencyContactName,
      emergencyContactNumber:
        payload?.attendeesUserDetail?.emergencyContactNumber,
      note: payload?.note,
      userId: payload?.attendeesUserDetail?._id || '',
      wechatId: payload?.attendeesUserDetail?.wechatId || '',
    };
    form.reset(defaultValues);

    return () => {
      form.reset({});
    };
  }, [payload]);

  return (
    <>
      <Drawer
        title="Edit Workshop Attendee"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Update Attendee
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AttendeeForm form={form} isEditable payload={payload} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditWorkshopAttendee;
