/* eslint-disable @typescript-eslint/no-explicit-any */
export enum SentEmailStatus {
  'QUEUED' = 'QUEUED',
  'SENT' = 'SENT',
  'FAILED' = 'FAILED',
}

export interface ISentEmailsRecords {
  _id: string;
  subject: string;
  body: string;
  recipientEmail: string | string[];
  senderEmail: string;
  replyTo?: string;
  attachments?: any[];
  sentAt?: Date;
  status: SentEmailStatus;
  originalStatus: SentEmailStatus.SENT | SentEmailStatus.FAILED;
  batchNo?: string;
  retryFrequency: number;
  messageId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
