import { Button, message, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
  DragOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import AddAssessmentQuestion from '../../components/assessment-questions/create-assessment-question';
import EditAssessmentQuestion from '../../components/assessment-questions/edit-assessment-question';
import DeleteAssessmentQuestion from '../../components/assessment-questions/delete-assessment-question';
import { AssessmentQuestion } from '../../types/assessment-question';
import {
  getAssessmentQuestions,
  updateBulkAssessmentQuestions,
} from '../../services/assessment-question-service';

import columns from './columns';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: transform ? `translateY(${transform.y}px)` : undefined,
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

interface DraggableTableProps {
  assessmentQuestions: AssessmentQuestion[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnsWithActions: any[]; // You should replace 'any[]' with the actual type for your columns
  onDragEnd: (event: DragEndEvent) => void;
}

const DraggableTable = ({
  assessmentQuestions,
  columnsWithActions,
  onDragEnd,
}: DraggableTableProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={assessmentQuestions.map((i) => i._id)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          dataSource={assessmentQuestions}
          columns={columnsWithActions}
          pagination={false}
          rowKey="_id"
          components={{
            body: {
              row: Row,
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

const AssessmentQuestions = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadAssessmentQuestions, setReloadAssessmentQuestions] =
    useState(true);
  const [assessmentQuestions, setAssessmentQuestions] = useState<
    AssessmentQuestion[]
  >([]);
  const [dragAndDropEnabled, setDragAndDropEnabled] = useState(false);
  const [originalAssessmentQuestions, setOriginalAssessmentQuestions] =
    useState<AssessmentQuestion[]>([]);

  const loadAssessmentQuestions = async () => {
    setRequesting(true);
    try {
      const response = await getAssessmentQuestions();
      setAssessmentQuestions(response?.data?.data);
      setOriginalAssessmentQuestions(response?.data?.data);
    } catch (e) {
      message.error('Something went wrong');
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadAssessmentQuestions(false);
    }
  };
  useEffect(() => {
    if (!requesting && reloadAssessmentQuestions) {
      loadAssessmentQuestions();
    }
  }, [reloadAssessmentQuestions, requesting]);

  const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setAssessmentQuestions((previous) => {
        const activeIndex = previous.findIndex((i) => i._id === active.id);
        const overIndex = previous.findIndex((i) => i._id === over?.id);

        const newAssessmentQuestions = [...previous];

        // Remove the dragged question from the array
        const [draggedQuestion] = newAssessmentQuestions.splice(activeIndex, 1);

        // Insert the dragged question back to the array at the target position
        newAssessmentQuestions.splice(overIndex, 0, draggedQuestion);

        // Update the sequence order for all questions based on their index
        newAssessmentQuestions.forEach((item, index) => {
          item.sequence = index + 1;
        });

        return newAssessmentQuestions;
      });
    }
  }, []);

  const toggleDragAndDrop = useCallback(() => {
    setDragAndDropEnabled((prevState) => !prevState);
  }, []);

  const saveOrderHandler = async () => {
    try {
      if (assessmentQuestions === originalAssessmentQuestions) {
        message.info('No Changes Found');
        setDragAndDropEnabled(false);
      } else {
        await updateBulkAssessmentQuestions(
          assessmentQuestions.map((question) => ({
            _id: question._id,
            sequence: question.sequence,
          }))
        );

        message.success('Assessment Questions Order Updated Successfully');
        setOriginalAssessmentQuestions(assessmentQuestions);
        setDragAndDropEnabled(false);
      }
    } catch (error) {
      message.error('Error occurred while updating the order');
      console.log(error);
    }
  };

  const resetSequenceOrder = useCallback(() => {
    setAssessmentQuestions(originalAssessmentQuestions);
    setDragAndDropEnabled(false);
  }, [originalAssessmentQuestions]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: AssessmentQuestion) => {
        return (
          <Space size="large">
            <EditAssessmentQuestion
              assesmentQuestionId={record._id}
              payload={record}
              successCallback={() => setReloadAssessmentQuestions(true)}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditAssessmentQuestion>
            <DeleteAssessmentQuestion
              assessmentQuestionId={record._id}
              successCallback={() => setReloadAssessmentQuestions(true)}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteAssessmentQuestion>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={3}>Assessment Questions</Typography.Title>
          <Space>
            <Tooltip title="Refresh" placement="left">
              <Button
                type="ghost"
                icon={<ReloadOutlined />}
                onClick={() => setReloadAssessmentQuestions(true)}
              />
            </Tooltip>
            <AddAssessmentQuestion
              successCallback={() => setReloadAssessmentQuestions(true)}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => (
                <Button
                  onClick={onClick}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add Assessment Question
                </Button>
              )}
            </AddAssessmentQuestion>
            {/* Button to toggle drag and drop */}
            {!dragAndDropEnabled && (
              <Button
                onClick={toggleDragAndDrop}
                type={'default'}
                icon={<DragOutlined />}
              >
                Edit Sequence Order
              </Button>
            )}
            {dragAndDropEnabled && (
              <Button
                onClick={saveOrderHandler}
                type={'primary'}
                icon={<CheckOutlined />}
              >
                Save
              </Button>
            )}
            {dragAndDropEnabled && (
              <Button onClick={resetSequenceOrder} type="dashed">
                Cancel
              </Button>
            )}
          </Space>
        </div>

        {/* Conditionally render the draggable table if dragAndDropEnabled is true */}
        {dragAndDropEnabled ? (
          <DraggableTable
            assessmentQuestions={assessmentQuestions}
            columnsWithActions={columnsWithActions}
            onDragEnd={onDragEnd}
          />
        ) : (
          // Render a regular table without drag and drop functionality
          <Table
            dataSource={assessmentQuestions}
            columns={columnsWithActions}
            pagination={false}
            rowKey="_id"
          />
        )}
      </div>
    </Spin>
  );
};

export default AssessmentQuestions;
