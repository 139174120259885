/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { Button, Drawer, message } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import AddProductForm from '../form';
import {
  getGiftVoucherProduct,
  updateGiftVoucherProduct,
} from '../../../../services/gift-voucher';
import { IGiftVoucherProduct } from '../../../../types/gift-voucher';

interface IEditProductProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  productId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditProduct: FC<IEditProductProps> = ({
  children,
  productId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [product, setProduct] = useState<IGiftVoucherProduct>();

  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const loadProduct = async () => {
    try {
      const response = await getGiftVoucherProduct(productId);
      setProduct(response?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (data: any) => {
    setRequesting(true);
    try {
      const payload = {
        name: data?.name,
        months: Number(data?.months),
        memberType: data?.memberType,
        discountInPercent: Number(data?.discountInPercent),
        imageUrl: data?.imageUrl,
      };

      await updateGiftVoucherProduct({ payload, productId });
      message.success('Successfull');
      loadProduct();
      handleDrawerVisibility();
      if (successCallback) {
        successCallback();
      }
    } catch (e: any) {
      if (e.response) {
        message.error(e.response?.data.MESSAGE_KEY);
      } else {
        message.error(e?.message);
      }
      console.log(e);

      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    loadProduct();
  }, []);

  useEffect(() => {
    form.reset({
      stripeId: product?.stripeId,
      memberType: product?.memberType,
      months: product?.months,
      imageUrl: product?.imageUrl,
      name: product?.name,
      defaultPrice: product?.defaultPrice,
      defaultCurrency: product?.defaultCurrency,
      discountInPercent: product?.discountInPercent,
      currencyOptionsArray: Object.keys(
        product?.currencyOptions ? product?.currencyOptions : {}
      ),
      currencyOptions: product?.currencyOptions,
    });
  }, [product]);

  return (
    <>
      <Drawer
        title="Edit Gift Voucher Product"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddProductForm isEditable={true} form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditProduct;
