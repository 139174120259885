/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, createElement, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, Drawer, Row } from 'antd';

import validationSchema from '../validation-schema';
import EndorsementForm from '../form';
import { IEndorsement } from '../../../types/endorsements-and-testimonials';
import { addEndorsement } from '../../../services/endorsements-service';

interface IAddEndorsementProps {
  successCallback?: () => void;
  errorCallback?: (e: any) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const AddEndorsement: FC<IAddEndorsementProps> = ({
  children,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);

  const [publishing, setPublishing] = useState(false);

  const [savingDraft, setSavingDraft] = useState(false);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<IEndorsement>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: { designations: [{}] },
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: IEndorsement) => {
    if (values?.isApproved) {
      setPublishing(true);
    } else {
      setSavingDraft(true);
    }
    try {
      await addEndorsement(values);
      setDrawerVisible(false);
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      if (errorCallback) {
        errorCallback(err);
      }
    } finally {
      if (values?.isApproved) {
        setPublishing(false);
      } else {
        setSavingDraft(false);
      }
    }
  };

  return (
    <>
      <Drawer
        title="Add Endorsement"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Row>
              <Col span={12} style={{ padding: '10px' }}>
                <Button
                  danger
                  block
                  type="primary"
                  loading={savingDraft}
                  onClick={(e) => {
                    form.setValue('isApproved', false);
                    form.handleSubmit(onSubmitHandler)(e);
                  }}
                >
                  Save As Draft
                </Button>
              </Col>
              <Col span={12} style={{ padding: '10px' }}>
                <Button
                  block
                  type="primary"
                  loading={publishing}
                  onClick={(e) => {
                    form.setValue('isApproved', true);
                    form.handleSubmit(onSubmitHandler)(e);
                  }}
                >
                  Publish
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <EndorsementForm isEditable={false} form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddEndorsement;
