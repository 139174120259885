import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

import {
  getQuestionCategories,
  getQuestions,
} from '../../services/questions-service';
import AddQuestion from '../../components/questions/add-question';
import DeleteQuestion from '../../components/questions/delete-question';
import EditQuestion from '../../components/questions/edit-question';
import { ICategory } from '../../components/questions/types';

import columns, { TQuestion } from './columns';

const Questions = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadQuestions, setReloadQuestions] = useState(true);
  const [questions, setQuestions] = useState<TQuestion[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);

  const loadQuestions = async () => {
    setRequesting(true);
    try {
      const response = await getQuestions();
      setQuestions(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadQuestions(false);
    }
  };

  const loadCategories = async () => {
    setRequesting(true);
    try {
      const response = await getQuestionCategories();
      setCategories(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadQuestions) {
      loadQuestions();
    }
  }, [reloadQuestions, requesting]);

  useEffect(() => {
    loadCategories();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      render: (value: string, record: TQuestion) => {
        const { slno, maxmarks, question } = record;
        return (
          <Space size="large">
            <EditQuestion
              categories={categories}
              questionId={record._id}
              question={{ category: slno, maxmarks, ...question }}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditQuestion>

            <DeleteQuestion
              questionId={record._id}
              successCallback={() => setReloadQuestions(true)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteQuestion>
          </Space>
        );
      },
      key: 'actions',
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={questions}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Questions</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadQuestions(true)}
                  />
                </Tooltip>
                <AddQuestion
                  categories={categories}
                  successCallback={() => setReloadQuestions(true)}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Question
                    </Button>
                  )}
                </AddQuestion>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};

export default Questions;
