import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { notification } from 'antd';

import { getCurrentUser, TUser } from '../../services/users-service';
import {
  getUserFromStorage,
  TSession,
  signOut,
  TLoginUser,
  signIn,
} from '../../services/auth-service';
import useInterval from '../../hooks/use-interval';

type TAuthStateContextType = {
  loadingSession: boolean;
  loadingUser: boolean;
  session: TSession | null;
  onLogout: () => void;
  onLogin: (payload: TLoginUser, callback?: VoidFunction) => void;
  onUpdateLoadingSession: (v: boolean) => void;
  onUpdateSession: (payload: TSession) => void;
  reloadUser: () => void;
  user: TUser | null;
};

const AuthStateContextDefaultValues: TAuthStateContextType = {
  loadingSession: false,
  loadingUser: false,
  session: null,
  onLogout: () => undefined,
  onLogin: () => undefined,
  onUpdateLoadingSession: () => undefined,
  onUpdateSession: () => undefined,
  user: null,
  reloadUser: () => undefined,
};

const AuthStateContext = createContext<TAuthStateContextType>(
  AuthStateContextDefaultValues
);

export const useAuthState = () => {
  return useContext(AuthStateContext);
};

type TProps = {
  children: ReactNode;
};

export const AuthStateProvider = ({ children }: TProps) => {
  const storageSession = getUserFromStorage();
  const [session, setSession] = useState<TSession | null>(storageSession);
  const [loadingSession, setLoadingSession] = useState<boolean>(false);
  const [logoutCountdown, setLogoutCountdown] = useState(120);
  const [countdownStarted, setCountdownStarted] = useState(false);
  const [user, setUser] = useState<TUser | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);

  const logoutUser = async () => {
    signOut();
    setSession(null);
    setUser(null);
  };

  const handleLogin = async (payload: TLoginUser, callback?: VoidFunction) => {
    const user = await signIn(payload);
    setSession(user);
    if (callback) callback();
  };

  const handleLogout = (force = true) => {
    if (!force) {
      setCountdownStarted(true);
    } else {
      logoutUser();
    }
  };

  const loadUser = async () => {
    setLoadingUser(true);
    try {
      const { data } = await getCurrentUser();
      setUser(data?.data || null);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    if (
      session?.session_type === 'SESSION_EXPIRED_BUT_ACCESS_TOKEN_STILL_ALIVE'
    ) {
      handleLogout(false);
    } else if (
      session?.session_type === 'SESSION_EXPIRED_AND_ACCESS_TOKEN_EXPIRED'
    ) {
      handleLogout();
    }
  }, [session]);

  useEffect(() => {
    if (session && !user) {
      loadUser();
    }
  }, [session]);

  useInterval(
    () => {
      if (countdownStarted && logoutCountdown === 0) {
        setCountdownStarted(false);
        notification.close('multiple-login');
        logoutUser();
      } else {
        setLogoutCountdown(logoutCountdown - 1);
        notification['info']({
          message: 'Multiple logins detected',
          key: 'multiple-login',
          duration: 0,
          closeIcon: <></>,
          placement: 'bottomRight',
          bottom: 50,
          description: (
            <>
              {`You will be logged out in next ${
                logoutCountdown - 1
              } seconds. Please save your in progress work (if any). If you refresh the page you will be immediately logged out.`}
            </>
          ),
        });
      }
    },
    countdownStarted ? 1000 : null
  );

  const value = {
    loadingSession,
    session,
    onLogout: handleLogout,
    onLogin: handleLogin,
    onUpdateLoadingSession: setLoadingSession,
    onUpdateSession: setSession,
    user,
    reloadUser: loadUser,
    loadingUser,
  };

  return (
    <>
      <AuthStateContext.Provider value={value}>
        {children}
      </AuthStateContext.Provider>
    </>
  );
};
