import fetchClient from '../utils/fetch-client';
import { FAQ } from '../constants/api';
import { FaqCategory, FaqDetail } from '../types/faq';

export const getFaqCategories = () => {
  return fetchClient.get(`${FAQ.FAQ}/category`);
};

export const addFaqCategories = (payload: FaqCategory) => {
  return fetchClient.post(`${FAQ.FAQ}/category`, payload);
};

export const updateFaqCategories = (
  payload: FaqCategory,
  faqCategoryId: string
) => {
  return fetchClient.put(`${FAQ.FAQ}/category/${faqCategoryId}`, payload);
};

export const deleteFaqCategories = (faqCategoryId?: string) => {
  return fetchClient.delete(`${FAQ.FAQ}/category/${faqCategoryId}`);
};

export const getFaq = (faqId?: string) => {
  if (faqId) {
    return fetchClient.get(`${FAQ.FAQ}/${faqId}`);
  }
  return fetchClient.get(`${FAQ.FAQ}`);
};

export const addFaq = (payload: FaqDetail) => {
  return fetchClient.post(`${FAQ.FAQ}`, payload);
};

export const updateFaq = (payload: FaqDetail, faqId: string) => {
  return fetchClient.put(`${FAQ.FAQ}/${faqId}`, payload);
};
export const deleteFaq = (faqId?: string) => {
  return fetchClient.delete(`${FAQ.FAQ}/${faqId}`);
};

export const updateFaqsSequence = ({
  faqId,
  faqs,
}: {
  faqId: string;
  faqs: FaqDetail[];
}) => {
  return fetchClient.put(`${FAQ.FAQ}/sequence/${faqId}`, { faqs });
};
