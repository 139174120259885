import { EXERCISE_CATEGORIES } from '../constants/api';
import { ExerciseCategoryDisplay } from '../types/exercise-categories';
import fetchClient from '../utils/fetch-client';

export const getCategoriesBySection = ({
  isClassVideo = false,
}: {
  isClassVideo?: boolean;
}) => {
  return fetchClient.get(
    `${EXERCISE_CATEGORIES.EXERCISE_CATEGORIES}?isclassvideo=${isClassVideo}`
  );
};

export const addSectionCategory = ({
  payload,
  isClassVideo = false,
}: {
  payload: ExerciseCategoryDisplay;
  isClassVideo?: boolean;
}) => {
  return fetchClient.post(`${EXERCISE_CATEGORIES.EXERCISE_CATEGORIES}`, {
    ...payload,
    isclassvideo: isClassVideo,
  });
};

export const updateSectionCategory = (
  payload: ExerciseCategoryDisplay,
  id: string
) => {
  return fetchClient.put(
    `${EXERCISE_CATEGORIES.EXERCISE_CATEGORIES}/${id}`,
    payload
  );
};

export const deleteSectionCategory = (id?: string) => {
  return fetchClient.delete(`${EXERCISE_CATEGORIES.EXERCISE_CATEGORIES}/${id}`);
};

export const updateBulkCategories = (
  payload: {
    _id: string;
    sequence: number;
  }[]
) => {
  return fetchClient.post(
    `${EXERCISE_CATEGORIES.EXERCISE_CATEGORIES}/update-all`,
    { categories: payload }
  );
};
