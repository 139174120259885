import * as yup from 'yup';

export default yup.object().shape({
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
  name: yup.string().required('Name is required'),
  faqCategories: yup.array().of(yup.string().required('required')),
});
