import { Layout, Spin } from 'antd';
import { Outlet } from 'react-router-dom';

import { useAuthState } from '../../context/auth-state';

import AppHeader from './header';
import AppFooter from './footer';
import AppSidebar from './sidebar';
import './styles.scss';

const { Content } = Layout;

const AppLayout = () => {
  const { session, loadingUser } = useAuthState();
  return (
    <Spin spinning={loadingUser}>
      <Layout className="layout-container">
        <AppHeader />
        <Layout>
          {session && <AppSidebar />}
          <Layout>
            <Content className="content-container">
              <Outlet />
            </Content>
            <AppFooter />
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  );
};

export default AppLayout;
