import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { FileUploadButton, InputField, SelectField } from '../../common/form';
import { ROLES } from '../../../constants/common';
import { COUNTRIES } from '../../../constants/countries';

interface IAddProductFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, object>;
  isEditable: boolean;
}

const AddProductForm = ({ form, isEditable }: IAddProductFormProps) => {
  const currencyOptionsArray = form.watch('currencyOptionsArray');
  return (
    <>
      <Form
        {...{
          labelCol: { span: 6 },
          wrapperCol: { span: 18 },
        }}
      >
        <InputField
          label="Name EN"
          name="name.en"
          required
          inputProps={{
            placeholder: 'Name (English)',
          }}
          form={form}
        />

        <InputField
          label="Name ES"
          name="name.es"
          required
          inputProps={{
            placeholder: 'Name (Spanish)',
          }}
          form={form}
        />

        <InputField
          label="Name IT"
          name="name.it"
          required
          inputProps={{
            placeholder: 'Name (Italian)',
          }}
          form={form}
        />

        <InputField
          label="Name DE"
          name="name.de"
          required
          inputProps={{
            placeholder: 'Name (German)',
          }}
          form={form}
        />

        <InputField
          label="Name PT"
          name="name.pt"
          required
          inputProps={{
            placeholder: 'Name (Portuguese)',
          }}
          form={form}
        />

        <InputField
          label="Name ZH"
          name="name.zh"
          required
          inputProps={{
            placeholder: 'Name (Mandarin)',
          }}
          form={form}
        />
        <br />
        <InputField
          label="Months"
          name="months"
          required
          inputProps={{
            placeholder: 'Enter duration of membership',
          }}
          form={form}
        />
        <SelectField
          required
          name={`memberType`}
          label="Member Type"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: ROLES,
            placeholder: 'Select Member Type',
          }}
        />
        <br />

        <SelectField
          label="Default Currency"
          required
          name="defaultCurrency"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: COUNTRIES.filter(
              (country) => country?.taxIds && country?.taxIds?.length > 0
            ).flatMap((country) => {
              return country.currencies.map((currency) => {
                return {
                  label: `${country.name} (${currency?.code})`,
                  value: currency?.code?.toLowerCase(),
                };
              });
            }),
            showSearch: true,
            allowClear: true,
            placeholder: 'Select Currency',
            disabled: isEditable,
          }}
        />
        <InputField
          label="Default Price"
          name="defaultPrice"
          required
          inputProps={{
            placeholder: 'Enter Default Price',
          }}
          form={form}
          disabled={isEditable}
        />
        <InputField
          label="Discount in %"
          name="discountInPercent"
          required
          inputProps={{
            placeholder: 'Enter discount',
          }}
          form={form}
        />
        <br />
        <FileUploadButton
          required
          className="filename-container"
          label="Product banner"
          name="imageUrl"
          triggerValidate={() => form.trigger('imageUrl')}
          defaultFileList={
            isEditable
              ? [
                  {
                    uid: '-1',
                    name: 'Product Banner',
                    status: 'done',
                    url: form.watch('imageUrl') as string,
                  },
                ]
              : []
          }
          form={form}
          buttonLabel="Upload Banner"
        />
        <br />
      </Form>
      <br />
      <Form layout="vertical">
        <h2>Add Other Currencies</h2>
        <SelectField
          label="Select Currency"
          required
          name="currencyOptionsArray"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: COUNTRIES.filter(
              (country) => country?.taxIds && country?.taxIds?.length > 0
            ).flatMap((country) => {
              return country.currencies.map((currency) => {
                return {
                  label: `${country.name} (${currency?.code})`,
                  value: currency?.code?.toLowerCase(),
                };
              });
            }),
            showSearch: true,
            allowClear: true,
            mode: 'multiple',
            placeholder: 'Select Currency',
            disabled: isEditable,
          }}
        />
        {currencyOptionsArray?.length > 0 &&
          currencyOptionsArray?.map((currency: string, index: number) => {
            return (
              <InputField
                key={index}
                label={`Price in ${currency.toUpperCase()}`}
                name={`currencyOptions.${currency}.unit_amount`}
                required
                inputProps={{
                  placeholder: 'Enter Price',
                }}
                form={form}
                disabled={isEditable}
              />
            );
          })}
      </Form>
    </>
  );
};

export default AddProductForm;
