import { NOT_AVAILABLE } from '../../constants/common';
import { IUserPages } from '../../types/user-pages';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: IUserPages) =>
      record?.name || NOT_AVAILABLE,
    width: 400,
  },
  {
    title: 'Slug',
    render: (value: string, record: IUserPages) =>
      record?.slug || NOT_AVAILABLE,
    width: 400,
  },
];

export default columns;
