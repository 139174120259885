import { createElement, FC, useEffect, useRef, useState } from 'react';
import { Button, Drawer, message } from 'antd';
import { useForm } from 'react-hook-form';

import { ICreditList } from '../../../types/credit-list';
import { updateCreditNote } from '../../../services/credit-list';

import EditNoteForm from './form';

interface IEditNoteProps {
  creditList: ICreditList;
  children: FC<{ onClick: () => void }>;
  errorCallback?: (e: unknown) => void;
  successCallback?: () => void;
}

interface FormField {
  note: string;
}

const EditNote: FC<IEditNoteProps> = ({
  children,
  creditList,
  errorCallback,
  successCallback,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const refChildren = useRef(children);

  const form = useForm<FormField>();

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: FormField) => {
    setRequesting(true);
    try {
      const payload = {
        note: values.note,
        creditListId: creditList?._id,
      };

      await updateCreditNote(payload);

      message.success('Note updated successfully');

      handleDrawerVisibility();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e);
      }
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    form.reset({ note: creditList?.note });

    return () => {
      form.reset({});
    };
  }, [creditList]);

  return (
    <>
      <Drawer
        title="Update Note"
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
          >
            Submit
          </Button>
        }
      >
        <div style={{ padding: 16 }}>
          <EditNoteForm form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditNote;
