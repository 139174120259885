import { yupResolver } from '@hookform/resolvers/yup';
import { FC, createElement, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Drawer, Popconfirm, message } from 'antd';

import { ICertificationRenewalResponse } from '../../types/certifciation-renewal-response';
import { addCertificationRenewalResponseReview } from '../../services/certificate-renewal-response-service';

import validationSchema from './validationSchema';
import CertificationRenewalReviewForm from './form';

interface IAddReivewProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  payload: ICertificationRenewalResponse;
  activeResponseId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const AddCertificationRenewalReview: FC<IAddReivewProps> = ({
  children,
  payload,
  activeResponseId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requestingApprove, setRequestingApprove] = useState(false);
  const [requestingReject, setRequestingReject] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const handleRejectPopConfirm = () => {
    setOpenReject((prev) => !prev);
  };

  const form = useForm<{ remarks: string }>({
    resolver: yupResolver(validationSchema),
    defaultValues: { remarks: payload?.remarks },
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
    setOpenReject(false);
  };

  const onSubmitHandler = async (values: {
    isApproved: boolean;
    remarks: string;
  }) => {
    if (values?.isApproved) {
      setRequestingApprove(true);
    } else setRequestingReject(true);
    try {
      await addCertificationRenewalResponseReview({
        ...values,
        responseId: payload?._id,
      });
      setDrawerVisible(false);

      message.success('Successfully');

      setOpenReject(false);
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequestingApprove(false);
      setRequestingReject(false);
    }
  };

  useEffect(() => {
    if (
      activeResponseId &&
      activeResponseId === payload?._id &&
      drawerVisible !== true
    ) {
      setDrawerVisible(true);
    }
  }, []);

  return (
    <>
      <Drawer
        title="Submit Reivew"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requestingApprove}
              onClick={form.handleSubmit((v) =>
                onSubmitHandler({ ...v, isApproved: true })
              )}
              style={{ marginBottom: '10px' }}
              disabled={payload?.isReviewed && payload?.isApproved}
            >
              Approve
            </Button>
            <Popconfirm
              title="Are You Sure ?"
              open={openReject}
              onConfirm={form.handleSubmit((v) =>
                onSubmitHandler({ ...v, isApproved: false })
              )}
              onCancel={handleRejectPopConfirm}
            >
              <Button
                block
                type="ghost"
                loading={requestingReject}
                onClick={() => setOpenReject(true)}
              >
                Reject
              </Button>
            </Popconfirm>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <div>
            {payload?.exercisesResponse?.map((i, index) => {
              return i?.response?.map((response) => {
                return (
                  <div key={response?.questionId}>
                    <h2>{response?.exerciseName}</h2>
                    {response?.answers.map((answer, index) => {
                      return (
                        <div key={index} style={{ marginBottom: '20px' }}>
                          <div style={{ fontWeight: 600, marginBottom: '5px' }}>
                            <span>Q.{index + 1}) </span>
                            {answer?.question}
                          </div>
                          <div>
                            <span>Ans. </span>
                            {answer?.answer}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              });
            })}
          </div>
          <div style={{ marginTop: '30px' }}>
            <CertificationRenewalReviewForm form={form} />
          </div>
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddCertificationRenewalReview;
