import { FILES_DIRECTORY } from '../constants/api';
import { FileDetail } from '../types/file';
import fetchClient from '../utils/fetch-client';

export const getFiles = () => {
  return fetchClient.get(`${FILES_DIRECTORY.FILES}`);
};
export const uploadFile = (payload: FileDetail) => {
  return fetchClient.post(`${FILES_DIRECTORY.FILES}`, payload);
};
export const editFile = (payload: FileDetail) => {
  return fetchClient.put(`${FILES_DIRECTORY.FILES}`, payload);
};
export const deleteFile = (id?: string) => {
  return fetchClient.delete(`${FILES_DIRECTORY.FILES}/${id}`);
};
