import { Button, Drawer, Spin, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AddDealForm from '../form';
import validationSchema from '../validation-schema';
import { handleError } from '../../../utils/common';
import { IDeals, IDealsFormField } from '../../../types/deals';
import { getDeal, updateDeal } from '../../../services/deals';

interface IEditDealProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  dealId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditDeal: FC<IEditDealProps> = ({
  children,
  dealId,
  successCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [deal, setDeal] = useState<IDeals | null>(null);

  const form = useForm<IDealsFormField>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: IDealsFormField) => {
    setRequesting(true);
    try {
      await updateDeal({
        dealId,
        payload: {
          name: values?.name,
          type: values?.type || undefined,
          bannerUrl: values?.bannerUrl || undefined,
          headLine: values?.headLine || undefined,
          targetAudience: values?.targetAudience || undefined,
          targetCountry: values?.targetCountry || undefined,
          action: {
            type: values?.action?.type || undefined,
            discount: values?.action?.discount || undefined,
            actionDisplayType: values?.action?.actionDisplayType || undefined,
            actionLabel: values?.action?.actionLabel || undefined,
            discountedPlans: values?.action?.discountedPlans || undefined,
            url: values?.action?.url || undefined,
            mailchimpTag: values?.action?.mailchimpTag || undefined,
          },
        },
      });
      setDrawerVisible(false);
      message.success('Deal Updated successfully');
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  const loadDeal = async () => {
    setLoading(true);
    try {
      const response = await getDeal({ dealId });
      const data = response?.data?.data;
      setDeal(data);
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (drawerVisible) loadDeal();
  }, [drawerVisible]);

  useEffect(() => {
    if (deal) {
      form.reset({
        name: deal?.name,
        type: deal?.type,
        bannerUrl: deal?.bannerUrl,
        headLine: deal?.headLine,
        action: deal?.action,
        targetCountry: deal?.targetCountry,
        targetAudience: deal?.targetAudience,
      });
    }

    return () => {
      form.reset({});
    };
  }, [deal]);

  return (
    <>
      <Drawer
        title="Edit Deal"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={700}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <Spin spinning={loading}>
          <div style={{ padding: 16 }}>
            <AddDealForm isEditable={true} form={form} />
          </div>
        </Spin>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditDeal;
