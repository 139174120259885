import { Button, message, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
  DragOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  getTechniques,
  getWorkshopInstructors,
  updateBulkTechnique,
} from '../../services/technique-service';
import AddTechnique from '../../components/techniques/create-technique';
import EditTechnique from '../../components/techniques/edit-technique';
import DeleteTechnique from '../../components/techniques/delete-technique';
import { WorkshopInstructor } from '../../types/instructor';
import { TechniqueDetail } from '../../types/technique';

import columns from './columns';

interface DraggableTableProps {
  techniques: TechniqueDetail[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnsWithActions: any[];
  onDragEnd: (event: DragEndEvent) => void;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

const DraggableTable = ({
  techniques,
  columnsWithActions,
  onDragEnd,
}: DraggableTableProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={techniques.map((i: TechniqueDetail) => i._id)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          dataSource={techniques}
          columns={columnsWithActions}
          rowKey="_id"
          pagination={false}
          components={{
            body: {
              row: Row,
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

const Technique = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadTechniques, setReloadTechniques] = useState(false);
  const [techniques, setTechniques] = useState<TechniqueDetail[]>([]);
  const [instructors, setInstructors] = useState<WorkshopInstructor[]>([]);
  const [dragAndDropEnabled, setDragAndDropEnabled] = useState(false);
  const [originalTechniques, setOriginalTechniques] = useState<
    TechniqueDetail[]
  >([]);

  const loadTechniques = async () => {
    setRequesting(true);
    try {
      const response = await getTechniques();
      setTechniques(response?.data?.data || []);
      setOriginalTechniques(response?.data?.data || []);
    } catch (e) {
      console.log(e);
    } finally {
      setReloadTechniques(false);
      setRequesting(false);
    }
  };

  const loadInstructors = async () => {
    setRequesting(true);
    try {
      const response = await getWorkshopInstructors();
      setInstructors(response?.data?.data || []);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadTechniques) {
      loadTechniques();
    }
  }, [reloadTechniques, requesting]);

  useEffect(() => {
    loadTechniques();
    loadInstructors();
  }, []);

  const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setTechniques((previous) => {
        const activeIndex = previous.findIndex((i) => i._id === active.id);
        const overIndex = previous.findIndex((i) => i._id === over?.id);

        const newTechniques = [...previous];

        // Remove the dragged technique from the array
        const [draggedTechnique] = newTechniques.splice(activeIndex, 1);

        // Insert the dragged technique back to the array at the target position
        newTechniques.splice(overIndex, 0, draggedTechnique);

        // Update the sequence values based on the new order
        newTechniques.forEach((technique, index) => {
          technique.sequence = index + 1;
        });

        return newTechniques;
      });
    }
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: TechniqueDetail) => {
        return (
          <Space size="large">
            <EditTechnique
              techniqueId={record._id}
              payload={{ ...record }}
              instructors={instructors}
              successCallback={() => setReloadTechniques(true)}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditTechnique>
            <DeleteTechnique
              techniqueId={record._id}
              successCallback={() => setReloadTechniques(true)}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteTechnique>
          </Space>
        );
      },
    },
  ];

  // Function to toggle the drag and drop feature
  const toggleDragAndDrop = useCallback(() => {
    setDragAndDropEnabled((prevState) => !prevState);
  }, []);

  const saveOrderHandler = async () => {
    try {
      if (techniques === originalTechniques) {
        message.info('No Changes Found');
        setDragAndDropEnabled(false);
      } else {
        await updateBulkTechnique(
          techniques.map((technique) => ({
            _id: technique?._id,
            sequence: technique?.sequence,
          }))
        );
        message.success('Updated Successfully');
        setDragAndDropEnabled(false);
        setReloadTechniques(true);
      }
    } catch (error) {
      message.error('Network Error');
      console.log(error);
    }
  };

  // Function to reset order to its original state
  const resetSequenceOrder = useCallback(() => {
    setDragAndDropEnabled(false);
    setTechniques(
      originalTechniques.map((technique, index) => {
        technique.sequence = index + 1;
        return technique;
      })
    );
  }, [originalTechniques]);

  return (
    <Spin spinning={requesting}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={3}>Techniques</Typography.Title>
          <Space>
            <Tooltip title="Refresh" placement="left">
              <Button
                type="ghost"
                icon={<ReloadOutlined />}
                onClick={() => setReloadTechniques(true)}
              />
            </Tooltip>
            <AddTechnique
              successCallback={() => setReloadTechniques(true)}
              errorCallback={(e) => message.error(e.message)}
              instructors={instructors}
            >
              {({ onClick }) => (
                <Button
                  onClick={onClick}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add Technique
                </Button>
              )}
            </AddTechnique>
            {/* Button to toggle drag and drop */}
            {!dragAndDropEnabled && (
              <Button
                onClick={toggleDragAndDrop}
                type={'default'}
                icon={<DragOutlined />}
              >
                Edit Sequence Order
              </Button>
            )}
            {dragAndDropEnabled && (
              <Button
                onClick={saveOrderHandler}
                type={'primary'}
                icon={<CheckOutlined />}
              >
                Save
              </Button>
            )}
            {dragAndDropEnabled && (
              <Button onClick={resetSequenceOrder} type="dashed">
                Cancel
              </Button>
            )}
          </Space>
        </div>

        {/* Conditionally render the draggable table if dragAndDropEnabled is true */}
        {dragAndDropEnabled ? (
          <DraggableTable
            techniques={techniques}
            columnsWithActions={columnsWithActions}
            onDragEnd={onDragEnd}
          />
        ) : (
          // Render a regular table without drag and drop functionality
          <Table
            dataSource={techniques}
            columns={columnsWithActions}
            rowKey="_id"
            pagination={false}
          />
        )}
      </div>
    </Spin>
  );
};

export default Technique;
