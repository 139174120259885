import {
  Button,
  Form,
  Row,
  Col,
  Typography,
  Space,
  TreeSelect,
  Image,
} from 'antd';
import { useEffect } from 'react';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

import {
  FileUploadButton,
  InputField,
  SelectField,
  TextAreaField,
} from '../common/form';
import { ExerciseVideo, VideoFormData } from '../../types/exercises';
import { LANGUAGES, ROLES } from '../../constants/common';
import ReactQuillField from '../common/form/react-quill-fields';
import TreeSelectField from '../common/form/tree-select';
import SlugNote from '../common/slug-note';

import VideoUploader from './video-uploader';

interface IExerciseFormProps {
  form: UseFormReturn<ExerciseVideo, object>;
  isEditable: boolean;
  videoFormData: VideoFormData;
}

const defficultyLevel = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
];

const ExerciseForm = ({
  videoFormData,
  form,
  isEditable,
}: IExerciseFormProps) => {
  const { control } = form;

  const values = form.watch();

  console.log(form.formState.errors);

  const {
    fields: faqsFields,
    append: addFaq,
    remove: removeFaq,
  } = useFieldArray({
    control,
    name: 'faqs',
  });

  useEffect(() => {
    const handleTabClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return (event.returnValue = 'Are you sure you want to exit?');
    };
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return (
    <>
      <VideoUploader form={form} isEditMode={isEditable} />
      <br />
      <Form layout="vertical">
        <Row>
          <Col span={8}>
            <SelectField
              required
              name={'language'}
              label="Language"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: LANGUAGES,
                placeholder: 'Select Language',
                disabled: true,
              }}
            />
          </Col>
          <Col span={8}>
            <InputField
              name="videoName"
              label="Video Name"
              required
              inputProps={{
                placeholder: 'Enter a name for video',
              }}
              form={form}
            />
          </Col>
          <Col span={8}>
            <InputField
              label="Slug"
              name="slug"
              required
              inputProps={{
                placeholder: 'Slug : eg(video-name)',
                disabled: isEditable === true,
              }}
              disabled={isEditable}
              form={form}
              style={{ marginBottom: '4px' }}
            />
            <SlugNote />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <SelectField
              required
              name="equipments"
              label="Select Equipment"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: videoFormData?.equipment?.map((e) => {
                  return {
                    label: e?.name?.en,
                    value: JSON.stringify({ name: e?.name, url: e?.imageUrl1 }),
                  };
                }),
                mode: 'multiple',
                placeholder: 'Select Euipments',
              }}
            />
          </Col>
          <Col span={8}>
            <InputField
              label="Music Playlist"
              name="musicUsed"
              required
              inputProps={{
                placeholder: 'Enter Music Used',
              }}
              form={form}
            />
          </Col>
          <Col span={8}>
            <TreeSelectField
              name="categories"
              label="Categories"
              form={form}
              treeSelectFieldProps={{
                treeData: videoFormData?.techniques?.map((technique, index) => {
                  return {
                    label: technique.slug,
                    value: JSON.stringify(
                      videoFormData?.sections
                        ?.filter((item) =>
                          item.techniqueId?.includes(technique.slug)
                        )
                        .map((section) => {
                          return {
                            techniqueSlug: technique.slug,
                            sectionSlug: section.slug,
                          };
                        })
                    ),
                    key: JSON.stringify(
                      videoFormData?.sections
                        ?.filter((item) =>
                          item.techniqueId?.includes(technique.slug)
                        )
                        .map((section) => {
                          return {
                            techniqueSlug: technique.slug,
                            sectionSlug: section.slug,
                          };
                        })
                    ),
                    children: videoFormData?.sections
                      ?.filter((section) =>
                        section.techniqueId?.includes(technique.slug)
                      )
                      .map((section) => {
                        return {
                          label: `${section.slug}`,
                          value: JSON.stringify({
                            techniqueSlug: technique.slug,
                            sectionSlug: section.slug,
                          }),
                          key: JSON.stringify({
                            techniqueSlug: technique.slug,
                            sectionSlug: section.slug,
                          }),
                          children: videoFormData?.categories
                            ?.filter((category) =>
                              category.sections?.some(
                                (categorySection) =>
                                  categorySection?.techniqueSlug ===
                                    technique.slug &&
                                  categorySection?.sectionSlug === section.slug
                              )
                            )
                            .map((category) => {
                              return {
                                label: category.slug,
                                value: JSON.stringify({
                                  techniqueSlug: technique.slug,
                                  sectionSlug: section.slug,
                                  categorySlug: category.slug,
                                }),
                              };
                            }),
                        };
                      }),
                  };
                }),
                placeholder: 'Please Select Category',
                style: {
                  width: '100%',
                },
                treeCheckable: true,
                showCheckedStrategy: TreeSelect.SHOW_CHILD,
              }}
            />
          </Col>
        </Row>
        <Row>
          {!values?.isClassVideo && (
            <Col span={8}>
              <InputField
                name="exerciseStartSeconds"
                label="Time When Exercise Start (in seconds)"
                required
                inputProps={{
                  placeholder: 'Time when exercise starts(in seconds)',
                  type: 'number',
                  onChange: (e) => {
                    form.setValue(
                      'exerciseStartSeconds',
                      parseInt(e.target.value)
                    );
                  },
                }}
                form={form}
              />
            </Col>
          )}
          {!values?.isClassVideo && (
            <Col span={8}>
              <SelectField
                required
                name="difficultyLevel"
                label="Difficulty Level"
                form={form}
                labelKey="label"
                valueKey="value"
                selectFieldProps={{
                  options: defficultyLevel,
                  placeholder: 'select difficulty level',
                }}
              />
            </Col>
          )}

          <Col span={8}>
            <InputField
              label="Sequence"
              name="sequence"
              required
              inputProps={{
                placeholder: 'Enter sequence number e.g 1 or 1.2',
              }}
              form={form}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          {values?.isClassVideo && (
            <Col span={8}>
              <FileUploadButton
                form={form}
                defaultFileList={
                  isEditable && values?.classPlanPDFUrl
                    ? [
                        {
                          uid: '-1',
                          name: `${values?.videoName}.pdf`,
                          status: 'done',
                          url: values?.classPlanPDFUrl,
                        },
                      ]
                    : []
                }
                label="Class Plan PDF"
                name="classPlanPDFUrl"
                buttonLabel="Upload"
                className="exercise-video-custom-thumb"
                accept="application/pdf"
              />
            </Col>
          )}
          <Col span={8}>
            <FileUploadButton
              form={form}
              defaultFileList={
                isEditable && values?.customThumb
                  ? [
                      {
                        uid: '-1',
                        name: `${values?.videoName}.png`,
                        status: 'done',
                        url: values?.customThumb,
                      },
                    ]
                  : []
              }
              label="Custom Thumbnail"
              name="customThumb"
              buttonLabel="Upload"
              className="exercise-video-custom-thumb"
            />
          </Col>

          <Col
            span={8}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            key={values?.mediaId}
          >
            {values?.thumb && (
              <>
                <p>Default Thumbnail</p>
                <Image src={values?.thumb} alt="" width={200} height={'auto'} />
              </>
            )}
          </Col>
        </Row>
        <hr />
        <ReactQuillField
          name="description.forTeacher"
          label="Description for the teacher"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <ReactQuillField
          name="description.forStudent"
          label="Description for the students"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />

        {faqsFields.map((field, index) => {
          return (
            <div key={`${field._id}-${index}`}>
              <Typography.Title level={4}>Faq {index + 1}</Typography.Title>
              <Col>
                <InputField
                  name={`faqs[${index}].question`}
                  label="Frequently Asked Question (FAQ) for this video"
                  required
                  inputProps={{
                    placeholder:
                      'Frequently Asked Question (FAQ) for this video',
                  }}
                  form={form}
                />
                <TextAreaField
                  name={`faqs[${index}].answer`}
                  label="Answer for the above FAQ"
                  required
                  textAreaProps={{
                    rows: 2,
                    placeholder: 'Answer for the above FAQ',
                  }}
                  form={form}
                />
                <Row>
                  <Col span={12}>
                    <SelectField
                      required
                      name={`faqs[${index}].forMemberType`}
                      label="Select who this FAQ is for"
                      form={form}
                      labelKey="label"
                      valueKey="value"
                      selectFieldProps={{
                        options: ROLES,
                        placeholder: 'Select who this FAQ is for',
                      }}
                    />
                  </Col>
                  <Col span={4} style={{ paddingTop: '4.3vh' }}>
                    <Space>
                      {index + 1 === faqsFields.length && (
                        <Button
                          type="primary"
                          onClick={() => {
                            addFaq({
                              question: '',
                              answer: '',
                              forMemberType: { students: '', teachers: '' },
                            });
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      )}
                      {(index !== 0 || faqsFields.length > 1) && (
                        <Button
                          type="primary"
                          danger
                          onClick={() => {
                            removeFaq(index);
                          }}
                        >
                          <DeleteOutlined style={{ color: 'red' }} />
                        </Button>
                      )}
                    </Space>
                  </Col>
                </Row>
              </Col>
            </div>
          );
        })}
      </Form>
    </>
  );
};

export default ExerciseForm;
