import { ASSESSMENT } from '../constants/api';
import { AssessmentQuestion } from '../types/assessment-question';
import fetchClient from '../utils/fetch-client';

export const getAssessmentQuestions = () => {
  return fetchClient.get(`${ASSESSMENT.ASSESSMENT_QUESTION}`);
};
export const addAssessmentQuestion = (payload: AssessmentQuestion) => {
  return fetchClient.post(`${ASSESSMENT.ASSESSMENT_QUESTION}`, payload);
};

export const updateAssessmentQuestion = (
  payload: AssessmentQuestion,
  id: string
) => {
  return fetchClient.put(`${ASSESSMENT.ASSESSMENT_QUESTION}/${id}`, payload);
};

export const deleteAssessmentQuestion = (id?: string) => {
  return fetchClient.delete(`${ASSESSMENT.ASSESSMENT_QUESTION}/${id}`);
};

export const updateBulkAssessmentQuestions = (
  payload: {
    _id: string;
    sequence: number;
  }[]
) => {
  return fetchClient.post(`${ASSESSMENT.ASSESSMENT_QUESTION}/update-all`, {
    questions: payload,
  });
};
