import * as Yup from 'yup';

import {
  IDealsActionDisplayType,
  IDealsActionType,
  IDealsDiscountedPlans,
  IDealsType,
} from '../../types/deals';

const DealFormSchema = Yup.object().shape({
  name: Yup.string().required('Deal Name is required'),

  type: Yup.mixed()
    .oneOf(Object.values(IDealsType))
    .required('Deal Type is required'),

  bannerUrl: Yup.mixed().when('type', {
    is: IDealsType.popup,
    then: Yup.string().required('Banner is required for Popup deals'),
  }),

  headLine: Yup.mixed().when('type', {
    is: IDealsType.notification_bar,
    then: Yup.string().required(
      'Headline is required for Notification Bar deals'
    ),
  }),

  action: Yup.object().shape({
    type: Yup.mixed()
      .oneOf(Object.values(IDealsActionType))
      .required('Action Type is required'),

    discount: Yup.mixed().when('type', {
      is: IDealsActionType.signup,
      then: Yup.number()
        .typeError('Discount should be number')
        .required('Discount in Signup is required')
        .positive('Discount must be a positive number')
        .max(100, 'Discount cannot exceed 100%'),
    }),

    mailchimpTag: Yup.mixed().when('type', {
      is: IDealsActionType.signup,
      then: Yup.string().required('Discount in Signup is required'),
    }),

    discountedPlans: Yup.mixed().when('type', {
      is: IDealsActionType.signup,
      then: Yup.string()
        .oneOf(
          [
            IDealsDiscountedPlans.monthly,
            IDealsDiscountedPlans.yearly,
            IDealsDiscountedPlans.all,
          ],
          'Invalid plan selected'
        )
        .required('Discounted Plans are required'),
    }),

    url: Yup.mixed().when('type', {
      is: IDealsActionType.redirect,
      then: Yup.string()
        .required('Redirect URL is required')
        .url('Must be a valid URL'),
    }),

    actionLabel: Yup.mixed().when('type', {
      is: IDealsActionType.redirect,
      then: Yup.string().required('Action Label is required'),
    }),

    actionDisplayType: Yup.mixed().when('type', {
      is: IDealsActionType.redirect,
      then: Yup.mixed()
        .oneOf(Object.values(IDealsActionDisplayType))
        .required('Action Display Type is required'),
    }),
  }),

  targetCountry: Yup.array()
    .of(Yup.string())
    .min(1, 'At least one country must be selected')
    .required('Target country is required'),

  targetAudience: Yup.array()
    .of(Yup.string())
    .min(1, 'At least one must be selected')
    .required('Target Audience is required'),
});

export default DealFormSchema;
