import * as yup from 'yup';

export default yup.object().shape({
  category: yup.string().required('Please select a category'),
  en: yup.string().required('Please input question in english'),
  pt: yup.string().required('Please input question in portugese'),
  it: yup.string().required('Please input question in italian'),
  es: yup.string().required('Please input question in spanish'),
  de: yup.string().required('Please input question in german'),
  zh: yup.string().required('Please input question in mandrin'),
});
