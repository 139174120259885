import React, { FC, useState, useEffect } from 'react';
import { Card, Descriptions, Image, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { TUser } from '../../../../services/users-service';
import { AssessmentCategory } from '../../../../types/assessment-categories';
import { getAssessmentCategories } from '../../../../services/assessment-category-service';

import EditAwardsDetails from './edit-awards';

interface TAwardsProps {
  user: TUser;
  successCallback: (e: boolean) => void;
}

const Awards: FC<TAwardsProps> = ({ user, successCallback }) => {
  const [assessmentDetails, setAssessmentDetails] =
    useState<AssessmentCategory[]>();

  const loadAssessment = async () => {
    try {
      const res = await getAssessmentCategories();
      setAssessmentDetails(res?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadAssessment();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderAwardItems = (awards: any[], label: string) => {
    return awards.map((award, index) => (
      <Descriptions.Item label={label} className="info-item" key={index}>
        {award?.nameEn}
        <Image className="medal" src={award?.logoUrl} preview={false} />
      </Descriptions.Item>
    ));
  };

  return (
    <Card title="Awards (badges)" className="info-container">
      <span className="info-heading">
        <EditAwardsDetails
          userId={user?._id || ''}
          user={user}
          successCallback={successCallback}
          assessmentDetails={assessmentDetails}
          errorCallback={(e) => {
            message.error(e);
          }}
        >
          {({ onClick }) => (
            <EditOutlined onClick={onClick} style={{ float: 'right' }} />
          )}
        </EditAwardsDetails>
      </span>
      <Descriptions
        layout="vertical"
        column={2}
        labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
      >
        {renderAwardItems(user?.l1Awards || [], 'PBT LEVEL 1')}
        {renderAwardItems(user?.l2Awards || [], 'PBT LEVEL 2')}
        {renderAwardItems(user?.l3Awards || [], 'PBT LEVEL 3')}
      </Descriptions>
    </Card>
  );
};

export default Awards;
