import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { IFlagList } from '../../types/flag-list';
import { getFlagList } from '../../services/flag-list';
import AddFlagList from '../../components/flag-list/add-flag-list';
import { handleError } from '../../utils/common';
import EditFlagList from '../../components/flag-list/edit-flag-list';
import DeleteFlagList from '../../components/flag-list/delete-flag-list';

import columns from './columns';

const FlagList = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadTemplate, setReloadTemplate] = useState(true);
  const [flagList, setFlagList] = useState<IFlagList[]>([]);

  const loadFlagList = async () => {
    setRequesting(true);
    try {
      const response = await getFlagList();
      setFlagList(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadTemplate(false);
    }
  };
  useEffect(() => {
    if (!requesting && reloadTemplate) {
      loadFlagList();
    }
  }, [reloadTemplate, requesting]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: IFlagList) => {
        return (
          <Space size="large">
            <EditFlagList
              flagListId={record._id}
              payload={record}
              successCallback={() => setReloadTemplate(true)}
              errorCallback={handleError}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditFlagList>
            <DeleteFlagList
              flagListId={record._id}
              successCallback={() => setReloadTemplate(true)}
              errorCallback={handleError}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteFlagList>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={flagList}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Flag List</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadTemplate(true)}
                  />
                </Tooltip>
                <AddFlagList
                  successCallback={() => setReloadTemplate(true)}
                  errorCallback={handleError}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add
                    </Button>
                  )}
                </AddFlagList>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default FlagList;
