import { Tooltip } from 'antd';
import { CalendarOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Workshop } from '../../types/workshop';
import { CLIENT_FRONTEND_URL } from '../../constants/api';
import { getCountryByCode } from '../../constants/countries';
import { convertStripeAmount } from '../../utils/common';

const columns = [
  {
    title: 'Details',
    render: (record: Workshop) => (
      <div className="details">
        <div className="workshopTitle">
          <p className="workshopLink">
            <Link
              to={{
                pathname: `${CLIENT_FRONTEND_URL}/workshops/${record?.techniqueSlug}/${record?.slug}`,
              }}
              target="_blank"
            >
              {record.title}{' '}
              <small>
                {record?.techniqueSlug?.toUpperCase()} L{record.level}
              </small>
            </Link>
          </p>
        </div>
        <small>
          {record?.language}
          {record?.workshopMode === 'virtual'
            ? ', Online'
            : `, ${record?.city}`}
          {record.country && `, ${getCountryByCode(record.country)?.name}`}
        </small>
        <br />
        <small>
          <Tooltip placement="topLeft" title="Workshop Start Date">
            <CalendarOutlined />{' '}
            {moment(record.startDate)
              .tz(record.timezone)
              .format('MMM Do YYYY, h:mm A')}
            , {record.timezone}
          </Tooltip>
        </small>
        <br />
        <small>
          <Tooltip placement="topLeft" title="Sale End Date">
            <ShoppingCartOutlined />{' '}
            {moment(record.ticketEndDate)
              .tz(record.timezone)
              .format('MMM Do YYYY, h:mm A')}
          </Tooltip>
        </small>
      </div>
    ),
    key: 'details',
    width: 350,
  },
  {
    title: 'Workshop Owner',
    render: (record: Workshop) => (
      <div className="">
        {record?.workshopOwnerDetails?.fullName} <br />(
        {record?.workshopOwnerDetails?.email})
      </div>
    ),
  },
  {
    title: 'Instructor',
    render: (record: Workshop) => (
      <div className="">
        {record?.workshopInstructor?.fullname} <br />(
        {record?.workshopInstructor?.contactEmail})
      </div>
    ),
  },
  {
    title: 'TktCost',
    render: (record: Workshop) => (
      <div className="">
        {record?.currency}{' '}
        {convertStripeAmount(record?.ticketPrice, record?.currency)?.toFixed(2)}
      </div>
    ),
  },
  {
    title: 'Cap',
    render: (record: Workshop) => record?.totalTickets,
  },
  {
    title: 'Budget',
    render: (record: Workshop) => {
      return (
        <div>
          {record?.isAgreedOnDefaultExpense && 'Default'}
          <br />
          <div className="">
            ({record?.expenseAgreedCurrency}{' '}
            {convertStripeAmount(
              record?.expenseAgreedAmount,
              record?.expenseAgreedCurrency
            )?.toFixed(2)}
            )
          </div>
        </div>
      );
    },
  },
];

export default columns;
