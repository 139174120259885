import { AxiosError } from 'axios';
import { message } from 'antd';

import {
  Language,
  WorkshopLanguage,
  WORKSHOP_LANGUAGES,
  PRIMARY_LANGUAGES,
  STRIPE_ZERO_DECIMAL_COUNTRIES,
} from '../constants/common';
import ResKey from '../types/resKey';

export const isHttp = (url: string) => url.indexOf('http') > -1;
export const isHttps = (url: string) => url.indexOf('https') > -1;

export const downloadBufferFile = (fileName: string, bufferArray: number[]) => {
  const fileURL = window.URL.createObjectURL(
    new Blob([new Uint8Array(bufferArray)])
  );
  const alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = fileName;
  alink.click();
};

export const getFormattedLanguages = (languages: Language[]) =>
  PRIMARY_LANGUAGES.reduce((acc, language) => {
    if (languages.includes(language.value)) {
      acc.push(language.label);
    }
    return acc;
  }, [] as string[]);

export const getFormattedWorkshopLanguages = (languages: WorkshopLanguage[]) =>
  WORKSHOP_LANGUAGES.reduce((acc, language) => {
    if (languages.includes(language.value)) {
      acc.push(language.label);
    }
    return acc;
  }, [] as string[]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const resError = (err: any) => {
  if (err?.response?.data) {
    const message: string = err?.response?.data.MESSAGE_KEY;
    return ResKey[message as keyof typeof ResKey];
  } else {
    return err.message;
  }
};

export const convertStripeAmount = (
  amount: number,
  currency: string
): number => {
  if (!STRIPE_ZERO_DECIMAL_COUNTRIES.includes(currency.toUpperCase())) {
    return amount / 100;
  }
  return amount;
};

export const convertStripeAmountWithoutDecimal = (
  amount: number,
  currency: string
): string => {
  if (!STRIPE_ZERO_DECIMAL_COUNTRIES.includes(currency.toUpperCase())) {
    return (amount / 100).toFixed(2);
  }
  return amount.toFixed(0);
};

export const getTechniqueColor = (technique: string) => {
  switch (technique) {
    case 'pbt':
      return { color: '#B44263' };
    case 'pct':
      return { color: '#FA8C16' };
    case 'pbt-chinese':
      return { color: '#CF1322' };
    default:
      return { color: '#333' };
  }
};

export async function getCoverFile(
  videoFile: File,
  timeInSeconds: number
): Promise<File> {
  return new Promise<File>((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata'; // Load only metadata to get video duration
    video.onloadedmetadata = async () => {
      const duration = video.duration;
      video.currentTime = Math.min(timeInSeconds, duration); // Seek to the desired time
      video.onseeked = () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((blob) => {
            if (blob) {
              const frameFile = new File([blob], 'frame.png', {
                type: 'image/png',
              });
              resolve(frameFile);
            } else {
              reject(new Error('Failed to create Blob.'));
            }
          }, 'image/png');
        } else {
          reject(new Error('Canvas context is not supported.'));
        }
        video.pause();
        URL.revokeObjectURL(video.src); // Clean up the object URL
      };
    };

    video.onerror = (error) => {
      reject(error);
    };

    video.src = URL.createObjectURL(videoFile); // Create URL for the video file
  });
}

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError)?.isAxiosError !== undefined;
}

export const handleError = (err: unknown) => {
  if (isAxiosError(err)) {
    message?.error(err?.response?.data?.MESSAGE_KEY || err?.message);
  } else {
    message?.error((err as Error)?.message);
  }
};
