import { NOT_AVAILABLE } from '../../constants/common';
import { IMarketingAssets } from '../../types/marketingAssets';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: IMarketingAssets) =>
      record?.name || NOT_AVAILABLE,
    width: 600,
  },
  {
    title: 'Technique',
    render: (value: string, record: IMarketingAssets) =>
      record?.techniqueSlug?.toUpperCase() || NOT_AVAILABLE,
    width: 200,
  },
  {
    title: 'Language',
    render: (value: string, record: IMarketingAssets) =>
      record?.language?.toUpperCase() || NOT_AVAILABLE,
    width: 200,
  },
];

export default columns;
