import * as yup from 'yup';

export default yup.object().shape({
  fullName: yup.string().required('Please enter the full name'),
  displayName: yup.string().required('Please enter the Display Name'),
  emergencyContactName: yup.string(),
  emergencyContactNumber: yup.string(),
  profession: yup.string(),
  website: yup
    .string()
    .matches(/^https:\/\/(?:[a-z0-9\-]+\.)+[a-z]{2,}(?:\/[^\/]*)?$/i, {
      message: 'Please enter valid website url starting with https://',
      excludeEmptyString: true,
    }),
  facebook: yup
    .string()
    .matches(/^https:\/\/(?:www\.)?facebook\.[a-z]+\/?.*$/i, {
      message: 'Please enter a valid Facebook URL starting with https://',
      excludeEmptyString: true,
    }),
  instagram: yup
    .string()
    .matches(/^https:\/\/(?:www\.)?instagram\.[a-z]+\/?.*$/i, {
      message: 'Please enter a valid Instagram URL starting with https://',
      excludeEmptyString: true,
    }),
  twitter: yup.string().matches(/^https:\/\/(?:www\.)?twitter\.[a-z]+\/?.*$/i, {
    message: 'Please enter a valid Twitter URL starting with https://',
    excludeEmptyString: true,
  }),
  linkedin: yup
    .string()
    .matches(/^https:\/\/(?:www\.)?linkedin\.[a-z]+\/?.*$/i, {
      message: 'Please enter a valid LinkedIn URL starting with https://',
      excludeEmptyString: true,
    }),
});
