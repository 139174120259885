import { Drawer } from 'antd';
import { createElement, FC, useRef, useState } from 'react';

import { WorkshopAttendeeWithUserDetail } from '../../../types/workshop';

import ViewAttendeesDetails from './viewAttendeesDetails';

interface WorkshopDetails {
  title?: string;
  state?: string;
  country?: string;
  startDate?: string;
  timezone?: string;
  city?: string;
  workshopMode?: string;
}
interface ViewWorkshopAttendeeProps {
  payload: WorkshopAttendeeWithUserDetail;
  workshopDetails: WorkshopDetails;
  children: FC<{
    onClick: () => void;
  }>;
}

const ViewWorkshopAttendee: FC<ViewWorkshopAttendeeProps> = ({
  children,
  payload,
  workshopDetails,
}) => {
  const refChildren = useRef(children);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };
  return (
    <>
      <Drawer
        title="Workshop Attendee Details"
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
      >
        <div style={{ padding: 16 }}>
          <ViewAttendeesDetails
            payload={payload}
            workshopDetails={workshopDetails}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default ViewWorkshopAttendee;
