import { createElement, FC, useEffect, useRef, useState } from 'react';
import { Button, Drawer, message } from 'antd';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { addLocation } from '../../../../../services/users-service';
import { ILocationField } from '../index';

import AddLocationForm from './form';
import validationSchema from './validation-schema';

interface IAddUserProps {
  userId?: string | undefined;
  children: FC<{ onClick: () => void }>;
  errorCallback?: (e: Error) => void;
  successCallback: (e: boolean) => void;
}

const AddLocation: FC<IAddUserProps> = ({
  children,
  userId,
  errorCallback,
  successCallback,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const refChildren = useRef(children);

  const form = useForm<ILocationField>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const onSubmitHandler: SubmitHandler<ILocationField> = async (formData) => {
    console.log(formData);
    setRequesting(true);
    const payLoad = {
      address1: formData.address1?.trim(),
      address2: formData.address2?.trim(),
      city: formData.city?.trim(),
      region: formData.region?.trim(),
      country: formData.country?.trim(),
      zip: formData.zip,
    };
    await addLocation(payLoad, `${userId}`)
      .then((res) => {
        console.log(res.data);
        message.success('Added SuccessFully');
        setRequesting(false);
        form.reset();
        successCallback(true);
      })
      .catch((err) => {
        console.log(err.message);
        message.error('Server Error');
        setRequesting(false);
      });

    handleDrawerVisibility();
  };

  useEffect(() => {
    form.reset();
  }, [form]);

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <>
      <Drawer
        title="Add Location"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
          >
            Add
          </Button>
        }
      >
        <div style={{ padding: 16 }}>
          <AddLocationForm form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddLocation;
