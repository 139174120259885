import * as yup from 'yup';
import { Button, Drawer, message } from 'antd';
import { createElement, FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import UserPageForm from '../form';
import validationSchema from '../validation-schema';
import { addUserPage } from '../../../services/user-pages-service';
import { FaqCategory } from '../../../types/faq';
// import { Locale } from '../../../types';

interface IAddUserPageProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  children: FC<{
    onClick: () => void;
  }>;
  isEditable: boolean;
  faqCategories: FaqCategory[];
  // videos: Locale[];
}

const AddUserPage: FC<IAddUserPageProps> = ({
  children,
  successCallback,
  isEditable,
  errorCallback,
  faqCategories,
  // videos,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<yup.InferType<typeof validationSchema>>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
    form.reset();
  };

  const onSubmitHandler = async (
    values: yup.InferType<typeof validationSchema>
  ) => {
    setRequesting(true);
    try {
      await addUserPage({
        ...values,
        videos: values?.videos,
        faqCategories:
          values?.faqCategories?.map((item, index) => ({
            slug: item,
            sequence: index + 1,
          })) || [],
      });
      setDrawerVisible(false);
      message.success('Page added successfully');
      form.reset({});
      if (successCallback) {
        successCallback();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (errorCallback) {
        errorCallback(err?.response?.data?.MESSAGE_KEY || err?.message);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Add Page"
        onClose={() => {
          handleDrawerVisibility();
          form.reset({});
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <UserPageForm
            isEditable={isEditable}
            form={form}
            faqCategories={faqCategories}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddUserPage;
