import { Col, Form, Row, Image } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { AssessmentCategory } from '../../../../../types/assessment-categories';
import { CheckboxField } from '../../../../common/form';

interface EditAwardsDetailsFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
  assessmentDetails?: AssessmentCategory[];
}

const EditAwardsDetailsForm = ({
  form,
  assessmentDetails,
}: EditAwardsDetailsFormProps) => {
  return (
    <Form layout="vertical">
      <Row>
        {assessmentDetails?.map((assessment) => {
          const { logo, name, level } = assessment || {};
          const { en } = name || {};

          return (
            <Col key={en} span={24} style={{ margin: '10px 0' }}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                {logo && (
                  <Image
                    src={logo}
                    className="medal"
                    preview={false}
                    style={{ minWidth: '50px' }}
                  />
                )}
                <div>
                  <h3 style={{ marginBottom: '0px' }}>{en ? `${en}:` : ''}</h3>
                  <span>{`Level ${level}`}</span>
                </div>
                <div style={{ paddingBottom: '5px' }}>
                  <CheckboxField
                    name={assessment?._id}
                    defaultChecked={form.getValues(assessment?._id)}
                    form={form}
                    style={{ margin: '0 0' }}
                  />
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};

export default EditAwardsDetailsForm;
