import * as yup from 'yup';

export default yup
  .object()
  .shape({
    invoiceName: yup.string().required('Please enter the Name On Invoice'),
    invoiceAddress: yup.string().required('Please enter the Business Address'),
    invoiceCountry: yup.string().required('Please enter the Business Country'),
    businessName: yup.string().required('Please enter the Business Name'),
    taxIdName: yup
      .array()
      .of(yup.string())
      // .min(1, 'Required')
      .required('Required'),
    taxIdNumber: yup.array().of(yup.string().required('Required')),
  })
  .required('Required');
