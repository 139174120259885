import { FC, createElement, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Drawer, Spin, Timeline, Typography, message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { WorkshopAttendeeList } from '../../../types/workshop';
import UploadWorkshopPhotos from '../upload-workshop-photos';
import { handleError } from '../../../utils/common';
import {
  getWorkshopAttendees,
  updateWorkshop,
} from '../../../services/workshop-service';
import UpdateAttendence from '../../manage-workshop/update-attendence';

interface IConcludeWorkshopProps {
  children: FC<{
    onClick: () => void;
  }>;
  successCallback?: () => void;
  errorCallback?: (e: unknown) => void;
  workshopSlug: string;
}

const ConcludeWorkshop: FC<IConcludeWorkshopProps> = ({
  children,
  workshopSlug,
}) => {
  const refChildren = useRef(children);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const [loading, setLoading] = useState(false);
  const [reloadWorkshop, setReloadWorkshop] = useState(true);
  const [workshop, setWorkshop] = useState<WorkshopAttendeeList>();

  const handleDrawerVisibility = () => {
    setDrawerVisible((prev) => !prev);
  };

  const isWorkshopPhotosUploaded = useMemo(() => {
    return workshop ? !!workshop.pic1 : false;
  }, [workshop]);

  const isWorkshopAttendanceDone = useMemo(() => {
    return workshop?.attendees?.every((i) => i?.isAttendanceMarked) || false;
  }, [workshop]);

  const getAttendees = async () => {
    setLoading(true);
    try {
      const response = await getWorkshopAttendees(workshopSlug);
      setWorkshop(response?.data?.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      setReloadWorkshop(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setRequesting(true);
      if (workshop?.slug) {
        await updateWorkshop({ isWorkshopConcluded: true }, workshop?.slug);
        message.success('Workshop updated successfully');
        handleDrawerVisibility();
      } else {
        throw new Error('workshop slug not found');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (drawerVisible && reloadWorkshop) {
      getAttendees();
    }
  }, [reloadWorkshop, drawerVisible]);

  return (
    <>
      <Drawer
        title={
          <Typography.Title level={3} style={{ margin: 0 }}>
            Conclude workshop
          </Typography.Title>
        }
        onClose={handleDrawerVisibility}
        open={drawerVisible}
        bodyStyle={{ padding: '16px' }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={() => {
              if (isWorkshopAttendanceDone && isWorkshopPhotosUploaded) {
                handleSubmit();
              } else {
                if (!isWorkshopAttendanceDone) {
                  message.info('Please mark attendence first.');
                }

                if (!isWorkshopPhotosUploaded) {
                  message.info('Please upload workshop photos first.');
                }
              }
            }}
            disabled={workshop?.isWorkshopConcluded}
          >
            {workshop?.isWorkshopConcluded
              ? 'Workshop Concluded'
              : 'Conclude Workshop'}
          </Button>
        }
        headerStyle={{ padding: '16px' }}
      >
        <Spin spinning={loading}>
          <Timeline
            key={`${isWorkshopAttendanceDone}-${isWorkshopPhotosUploaded}`}
          >
            <Timeline.Item
              color={isWorkshopPhotosUploaded ? 'green' : 'gray'}
              dot={isWorkshopPhotosUploaded && <CheckCircleOutlined />}
            >
              <div>
                <Typography.Title level={5}>Workshop Photos</Typography.Title>
                <Typography.Text>
                  {isWorkshopPhotosUploaded ? (
                    <p>Workshop photos have been uploaded.</p>
                  ) : (
                    <p>
                      Workshop photos have not been uploaded yet. Please upload
                      them before you conclude workshop.
                    </p>
                  )}
                </Typography.Text>

                <UploadWorkshopPhotos
                  workshop={workshop}
                  errorCallback={handleError}
                  successCallback={() => setReloadWorkshop(true)}
                >
                  {({ onClick }) => (
                    <div>
                      <Button type="primary" onClick={onClick}>
                        {isWorkshopPhotosUploaded
                          ? 'Change photos'
                          : 'Upload photos'}
                      </Button>
                    </div>
                  )}
                </UploadWorkshopPhotos>
              </div>
            </Timeline.Item>

            <Timeline.Item
              color={
                isWorkshopAttendanceDone && isWorkshopPhotosUploaded
                  ? 'green'
                  : 'gray'
              }
              dot={
                isWorkshopAttendanceDone &&
                isWorkshopPhotosUploaded && <CheckCircleOutlined />
              }
            >
              <div>
                <Typography.Title level={5}>
                  Workshop Attendance
                </Typography.Title>
                <Typography.Text>
                  {isWorkshopAttendanceDone ? (
                    <p>Workshop attendance has been marked.</p>
                  ) : (
                    <p>
                      Workshop attendance has not been marked yet. Please mark
                      them before you conclude workshop.
                    </p>
                  )}
                </Typography.Text>

                <UpdateAttendence
                  workshopSlug={workshop?.slug || ''}
                  errorCallback={handleError}
                  successCallback={() => setReloadWorkshop(true)}
                >
                  {({ onClick }) => (
                    <div>
                      <Button
                        type="primary"
                        onClick={onClick}
                        disabled={!isWorkshopPhotosUploaded}
                      >
                        {isWorkshopAttendanceDone
                          ? 'Check Attendance'
                          : 'Update Attendance'}
                      </Button>
                    </div>
                  )}
                </UpdateAttendence>
              </div>
            </Timeline.Item>
          </Timeline>
        </Spin>
      </Drawer>

      {createElement(refChildren.current, {
        onClick: () => {
          setDrawerVisible(true);
          setReloadWorkshop(true);
        },
      })}
    </>
  );
};

export default ConcludeWorkshop;
