import { NOT_AVAILABLE } from '../../../constants/common';
import { FaqDetail } from '../../../types/faq';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 200,
  },
  {
    title: 'Category',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (value: string, record: any) => record?.slug || NOT_AVAILABLE,
    width: 300,
  },
  {
    title: 'Question',
    render: (value: string, record: FaqDetail) =>
      record?.que?.en || NOT_AVAILABLE,
    width: 800,
  },
];

export default columns;
