import { useEffect, useState } from 'react';
import { Button, Col, Form, Typography } from 'antd';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { DeleteFilled } from '@ant-design/icons';

import { InputField, SelectField } from '../common/form';
import { getAssessmentCategories } from '../../services/assessment-category-service';
import { getExercises } from '../../services/exercises';
import { AssessmentCategory } from '../../types/assessment-categories';
import { MultilingualExerciseVideo } from '../../types/MultiLingual-ExerciseVideo';
import SlugNote from '../common/slug-note';

interface IAddAssessmentQuestionFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, object>;
  isEditable: boolean;
}
const AssessmentQuestionForm = ({
  form,
  isEditable,
}: IAddAssessmentQuestionFormProps) => {
  const { control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  });

  const [exerciseVideos, setExerciseVideos] = useState<
    MultilingualExerciseVideo[]
  >([]);
  const [categories] = useState<{ _id: string; name: string }[]>([]);

  const loadData = async () => {
    const cate = await getAssessmentCategories();
    cate?.data?.data.map((cat: AssessmentCategory) => {
      const catNameEn = cat?.name?.en;
      categories.push({ _id: cat._id, name: catNameEn });
    });
    const vid = await getExercises();
    setExerciseVideos(vid?.data?.data);
  };

  useEffect(() => {
    loadData();
    if (!isEditable) {
      append({});
    }
  }, []);

  return (
    <>
      <Form layout="vertical">
        <Col>
          <InputField
            label="Slug"
            name="slug"
            required
            inputProps={{
              placeholder: 'Slug : eg(assessment-question-slug)',
              disabled: isEditable === true ? true : false,
            }}
            form={form}
            style={{ marginBottom: '4px' }}
          />
          <SlugNote />
        </Col>
        <SelectField
          label="Assessment Category"
          required
          name="category"
          form={form}
          labelKey="name"
          valueKey="_id"
          selectFieldProps={{
            options: categories,
            placeholder: 'Select Assessment Category',
            onChange: (v) => form.setValue('category', v),
          }}
        />
        <SelectField
          label="Video"
          required
          name="video"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: exerciseVideos.map((video) => ({
              label: video?.videoName?.en,
              value: video?._id,
            })),
            placeholder: 'Select Video',
            onChange: (v) => form.setValue('video', v),
          }}
        />

        <InputField
          label="Sequence"
          name="sequence"
          required
          inputProps={{
            placeholder: 'sequence',
            type: 'text',
          }}
          form={form}
        />

        {fields.map(({ id }, index) => {
          return (
            <div key={id} style={{ padding: '20px' }}>
              <Typography.Title level={5}>
                Question {index + 1}
              </Typography.Title>
              <InputField
                label="Question (EN)"
                name={`questions[${index}].en`}
                required
                inputProps={{
                  placeholder: 'Question (English)',
                }}
                form={form}
              />
              <InputField
                label="Question (ES)"
                name={`questions[${index}].es`}
                required
                inputProps={{
                  placeholder: 'Question (Spanish)',
                }}
                form={form}
              />
              <InputField
                label="Question (IT)"
                name={`questions[${index}].it`}
                required
                inputProps={{
                  placeholder: 'Question (Italian)',
                }}
                form={form}
              />
              <InputField
                label="Question (DE)"
                name={`questions[${index}].de`}
                required
                inputProps={{
                  placeholder: 'Question (German)',
                }}
                form={form}
              />
              <InputField
                label="Question (PT)"
                name={`questions[${index}].pt`}
                required
                inputProps={{
                  placeholder: 'Question (Portuguese)',
                }}
                form={form}
              />
              <InputField
                label="Question (ZH)"
                name={`questions[${index}].zh`}
                required
                inputProps={{
                  placeholder: 'Question (Mandarin)',
                }}
                form={form}
              />
              {fields.length > 1 && (
                <Button
                  style={{ float: 'right' }}
                  danger
                  onClick={() => remove(index)}
                >
                  <DeleteFilled style={{ color: 'red' }} />
                </Button>
              )}
            </div>
          );
        })}
        <Button type="primary" onClick={() => append({})}>
          +
        </Button>
      </Form>
    </>
  );
};

export default AssessmentQuestionForm;
