import axios from 'axios';

import { AUTH } from '../constants/api';
import fetchClient from '../utils/fetch-client';

export type TSession = {
  accessToken: string;
  refreshToken: string;
  session_type: string;
};

export type TLoginUser = {
  email: string;
  password: string;
};

export type TForgotPasswordInput = {
  email: string;
  returnUrl: string;
};

export const signIn = async ({ password, email }: TLoginUser) => {
  const response = await axios.post(AUTH.LOGIN, {
    password,
    email,
  });
  if (response) {
    const { resKey, ...rest } = response.data?.data;
    const userSession = {
      ...rest,
      session_type: resKey || 'OK',
    };
    localStorage.setItem('user', JSON.stringify(userSession));
    return Promise.resolve(userSession);
  } else {
    return null;
  }
};

export const signOut = () => {
  localStorage.removeItem('user');
};

export const getUserFromStorage = () => {
  const stringifiedUser = localStorage.getItem('user');
  return stringifiedUser ? (JSON.parse(stringifiedUser) as TSession) : null;
};

export const refreshSession = async () => {
  const storeSession = getUserFromStorage();
  const response = await fetchClient.post(AUTH.REFRESH_SESSION, {
    refreshToken: storeSession?.refreshToken,
  });
  const { resKey, ...rest } = response.data?.data;
  const refreshedSession = {
    ...storeSession,
    ...rest,
    session_type: resKey || 'OK',
  } as TSession;
  localStorage.setItem('user', JSON.stringify(refreshedSession));
  return Promise.resolve(refreshedSession);
};

export const forgotPassword = (payload: TForgotPasswordInput) => {
  return fetchClient.post(AUTH.FORGOT_PASSWORD, payload);
};
