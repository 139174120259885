import * as yup from 'yup';

export default yup.object().shape({
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
  category_name: yup.object().shape({
    en: yup
      .string()
      .required('Please input Name in English')
      .min(3, 'Name must be at least 3 characters ')
      .max(100, 'Name must be at max 100 characters'),
    pt: yup
      .string()
      .required('Please input Name in Portugese')
      .min(3, 'Name must be at least 3 characters ')
      .max(100, 'Name must be at max 100 characters'),
    it: yup
      .string()
      .required('Please input Name in Italian')
      .min(3, 'Name must be at least 3 characters ')
      .max(100, 'Name must be at max 100 characters'),
    es: yup
      .string()
      .required('Please input Name in Spanish')
      .min(3, 'Name must be at least 3 characters ')
      .max(100, 'Name must be at max 100 characters'),
    de: yup
      .string()
      .required('Please input Name in German')
      .min(3, 'Name must be at least 3 characters ')
      .max(100, 'Name must be at max 100 characters'),
    zh: yup
      .string()
      .required('Please input Name in Mandrin')
      .min(2, 'Name must be at least 2 characters ')
      .max(100, 'Name must be at max 100 characters'),
  }),
});
