import { useState } from 'react';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { MultilingualExerciseVideo } from '../../types/MultiLingual-ExerciseVideo';
import './style.css';
import EditExercise from '../../components/exercises/edit-exercise';
import { ExerciseVideo, VideoFormData } from '../../types/exercises';
import { LanguageEnum } from '../../constants/common';
import { handleError } from '../../utils/common';

const MultiUploadModal = ({
  ExerciseVideo,
  videoFormData,
  setReloadExercises,
  isClassVideo,
}: {
  ExerciseVideo: MultilingualExerciseVideo;
  videoFormData: VideoFormData;
  setReloadExercises: (e: boolean) => void;
  isClassVideo: boolean;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleEdit = (lang: LanguageEnum): ExerciseVideo => {
    return {
      _id: ExerciseVideo?._id,
      slug: ExerciseVideo?.slug,
      language: lang,
      musicUsed: ExerciseVideo?.musicUsed?.[lang] || '',
      videoName: ExerciseVideo?.videoName?.[lang] || '',
      difficultyLevel: ExerciseVideo?.difficultyLevel as 1 | 2 | 3,
      ...(ExerciseVideo?.mediaId?.[lang] && {
        mediaId: ExerciseVideo?.mediaId?.[lang],
      }),
      sequence: ExerciseVideo?.sequence,
      thumb: ExerciseVideo?.thumb?.[lang] || '',
      customThumb: ExerciseVideo?.customThumb?.[lang] || '',
      classPlanPDFUrl: ExerciseVideo?.classPlanPDFUrl?.[lang] || '',
      videoUrl: ExerciseVideo?.videoUrl?.[lang] || '',
      duration: ExerciseVideo?.duration?.[lang] || 0,
      description: ExerciseVideo?.description?.[lang] || {},
      exerciseStartSeconds: ExerciseVideo?.exerciseStartSeconds?.[lang],
      faqs:
        ExerciseVideo?.faqs?.[lang]?.length > 0
          ? ExerciseVideo?.faqs?.[lang]
          : [
              {
                question: '',
                answer: '',
                forMemberType: { students: '', teachers: '' },
              },
            ],
      equipments: ExerciseVideo?.equipments,
      categories: ExerciseVideo?.categories,
      isPublished: ExerciseVideo?.publishedLang?.[lang],
      isClassVideo: isClassVideo,
    };
  };

  const languageVersions = [
    { lang: 'en', label: 'English Version' },
    { lang: 'es', label: 'Spanish Version' },
    { lang: 'de', label: 'German Version' },
    { lang: 'zh', label: 'Chinese Version' },
    { lang: 'it', label: 'Italian Version' },
    { lang: 'pt', label: 'Portuguese Version' },
  ];

  return (
    <>
      <Button type="text" onClick={showModal}>
        <EditOutlined />
      </Button>
      <Modal
        open={modalVisible}
        title="Manage Video"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {languageVersions.map(({ lang, label }) => (
          <div className="version" key={lang}>
            <span>{label}:</span>
            {ExerciseVideo?.publishedLang?.[lang as LanguageEnum] ? (
              <EditExercise
                exerciseId={ExerciseVideo?._id}
                payload={handleEdit(lang as LanguageEnum)}
                videoFormData={videoFormData}
                isPublished={
                  ExerciseVideo?.publishedLang?.[lang as LanguageEnum]
                }
                isClassVideo={isClassVideo}
                isAddingTranslation={false}
                successCallback={() => {
                  setModalVisible(false);
                  setReloadExercises(true);
                }}
                errorCallback={handleError}
              >
                {({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={() => {
                      onClick();
                    }}
                  >
                    Edit
                  </Button>
                )}
              </EditExercise>
            ) : (
              <EditExercise
                exerciseId={ExerciseVideo?._id}
                payload={handleEdit(lang as LanguageEnum)}
                videoFormData={videoFormData}
                isPublished={
                  ExerciseVideo?.publishedLang?.[lang as LanguageEnum]
                }
                isClassVideo={isClassVideo}
                isAddingTranslation={true}
                successCallback={() => {
                  setModalVisible(false);
                  setReloadExercises(true);
                }}
                errorCallback={handleError}
              >
                {({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={() => {
                      onClick();
                      handleOk();
                    }}
                  >
                    Upload
                  </Button>
                )}
              </EditExercise>
            )}
          </div>
        ))}
      </Modal>
    </>
  );
};

export default MultiUploadModal;
