import * as yup from 'yup';

export default yup.object().shape({
  name: yup.object().shape({
    en: yup.string().required('Please input name in english'),
    pt: yup.string().required('Please input name in portugese'),
    it: yup.string().required('Please input name in italian'),
    es: yup.string().required('Please input name in spanish'),
    de: yup.string().required('Please input name in german'),
    zh: yup.string().required('Please input name in mandrin'),
  }),
});
