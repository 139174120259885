import { Card, Divider, Menu, MenuProps, Row, Typography } from 'antd';
import {
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  DollarOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { FC, useRef } from 'react';

import { TUser } from '../../services/users-service';
import './style.scss';
import { NOT_AVAILABLE } from '../../constants/common';
import { convertStripeAmount } from '../../utils/common';
import UpdateMemberType from '../../components/users/user-details/update-member-type';

interface TUserData {
  user: TUser;
  selectedMenuItem: string;
  setSelectedMenuItems: (e: string) => void;
  setReloadUsers: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserCard: FC<TUserData> = ({
  user,
  selectedMenuItem,
  setSelectedMenuItems,
  setReloadUsers,
}) => {
  const changeMenuItemHandler: MenuProps['onClick'] = (e) => {
    setSelectedMenuItems(e.key);
  };

  const scrollableDivRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (scrollableDivRef.current) {
      if (e.deltaY !== 0) {
        e.preventDefault();
        scrollableDivRef.current.scrollLeft += e.deltaY;
      }
    }
  };

  return (
    <Card
      style={{
        width: '100%',
        overflow: 'hidden',
      }}
      className="info-item custom-scrollbar"
      bodyStyle={{ padding: 0 }}
    >
      <div
        style={{
          padding: '8px 12px 16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        className="main_title"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography.Title
            level={5}
            style={{
              fontSize: '24px',
              marginTop: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {user?.fullName || NOT_AVAILABLE}
          </Typography.Title>
          <Typography.Text className="activeStatus">
            {user?.subscription?.status
              ? user?.subscription?.status.toUpperCase()
              : !user?.subscription?.status && user.isDeleted
              ? 'DELETED'
              : NOT_AVAILABLE}
          </Typography.Text>
        </div>
        <Row style={{ display: 'flex', gap: '10px' }}>
          <div className="des_item">
            <div>
              <Typography.Text className="des_value">
                <EnvironmentOutlined style={{ fontSize: '11px' }} />{' '}
                {user?.locations && user?.locations?.length > 0
                  ? `${
                      user?.locations &&
                      user.locations[0]?.city +
                        ', ' +
                        user?.locations[0]?.country
                    }`
                  : NOT_AVAILABLE}
              </Typography.Text>
            </div>
          </div>
          <div className="des_item">
            <div>
              <Typography.Text className="des_value">
                <MailOutlined /> {user?.email || NOT_AVAILABLE}
              </Typography.Text>
            </div>
          </div>
          <div className="des_item">
            <div>
              <Typography.Text className="des_value">
                <PhoneOutlined /> {user?.mobileNumber || NOT_AVAILABLE}
              </Typography.Text>
            </div>
          </div>
          {/* <div className="des_item">
            <div>
              <Typography.Text className="des_value">
                <ShoppingOutlined />
                {user?.subscription?.items?.data[0].price.recurring.interval ||
                  NOT_AVAILABLE}
              </Typography.Text>
            </div>
          </div> */}
          <div className="des_item">
            <div>
              <Typography.Text className="des_value">
                <DollarOutlined />{' '}
                {user?.subscription?.currency &&
                user?.subscription?.items?.data?.[0]?.price?.recurring
                  ?.interval &&
                user?.subscription?.items?.data?.[0]?.price.currency_options?.[
                  user?.subscription?.currency
                ]?.unit_amount ? (
                  <span>
                    {`${user.subscription.currency} @${convertStripeAmount(
                      user.subscription.items.data[0].price.currency_options?.[
                        user.subscription.currency
                      ]?.unit_amount,
                      user.subscription.currency
                    )} per ${
                      user.subscription.items.data[0].price.recurring?.interval
                    }`}
                  </span>
                ) : (
                  NOT_AVAILABLE
                )}
              </Typography.Text>
            </div>
          </div>
          <div className="des_item">
            <div>
              <Typography.Text className="des_value">
                <ShoppingOutlined />{' '}
                {user?.certifications?.length
                  ? user?.certifications
                      ?.map(
                        (certificate) =>
                          `${certificate?.code?.toUpperCase()} L${
                            certificate?.level
                          }`
                      )
                      ?.join(', ')
                  : NOT_AVAILABLE}
              </Typography.Text>
            </div>
          </div>
        </Row>

        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

        <div
          style={{
            display: 'flex',
            paddingInline: '10px',
            gap: '25px',
          }}
          ref={scrollableDivRef}
          onWheel={handleScroll}
          className="scrollableDiv custom-scrollbar"
        >
          <div className="des_item">
            <Typography.Text
              className="des_label"
              style={{ whiteSpace: 'nowrap' }}
            >
              ROLES :
            </Typography.Text>
            <Typography.Text
              className="des_value"
              style={{ whiteSpace: 'nowrap' }}
            >
              {user?.role || NOT_AVAILABLE}
            </Typography.Text>
          </div>
          <div className="des_item">
            <Typography.Text
              className="des_label"
              style={{ whiteSpace: 'nowrap' }}
            >
              MEMBER TYPE :
            </Typography.Text>
            <Typography.Text
              className="des_value"
              style={{ whiteSpace: 'nowrap' }}
            >
              <UpdateMemberType user={user} setReloadUsers={setReloadUsers} />
            </Typography.Text>
          </div>
          <div className="des_item">
            <Typography.Text
              className="des_label"
              style={{ whiteSpace: 'nowrap' }}
            >
              ADMIN TYPE :
            </Typography.Text>
            <Typography.Text
              className="des_value"
              style={{ whiteSpace: 'nowrap' }}
            >
              {user?.adminType || NOT_AVAILABLE}
            </Typography.Text>
          </div>
          <div className="des_item">
            <Typography.Text
              className="des_label"
              style={{ whiteSpace: 'nowrap' }}
            >
              USER ID :
            </Typography.Text>
            <Typography.Text
              className="des_value"
              style={{ whiteSpace: 'nowrap' }}
            >
              {user?._id || NOT_AVAILABLE}
            </Typography.Text>
          </div>
          <div className="des_item">
            <Typography.Text
              className="des_label"
              style={{ whiteSpace: 'nowrap' }}
            >
              SLUG :
            </Typography.Text>
            <Typography.Text
              className="des_value"
              style={{ whiteSpace: 'nowrap' }}
            >
              {user?.slug || NOT_AVAILABLE}
            </Typography.Text>
          </div>
        </div>
      </div>
      <Menu
        mode="horizontal"
        defaultSelectedKeys={[selectedMenuItem]}
        onClick={changeMenuItemHandler}
        style={{ fontSize: '12px' }}
      >
        <Menu.Item key={'ABOUT'}>ABOUT</Menu.Item>
        <Menu.Item key={'WORKSHOPS_ATTENDED'}>WORKSHOPS ATTENDED</Menu.Item>
        {/* <Menu.Item key={'3'}>PERFORMANCE</Menu.Item> */}
        {/* <Menu.Item key={'4'}>FINANCES</Menu.Item> */}
        {/* <Menu.Item key={'5'}>LEARNING TIMELINE</Menu.Item> */}
        {/* <Menu.Item key={'6'}>FINANCIAL TIMELINE</Menu.Item> */}
        <Menu.Item key={'EMAIL_SENT_TO_CUSTOMER'}>
          EMAILS SENT TO {user?.fullName?.toUpperCase()}
        </Menu.Item>
        {/* <Menu.Item key={'8'}>INTERNAL NOTES</Menu.Item> */}
      </Menu>
    </Card>
  );
};

export default UserCard;
