import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  isApproved: Yup.boolean().required('isApproved is required'),
  sequence: Yup.number().when('isApproved', {
    is: true,
    then: Yup.number().required('Sequence No. is required'),
  }),
  technique: Yup.string().when('isApproved', {
    is: true,
    then: Yup.string().required('Technique is required'),
  }),
  name: Yup.string().when('isApproved', {
    is: true,
    then: Yup.string().required('Full Name is required'),
  }),
  description: Yup.object().shape({
    en: Yup.string(),
    es: Yup.string(),
    de: Yup.string(),
    pt: Yup.string(),
    it: Yup.string(),
    zh: Yup.string(),
  }),
  message: Yup.object().when('isApproved', {
    is: true,
    then: (schema) =>
      schema.shape({
        en: Yup.string().required('Message (EN) is required'),
        es: Yup.string().required('Message (ES) is required'),
        de: Yup.string().required('Message (DE) is required'),
        pt: Yup.string().required('Message (PT) is required'),
        it: Yup.string().required('Message (IT) is required'),
        zh: Yup.string().required('Message (ZH) is required'),
      }),
    otherwise: (schema) =>
      schema.shape({
        en: Yup.string(),
        es: Yup.string(),
        de: Yup.string(),
        pt: Yup.string(),
        it: Yup.string(),
        zh: Yup.string(),
      }),
  }),

  pic: Yup.string().when('isApproved', {
    is: true,
    then: Yup.string().required('Picture is required'),
  }),
  sliderPic: Yup.string().when('isApproved', {
    is: true,
    then: Yup.string().required('Slider Picture is required'),
  }),
  designations: Yup.array().when('isApproved', {
    is: true,
    then: (schema) =>
      schema
        .of(
          Yup.object().shape({
            title: Yup.string().required('Title is required'),
            schoolname: Yup.string(),
            schoolweb: Yup.string().matches(
              /^(https?:\/\/)/,
              'School website must start with https://'
            ),
          })
        )
        .required('Designation is required')
        .min(1, 'At least one designation is required'),
    otherwise: (schema) =>
      schema.of(
        Yup.object().shape({
          title: Yup.string(),
          schoolname: Yup.string(),
          schoolweb: Yup.string().matches(
            /^(https?:\/\/)/,
            'School website must start with https://'
          ),
        })
      ),
  }),
});

export default validationSchema;
