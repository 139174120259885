import moment from 'moment';

import { NOT_AVAILABLE } from '../../../constants/common';

import { ISearchAttendees } from '.';

const attendanceStatus = {
  ATTENDED: 'Attended',
  NOT_ATTENDED: 'Not attended',
  NOT_CERTIFIED: 'Not certified',
};

const columns = [
  {
    title: 'Event State',
    render: (value: string, record: ISearchAttendees) =>
      record?.workshop?.isWorkshopConcluded ? 'closed' : 'open',
    key: 'event-state',
    width: 120,
  },
  {
    title: 'Attendee',
    render: (value: string, record: ISearchAttendees) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{record?.fullName}</span>

          <span>( {record?.email} )</span>
        </div>
      );
    },
    key: 'attendee',
    width: 250,
  },
  {
    title: 'Attendance status',
    render: (value: string, record: ISearchAttendees) => {
      return (
        <span>
          {record?.isAttendanceMarked
            ? attendanceStatus[record?.attendanceStatus]
            : NOT_AVAILABLE}
        </span>
      );
    },
    key: 'attendee',
    width: 200,
  },
  {
    title: 'Booked On',
    render: (value: string, record: ISearchAttendees) => {
      return (
        <>
          {moment(record?.createdAt).format('ll hh:mm')} (
          {moment(record?.createdAt).tz('UTC').format('z')})
        </>
      );
    },
    key: 'booked-on',
    width: 200,
  },
  {
    title: 'Buyer',
    render: (value: string, record: ISearchAttendees) => record?.email,
    key: 'buyer',
    width: 200,
  },
  {
    title: 'Event',
    render: (value: string, record: ISearchAttendees) => {
      return (
        <>
          {record?.workshop?.title}
          {', '}
          {record?.workshop?.country}{' '}
          {moment(record?.workshop?.startDate)
            .tz(record?.workshop?.timezone || 'UTC')
            .format('ll')}
        </>
      );
    },
    key: 'event',
    width: 250,
  },
  {
    title: 'Admin',
    render: (value: string, record: ISearchAttendees) =>
      record?.workshop?.owner?.fullName,
    key: 'admin',
  },
];

export default columns;
