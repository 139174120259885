import { ExerciseClasses } from '../../types/exercise-classes';

const getTechniqueColor = (technique: string) => {
  switch (technique) {
    case 'pbt':
      return { color: '#B44263' };
    case 'pct':
      return { color: '#FA8C16' };
    case 'pbt-chinese':
      return { color: '#CF1322' };
    default:
      return { color: '#333' };
  }
};

const columns = [
  {
    title: 'Sequence',
    render: (value: string, record: ExerciseClasses) => record?.sequence,
    width: 200,
  },
  {
    title: 'Name ',
    render: (value: string, record: ExerciseClasses) => <>{record?.name?.en}</>,
    width: 250,
  },
  {
    title: 'Technique and Sections',
    render: (value: string, record: ExerciseClasses) => {
      const desiredTechniquesOrder = ['pbt'];

      return (
        <>
          {desiredTechniquesOrder.map((technique) =>
            record?.sections?.some(
              (item) =>
                typeof item !== 'string' && item.techniqueSlug === technique
            ) ? (
              <div key={technique}>
                <h4 style={getTechniqueColor(technique)}>{technique} :</h4>
                <p>
                  {record.sections
                    .filter(
                      (item) =>
                        typeof item !== 'string' &&
                        item.techniqueSlug === technique
                    )
                    .map((item) => typeof item !== 'string' && item.sectionSlug)
                    .join(', ')}
                </p>
              </div>
            ) : null
          )}
        </>
      );
    },
    width: 400,
  },
];

export default columns;
