import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Drawer, Form, message } from 'antd';
import { createElement, FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { addWorkshopAttendee } from '../../../services/workshop-service';
import { InputField } from '../../common/form';
import { IAddAttendee } from '../../../types/workshop';

interface AddWorkshopAttendeeProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  workshopSlug: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  cemail: yup
    .string()
    .email('Invalid email format')
    .required('Confirm Email is required')
    .oneOf([yup.ref('email')], 'Emails must match'),
  note: yup.string(),
});

const AddWorkshopAttendee: FC<AddWorkshopAttendeeProps> = ({
  workshopSlug,
  children,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<IAddAttendee>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: IAddAttendee) => {
    setRequesting(true);
    try {
      await addWorkshopAttendee(workshopSlug, values);
      setDrawerVisible(false);
      message.success('Workshop Attendee added successfully');
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Add Workshop Attendee"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Add Attendee
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <Form layout="vertical">
            <InputField
              label="First Name"
              name="firstName"
              required
              inputProps={{
                placeholder: 'First Name',
              }}
              form={form}
            />
            <InputField
              label="Last Name"
              name="lastName"
              required
              inputProps={{
                placeholder: 'Last Name',
              }}
              form={form}
            />
            <InputField
              label="Email"
              name="email"
              required
              inputProps={{
                placeholder: 'Email',
              }}
              form={form}
            />
            <InputField
              label="Confirm Email"
              name="cemail"
              required
              inputProps={{
                placeholder: 'Confirm email',
              }}
              form={form}
            />
            <InputField
              label="Note"
              name="note"
              required
              inputProps={{
                placeholder: 'note',
              }}
              form={form}
            />
          </Form>
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddWorkshopAttendee;
