import { QUESTIONS } from '../constants/api';
import { Locale } from '../types';
import fetchClient from '../utils/fetch-client';

type TQuestionPayload = {
  category: string;
  question: Locale;
  maxmarks?: number;
  _id?: string;
};

type TDeleteQuestionPayload = {
  isDeleted: boolean;
  _id: string;
};

export const getQuestions = () => {
  return fetchClient.get(QUESTIONS.GET_QUESTIONS);
};

export const getQuestionCategories = () => {
  return fetchClient.get(QUESTIONS.GET_CATEGORIES);
};

export const addQuestion = (payload: TQuestionPayload) => {
  return fetchClient.post(QUESTIONS.ADD_UPDATE_QUESTION, payload);
};

export const updateQuestion = (payload: TQuestionPayload) => {
  return fetchClient.post(QUESTIONS.ADD_UPDATE_QUESTION, payload);
};

export const deleteQuestion = (payload: TDeleteQuestionPayload) => {
  return fetchClient.post(QUESTIONS.ADD_UPDATE_QUESTION, payload);
};
