import * as Yup from 'yup';

const schema = Yup.object().shape({
  isApproved: Yup.boolean().required(),
  remarks: Yup.string().when('isApproved', {
    is: (isApproved: boolean) => !isApproved,
    then: (schema) => schema.required('remarks is required when rejecting'),
    otherwise: (schema) => schema,
  }),
  assignedCertificationCode: Yup.array().when('isApproved', {
    is: true,
    then: (schema) => schema.of(Yup.string()).min(1, 'required'),
    otherwise: (schema) => schema.of(Yup.string()),
  }),
});

export default schema;
