import { ICertificationRenewalQuestion } from '../../types/CertificationRenewalQuestion';

const columns = [
  {
    title: 'Sequence',
    render: (value: string, record: ICertificationRenewalQuestion) =>
      record?.sequence,
    width: 200,
  },
  {
    title: 'Renewal Year',
    render: (value: string, record: ICertificationRenewalQuestion) => (
      <div>{record?.renewalYear}</div>
    ),
    width: 200,
  },
  {
    title: 'Category',
    render: (value: string, record: ICertificationRenewalQuestion) => (
      <div>{record?.category}</div>
    ),
    width: 200,
  },

  {
    title: 'Question',
    render: (value: string, record: ICertificationRenewalQuestion) => (
      <div>
        {record?.questions?.map((question) => {
          return <h4>{question?.en}</h4>;
        })}
      </div>
    ),
    width: 800,
  },
];

export default columns;
