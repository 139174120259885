import { useEffect } from 'react';
import { Col, Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { PRIMARY_LANGUAGES, WORKSHOP_LANGUAGES } from '../../constants/common';
import { WorkshopInstructor } from '../../types/instructor';
import { TechniqueDetail } from '../../types/technique';
import {
  InputField,
  FileUploadButton,
  CheckboxField,
  SelectField,
} from '../common/form';

import './form.scss';
import SlugNote from '../common/slug-note';

interface TechniqueFormProps {
  form: UseFormReturn<TechniqueDetail, object>;
  isEditable: boolean;
  instructors: WorkshopInstructor[];
}
const TechniqueForm = ({
  form,
  isEditable,
  instructors,
}: TechniqueFormProps) => {
  const values = form.getValues();
  const watchMultiLevelCertification = form.watch('isCertificationMultiLevel');
  const watchCertificationHighestLevel = form.watch(
    'certificationHighestLevel'
  );
  const watchOwner = form.watch('createdBy');

  useEffect(() => {
    form.resetField('certificationHighestLevel');
  }, [watchMultiLevelCertification]);

  return (
    <Form layout="vertical">
      <Col>
        <InputField
          label="Slug"
          name="slug"
          required
          inputProps={{
            placeholder: 'Slug : eg(technique-name)',
          }}
          form={form}
          disabled={isEditable}
          style={{ marginBottom: '4px' }}
        />
        <SlugNote />
      </Col>
      <InputField
        label="Short Name"
        name="shortName"
        required
        inputProps={{
          placeholder: 'Short name',
        }}
        form={form}
      />
      <InputField
        label="Sequence"
        name="sequence"
        required
        inputProps={{
          placeholder: 'sequence',
        }}
        form={form}
      />
      <InputField
        label="Name (EN)"
        name="name.en"
        required
        inputProps={{
          placeholder: 'Name (English)',
        }}
        form={form}
      />
      <InputField
        label="Name (ES)"
        name="name.es"
        required
        inputProps={{
          placeholder: 'Name (Spanish)',
        }}
        form={form}
      />
      <InputField
        label="Name (IT)"
        name="name.it"
        required
        inputProps={{
          placeholder: 'Name (Italian)',
        }}
        form={form}
      />
      <InputField
        label="Name (DE)"
        name="name.de"
        required
        inputProps={{
          placeholder: 'Name (German)',
        }}
        form={form}
      />
      <InputField
        label="Name (PT)"
        name="name.pt"
        required
        inputProps={{
          placeholder: 'Name (Portuguese)',
        }}
        form={form}
      />
      <InputField
        label="Name (ZH)"
        name="name.zh"
        required
        inputProps={{
          placeholder: 'Name (Mandarin)',
        }}
        form={form}
      />
      <SelectField
        label="Owner"
        name="createdBy"
        required
        form={form}
        labelKey="fullName"
        valueKey="_id"
        selectFieldProps={{
          options: instructors,
          placeholder: 'Select Owner',
          onChange: (v) => form.setValue('createdBy', v),
        }}
      />
      {watchOwner && (
        <InputField
          label="Owner Share"
          name="ownerShare"
          required
          inputProps={{
            placeholder: 'Owner share in %',
            type: 'number',
          }}
          form={form}
        />
      )}
      <InputField
        label="HPL Share"
        name="hplShare"
        required
        inputProps={{
          placeholder: 'Hpl share in %',
          type: 'number',
        }}
        form={form}
      />
      <InputField
        label="Instructor Share"
        name="instructorShare"
        required
        inputProps={{
          placeholder: 'Instructor share in %',
          type: 'number',
        }}
        form={form}
      />
      <SelectField
        label="Primary Languages"
        required
        name="primaryLanguages"
        form={form}
        labelKey="label"
        valueKey="value"
        selectFieldProps={{
          options: PRIMARY_LANGUAGES,
          placeholder: 'Select Primay Languages',
          onChange: (v) => form.setValue('primaryLanguages', v),
          mode: 'multiple',
        }}
      />
      <SelectField
        label="Workshop Languages"
        required
        name="workshopLanguages"
        form={form}
        labelKey="label"
        valueKey="value"
        selectFieldProps={{
          options: WORKSHOP_LANGUAGES,
          placeholder: 'Select Workshop Languages',
          onChange: (v) => form.setValue('workshopLanguages', v),
          mode: 'multiple',
        }}
      />
      <CheckboxField
        label="Multi Level Certification"
        message="Yes / No"
        name="isCertificationMultiLevel"
        form={form}
        defaultChecked={values.isCertificationMultiLevel}
      />
      {watchMultiLevelCertification && (
        <SelectField
          label="Certification Highest Level"
          name="certificationHighestLevel"
          required
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: [
              {
                label: 'One',
                value: 1,
              },
              {
                label: 'Two',
                value: 2,
              },
              {
                label: 'Three',
                value: 3,
              },
            ],
            placeholder: 'Select certification highest level',
            onChange: (v) => form.setValue('certificationHighestLevel', v),
          }}
        />
      )}
      {watchMultiLevelCertification ? (
        <>
          {watchCertificationHighestLevel >= 1 &&
            watchCertificationHighestLevel <= 3 && (
              <InputField
                label="Certificate Template Name Level 1"
                name="certificateTemplates[0]"
                required
                inputProps={{
                  placeholder: 'Certificate Template Name Level 1',
                }}
                form={form}
              />
            )}
          {watchCertificationHighestLevel > 1 &&
            watchCertificationHighestLevel <= 3 && (
              <InputField
                label="Certificate Template Name Level 2"
                name="certificateTemplates[1]"
                required
                inputProps={{
                  placeholder: 'Certificate Template Name Level 2',
                }}
                form={form}
              />
            )}
          {watchCertificationHighestLevel > 2 &&
            watchCertificationHighestLevel <= 3 && (
              <InputField
                label="Certificate Template Name Level 3"
                name="certificateTemplates[2]"
                required
                inputProps={{
                  placeholder: 'Certificate Template Name Level 3',
                }}
                form={form}
              />
            )}
        </>
      ) : (
        <InputField
          label="Certificate Template Name"
          name="certificateTemplates[0]"
          required
          inputProps={{
            placeholder: 'Certificate Template Name',
          }}
          form={form}
        />
      )}
      <InputField
        label="Certification Life (Years)"
        name="certificateLifeInYears"
        required
        inputProps={{
          placeholder: 'Certification life in years',
          type: 'number',
        }}
        form={form}
      />
      <FileUploadButton
        required
        form={form}
        triggerValidate={() => form.trigger('logoSmallForLightBg')}
        className="filename-container"
        defaultFileList={
          isEditable
            ? [
                {
                  uid: '-1',
                  name: 'Logo Light Background (S)',
                  status: 'done',
                  url: values.logoSmallForLightBg as string,
                },
              ]
            : []
        }
        label="Logo Light Background (S)"
        name="logoSmallForLightBg"
        buttonLabel="Upload Logo"
      />
      <FileUploadButton
        required
        form={form}
        triggerValidate={() => form.trigger('logoMediumForLightBg')}
        className="filename-container"
        defaultFileList={
          isEditable
            ? [
                {
                  uid: '-1',
                  name: 'Logo Light Background (M)',
                  status: 'done',
                  url: values.logoMediumForLightBg as string,
                },
              ]
            : []
        }
        label="Logo Light Background (M)"
        name="logoMediumForLightBg"
        buttonLabel="Upload Logo"
      />
      <FileUploadButton
        required
        form={form}
        triggerValidate={() => form.trigger('logoSmallForDarkBg')}
        className="filename-container"
        defaultFileList={
          isEditable
            ? [
                {
                  uid: '-1',
                  name: 'Logo Dark Background (S)',
                  status: 'done',
                  url: values.logoSmallForDarkBg as string,
                },
              ]
            : []
        }
        label="Logo Dark Background (S)"
        name="logoSmallForDarkBg"
        buttonLabel="Upload Logo"
      />
      <FileUploadButton
        required
        form={form}
        triggerValidate={() => form.trigger('logoMediumForDarkBg')}
        className="filename-container"
        defaultFileList={
          isEditable
            ? [
                {
                  uid: '-1',
                  name: 'Logo Dark Background (M)',
                  status: 'done',
                  url: values.logoMediumForDarkBg as string,
                },
              ]
            : []
        }
        label="Logo Dark Background (M)"
        name="logoMediumForDarkBg"
        buttonLabel="Upload Logo"
      />
    </Form>
  );
};

export default TechniqueForm;
