import { Col, Form, Row } from 'antd';
import { UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { InputField, SelectField } from '../../../../common/form';
import { COUNTRIES } from '../../../../../constants/countries';

import { TFieldValues } from './';

interface IAddUserFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
}

const EditBusinessDetailsForm = ({ form }: IAddUserFormProps) => {
  const formValues: TFieldValues = form.getValues();

  const [selectedCountry, setSelectedCountry] = useState(
    formValues.invoiceCountry
  );
  const [selectedTaxIds, setSelectedTaxIds] = useState(formValues.taxIdName);

  const taxIds = selectedCountry
    ? COUNTRIES.filter((country) => {
        return country.alpha2Code === selectedCountry;
      })[0].taxIds
    : [];

  useEffect(() => {
    setSelectedTaxIds(formValues.taxIdName || []);
  }, [formValues?.taxIdName]);

  const handleTaxIdsChange = (values: string[]) => {
    setSelectedTaxIds(values);
    form.setValue('taxIdName', values);
  };

  return (
    <Form layout="vertical">
      <Row>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'Name On Invoice'}
            name="invoiceName"
            required
            form={form}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField label={'Business Name'} name="businessName" form={form} />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'Business Address'}
            name="invoiceAddress"
            form={form}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'Postal Code'}
            name="invoicePostalCode"
            form={form}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <SelectField
            label={'Business Country'}
            name="invoiceCountry"
            form={form}
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: COUNTRIES.map((country) => {
                return {
                  label: `${country.name} ( ${country.alpha2Code} )`,
                  value: country.alpha2Code,
                };
              }),
              placeholder: 'Select Country',
              onChange: (v: string) => {
                form.setValue('invoiceCountry', v);
                form.setValue('taxIdName', []);

                setSelectedCountry(v);
              },
              showSearch: true,
            }}
          />
        </Col>

        <Col span={12} className="pbt-pr">
          <SelectField
            label={'Tax Ids'}
            name="taxIdName"
            form={form}
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: taxIds?.map((p) => {
                return {
                  label: `${Object.values(p)}`,
                  value: `${Object.values(p)}`,
                };
              }),
              placeholder: `${
                taxIds?.length ? 'Select TaxIds' : 'No TaxId To Select'
              }`,
              onChange: handleTaxIdsChange,
              allowClear: true,
              mode: 'multiple',
            }}
          />
        </Col>
        {selectedTaxIds?.map((taxs, index) => {
          return (
            <Col key={index} span={24} className="pbt-pr">
              <InputField
                label={taxs}
                name={`taxIdNumber[${index}]`}
                required
                form={form}
              />
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};

export default EditBusinessDetailsForm;
