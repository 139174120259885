/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, FC } from 'react';
import { Table, Spin, message, Button, Image } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import { UseFormReturn } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { getFiles } from '../../../services/workshop-service';
import { isAxiosError } from '../../../utils/common';
import { FileType, Files } from '../../../types/workshop';

export interface ISelectFilePopup {
  type: FileType;
  form?: UseFormReturn<any, any>;
}

const SelectFilePopup: FC<ISelectFilePopup> = ({ type, form }) => {
  const [requesting, setRequesting] = useState(false);
  const [files, setFiles] = useState<Files[]>([]);

  const loadFiles = async () => {
    setRequesting(true);
    try {
      const response = await getFiles(type);
      setFiles(response?.data?.data);
    } catch (err) {
      if (isAxiosError(err)) {
        message?.error(err?.response?.data?.MESSAGE_KEY || err?.message);
      } else {
        message?.error((err as Error)?.message);
      }
    } finally {
      setRequesting(false);
    }
  };

  const columns: ColumnsType<Files> = [
    {
      title: 'Name',
      render: (record: Files) => record?.name,
      key: 'givenName',
    },
    {
      title: `Type`,
      render: (record: Files) => record?.type,
      key: 'type',
    },
    {
      title: 'Created',
      render: (record: Files) =>
        moment(record?.createdAt).format('MMM Do YYYY'),
      key: 'createdAt',
    },
    {
      title: 'Preview',
      render: (record: Files) =>
        record?.type === FileType.banner ? (
          <div className="preview">
            <Image
              src={record?.file}
              width="75px"
              height="75px"
              style={{ position: 'relative' }}
              alt="file"
            />
          </div>
        ) : (
          <Link to={record.file} target="_blank">
            Preview
          </Link>
        ),
      key: 'preview',
    },
    {
      title: 'Select',
      render: (record: Files) => (
        <Button
          type="link"
          className="imageSelectModal"
          onClick={() => {
            form?.setValue(type + 'Url', record.file);
          }}
          role="link"
        >
          {form?.watch(type + 'Url') === record?.file ? (
            <span>
              Select <CheckCircleTwoTone twoToneColor="#52c41a" />
            </span>
          ) : (
            'Select'
          )}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (!requesting) {
      loadFiles();
    }
  }, [type]);

  return (
    <Spin spinning={requesting}>
      <Table dataSource={files} columns={columns} />
    </Spin>
  );
};

export default SelectFilePopup;
