/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Form } from 'antd';
import _ from 'lodash';

interface IReactQuillFieldProps {
  label?: string;
  required?: boolean;
  name: string;
  readOnly?: boolean;
  form: UseFormReturn<any, any>;
  reactQuillProps?: any;
  showToolBar?: boolean;
}

const toolbarOptions = [
  [{ font: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  [{ script: 'sub' }, { script: 'super' }],
  ['blockquote', 'code-block'],
  [{ indent: '-1' }, { indent: '+1' }, { align: [] }, { direction: 'rtl' }],
  ['link', 'image', 'video'],
  ['clean'],
];

const ReactQuillField: FC<IReactQuillFieldProps> = ({
  form: {
    formState: { errors },
    control,
  },
  label,
  readOnly = false,
  required,
  name,
  reactQuillProps,
  showToolBar = true,
}) => {
  const [isInitialRender, setIsInitialRender] = useState(true);

  const errorMessages = _.get(errors, name);
  const hasError = !!(errors && errorMessages);

  useEffect(() => setIsInitialRender(false), []);

  return !isInitialRender ? (
    <Form.Item
      label={label}
      help={errorMessages?.message}
      validateStatus={hasError ? 'error' : 'success'}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ReactQuill
            {...field}
            {...reactQuillProps}
            readOnly={readOnly}
            modules={{ toolbar: showToolBar ? toolbarOptions : false }}
          />
        )}
      />
    </Form.Item>
  ) : null;
};

export default ReactQuillField;
