import { AssessmentQuestion } from '../../types/assessment-question';

const columns = [
  {
    title: 'Sequence',
    render: (value: string, record: AssessmentQuestion) => record?.sequence,
    width: 200,
  },
  {
    title: 'Assessment Category',
    render: (value: string, record: AssessmentQuestion) =>
      typeof record.category !== 'string' && (
        <div>{record?.category?.name?.en}</div>
      ),
    width: 200,
  },
  {
    title: 'Exercise Name',
    render: (value: string, record: AssessmentQuestion) =>
      typeof record.video !== 'string' && (
        <div>{record?.video?.videoName?.en}</div>
      ),
    width: 200,
  },
  {
    title: 'Question',
    render: (value: string, record: AssessmentQuestion) => (
      <div>
        {record?.questions.map((question) => {
          return <h4>{question?.en}</h4>;
        })}
      </div>
    ),
    width: 800,
  },
];

export default columns;
