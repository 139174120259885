import { Col, Form, Row } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { InputField, SelectField } from '../../../../common/form';
import { COUNTRIES } from '../../../../../constants/countries';
import { getRegionsByCountry } from '../../../../../constants/regions';

interface IAddUserFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
}
const AddLocationForm = ({ form }: IAddUserFormProps) => {
  return (
    <Form layout="vertical">
      <Row>
        <Col span={24} className="pbt-pr">
          <InputField
            label={'Address Line 1'}
            name="address1"
            required
            form={form}
            inputProps={{ placeholder: 'Enter Address Line 1' }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="pbt-pr">
          <InputField
            label={'Address Line 2'}
            name="address2"
            form={form}
            inputProps={{ placeholder: 'Enter Address Line 2 ( Optional )' }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'City'}
            name="city"
            form={form}
            required
            inputProps={{ placeholder: 'Enter City' }}
          />
        </Col>

        <Col span={12} className="pbt-pr">
          <SelectField
            label={'Country'}
            name="country"
            form={form}
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: COUNTRIES.map((country) => {
                return {
                  label: `${country.name} ( ${country.alpha2Code} )`,
                  value: country.alpha2Code,
                };
              }),

              placeholder: 'Select country',
              onChange: (v) => form.setValue('country', v),
              showSearch: true,
            }}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <SelectField
            label={'Region'}
            name="region"
            form={form}
            required
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: getRegionsByCountry(form.watch('country')).map(
                (region) => {
                  return {
                    label: region.name,
                    value: region.name,
                  };
                }
              ),

              placeholder: 'Select region',
              onChange: (v) => form.setValue('region', v),
              showSearch: true,
            }}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'Zip Code'}
            name="zip"
            form={form}
            required
            inputProps={{ maxLength: 6, placeholder: 'Enter Zip Code' }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default AddLocationForm;
