import { UseFormReturn } from 'react-hook-form';
import { Form } from 'antd';

import { InputField } from '../../common/form';

interface IEditNoteFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
}
const EditNoteForm = ({ form }: IEditNoteFormProps) => {
  return (
    <Form layout="vertical">
      <InputField
        name="note"
        form={form}
        inputProps={{
          placeholder: 'Note',
        }}
      />
      <p>
        Note: Edit note here. Use pipe | as note separator. Eg:
        <strong>
          {' '}
          Attendee is non-dancer | Transfer requested via email to
          myemail@pbt.dance dtd 20/04/2022
        </strong>
      </p>
    </Form>
  );
};

export default EditNoteForm;
