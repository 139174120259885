import { createElement, FC, useEffect, useRef, useState } from 'react';
import { Button, Drawer, message } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ICreditList } from '../../../types/credit-list';
import { transferCreditUserToWorkshop } from '../../../services/credit-list';

import validationSchema from './validation-schema';
import EditUserForm from './form';

interface IAddUsertoWorkshopProps {
  creditList: ICreditList;
  children: FC<{ onClick: () => void }>;
  errorCallback?: (e: unknown) => void;
  successCallback?: () => void;
}

interface FormField {
  note: string;
  workshopSlug: string;
}

const AddUsertoWorkshop: FC<IAddUsertoWorkshopProps> = ({
  children,
  creditList,
  errorCallback,
  successCallback,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const refChildren = useRef(children);

  const form = useForm<FormField>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: FormField) => {
    setRequesting(true);
    try {
      const payload = {
        ...values,
        creditListId: creditList?._id,
      };

      await transferCreditUserToWorkshop(payload);

      message.success('User added to the workshop');

      handleDrawerVisibility();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e);
      }
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    form.reset({ note: creditList?.note });

    return () => {
      form.reset({});
    };
  }, [creditList]);

  return (
    <>
      <Drawer
        title="Add User to Upcoming Workshop"
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
          >
            Submit
          </Button>
        }
      >
        <div style={{ padding: 16 }}>
          <EditUserForm form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddUsertoWorkshop;
