import parse from 'html-react-parser';

import { IUnansweredComments } from '../../types/MultiLingual-ExerciseVideo';

const columns = [
  {
    title: '# No',
    render: (value: number, record: IUnansweredComments, index: number) =>
      index + 1,
    key: 'slno',
    width: 90,
  },
  {
    title: 'Name',
    render: (value: string, record: IUnansweredComments) =>
      record?.comment?.writer?.name,
    key: 'name',
    width: 600,
  },
  {
    title: 'Comment',
    render: (value: string, record: IUnansweredComments) =>
      parse(record?.comment?.text),
    key: 'comment',
    width: 800,
  },
  {
    title: 'Exercise',
    render: (value: string, record: IUnansweredComments) =>
      record?.videoName?.en,
    key: 'exercise',
    width: 800,
  },
];

export default columns;
