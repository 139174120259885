import { Typography, Image, List } from 'antd';
import parse from 'html-react-parser';

import { ISentEmailsRecords } from '../../../types/email-records';
import './styles.scss';

interface EmailContentProps {
  selectedEmail: ISentEmailsRecords | null;
  pic: string;
}

const { Text, Paragraph } = Typography;

const EmailContent: React.FC<EmailContentProps> = ({ selectedEmail, pic }) => {
  return (
    <div className="email-content">
      {selectedEmail ? (
        <>
          <div className="email-details">
            <List.Item.Meta
              avatar={
                <Image src={pic} preview={false} className="email-avatar" />
              }
              title={
                <div className="email-title">
                  <Text>{selectedEmail.recipientEmail}</Text>
                  <Text type="secondary">
                    {selectedEmail?.sentAt
                      ? new Date(selectedEmail.sentAt).toLocaleString()
                      : ''}
                  </Text>
                </div>
              }
              description={selectedEmail.subject}
            />
          </div>
          <div className="email-body email-scrollbar">
            <Paragraph>{parse(selectedEmail.body || '')}</Paragraph>
          </div>
        </>
      ) : (
        <div className="no-email">
          <Text>No email selected</Text>
        </div>
      )}
    </div>
  );
};

export default EmailContent;
