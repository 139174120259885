/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useCallback } from 'react';

import { getPSign } from '../../../services/exercises';

const Tcplayer = ({ fileId }: { fileId?: string }) => {
  const playHandler = useCallback(async () => {
    const response = await getPSign(fileId || '');
    const psign = response?.data?.data?.psign;
    (window as any).TCPlayer('tencentPlayerContainer', {
      fileID: fileId,
      appID: process.env.REACT_APP_TENCENT_APP_ID,
      psign,
      autoplay: false,
      language: 'en',
    });
  }, [fileId]);

  useEffect(() => {
    if (!fileId) return;

    const script = document.createElement('script');
    script.src =
      'https://web.sdk.qcloud.com/player/tcplayer/release/v4.9.0/tcplayer.v4.9.0.min.js';
    script.async = true;

    script.onload = async () => {
      await playHandler();
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [fileId, playHandler]);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: '100%',
          height: 'auto',
          minHeight: 300,
          maxHeight: 300,
        }}
      >
        <video
          id="tencentPlayerContainer"
          preload="auto"
          playsInline
          style={{
            width: '100%',
            height: '100%',
            minHeight: 300,
          }}
        />
      </div>
    </div>
  );
};

export default Tcplayer;
