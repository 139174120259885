const SlugNote = () => {
  return (
    <p>
      Note: The slug must contain only lowercase letters (a-z) and words
      separated by hyphens ("-").
      <br />
      <strong>Example:</strong> Valid: <em>example-slug</em> | Invalid:{' '}
      <em>Example_Slug, exámple-slug, exampleslug, example slug</em>
    </p>
  );
};

export default SlugNote;
