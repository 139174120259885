import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import AddInstructorPaymentProcessorForm from '../form';
import { updateInstructorPaymentProcessor } from '../../../services/instructor-service';
import { AdminType } from '../../../services/users-service';

interface IPaymentProcessor {
  paymentProcessor: string;
  haveStripeId?: boolean;
  stripeId?: string;
  accountHolderName?: string;
  accountNumber?: number;
  routingNumber?: number;
  accountHolderType?: string;
  currency?: string;
  country?: string;
  paypalEmailId?: string;
  adminType?: AdminType;
}

interface IAddTInstructorPaymentProcessorProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
  payload: {
    paymentProcessor?: string;
    stripeConenct?: string;
    paypalEmailId?: string;
    adminType?: AdminType;
  };
  userId?: string;
  allowedAdminType: AdminType[];
}

const AddInstructorPaymentProcessor: FC<
  IAddTInstructorPaymentProcessorProps
> = ({
  children,
  successCallback,
  errorCallback,
  payload,
  userId,
  allowedAdminType,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<IPaymentProcessor>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: IPaymentProcessor) => {
    setRequesting(true);
    try {
      const paymentProcessor = values['paymentProcessor'];
      let payload;
      if (paymentProcessor === 'stripe') {
        if (values?.haveStripeId) {
          payload = {
            paymentProcessor,
            stripeId: values?.stripeId,
          };
        } else {
          payload = {
            paymentProcessor,
            country: values?.country,
            currency: values?.currency,
            accountHolderName: values?.accountHolderName,
            accountHolderType: values?.accountHolderType,
            accountNumber: values?.accountNumber,
            ...(values?.routingNumber && {
              routingNumber: values?.routingNumber,
            }),
          };
        }
      }
      if (paymentProcessor === 'paypal') {
        payload = {
          paymentProcessor,
          paypalEmailId: values?.paypalEmailId,
        };
      }
      if (paymentProcessor === 'ebanx') {
        payload = {
          paymentProcessor,
        };
      }

      await updateInstructorPaymentProcessor(payload, userId);
      setDrawerVisible(false);
      message.success('Payment Processor Updated successfully');
      form.reset();
      if (successCallback) successCallback();
    } catch (e) {
      if (errorCallback) errorCallback(e as Error);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    form.reset({
      paymentProcessor: payload?.paymentProcessor,
      haveStripeId: payload?.stripeConenct
        ? !!payload?.stripeConenct
        : undefined,
      stripeId: payload?.stripeConenct,
      paypalEmailId: payload?.paypalEmailId,
      adminType: payload.adminType,
    });
  }, [payload]);

  return (
    <>
      <Drawer
        title="Update Admin Payment Details"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddInstructorPaymentProcessorForm
            form={form}
            allowedAdminType={allowedAdminType}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddInstructorPaymentProcessor;
