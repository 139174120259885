import { INSTRUCTOR, STRIPE } from '../constants/api';
import { WorkshopInstructor } from '../types/instructor';
import { AllowInstructorDetail } from '../types/instructor';
import fetchClient from '../utils/fetch-client';

export const getInstructors = () => {
  return fetchClient.get(INSTRUCTOR.INSTRUCTOR);
};
export const addInstructor = (payload: Partial<WorkshopInstructor>) => {
  return fetchClient.post(INSTRUCTOR.INSTRUCTOR, payload);
};

export const allowInstructorFunc = (
  payload: AllowInstructorDetail,
  instructorId: string | undefined
) => {
  return fetchClient.put(
    `${INSTRUCTOR.UPDATE_PERMISSIONS}/${instructorId}`,
    payload
  );
};

export const updateInstructor = (
  payload: Partial<WorkshopInstructor>,
  instructorId: string
) => {
  return fetchClient.put(`${INSTRUCTOR.INSTRUCTOR}/${instructorId}`, payload);
};
export const deleteInstructor = (instructorId: string | undefined) => {
  return fetchClient.delete(`${INSTRUCTOR.INSTRUCTOR}/${instructorId}`);
};

export interface IStripeUpdate {
  stripeConnect: string;
  return_url: string;
  refresh_url: string;
}
export const stripeUpdate = (payload: IStripeUpdate) => {
  return fetchClient.post(STRIPE.ACCOUNT_UPDATE, payload);
};

export const updateInstructorPaymentProcessor = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any,
  instructorId: string | undefined
) => {
  return fetchClient.put(
    `${INSTRUCTOR.UPDATE_PAYMENT_PROCESSOR}/${instructorId}`,
    payload
  );
};
