import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  ReloadOutlined,
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { IGiftVoucherProduct } from '../../../types/gift-voucher';
import { getGiftVoucherProduct } from '../../../services/gift-voucher';
import AddProduct from '../../../components/gift-voucher/products/add-products';
import EditProduct from '../../../components/gift-voucher/products/edit-products';
import DeleteProduct from '../../../components/gift-voucher/products/delete-products';

import columns from './columns';

const GiftVoucherProducts = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadProduct, setReloadProduct] = useState(true);
  const [product, setProduct] = useState<IGiftVoucherProduct[]>([]);

  const loadProduct = async () => {
    setRequesting(true);
    try {
      const response = await getGiftVoucherProduct();
      setProduct(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadProduct(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadProduct) {
      loadProduct();
    }
  }, [reloadProduct, requesting]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      render: (value: string, record: IGiftVoucherProduct) => {
        return (
          <Space size="large">
            <EditProduct
              productId={record._id as string}
              successCallback={() => setReloadProduct(true)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditProduct>

            <DeleteProduct
              productId={record._id as string}
              successCallback={() => setReloadProduct(true)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteProduct>
          </Space>
        );
      },
      key: 'actions',
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={product}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>
                Gift Voucher Products
              </Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadProduct(true)}
                  />
                </Tooltip>
                <AddProduct successCallback={() => setReloadProduct(true)}>
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Product
                    </Button>
                  )}
                </AddProduct>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};

export default GiftVoucherProducts;
