import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { TextAreaField } from '../common/form';

interface IAddCertificationRenewalReivewFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, object>;
}
const CertificationRenewalReviewForm = ({
  form,
}: IAddCertificationRenewalReivewFormProps) => {
  return (
    <>
      <Form layout="vertical">
        <TextAreaField
          label="Remarks"
          name="remarks"
          textAreaProps={{
            placeholder: 'Type Remarks',
            rows: 5,
          }}
          form={form}
        />
      </Form>
    </>
  );
};

export default CertificationRenewalReviewForm;
