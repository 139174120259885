import { Col, Form } from 'antd';
// import { TreeSelect } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { Blog } from '../../types/blog';
import { FileUploadButton, InputField, SelectField } from '../common/form';
import { BlogCategory } from '../../types/blog-categories';
import CKEditorWrapper from '../ckeditor';
import SlugNote from '../common/slug-note';
// import { useState } from 'react';

export type BlogResArr = {
  value: string;
  label: string;
};
interface IBlogFormProps {
  form: UseFormReturn<Blog, object>;
  isEditable: boolean;
  category: string;
  categories: BlogCategory[];
}
const BlogForm = ({
  form,
  categories,
  isEditable,
  category,
}: IBlogFormProps) => {
  const values = form.getValues();

  return (
    <>
      <Form layout="vertical">
        <Col>
          <InputField
            label="Slug"
            name="slug"
            required
            disabled={isEditable === true ? true : false}
            inputProps={{
              placeholder: 'Slug : eg(blog-slug)',
            }}
            form={form}
            style={{ marginBottom: '4px' }}
          />
          <SlugNote />
        </Col>

        <SelectField
          name="category"
          label="Category"
          form={form}
          labelKey="label"
          valueKey="value"
          required
          selectFieldProps={{
            options: categories.map((category: BlogCategory) => {
              return {
                label: category.name.en,
                value: category.slug,
              };
            }),
            placeholder: 'Please Select Category',
            style: {
              width: '100%',
            },
          }}
        />

        <InputField
          label="Title (EN)"
          name="title.en"
          required
          inputProps={{
            placeholder: 'WARM UP',
          }}
          form={form}
        />
        <InputField
          label="Title (PT)"
          name="title.pt"
          required
          inputProps={{
            placeholder: 'AQUECER',
          }}
          form={form}
        />
        <InputField
          label="Title (IT)"
          name="title.it"
          required
          inputProps={{
            placeholder: 'RISCALDARE',
          }}
          form={form}
        />
        <InputField
          label="Title (ES)"
          name="title.es"
          required
          inputProps={{
            placeholder: 'CALENTAMIENTO',
          }}
          form={form}
        />
        <InputField
          label="Title (DE)"
          name="title.de"
          required
          inputProps={{
            placeholder: 'AUFWARMEN',
          }}
          form={form}
        />
        <InputField
          label="Title (ZH)"
          name="title.zh"
          required
          inputProps={{
            placeholder: '暖身',
          }}
          form={form}
        />

        <InputField
          label="Opening Para (EN)"
          name="openingPara.en"
          required
          inputProps={{
            placeholder: 'Enter Opening Para',
          }}
          form={form}
        />
        <InputField
          label="Opening Para (PT)"
          name="openingPara.pt"
          required
          inputProps={{
            placeholder: 'AQUECER',
          }}
          form={form}
        />
        <InputField
          label="Opening Para (IT)"
          name="openingPara.it"
          required
          inputProps={{
            placeholder: 'RISCALDARE',
          }}
          form={form}
        />
        <InputField
          label="Opening Para (ES)"
          name="openingPara.es"
          required
          inputProps={{
            placeholder: 'CALENTAMIENTO',
          }}
          form={form}
        />
        <InputField
          label="Opening Para (DE)"
          name="openingPara.de"
          required
          inputProps={{
            placeholder: 'AUFWARMEN',
          }}
          form={form}
        />
        <InputField
          label="Opening Para (ZH)"
          name="openingPara.zh"
          required
          inputProps={{
            placeholder: '暖身',
          }}
          form={form}
        />
        {/* <InputField
          label="Sequence"
          name="sequenceNo"
          required
          inputProps={{
            placeholder: '1',
          }}
          form={form}
        /> */}
        <FileUploadButton
          form={form}
          triggerValidate={() => form.trigger('heroImage')}
          defaultFileList={
            isEditable
              ? [
                  {
                    uid: '-1',
                    name: `${values?.slug}.png`,
                    status: 'done',
                    url: form.watch('heroImageUrl') as string,
                  },
                ]
              : []
          }
          required
          label="Hero Image"
          name="heroImage"
          buttonLabel="Click to Upload Image"
        />

        <CKEditorWrapper
          name="htmlContent.en"
          label="Blog Content (EN)"
          required
          form={form}
        />

        <CKEditorWrapper
          name="htmlContent.pt"
          label="Blog Content (PT)"
          required
          form={form}
        />
        <CKEditorWrapper
          name="htmlContent.it"
          label="Blog Content (IT)"
          required
          form={form}
        />
        <CKEditorWrapper
          name="htmlContent.es"
          label="Blog Content (ES)"
          required
          form={form}
        />
        <CKEditorWrapper
          name="htmlContent.de"
          label="Blog Content (DE)"
          required
          form={form}
        />
        <CKEditorWrapper
          name="htmlContent.zh"
          label="Blog Content (ZH)"
          required
          form={form}
        />
      </Form>
    </>
  );
};

export default BlogForm;
