/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
  // Pagination,
  Select,
  Switch,
  Dropdown,
} from 'antd';
import { useEffect, useState } from 'react';
import { ReloadOutlined, EllipsisOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import './style.scss';

import { Workshop } from '../../types/workshop';
import {
  getWorkshops,
  toggleWorkshopVisibility,
} from '../../services/workshop-service';
import { getInstructors } from '../../services/instructor-service';
import DeleteWorkshop from '../../components/workshops/delete-workshop';
import { WorkshopInstructor } from '../../types/instructor';
import UploadWorkshopPhotos from '../../components/workshops/upload-workshop-photos';
import UpdateAttendence from '../../components/manage-workshop/update-attendence';
import WorkshopIncome from '../../components/workshops/workshop-income';
import { useAuthState } from '../../context/auth-state';
import { handleError } from '../../utils/common';
import { AdminType } from '../../services/users-service';
import ConcludeWorkshop from '../../components/workshops/conclude-workshop';
import ViewWorkshop from '../../components/workshops/view-launched-workshop';

import columns from './columns';

type WorkshopStatus = 'open' | 'closed' | 'upcoming';

const Workshops: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuthState();

  const [searchParam] = useSearchParams();

  const workshopSlugQuery = searchParam.get('workshopSlug');

  const [requesting, setRequesting] = useState(false);
  const [reloadWorkshops, setReloadWorkshops] = useState(true);

  const queryParams = new URLSearchParams(location.search);

  const statusParam = queryParams.get('status');
  const status: WorkshopStatus =
    statusParam === 'open' ||
    statusParam === 'closed' ||
    statusParam === 'upcoming'
      ? statusParam
      : 'upcoming';
  const region = queryParams.get('region') || 'all';
  const createdBy = queryParams.get('createdBy') || 'all';

  const [workshops, setWorkshops] = useState<Workshop[]>([]);
  const [launchedWorkshop, setLaunchedWorkshop] = useState<Workshop>();

  const [instructor, setInstructor] =
    useState<{ value?: string; label: string }[]>();

  const editWorkshop = (workshop_id: string) => {
    // eslint-disable-next-line no-restricted-globals
    const data = confirm('Want to edit the details of the workshop?');
    if (data) {
      navigate(`/edit-workshop/${workshop_id}`);
    }
  };

  const handleManageWorkshop = (workshopSlug: string) => {
    navigate(`/workshop-attendees/${workshopSlug}`);
  };

  const handleDiscountCoupons = (workshopSlug: string) => {
    navigate(`/manage-discount-coupons?workshopSlug=${workshopSlug}`);
  };

  const loadWorkshops = async () => {
    setRequesting(true);
    try {
      const response = await getWorkshops({
        status: status,
        createdBy,
        region: region,
      });
      setWorkshops(response?.data?.data);
      navigate({
        pathname: location.pathname,
        search: `?status=${status}&region=${region}&createdBy=${createdBy}`,
      });
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
      setReloadWorkshops(false);
    }
  };

  const getInst = async () => {
    setRequesting(true);
    try {
      const Instres = await getInstructors();
      const allInst: { value?: string; label: string }[] = [
        { value: 'all', label: 'All Instructor' },
      ];
      Instres?.data?.data
        ?.filter((i: WorkshopInstructor) =>
          user?.assignedInstructor?.includes(i?._id?.toString())
        )
        ?.map((inst: WorkshopInstructor) => {
          allInst.push({
            value: inst?._id,
            label: inst?.fullName,
          });
        });
      if (allInst) setInstructor(allInst);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  const handleHideWorkshop = async (value: boolean, id: string) => {
    setRequesting(true);
    try {
      await toggleWorkshopVisibility({ isHidden: value }, id);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
      setReloadWorkshops(true);
    }
  };

  useEffect(() => {
    if (!requesting && reloadWorkshops) {
      loadWorkshops();
    }
  }, [reloadWorkshops]);

  useEffect(() => {
    loadWorkshops();
  }, [status, createdBy, region]);

  useEffect(() => {
    getInst();
  }, [user]);

  useEffect(() => {
    const getLaunchedWorkshop = workshops.filter(
      (workshop: Workshop) => workshop.slug === workshopSlugQuery
    )[0];
    setLaunchedWorkshop(getLaunchedWorkshop);
  }, [workshopSlugQuery, workshops]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Hide Workshop',
      key: 'isHidden',
      width: 150,
      render: (value: string, record: Workshop) => {
        return (
          <Space style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip
              title={
                record?.isHidden
                  ? 'Would you like to public this workshop ?'
                  : 'Would you like to hide this workshop ?'
              }
              placement="right"
            >
              <Switch
                size="small"
                defaultChecked={record?.isHidden || false}
                onChange={async (e) => {
                  await handleHideWorkshop(e, record?.slug);
                }}
                disabled={
                  !(
                    user?.adminType === AdminType.root ||
                    user?._id === record?.workshopOwner
                  )
                }
              />
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: 'Actions',
      width: 150,
      render: (record: Workshop) => (
        <Space>
          <Dropdown
            menu={{
              items: [
                {
                  key: 'edit-workshop',
                  label: <p className="no-margin">Edit Workshop</p>,
                  onClick: () => editWorkshop(record?.slug),
                  disabled: moment.now() > moment(record?.startDate).valueOf(),
                },
                {
                  key: 'manage-workshop',
                  label: <p className="no-margin">Manage Workshop</p>,
                  onClick: () => handleManageWorkshop(record?.slug),
                },
                {
                  key: 'discount-coupons-workshop',
                  label: <p className="no-margin">Discount Coupons</p>,
                  onClick: () => handleDiscountCoupons(record?.slug),
                },
                {
                  key: 'workshop-income',
                  label: (
                    <WorkshopIncome workshopSlug={record?.slug}>
                      {({ onClick }) => (
                        <p
                          className="no-margin"
                          onClick={() => {
                            if (
                              user?._id === record?.workshopOwner ||
                              user?.adminType === AdminType.root
                            ) {
                              onClick();
                            }
                          }}
                        >
                          Workshop income
                        </p>
                      )}
                    </WorkshopIncome>
                  ),
                  disabled: !(
                    user?._id === record?.workshopOwner ||
                    user?.adminType === AdminType.root
                  ),
                },
                {
                  type: 'divider',
                },
                {
                  key: 'upload-photos',
                  label: (
                    <UploadWorkshopPhotos
                      workshop={record}
                      successCallback={() => setReloadWorkshops(true)}
                      errorCallback={(e) => handleError(e)}
                    >
                      {({ onClick }) => (
                        <p
                          className="no-margin"
                          onClick={() => {
                            if (
                              moment.now() > moment(record?.startDate).valueOf()
                            ) {
                              onClick();
                            }
                          }}
                        >
                          Upload Photos
                        </p>
                      )}
                    </UploadWorkshopPhotos>
                  ),
                  disabled: !(
                    moment.now() > moment(record?.startDate).valueOf()
                  ),
                },
                {
                  key: 'update-attendence',
                  label: (
                    <UpdateAttendence
                      workshopSlug={record.slug}
                      successCallback={() => setReloadWorkshops(true)}
                      errorCallback={(e) => handleError(e)}
                    >
                      {({ onClick }) => (
                        <p
                          className="no-margin"
                          onClick={() => {
                            if (
                              moment.now() >
                              moment(record?.ticketEndDate).valueOf()
                            ) {
                              onClick();
                            }
                          }}
                        >
                          Update Attendence
                        </p>
                      )}
                    </UpdateAttendence>
                  ),
                  disabled: !(
                    moment.now() > moment(record?.ticketEndDate).valueOf()
                  ),
                },
                {
                  key: 'conclude-workshop',
                  label: (
                    <ConcludeWorkshop
                      workshopSlug={record?.slug}
                      successCallback={() => setReloadWorkshops(true)}
                    >
                      {({ onClick }) => (
                        <p
                          className="no-margin"
                          onClick={() => {
                            if (
                              moment.now() > moment(record?.startDate).valueOf()
                            ) {
                              onClick();
                            }
                          }}
                        >
                          Conclude workshop
                        </p>
                      )}
                    </ConcludeWorkshop>
                  ),
                  disabled: moment(record?.startDate).valueOf() > moment.now(),
                },
                {
                  type: 'divider',
                },
                {
                  key: 'delete-workshop',
                  label: (
                    <DeleteWorkshop
                      workshopSlug={record.slug}
                      successCallback={() => setReloadWorkshops(true)}
                      errorCallback={handleError}
                    >
                      {({ onClick }) => (
                        <p
                          className="no-margin"
                          onClick={() => {
                            if (
                              record?.closedTickets === 0 &&
                              (user?._id === record?.workshopOwner ||
                                user?.adminType === AdminType.root)
                            ) {
                              onClick();
                            }
                          }}
                        >
                          Delete Workshop
                        </p>
                      )}
                    </DeleteWorkshop>
                  ),
                  danger: true,
                  disabled:
                    record?.closedTickets > 0 ||
                    !(
                      user?._id === record?.workshopOwner ||
                      user?.adminType === AdminType.root
                    ),
                },
              ],
            }}
            arrow
            trigger={['click']}
            placement="bottomRight"
            className="workshop-dropdown"
            overlayClassName="workshop-dropdown-overlay"
          >
            <EllipsisOutlined />
          </Dropdown>
        </Space>
      ),
      key: 'actions',
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={workshops}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Workshops</Typography.Title>
              <Space>
                <Select
                  defaultValue={status}
                  style={{ width: 200 }}
                  onChange={(e: WorkshopStatus) =>
                    navigate({
                      pathname: location.pathname,
                      search: `?status=${e}&region=${region}&createdBy=${createdBy}`,
                    })
                  }
                  options={[
                    { label: 'Open Workshops', value: 'open' },
                    { label: 'Closed Workshops', value: 'closed' },
                    { label: 'Upcoming Workshops', value: 'upcoming' },
                  ]}
                />
                <Select
                  defaultValue={region}
                  style={{ width: 200 }}
                  onChange={(e) =>
                    navigate({
                      pathname: location.pathname,
                      search: `?status=${status}&region=${e}&createdBy=${createdBy}`,
                    })
                  }
                  options={[
                    { label: 'All Regions', value: 'all' },
                    { label: 'UK / Europe', value: 'uk-and-europe' },
                    { label: 'Latin America', value: 'latin-america' },
                    { label: 'USA / Canada', value: 'usa-and-canada' },
                    { label: 'China', value: 'china' },
                    { label: 'Asia', value: 'asia' },
                    {
                      label: 'Australia / New Zealand',
                      value: 'australia-and-new-zealand',
                    },
                    { label: 'Others', value: 'others' },
                  ]}
                />
                {user &&
                  (user?.adminType === 'root' ||
                    user?.adminType === 'workshop') && (
                    <Select
                      defaultValue={createdBy}
                      style={{ width: 200 }}
                      onChange={(e) =>
                        navigate({
                          pathname: location.pathname,
                          search: `?status=${status}&region=${region}&createdBy=${e}`,
                        })
                      }
                      options={instructor}
                      value={createdBy}
                    />
                  )}
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadWorkshops(true)}
                  />
                </Tooltip>
              </Space>
            </div>
          );
        }}
      />

      {workshopSlugQuery && !requesting && (
        <ViewWorkshop workshop={launchedWorkshop} />
      )}
    </Spin>
  );
};

export default Workshops;
