import * as yup from 'yup';

export default yup.object().shape({
  que: yup.object().shape({
    en: yup.string().required('Please input question in english'),
    es: yup.string().required('Please input question in spanish'),
    it: yup.string().required('Please input question in Italian'),
    de: yup.string().required('Please input question in German'),
    pt: yup.string().required('Please input question in Portuguese'),
    zh: yup.string().required('Please input question in Mandarin'),
  }),
  ans: yup.object().shape({
    en: yup.string().required('Please input answer in english'),
    es: yup.string().required('Please input answer in spanish'),
    it: yup.string().required('Please input answer in Italian'),
    de: yup.string().required('Please input answer in German'),
    pt: yup.string().required('Please input answer in Portuguese'),
    zh: yup.string().required('Please input answer in Mandarin'),
  }),
  slug: yup.string().required('Please select Faq Category'),
});
