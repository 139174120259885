import { NOT_AVAILABLE } from '../../constants/common';
import { IEmailAttachments } from '../../types/email-attachment';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: IEmailAttachments) =>
      record?.name || NOT_AVAILABLE,
    width: 400,
  },
  {
    title: 'Description',
    render: (value: string, record: IEmailAttachments) =>
      record?.description || NOT_AVAILABLE,
    width: 400,
  },
];

export default columns;
