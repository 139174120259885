import { CERTIFICATE_RESPONSE } from '../constants/api';
import fetchClient from '../utils/fetch-client';

interface addReivewProps {
  assignedCertificationCode: string[];
  assignedCertificationLevel: Record<string, number>;
  responseId: string;
  isApproved: boolean;
  remarks: string;
}

export const getUploadCertificatesResponse = ({
  page = 1,
  limit = 10,
  approvedCertificates,
}: {
  page: number;
  limit?: number;
  approvedCertificates?: boolean;
}) => {
  return fetchClient.get(
    `${CERTIFICATE_RESPONSE.UPLOAD}/all?page=${page}&limit=${limit}&approvedCertificates=${approvedCertificates}`
  );
};

export const addReivewToUploadedCertificate = (payload: addReivewProps) => {
  return fetchClient.post(`${CERTIFICATE_RESPONSE.UPLOAD}/add-review`, payload);
};

export const getAssignees = () => {
  return fetchClient.get(`${CERTIFICATE_RESPONSE.UPLOAD}/assignees`);
};
export const addAssignee = (payload: {
  responseId: string;
  assigneeId: string;
}) => {
  return fetchClient.post(
    `${CERTIFICATE_RESPONSE.UPLOAD}/add-assignee`,
    payload
  );
};
