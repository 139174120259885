import { createElement, FC, useRef, useState } from 'react';
import { Button, Drawer, message } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ExerciseEquipmentForm from '../form';
import validationSchema from '../validation-schema';
import { updateExerciseEquipment } from '../../../services/exercise-equipment-service';
import { ExerciseEquipment } from '../../../types/exercise-equipment';
import { isHttp } from '../../../utils/common';

interface IEditExerciseEquipmentProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  payload: ExerciseEquipment;
  equipmentId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditExerciseEquipment: FC<IEditExerciseEquipmentProps> = ({
  children,
  payload,
  equipmentId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<ExerciseEquipment>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: payload,
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (data: ExerciseEquipment) => {
    const { imageUrl1, ...rest } = data;
    const payload = {
      _id: equipmentId,
      ...rest,
      ...(imageUrl1 && !isHttp(imageUrl1) && { imageUrl1 }),
    };

    setRequesting(true);
    try {
      await updateExerciseEquipment(payload);
      message.success('Exercise equipment updated successfully');
      handleDrawerVisibility();
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Edit Exercise Equipment"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
          >
            Update
          </Button>
        }
      >
        <div style={{ padding: 16 }}>
          <ExerciseEquipmentForm form={form} isEditable />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditExerciseEquipment;
