import { Button, message, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import type { PaginationProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { ICertificationRenewalResponse } from '../../types/certifciation-renewal-response';
import { IAssignee } from '../../types/assesment-response';
import {
  getAssignees,
  getCertificationRenewalResponses,
} from '../../services/certificate-renewal-response-service';
import AddCertificationRenewalReivew from '../../components/certification-renewal-response-review';

import columns from './columns';
import AssigneeModel from './assignee-model';

interface certficationRenewalResponsesProps {
  totalDocuments: number;
  totalPages: number;
  currentPage: string;
  certficationRenewalResponses: ICertificationRenewalResponse[];
}

const CertificationRenewalResponses = () => {
  const [requesting, setRequesting] = useState(false);
  const [loadingAssignees, setLoadingAssignees] = useState(false);
  const [assignees, setAssignees] = useState<IAssignee[]>();
  const [
    reloadCertficationRenewalResponses,
    setReloadCertficationRenewalResponses,
  ] = useState(true);
  const [certficationRenewalResponses, setCertficationRenewalResponses] =
    useState<certficationRenewalResponsesProps>({
      totalDocuments: 0,
      totalPages: 0,
      currentPage: '1',
      certficationRenewalResponses: [],
    });
  const [activeResponseId, setActiveResponseId] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const currentURL = location?.pathname || '';

  const page = queryParams.get('page')
    ? parseInt(queryParams.get('page') || '')
    : 1;
  const limit = queryParams.get('limit')
    ? parseInt(queryParams.get('limit') || '')
    : 10;

  const loadCertficationRenewalResponses = async () => {
    setRequesting(true);
    try {
      const response = await getCertificationRenewalResponses({
        page: page,
        ...(limit && { limit }),
        approvedResponses: currentURL?.includes('approved'),
      });
      setCertficationRenewalResponses(response?.data?.data);
    } catch (e) {
      message.error('Something went wrong');
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadCertficationRenewalResponses(false);
    }
  };

  const removeQueryParam = () => {
    setActiveResponseId('');
    navigate({
      pathname: location.pathname,
    });
  };

  const loadAssignees = async () => {
    setLoadingAssignees(true);
    try {
      const response = await getAssignees();
      setAssignees(response.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingAssignees(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadCertficationRenewalResponses)
      loadCertficationRenewalResponses();
  }, [reloadCertficationRenewalResponses, requesting]);

  useEffect(() => {
    setReloadCertficationRenewalResponses(true);
  }, [page, limit, location.pathname]);

  useEffect(() => {
    const activeResponseId = queryParams.get('id');
    if (activeResponseId) setActiveResponseId(activeResponseId as string);
  }, []);

  useEffect(() => {
    loadAssignees();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Assignee',
      render: (value: string, record: ICertificationRenewalResponse) => {
        return (
          <AssigneeModel
            assignees={assignees}
            loadingAssignees={loadingAssignees}
            response={record}
            successCallback={() => setReloadCertficationRenewalResponses(true)}
          />
        );
      },
      width: 200,
    },
    {
      title: 'Action',
      render: (value: string, record: ICertificationRenewalResponse) => {
        return (
          <>
            <AddCertificationRenewalReivew
              payload={record}
              activeResponseId={activeResponseId}
              successCallback={() => {
                removeQueryParam();
                setReloadCertficationRenewalResponses(true);
              }}
              errorCallback={(e) => message.error(e.message)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </AddCertificationRenewalReivew>
          </>
        );
      },
      width: 200,
    },
  ];

  const handleTableChange: PaginationProps['onChange'] = (pagination, size) => {
    setReloadCertficationRenewalResponses(true);
    navigate({
      pathname: location.pathname,
      search: `?page=${pagination}&limit=${size}`,
    });
  };

  return (
    <Spin spinning={requesting}>
      <Table
        key={JSON.stringify(certficationRenewalResponses)}
        dataSource={certficationRenewalResponses?.certficationRenewalResponses}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={{
          position: ['bottomRight'],
          total: certficationRenewalResponses?.totalDocuments,
          defaultCurrent: page,
          defaultPageSize: limit,
          onChange: handleTableChange,
          showSizeChanger: true,
        }}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>
                {currentURL?.includes('approved')
                  ? 'Approved Certification Renewal Responses'
                  : 'Certification Renewal Responses'}
              </Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadCertficationRenewalResponses(true)}
                  />
                </Tooltip>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};

export default CertificationRenewalResponses;
