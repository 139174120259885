import { SECTIONS } from '../constants/api';
import { SectionDetail } from '../types/sections';
import fetchClient from '../utils/fetch-client';

export const getSections = () => {
  return fetchClient.get(`${SECTIONS.SECTIONS}`);
};

export const postSection = (payload: SectionDetail) => {
  return fetchClient.post(`${SECTIONS.SECTIONS}`, payload);
};

export const updateSection = (payload: SectionDetail, id: string) => {
  return fetchClient.put(`${SECTIONS.SECTIONS}/${id}`, payload);
};
export const updateBulkSection = (
  payload: {
    _id: string;
    sequence: number;
  }[]
) => {
  return fetchClient.post(`${SECTIONS.SECTIONS}/update-all`, {
    sections: payload,
  });
};

export const deleteSection = (id?: string) => {
  return fetchClient.delete(`${SECTIONS.SECTIONS}/${id}`);
};
