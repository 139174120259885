import { MuscleCategoryDetail } from '../../../types/muscle';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 200,
  },
  {
    title: 'Slug',
    render: (value: string, record: MuscleCategoryDetail) => record.slug,
  },
  {
    title: 'Name',
    render: (value: string, record: MuscleCategoryDetail) => record?.name?.en,
    width: 800,
  },
];

export default columns;
