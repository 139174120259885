import { Form, Input, Image } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { SectionDetail } from '../../../../types/sections';
import './view.css';

export type SectionsResArr = {
  value: string;
  label: string;
};
interface IAddTemplateFormProps {
  form: UseFormReturn<SectionDetail, object>;
  isEditable: boolean;
}
const ViewForm = ({ form, isEditable = true }: IAddTemplateFormProps) => {
  const values = form.formState.defaultValues;

  return (
    <>
      <Form layout="vertical" className="view">
        {/*  */}
        <label>
          {' '}
          <b> Slug:</b>
        </label>
        <Input value={values?.slug} readOnly />

        <label>
          <b> Section Name:</b>
        </label>
        <Input value={'en : ' + values?.name?.en} readOnly />
        <Input value={'pt : ' + values?.name?.pt} readOnly />
        <Input value={'it : ' + values?.name?.it} readOnly />
        <Input value={'es : ' + values?.name?.es} readOnly />
        <Input value={'de : ' + values?.name?.de} readOnly />
        <Input value={'zh : ' + values?.name?.zh} readOnly />

        <label>
          {' '}
          <b> Age Group:</b>{' '}
        </label>
        <Input value={'min : ' + values?.ageGroup?.min} readOnly />
        <Input value={'max : ' + values?.ageGroup?.max} readOnly />

        <label>
          {' '}
          <b> Sequence: </b>
        </label>
        <Input value={values?.sequence} readOnly />
        <br />
        <br />
        <Form.Item>
          <Image.PreviewGroup>
            <label>
              {' '}
              <b> Tutorial Image: </b>
            </label>
            <Image width={150} src={values?.tutorialPicUrl} />
            <br />
            <br />
            <label>
              {' '}
              <b> Class Plan Image</b>
            </label>
            <Image width={150} src={values?.classPlanPicUrl} />
          </Image.PreviewGroup>
          <br />
          <br />
          <label>
            {' '}
            <b> Is Class Section Too?</b>
          </label>
          {'  '}
          <span>{values?.isClassToo ? 'Yes' : 'No'}</span>
        </Form.Item>
      </Form>
    </>
  );
};

export default ViewForm;
