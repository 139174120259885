import { Drawer } from 'antd';
import { useState, FC, useCallback, createElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  changeBillingCycle,
  TUser,
} from '../../../../../services/users-service';

import ChangeBillingCycleForm, {
  getValidationSchema,
  TChangeBillingCycle,
} from './form';

interface IChangeBillingCycleProps {
  successCallback: (e: boolean) => void;
  errorCallback?: (e: Error) => void;
  billingCycle?: TChangeBillingCycle;
  user?: TUser;
  children: FC<{
    onClick: () => void;
  }>;
}

const ChangeBillingCycle: FC<IChangeBillingCycleProps> = ({
  children,
  billingCycle,
  user,
  successCallback,
  errorCallback,
}) => {
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<TChangeBillingCycle>({
    resolver: yupResolver(getValidationSchema()),
    defaultValues: {
      billingCycle: user?.subscription?.items?.data[0]?.price?.nickname,
    },
    mode: 'all',
  });

  const handleDrawerVisibility = useCallback(() => {
    setDrawerVisible((prev) => !prev);
  }, []);

  const onSubmitHandler = useCallback(
    async (data: TChangeBillingCycle) => {
      setLoading(true);
      try {
        const payload = {
          newPlanId: data.billingCycle,
          userId: user?._id,
        };
        await changeBillingCycle(payload);

        successCallback(true);

        handleDrawerVisibility();
      } catch (e) {
        errorCallback?.(e as Error);
      } finally {
        setLoading(false);
      }
    },
    [
      billingCycle,
      errorCallback,
      form,
      handleDrawerVisibility,
      successCallback,
      user,
    ]
  );

  useEffect(() => {
    form.reset(billingCycle);
  }, [user]);

  return (
    <>
      <Drawer
        title="Change Billing Cycle"
        onClose={() => {
          handleDrawerVisibility();
          form.reset(billingCycle);
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
      >
        <div style={{ padding: 16 }}>
          <ChangeBillingCycleForm
            form={form}
            loading={loading}
            onSubmit={form.handleSubmit(onSubmitHandler)}
            currency={user?.subscription?.currency}
            memberType={user?.memberType}
          />
        </div>
      </Drawer>
      {createElement(children, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default ChangeBillingCycle;
