import moment from 'moment';
import parse from 'html-react-parser';

import { IAttendeesEmail } from '../../../../types/workshop';

const columns = [
  {
    title: 'Date Saved',
    render: (value: string, record: IAttendeesEmail) => (
      <div>{moment(record?.createdAt).format('ll')}</div>
    ),
  },

  {
    title: 'Subject',
    render: (value: string, record: IAttendeesEmail) => (
      <div>{parse(record?.subject)}</div>
    ),
  },
  {
    title: 'Status',
    render: (value: string, record: IAttendeesEmail) => {
      const now = moment().valueOf();
      const scheduleDate = moment(record?.emailScheduleDate).valueOf();

      let status = 'FAILED';
      if (record?.isScheduled) {
        if (now > scheduleDate) {
          status = record?.isEmailSent ? 'SENT' : 'FAILED';
        } else {
          status = 'PENDING';
        }
      } else if (record?.isEmailSent) {
        status = 'SENT';
      }

      return <div>{status}</div>;
    },
  },
];

export default columns;
