import * as yup from 'yup';

export default yup.object().shape({
  subject: yup.string().required('Subject for email is required'),
  body: yup.string().required('Body for email input is required'),
  isScheduled: yup
    .boolean()
    .required('Send Now or Schedule for later details are required'),
  emailScheduleDate: yup.date(),
  recipients: yup.array().min(1).required('Minimum 1 recipient are required'),
});
