import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { TUser } from '../../../../../services/users-service';
import {
  IAllowedSections,
  updateAllowedSections,
} from '../../../../../services/admin-service';
import { useAuthState } from '../../../../../context/auth-state';

import EditAdminForm from './form';

interface IEditAdminProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  children: FC<{
    onClick: () => void;
  }>;
  user: TUser;
}

const EditAllowedSection: FC<IEditAdminProps> = ({
  children,
  successCallback,
  errorCallback,
  user,
}) => {
  const { reloadUser } = useAuthState();
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm();

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: { allowedSections?: string[] }) => {
    setRequesting(true);
    try {
      const payload: IAllowedSections['allowedSections'] = [];

      (values?.allowedSections || []).forEach((i) => {
        const { key, subSection } = JSON.parse(i);
        const index = payload.findIndex((item) => item.key === key);

        if (index !== -1) {
          payload[index].subSections.push({
            key: subSection.key,
            label: subSection?.label,
          });
        } else {
          payload.push({
            key,
            subSections: [{ key: subSection.key, label: subSection?.label }],
          });
        }
      });

      if (user?._id) {
        await updateAllowedSections({
          id: user?._id,
          payload: { allowedSections: payload },
        });
      }
      setDrawerVisible(false);
      message.success('Successfully');
      if (successCallback) {
        successCallback();
      }
      reloadUser();
    } catch (err) {
      console.log(err);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    form.reset({
      allowedSections: user?.allowedSections?.flatMap((section) => {
        return section?.subSections?.map((subSection) => {
          return JSON.stringify({
            key: section?.key,
            subSection: { key: subSection?.key, label: subSection?.label },
          });
        });
      }),
    });
  }, [user?.allowedSections]);

  return (
    <>
      <Drawer
        title="Edit Allowed Section"
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <EditAdminForm form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditAllowedSection;
