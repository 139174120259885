/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { PaginationProps } from 'antd/es/pagination';

import { getGiftVoucherOrders } from '../../../services/gift-voucher';
import { IGiftVoucherOrders } from '../../../types/gift-voucher';

import columns from './columns';

interface orderResponsesProps {
  totalDocuments: number;
  totalPages: number;
  currentPage: string;
  orders: IGiftVoucherOrders[];
}

const GiftVoucherOrders = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadOrders, setReloadOrders] = useState(true);
  const [ordersResponse, setOrdersResponse] = useState<orderResponsesProps>({
    totalDocuments: 0,
    totalPages: 0,
    currentPage: '1',
    orders: [],
  });

  const loadOrders = async ({
    page,
    limit,
  }: {
    page: number;
    limit?: number;
  }) => {
    setRequesting(true);
    try {
      const response = await getGiftVoucherOrders({
        page: page,
        ...(limit && { limit }),
      });
      setOrdersResponse(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadOrders(false);
    }
  };

  const handleTableChange: PaginationProps['onChange'] = (pagination, size) => {
    loadOrders({ page: pagination, limit: size });
  };

  useEffect(() => {
    if (!requesting && reloadOrders) {
      loadOrders({ page: 1 });
    }
  }, [reloadOrders, requesting]);

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={ordersResponse.orders}
        columns={columns}
        rowKey="_id"
        pagination={{
          position: ['bottomRight'],
          total: ordersResponse?.totalDocuments,
          defaultCurrent: 1,
          defaultPageSize: 25,
          onChange: handleTableChange,
          showSizeChanger: true,
        }}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Gift Voucher Orders</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadOrders(true)}
                  />
                </Tooltip>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};

export default GiftVoucherOrders;
