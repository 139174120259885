import { List, Typography, Image } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingOutlined } from '@ant-design/icons';

import { ISentEmailsRecords } from '../../../types/email-records';
import './styles.scss';

const parser = new DOMParser();

interface EmailListProps {
  emailRecords: ISentEmailsRecords[];
  hasMore: boolean;
  selectedEmailId: string | null;
  loadMoreEmails: () => void;
  handleEmailSelect: (email: ISentEmailsRecords) => void;
  pic: string;
  loading: boolean;
}

const { Text, Paragraph } = Typography;

const EmailList: React.FC<EmailListProps> = ({
  emailRecords,
  hasMore,
  selectedEmailId,
  loadMoreEmails,
  handleEmailSelect,
  pic,
  loading,
}) => (
  <InfiniteScroll
    dataLength={emailRecords.length}
    next={loadMoreEmails}
    hasMore={hasMore}
    loader={
      loading ? (
        <div style={{ textAlign: 'center', padding: '10px' }}>
          <LoadingOutlined />
        </div>
      ) : null
    }
    endMessage={
      <div style={{ textAlign: 'center', padding: '10px' }}>
        <Text>No more emails to load</Text>
      </div>
    }
    scrollableTarget="email-list-scroll"
  >
    <List
      id="email-list-scroll"
      itemLayout="horizontal"
      dataSource={emailRecords}
      className="email-scrollbar"
      renderItem={(email) => (
        <List.Item
          onClick={() => handleEmailSelect(email)}
          className={`email-item ${
            selectedEmailId === email._id ? 'selected' : ''
          }`}
        >
          <List.Item.Meta
            avatar={
              <Image src={pic} preview={false} className="email-avatar" />
            }
            title={
              <div className="email-title">
                <Text>{email.recipientEmail}</Text>
                <Text type="secondary">
                  {email?.sentAt ? new Date(email.sentAt).toLocaleString() : ''}
                </Text>
              </div>
            }
            description={
              <>
                <Text strong>{email.subject}</Text>
                <Paragraph ellipsis>
                  {
                    parser.parseFromString(email.body, 'text/html').body
                      .textContent
                  }
                </Paragraph>
              </>
            }
          />
        </List.Item>
      )}
    />
  </InfiniteScroll>
);

export default EmailList;
