import { Button, Drawer, message } from 'antd';
import { createElement, FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AddFaqCategoryForm from '../form';
import validationSchema from '../validation-schema';
import { addFaqCategories } from '../../../../services/faq-services';
import { FaqCategory } from '../../../../types/faq';

interface IAddTFaqCategoryProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const AddFaqCategory: FC<IAddTFaqCategoryProps> = ({
  children,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<FaqCategory>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: FaqCategory) => {
    setRequesting(true);
    await addFaqCategories(values)
      .then(() => {
        setRequesting(false);
        setDrawerVisible(false);
        message.success('Faq Category Added successfully');
        form.reset();
        if (successCallback) {
          successCallback();
        }
      })
      .catch((err) => {
        setRequesting(false);

        if (err.response) {
          message.error(err.response?.data.MESSAGE_KEY);
        } else {
          message.error(err?.message);
        }
        console.log(err);
      });
  };

  return (
    <>
      <Drawer
        title="Add Faq Category"
        onClose={() => {
          handleDrawerVisibility();
          // form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddFaqCategoryForm form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddFaqCategory;
