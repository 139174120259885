import { useEffect, useState } from 'react';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';
import {
  ReloadOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import './index.scss';
import { PlusCircleOutlined } from '@ant-design/icons';

import { handleError } from '../../../utils/common';
import {
  getWorkshopCoupons,
  getWorkshopsBySlug,
} from '../../../services/workshop-service';
import { IWorkshopCoupon, Workshop } from '../../../types/workshop';
import { getCountryByCode } from '../../../constants/countries';

import columns from './columns';
import AddCoupon from './add-coupon';
import EditCoupon from './edit-coupon';
import DeleteCoupon from './delete-coupon';

const WorkshopDiscountCouponManager = () => {
  const [query] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [workshop, setWorkshop] = useState<Workshop | undefined>();
  const [workshopCoupons, setWorkshopCoupons] = useState<
    IWorkshopCoupon[] | undefined
  >();
  const [reloadCoupons, setReloadCoupons] = useState(false);

  const workshopSlug = query?.get('workshopSlug') ?? '';

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 200,
      render: (value: string, record: IWorkshopCoupon) => {
        return (
          <Space size="large">
            {record.active && (
              <EditCoupon
                id={record.promotionCodeId}
                successCallback={() => setReloadCoupons(true)}
              >
                {({ onClick }) => <EditOutlined onClick={onClick} />}
              </EditCoupon>
            )}
            <DeleteCoupon
              id={record.couponId}
              successCallback={() => setReloadCoupons(true)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteCoupon>
          </Space>
        );
      },
    },
  ];

  const getWorkshop = async (workshopSlug: string) => {
    setLoading(true);
    try {
      const response = await getWorkshopsBySlug(workshopSlug);
      setWorkshop(response?.data?.data?.workshop);
      //get coupons---
      const couponsRes = await getWorkshopCoupons(workshopSlug);
      setWorkshopCoupons(couponsRes?.data?.data);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };
  const getCoupons = async () => {
    setLoading(true);
    try {
      const couponsRes = await getWorkshopCoupons(workshopSlug);
      setWorkshopCoupons(couponsRes?.data?.data);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
      setReloadCoupons(false);
    }
  };

  useEffect(() => {
    if (workshopSlug) {
      getWorkshop(workshopSlug);
    }
  }, []);

  useEffect(() => {
    if (!loading && reloadCoupons) {
      getCoupons();
    }
  }, [reloadCoupons, loading]);

  return (
    <Table
      loading={loading}
      title={() => {
        return (
          <div className="discount-heading">
            <div>
              <Typography.Title level={3}>
                Discount Coupons - {workshop?.title}{' '}
                <small>L{workshop?.level} </small>
              </Typography.Title>
              <div>
                <p>
                  {workshop?.workshopMode === 'virtual'
                    ? 'Online'
                    : workshop?.city}
                  {workshop?.country &&
                    `, ${getCountryByCode(workshop?.country)?.name}`}
                </p>
                <br />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '0.5rem',
              }}
            >
              <Tooltip title="Refresh" placement="left">
                <Button
                  type="ghost"
                  icon={<ReloadOutlined />}
                  onClick={() => setReloadCoupons(true)}
                />
              </Tooltip>
              <AddCoupon
                workshopSlug={workshopSlug}
                successCallback={() => setReloadCoupons(true)}
              >
                {({ onClick }) => (
                  <Button
                    onClick={onClick}
                    type="primary"
                    icon={<PlusCircleOutlined />}
                  >
                    Add Coupon
                  </Button>
                )}
              </AddCoupon>
            </div>
          </div>
        );
      }}
      dataSource={workshopCoupons}
      columns={columnsWithActions}
    />
  );
};

export default WorkshopDiscountCouponManager;
