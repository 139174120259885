import { FC, createElement, useEffect, useRef, useState } from 'react';
import { Button, Drawer, Form, message } from 'antd';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import {
  TransferType,
  Workshop,
  WorkshopAttendeeList,
  WorkshopAttendeeWithUserDetail,
} from '../../types/workshop';
import { handleError } from '../../utils/common';
import { InputField, SelectField } from '../../components/common/form';
import {
  getWorkshopsForTransfer,
  transferWorkshopAttendee,
  transferWorkshopPosition,
} from '../../services/workshop-service';

interface TransferAttendeeProps {
  attendee: WorkshopAttendeeWithUserDetail;
  workshop?: WorkshopAttendeeList;
  setReloadWorkshopAttendees: React.Dispatch<React.SetStateAction<boolean>>;
  children: FC<{
    onClick: () => void;
  }>;
}

interface TransferAttendeeFormField {
  workshopSlug?: string;
  transferTo: string;
  note: string;
  transferToName?: string;
  transferToEmail?: string;
}

const TransferState = {
  NOT_REQUESTED: 'Not Requested',
  REQUESTED: 'Requested',
  REQUEST_ACCEPTED_PENDING: 'Request Pending',
  REQUEST_ACCEPTED_COMPLETED: 'Request Completed',
  REQUEST_DECLINED: 'Request Declined',
};

enum transferToState {
  'newWorkshop' = 'newWorkshop',
  'creditList' = 'creditList',
  'anotherPerson' = 'anotherPerson',
}

const validationSchema = yup.object().shape({
  note: yup.string(),
  transferTo: yup
    .string()
    .oneOf(['newWorkshop', 'creditList', 'anotherPerson'])
    .required('required '),
  transferToName: yup.string().when('transferTo', {
    is: 'anotherPerson',
    then: yup.string().required('Transfer To Full Name is required'),
    otherwise: (schema) => schema,
  }),
  transferToEmail: yup.string().when('transferTo', {
    is: 'anotherPerson',
    then: yup.string().required('Transfer To Email is required'),
    otherwise: (schema) => schema,
  }),
  workshopSlug: yup.string().when('transferTo', {
    is: 'newWorkshop',
    then: (schema) => schema.required('required'),
    otherwise: (schema) => schema,
  }),
});

const TransferAttendee: FC<TransferAttendeeProps> = ({
  attendee,
  setReloadWorkshopAttendees,
  children,
  workshop,
}) => {
  const refChildren = useRef(children);

  const [open, setOpen] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [loading, setLoading] = useState(false);

  const [workshops, setWorkshops] = useState<Workshop[]>([]);

  const form = useForm<TransferAttendeeFormField>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const isRequestSubmitted =
    attendee?.transferStatus !== 'NOT_REQUESTED' &&
    attendee?.transferStatus === 'REQUEST_ACCEPTED_PENDING';

  const transferTo = form.watch('transferTo');
  const workshopSlug = form.watch('workshopSlug');

  const handleTransfer = async (value: TransferAttendeeFormField) => {
    setRequesting(true);
    try {
      if (transferTo === 'anotherPerson') {
        await transferWorkshopPosition({
          attendeeId: attendee?._id,
          payload: {
            note: value?.note,
            transferToName: value?.transferToName || '',
            transferToEmail: value?.transferToEmail || '',
          },
        });
        message.success('Transfer Processed Successfully');
      } else {
        await transferWorkshopAttendee({
          attendeeId: attendee?._id,
          payload: value,
        });
        message.success('Transfered Successfully');
      }
      setOpen(false);
      setReloadWorkshopAttendees(true);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  const loadWorkshops = async () => {
    setLoading(true);
    try {
      const response = await getWorkshopsForTransfer();
      setWorkshops(response?.data?.data);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) loadWorkshops();
  }, [open]);

  useEffect(() => {
    if (!open) return;

    const payload = attendee
      ? {
          note: attendee?.note,
          ...(attendee?.transferType ===
            TransferType.TRANSFER_TO_ANOTHER_PERSON &&
            attendee?.transferTo?.email && {
              transferTo: 'anotherPerson',
              transferToEmail: attendee.transferTo.email,
              transferToName: attendee.transferTo.name,
            }),
        }
      : {};

    form.reset(payload);

    return () => form.reset({});
  }, [attendee, open]);

  return (
    <>
      <Drawer
        open={open}
        onClose={() => {
          form.reset({});
          setOpen(false);
        }}
        destroyOnClose
        title="Manage Attendee Transfer"
        width={700}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(handleTransfer)}
              disabled={isRequestSubmitted}
            >
              {isRequestSubmitted ? 'Submitted' : 'Submit'}
            </Button>
          </>
        }
      >
        <div>
          <h2 style={{ fontWeight: '600', marginBottom: '0px' }}>
            {workshop?.title}
          </h2>
          <p style={{ marginBottom: '2px' }}>
            <span>
              {workshop?.workshopMode === 'virtual' ? 'Online' : workshop?.city}
            </span>
            <span>{`, ` + workshop?.country}</span>
          </p>
          <p style={{ marginBottom: '10px' }}>
            <span>
              {moment(workshop?.startDate)
                .tz(workshop?.timezone || 'UTC')
                .format('MMM DD, YYYY h:mm a')}
            </span>
            <span> ( {workshop?.timezone} )</span>
          </p>
          <hr />
          <p className="no-margin" style={{ fontSize: '18px' }}>
            Transfer Status:{' '}
            <strong>{TransferState?.[attendee?.transferStatus]}</strong>
          </p>
          {attendee?.transferType && (
            <p className="no-margin" style={{ fontSize: '18px' }}>
              Request Type: <strong>{attendee?.transferType}</strong>
            </p>
          )}
          <hr />
          <div>
            <p style={{ fontSize: '18px' }}>
              Transfer{' '}
              <strong className="attendee-name">{attendee?.fullName}</strong>{' '}
              {form.watch('transferTo') === 'anotherPerson' ? 'position' : ''}{' '}
              to :
            </p>
            <Form>
              <SelectField
                name="transferTo"
                form={form}
                labelKey="label"
                valueKey="value"
                selectFieldProps={{
                  options: [
                    {
                      label: 'To a new workshop',
                      value: 'newWorkshop',
                    },
                    {
                      label: 'To credit list',
                      value: 'creditList',
                    },
                    {
                      label: 'To another person',
                      value: 'anotherPerson',
                    },
                  ],
                  placeholder: 'Select',
                  disabled: isRequestSubmitted,
                  onChange: (v) => {
                    form.reset({ transferTo: v });
                  },
                }}
              />
              {form.getValues('transferTo') === 'anotherPerson' && (
                <>
                  <InputField
                    name="transferToName"
                    form={form}
                    disabled={isRequestSubmitted}
                    inputProps={{
                      placeholder: 'Transfer To Name',
                    }}
                  />
                  <InputField
                    name="transferToEmail"
                    form={form}
                    disabled={isRequestSubmitted}
                    inputProps={{
                      placeholder: 'Transfer To Email',
                    }}
                  />
                </>
              )}
              {transferTo === transferToState.newWorkshop && (
                <SelectField
                  name="workshopSlug"
                  form={form}
                  labelKey="label"
                  valueKey="value"
                  selectFieldProps={{
                    options: workshops
                      ?.filter((i) => i?.slug !== attendee?.workshopSlug)
                      ?.map((workshop) => ({
                        label: (
                          <p
                            className="no-margin"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {workshop?.title} with{' '}
                            {workshop?.workshopInstructor?.fullname} -{' '}
                            {moment(workshop?.startDate)
                              .tz(workshop?.timezone)
                              .format('ll')}
                          </p>
                        ),
                        value: workshop?.slug,
                        loading: loading,
                      })),
                    placeholder: 'Select the event to transfer in',
                  }}
                />
              )}

              <InputField
                name="note"
                form={form}
                disabled={isRequestSubmitted}
                inputProps={{
                  placeholder: 'Note',
                }}
              />
              <div>
                Note:
                <p>
                  1. Add transfer note here. Use pipe | as note separator. Eg:
                  <strong>
                    Attendee is non-dancer | Transfer requested via email to
                    myemail@pbt.dance dtd 20/04/2022
                  </strong>
                </p>
                {workshopSlug &&
                  workshops
                    ?.find((i) => i?.slug === workshopSlug)
                    ?.currency?.toLowerCase() !==
                    attendee?.paymentCurrency?.toLowerCase() && (
                    <p>
                      2. Since the currency of the attendee's original workshop
                      differs from the currency of the workshop they are being
                      transferred to, Ensure that the equivalent expense
                      amount(in current workshop's currency) is add in the
                      current workshop.
                    </p>
                  )}
              </div>
            </Form>
          </div>
        </div>
      </Drawer>

      {createElement(refChildren.current, { onClick: () => setOpen(true) })}
    </>
  );
};

export default TransferAttendee;
