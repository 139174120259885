import { createElement, FC, useRef, useState } from 'react';
import { Modal, Button } from 'antd';

import { deleteDeal } from '../../../services/deals';

interface IDeleteDealProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  _id: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const DeleteDeal: FC<IDeleteDealProps> = ({
  children,
  _id,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [modalVisible, setModalVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const onDelete = async () => {
    setRequesting(true);
    try {
      await deleteDeal({ dealId: _id });
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  const handleModalVisibility = () => {
    setModalVisible(!modalVisible);
  };
  return (
    <>
      <Modal
        open={modalVisible}
        onCancel={handleModalVisibility}
        width={'300px'}
        title={'Delete Deal'}
        footer={
          <div>
            <Button key="cancel" onClick={handleModalVisibility}>
              Cancel
            </Button>
            <Button
              key="delete"
              type="primary"
              loading={requesting}
              onClick={onDelete}
            >
              Delete
            </Button>
          </div>
        }
      >
        Are you sure you want to delete this deal?
      </Modal>
      {createElement(refChildren.current, { onClick: handleModalVisibility })}
    </>
  );
};

export default DeleteDeal;
