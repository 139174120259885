import * as yup from 'yup';

export default yup.object().shape({
  userId: yup.string(),
  fullName: yup.string().required('Fullname is required'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  country: yup.string().when('userId', {
    is: (userId: string) => !!userId,
    then: (schema) => schema.required('Country is required'),
    otherwise: (schema) => schema,
  }),
  state: yup.string().when('userId', {
    is: (userId: string) => !!userId,
    then: (schema) => schema.required('State is required'),
    otherwise: (schema) => schema,
  }),
  address1: yup.string().when('userId', {
    is: (userId: string) => !!userId,
    then: (schema) => schema.required('Address1 is required'),
    otherwise: (schema) => schema,
  }),
  address2: yup.string(),
  city: yup.string().when('userId', {
    is: (userId: string) => !!userId,
    then: (schema) => schema.required('city is required'),
    otherwise: (schema) => schema,
  }),
  zip: yup.string().when('userId', {
    is: (userId: string) => !!userId,
    then: (schema) => schema.required('zip is required'),
    otherwise: (schema) => schema,
  }),
  nameAsOnCertificate: yup.string().when('userId', {
    is: (userId: string) => !!userId,
    then: (schema) => schema.required('Name on certificate is required'),
    otherwise: (schema) => schema,
  }),
  note: yup.string(),
  mobileNumber: yup.string().when('userId', {
    is: (userId: string) => !!userId,
    then: (schema) =>
      schema
        .required('Mobile Number is required')
        .matches(/^[- +()]*[0-9][- +()0-9]*$/, 'Invalid Mobile Number'),
    otherwise: (schema) => schema,
  }),
});
