/* eslint-disable */
import { useEffect } from 'react';
import './wistia.scss';

declare const window: any;

export interface ISetData {
  duration?: number;
  wistiaId: string;
  thumb: string;
  videoUrl: string;
}

interface IWistiaUploaderProps {
  setData: (data: ISetData) => void;
}

const WistiaUploader = (props: IWistiaUploaderProps) => {
  useEffect(() => {
    const cssId = 'myCss';
    if (!document.getElementById(cssId)) {
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');
      link.id = cssId;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = 'https://fast.wistia.com/assets/external/uploader.css';
      link.media = 'all';
      head.appendChild(link);
    }
    const script = document.createElement('script');
    script.src = 'https://fast.wistia.com/assets/external/api.js';
    script.async = true;
    document.body.appendChild(script);

    window._wapiq.push(function (W: any) {
      window.wistiaUploader = new W.Uploader({
        accessToken: process.env.REACT_APP_WISTIA_ACCESS_TOKEN,
        dropIn: 'wistia_uploader',
        embedCodeOptions: {
          playerColor: '54bbff',
        },
        projectId: 'ilnqomyjxp',
      });
      window.wistiaUploader.bind('uploadstart', function (file: any) {});

      window.wistiaUploader.bind('uploadcancelled', function (file: any) {
        console.log('We stopped uploading ' + file.name);
      });

      window.wistiaUploader.bind(
        'uploadfailed',
        function (file: any, errorResponse: any) {
          console.log('upload failed:', errorResponse.error);
        }
      );

      window.wistiaUploader.bind(
        'uploadsuccess',
        function (file: any, media: any) {
          alert(
            `Finished uploading video. \nPlease click on 'Final Submit' button now`
          );
          const newMedia = media;
          const url = new URL(newMedia.thumbnail.url);
          const thumb = `${url?.origin}${url.pathname}?image_quality=100&ssl=true`;

          const data = {
            duration: newMedia?.duration,
            wistiaId: newMedia.id,
            thumb,
            videoUrl: newMedia.url,
          };
          props.setData(data);
        }
      );
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  window._wapiq = window._wapiq || [];
  console.log(window._wapiq.length);

  return (
    <div>
      <div
        id="wistia_uploader"
        style={{ height: '300px', width: '100%', margin: 'auto' }}
      ></div>
    </div>
  );
};

export default WistiaUploader;
