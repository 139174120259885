import * as yup from 'yup';

export default yup.object().shape({
  name: yup.object().shape({
    en: yup.string().required('Please enter section name in english'),
    pt: yup.string().required('Please enter section name in portugese'),
    it: yup.string().required('Please enter section name in italian'),
    es: yup.string().required('Please enter section name in spanish'),
    de: yup.string().required('Please enter section name in german'),
    zh: yup.string().required('Please enter section name in mandrin'),
  }),
  ageGroup: yup.object().shape({
    min: yup
      .number()
      .typeError('Minimum age must be a number')
      .required('Please enter minimum age')
      .min(0, 'Minimum age cannot be less then 0')
      .max(49, 'Minimum age cannot be greater then 49'),
    max: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(0, 'Maximum age cannot be less then 0')
      .moreThan(
        yup.ref('min'),
        'Maximum age should be greater then minimum age'
      )
      .max(50, 'Maximum age cannot be greater then 50'),
  }),
  sequence: yup.number().required('Sequence is required'),
  techniqueId: yup
    .array()
    .of(yup.string())
    .min(1, 'Atleast one technique is required')
    .required('Technique is required'),
  tutorialPic: yup.string().required('Tutorial image is required'),
  classPlanPic: yup.string().required('Class plan image is required'),
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
});
