import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Card,
  Spin,
  Typography,
  Descriptions,
  Row,
  Col,
  Modal,
  Button,
  message,
  Tooltip,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import './style.scss';

import {
  getUser,
  TUser,
  sendPasswordResetLink,
  sendPasswordMail,
} from '../../services/users-service';
import EditUser from '../../components/users/edit-user';
import { NOT_AVAILABLE } from '../../constants/common';
import FormattedDate from '../../components/date/FormattedDate';
import UserProfilePicture from '../../components/users/profile-pic/UserProfilePicture';
import Location from '../../components/users/user-details/user-location';
import Schools from '../../components/users/user-details/user-schools';
import InstructorProfile from '../../components/users/user-details/user-instructor-profile';
import BusinessDetails from '../../components/users/user-details/BusinessDetails';
import SubscriptionDetails from '../../components/users/user-details/subscriptionDetails';
import DiscountDetails from '../../components/users/user-details/Discount';
import InvoiceDetails from '../../components/users/user-details/invoiceDetails';
import { convertStripeAmount } from '../../utils/common';
import { CLIENT_FRONTEND_URL } from '../../constants/api';
import WorkshopAttendedHistory from '../../components/users/workshop-attended';
import Awards from '../../components/users/user-details/Awards';
import Certifications from '../../components/users/user-details/Certifications';
import AllowedSections from '../../components/users/user-details/allowed-sections';
import DeleteAccount from '../../components/users/delete-account';
import EmailReceived from '../../components/users/emailReceived';

import UserCard from './users-dashboard';

const UserDetails = () => {
  const { id } = useParams();

  const [requesting, setRequesting] = useState(false);
  const [reloadUsers, setReloadUsers] = useState(true);
  const [loadingReset, setLoadingReset] = useState(false);
  const [loadingMail, setLoadingMail] = useState(false);
  const [user, setUser] = useState<TUser>();
  const [selectedMenuItem, setSelectedMenuItems] = useState('ABOUT');
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] =
    useState(false);

  const showModal = () => {
    setIsPasswordResetModalOpen(true);
  };

  const handleCancel = () => {
    setIsPasswordResetModalOpen(false);
  };

  const sendResetLink = async () => {
    setLoadingReset(true);
    const payload = {
      email: user?.email || '',
      returnUrl: `${CLIENT_FRONTEND_URL}/setup-new-password`,
    };
    await sendPasswordResetLink(payload);
    setLoadingReset(false);
  };

  const sendMailWithPassword = async () => {
    setLoadingMail(true);

    const payload = {
      email: user?.email || '',
      userId: user?._id || '',
    };
    await sendPasswordMail(payload);
    setLoadingMail(false);
  };

  const loadUsers = async () => {
    setRequesting(true);
    try {
      const response = await getUser({
        _id: id,
      });
      setUser(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadUsers(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadUsers) {
      loadUsers();
    }
  }, [reloadUsers]);

  return (
    <Spin spinning={requesting}>
      <Modal
        title="Reset Password"
        open={isPasswordResetModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <p>
            <Button
              type="primary"
              loading={loadingReset}
              onClick={async () => {
                await sendResetLink()
                  .then(() =>
                    message.success('Password reset link sent successfilly')
                  )
                  .catch(() => message.error('Unknown Error Occured'));
              }}
            >
              Send Password Reset Link
            </Button>
          </p>
          <p>
            <Button
              type="primary"
              loading={loadingMail}
              onClick={async () => {
                await sendMailWithPassword()
                  .then(() =>
                    message.success('A Mail with password is sent succesfully')
                  )
                  .catch(() => message.error('Unknown Error Occured'));
              }}
            >
              Send Email With Password
            </Button>
          </p>
        </div>
      </Modal>
      <Card
        bodyStyle={{ padding: '0' }}
        style={{ marginBottom: '20px', backgroundColor: '#F3F4F7' }}
      >
        <div
          style={{
            display: 'flex',
            border: '1px solid #f9f0f0',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <UserProfilePicture
            imageUrl={user?.pic}
            width={'253px'}
            user={{ ...user }}
            setReloadUsers={setReloadUsers}
          />
          <UserCard
            user={{ ...user }}
            setSelectedMenuItems={setSelectedMenuItems}
            selectedMenuItem={selectedMenuItem}
            setReloadUsers={setReloadUsers}
          />
        </div>

        {selectedMenuItem === 'ABOUT' && (
          <About
            user={user}
            setReloadUsers={setReloadUsers}
            showModal={showModal}
            loadUsers={loadUsers}
            id={id || ''}
          />
        )}

        {selectedMenuItem === 'WORKSHOPS_ATTENDED' && (
          <WorkshopAttendedHistory user={{ ...user }} />
        )}

        {selectedMenuItem === 'EMAIL_SENT_TO_CUSTOMER' && (
          <EmailReceived userId={user?._id || ''} pic={user?.pic35} />
        )}
      </Card>
    </Spin>
  );
};

const About: FC<{
  user?: TUser;
  setReloadUsers: (e: boolean) => void;
  showModal: () => void;
  loadUsers: () => void;
  id: string;
}> = ({ user, setReloadUsers, showModal, loadUsers, id }) => {
  const [daysLeftForAccountDeletion, setDaysLeftForAccountDeletion] =
    useState(60);

  useEffect(() => {
    const calculateAccountDeletionDays = (deleteDate: string) => {
      const currentDate = new Date();
      const targetDate = new Date(deleteDate);

      const currentMonth = currentDate.getMonth();
      const targetMonth = targetDate.getMonth();

      const currentDay = currentDate.getDate();
      const targetDay = targetDate.getDate();

      let daysPassed = 0;

      // Compare month first, then day
      if (currentMonth > targetMonth) {
        // If the current month is greater, calculate the days passed based on full months + days
        daysPassed =
          (currentMonth - targetMonth) * 60 + (currentDay - targetDay);
      } else if (currentMonth === targetMonth) {
        // If the month is the same, just compare the days
        daysPassed = currentDay - targetDay;
      } else {
        // If the current month is less, assume the date hasn't reached yet in this context
        daysPassed = -(
          (targetMonth - currentMonth) * 60 +
          (targetDay - currentDay)
        );
      }

      // Calculate days left out of the 60-day period
      const remainingDays = 60 - daysPassed;

      setDaysLeftForAccountDeletion(remainingDays);
    };

    const deleteDate = user?.deletedOn || '';

    // Initial calculation
    calculateAccountDeletionDays(deleteDate);

    // Calculate time to next midnight
    const now = new Date();
    const timeToMidnight =
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
        0,
        0,
        0
      ).getTime() - now.getTime();

    // Set timeout to trigger at midnight, then start a daily interval
    const timeoutId = setTimeout(() => {
      calculateAccountDeletionDays(deleteDate);

      const intervalId = setInterval(() => {
        calculateAccountDeletionDays(deleteDate);
      }, 86400000); // 1 day in milliseconds

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }, timeToMidnight);

    // Cleanup timeout on component unmount
    return () => {
      clearTimeout(timeoutId);
    };
  }, [user?.isDeleted]);

  return (
    <>
      <Card
        style={{ backgroundColor: '#F3F4F7' }}
        bodyStyle={{ padding: 0, paddingTop: 10 }}
      >
        <Row className="info-main-container">
          <Col style={{ width: '49%' }}>
            <Card title={`Personal Detail`} className="info-container">
              <p className="info-heading myprofile-actions">
                {!user?.isDeleted && (
                  <DeleteAccount userId={id} loadUsers={loadUsers} />
                )}
                <EditUser
                  userId={user?._id}
                  user={{ ...user }}
                  successCallback={() => setReloadUsers(true)}
                >
                  {({ onClick }) => (
                    <Tooltip title={'Edit Profile'}>
                      <EditOutlined onClick={onClick} title="Edit Profile" />
                    </Tooltip>
                  )}
                </EditUser>
              </p>
              <Descriptions
                layout="vertical"
                column={2}
                labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
              >
                <Descriptions.Item label="EMAIL" className="info-item">
                  {user?.email || NOT_AVAILABLE}{' '}
                </Descriptions.Item>
                <Descriptions.Item label="PASSWORD" className="info-item">
                  ***
                  <EditOutlined
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      color: 'rgb(0, 115, 255)',
                      right: '0px',
                    }}
                    onClick={showModal}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="FULL NAME" className="info-item">
                  {user?.fullName || NOT_AVAILABLE}
                </Descriptions.Item>
                <Descriptions.Item label="DISPLAY NAME" className="info-item">
                  {user?.displayName || NOT_AVAILABLE}
                </Descriptions.Item>
                <Descriptions.Item
                  label="NAME AS ON CERTIFICATE"
                  className="info-item"
                >
                  {user?.nameAsOnCertificate || NOT_AVAILABLE}
                </Descriptions.Item>

                <Descriptions.Item label="ROLES" className="info-item">
                  {user?.role || NOT_AVAILABLE}
                </Descriptions.Item>

                <Descriptions.Item label="MEMBER TYPE" className="info-item">
                  {user?.memberType || NOT_AVAILABLE}
                </Descriptions.Item>

                <Descriptions.Item label="ADMIN TYPE" className="info-item">
                  {user?.adminType || NOT_AVAILABLE}
                </Descriptions.Item>

                <Descriptions.Item label="LANGUAGE" className="info-item">
                  {user?.language || NOT_AVAILABLE}
                </Descriptions.Item>
                <Descriptions.Item label="PROFILE URL" className="info-item">
                  {NOT_AVAILABLE}
                </Descriptions.Item>
                <Descriptions.Item label="WEBSITE" className="info-item">
                  {user?.website ? (
                    <Link to={{ pathname: `${user?.website}` }} target="_blank">
                      Open
                    </Link>
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="FACEBOOK" className="info-item">
                  {user?.facebook ? (
                    <Link
                      to={{ pathname: `${user?.facebook}` }}
                      target="_blank"
                    >
                      Open
                    </Link>
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="TWITTER" className="info-item">
                  {user?.twitter ? (
                    <Link to={{ pathname: `${user?.website}` }} target="_blank">
                      Open
                    </Link>
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="LINKEDIN" className="info-item">
                  {user?.linkedin ? (
                    <Link
                      to={{ pathname: `${user?.linkedin}` }}
                      target="_blank"
                    >
                      Open
                    </Link>
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="INSTAGRAM" className="info-item">
                  {user?.instagram ? (
                    <Link
                      to={{ pathname: `${user?.instagram}` }}
                      target="_blank"
                    >
                      Open
                    </Link>
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="PROFESSION" className="info-item">
                  {user?.profession || NOT_AVAILABLE}
                </Descriptions.Item>

                <Descriptions.Item
                  label="EMERGENCY CONTACT NUMBER"
                  className="info-item"
                >
                  {user?.emergencyContactNumber || NOT_AVAILABLE}
                </Descriptions.Item>

                <Descriptions.Item
                  label="EMERGENCY CONTACT NAME"
                  className="info-item"
                >
                  {user?.emergencyContactName || NOT_AVAILABLE}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions
                layout="vertical"
                column={2}
                labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
              >
                <Descriptions.Item
                  label="BIO"
                  style={{
                    padding: 1,
                    marginBottom: '10px',
                    textAlign: 'justify',
                  }}
                >
                  {user?.multiLangBio?.en ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: user?.multiLangBio?.en,
                      }}
                    />
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>

            <Location user={{ ...user }} successCallback={setReloadUsers} />

            <Schools user={{ ...user }} successCallback={setReloadUsers} />

            <Certifications
              user={{ ...user }}
              successCallback={() => setReloadUsers(true)}
            />

            <Awards user={{ ...user }} successCallback={setReloadUsers} />

            <BusinessDetails
              user={{ ...user }}
              successCallback={setReloadUsers}
            />
            <InstructorProfile
              user={{ ...user }}
              successCallback={setReloadUsers}
            />

            {user?.role === 'admin' && (
              <AllowedSections
                user={{ ...user }}
                successCallback={() => setReloadUsers(true)}
              />
            )}
            {/* <Card
            title="Teachers Certified by Marie (50+)"
            className="info-container"
          >
            <Descriptions
              layout="vertical"
              column={2}
              labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
            >
              <Descriptions.Item className="info-item">
                <div className="userBadge">
                  <div className="userBadge-image">
                    <Avatar size={34} icon={<UserOutlined />} />
                  </div>
                  <div className="userBadge-detail">
                    <Typography.Text>Johana Goldsmith</Typography.Text>
                    <Typography.Text>PBT Level 2, PCT</Typography.Text>
                  </div>
                </div>
              </Descriptions.Item>
              <Descriptions.Item className="info-item">
                <div className="userBadge">
                  <div className="userBadge-image">
                    <Avatar size={34} icon={<UserOutlined />} />
                  </div>
                  <div className="userBadge-detail">
                    <Typography.Text>Barbara Johnson</Typography.Text>
                    <Typography.Text>PBT Level 1</Typography.Text>
                  </div>
                </div>
              </Descriptions.Item>
            </Descriptions>
            <div className="link">
              <Typography.Text className="link">View all</Typography.Text>
            </div>
          </Card>

          <Card title="My Students (20+)" className="info-container">
            <Descriptions
              layout="vertical"
              column={2}
              labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
            >
              <Descriptions.Item className="info-item">
                <div className="userBadge">
                  <div className="userBadge-image">
                    <Avatar size={34} icon={<UserOutlined />} />
                  </div>
                  <div className="userBadge-detail">
                    <Typography.Text>Timothy Bob</Typography.Text>
                    <Typography.Text>20% progress</Typography.Text>
                  </div>
                </div>
              </Descriptions.Item>
              <Descriptions.Item className="info-item">
                <div className="userBadge">
                  <div className="userBadge-image">
                    <Avatar size={34} icon={<UserOutlined />} />
                  </div>
                  <div className="userBadge-detail">
                    <Typography.Text>Albert Kim</Typography.Text>
                    <Typography.Text>60% progress</Typography.Text>
                  </div>
                </div>
              </Descriptions.Item>
            </Descriptions>
            <div className="link">
              <Typography.Text className="link">View all</Typography.Text>
            </div>
          </Card> */}
            {user?.isDeleted === true && (
              <Card title="Deletion" className="info-container deletion">
                <Descriptions
                  layout="vertical"
                  column={1}
                  labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
                >
                  <Descriptions.Item
                    label="Your account will be permanently deleted in"
                    className="info-item"
                    labelStyle={{ color: '#fff', fontSize: '1rem' }}
                  >
                    <p style={{ fontSize: '1.1rem' }}>
                      {daysLeftForAccountDeletion} Days
                    </p>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            )}
          </Col>

          <Col style={{ width: '49%' }}>
            <Card title="Onboarding Details" className="info-container">
              <Descriptions
                layout="vertical"
                column={2}
                labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
              >
                <Descriptions.Item
                  label="ONBOARDING STARTED ON"
                  className="info-item"
                >
                  {user ? (
                    <FormattedDate
                      date={user?.createdAt}
                      fromNow={true}
                      ShowTime={false}
                    />
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label="ONBOARDING COMPLETED ON"
                  className="info-item"
                >
                  {user?.signupCompletedOn ? (
                    <FormattedDate
                      date={user?.signupCompletedOn}
                      fromNow={true}
                      ShowTime={false}
                    />
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>

                <Descriptions.Item
                  label="TRIAL CONVERTED ON"
                  className="info-item"
                >
                  {user?.trialConverted ? (
                    <FormattedDate
                      date={user?.trialConvertedOn}
                      fromNow={false}
                      ShowTime={false}
                    />
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="REFERRER" className="info-item">
                  {user?.referrer ? (
                    <Link
                      to={{ pathname: `/userdetails/${user?.referrer}` }}
                      onClick={() => {
                        setReloadUsers(true);
                      }}
                    >
                      <span>{user?.referrer}</span>{' '}
                    </Link>
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>

                <Descriptions.Item
                  label="AMOUNT PAID TO THE REFERRER"
                  className="info-item"
                >
                  {user?.amountPaidToReferrer ? 'YES' : NOT_AVAILABLE}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions
                layout="vertical"
                column={1}
                labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
              >
                <Descriptions.Item
                  label="GENERAL TERMS OF SERVICE & PRIVACY POLICY"
                  className="info-item"
                >
                  {user?.tosAndPrivacyPolicyAccepted &&
                  user?.tosAndPrivacyPolicyAcceptedOn ? (
                    <span>
                      Accepted On{` `}
                      {
                        <FormattedDate
                          date={user?.tosAndPrivacyPolicyAcceptedOn}
                          fromNow={false}
                          ShowTime={true}
                        />
                      }
                      from {user?.tosAndPrivacyPolicyAcceptedIp}{' '}
                      {user?.tosAndPrivacyPolicyAcceptedUa}`
                    </span>
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label="TERMS OF PAYMENT FOR SUBSCRIPTION SERVICE"
                  className="info-item"
                >
                  {user?.paymentConsentToSAccepted ? (
                    <span>
                      Accepted On
                      {
                        <FormattedDate
                          date={user?.paymentConsentToSAcceptedOn}
                          fromNow={false}
                          ShowTime={true}
                        />
                      }
                      from {user?.paymentConsentToSAcceptedIp}{' '}
                      {user?.paymentConsentToSAcceptedUa}`
                    </span>
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>

            <SubscriptionDetails
              user={{ ...user }}
              successCallback={setReloadUsers}
            />

            <Card title="Current Pause Details" className="info-container">
              <Descriptions
                layout="vertical"
                column={2}
                labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
              >
                <Descriptions.Item
                  label="PAUSE COLLECTION BEHAVIOUR"
                  className="info-item"
                >
                  {user?.subscription?.pause_collection?.behavior ||
                    NOT_AVAILABLE}
                </Descriptions.Item>
                <Descriptions.Item
                  label="RESUME COLLECTION ON"
                  className="info-item"
                >
                  {user?.subscription?.pause_collection?.resumes_at ? (
                    <FormattedDate
                      date={user?.subscription?.pause_collection?.resumes_at}
                    />
                  ) : (
                    NOT_AVAILABLE
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>

            <Card title="Pause History" className="info-container">
              <Descriptions
                layout="vertical"
                column={1}
                labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
              >
                {user?.pauses && user?.pauses?.length > 0 ? (
                  user.pauses.map((items) => {
                    return (
                      <Descriptions.Item
                        label={
                          <FormattedDate date={items?.createdAt} addON={true} />
                        }
                        className="info-item"
                      >
                        {`${items?.behavior} for ${items?.requestedPauseMonths} months. Reason: ${items?.purpose}`}
                      </Descriptions.Item>
                    );
                  })
                ) : (
                  <Descriptions.Item label={false} className="info-item">
                    NO PAUSE HISTORY TILL NOW
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Card>

            <DiscountDetails user={{ ...user }} />

            {/* <Card
            title="Subscription Items (Old Subscriptions)"
            className="info-container"
          >
            <Descriptions
              layout="vertical"
              column={2}
              labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
            >
              <Descriptions.Item label="PLAN ID" className="info-item">
                TMIN
                <Typography.Text className="inactiveStatus">
                  INACTIVE
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="PLAN NICKNAME" className="info-item">
                Teachers Monthly
              </Descriptions.Item>
              <Descriptions.Item label="PLAN AMOUNT" className="info-item">
                USD 25
              </Descriptions.Item>
              <Descriptions.Item label="PLAN INTERVAL" className="info-item">
                1 Month
              </Descriptions.Item>
            </Descriptions>
          </Card> */}

            <Card
              title="Subscription Items (New Subscriptions)"
              className="info-container"
            >
              <Descriptions
                layout="vertical"
                column={2}
                labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
              >
                <Descriptions.Item label="PLAN ID" className="info-item">
                  {user?.subscription?.items?.data[0]?.price?.id ||
                    NOT_AVAILABLE}
                  <Typography.Text
                    className={
                      user?.subscription?.items?.data[0].price?.active
                        ? 'activeStatus'
                        : 'inactiveStatus'
                    }
                  >
                    {user?.subscription?.items?.data[0].price?.active
                      ? 'ACTIVE'
                      : 'INACTIVE'}
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label="PLAN NICKNAME" className="info-item">
                  {user?.subscription?.items?.data[0]?.price?.nickname
                    ? `${user?.subscription?.items?.data[0]?.price?.nickname}`
                    : NOT_AVAILABLE}
                </Descriptions.Item>
                <Descriptions.Item label="PLAN AMOUNT" className="info-item">
                  {user?.subscription?.items?.data &&
                  user.subscription.items.data.length > 0 &&
                  user.subscription.items.data[0].price &&
                  user.subscription.currency &&
                  user.subscription.items.data[0].price.currency_options[
                    user.subscription.currency
                  ]?.unit_amount
                    ? `${user.subscription.currency.toUpperCase()} ${convertStripeAmount(
                        user.subscription.items.data[0].price.currency_options[
                          user.subscription.currency
                        ].unit_amount,
                        user.subscription.currency
                      )}`
                    : NOT_AVAILABLE}
                </Descriptions.Item>

                <Descriptions.Item label="PLAN INTERVAL" className="info-item">
                  {user?.subscription?.items?.data[0].price?.recurring?.interval
                    ? `${user.subscription?.items?.data[0].price?.recurring?.interval_count} ${user.subscription?.items?.data[0]?.price?.recurring?.interval}`
                    : NOT_AVAILABLE}
                </Descriptions.Item>
              </Descriptions>
            </Card>

            <InvoiceDetails user={{ ...user }} />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default UserDetails;
