import { Col, Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { FileUploadButton, InputField, SelectField } from '../common/form';
import { IMarketingAssets } from '../../types/marketingAssets';
import { getTechniques } from '../../services/technique-service';
import { TechniqueDetail } from '../../types/technique';

interface IAddMarketingAssetsFormProps {
  form: UseFormReturn<IMarketingAssets, object>;
  isEditable: boolean;
}
const AddMarketingAssetsForm = ({
  form,
  isEditable,
}: IAddMarketingAssetsFormProps) => {
  const [techniques, setTechniques] = useState<TechniqueDetail[]>([]);

  const values = form.getValues();

  const technique = async () => {
    try {
      const response = await getTechniques();
      setTechniques(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    technique();
  }, []);

  return (
    <>
      <Form layout="vertical">
        <Col span={24}>
          <SelectField
            label="Technique"
            required
            name="techniqueSlug"
            form={form}
            labelKey="name.en"
            valueKey="slug"
            selectFieldProps={{
              options: techniques,
              loading: techniques.length < 1,
              placeholder:
                techniques.length < 1 ? 'Loading...' : 'Select Technique',
              onChange: (v) => form.setValue('techniqueSlug', v),
              showSearch: true,
              allowClear: true,
            }}
          />
        </Col>

        <Col span={24}>
          <SelectField
            label="Language"
            required
            name="language"
            form={form}
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: [
                { label: 'English', value: 'en' },
                { label: 'Chinese', value: 'zh' },
                { label: 'Spanish', value: 'es' },
                { label: 'Portuguese', value: 'pt' },
                { label: 'Italian', value: 'it' },
              ],
              placeholder: 'Select Language',
              onChange: (v) => form.setValue('language', v),
              showSearch: true,
              allowClear: true,
            }}
          />
        </Col>

        <InputField
          label="Asset Name"
          name="name"
          required
          inputProps={{
            placeholder: 'Asset Name',
          }}
          form={form}
        />

        <br />

        <FileUploadButton
          form={form}
          defaultFileList={
            isEditable
              ? [
                  {
                    uid: '-1',
                    name: `${values?.name}`,
                    status: 'done',
                    url: values?.url,
                  },
                ]
              : []
          }
          label="Asset"
          required
          name="url"
          buttonLabel="Upload"
          allowAny
          afterUpload={(v) => form.setValue('fileType', v)}
        />
        <FileUploadButton
          form={form}
          defaultFileList={
            isEditable
              ? [
                  {
                    uid: '-1',
                    name: `${values?.name}`,
                    status: 'done',
                    url: values?.previewUrl,
                  },
                ]
              : []
          }
          label="Asset Preview Image"
          required
          name="previewUrl"
          buttonLabel="Upload"
          allowedFileTypes={['image/png', 'image/jpg', 'image/jpeg']}
        />
      </Form>
    </>
  );
};

export default AddMarketingAssetsForm;
