import * as yup from 'yup';

export default yup.object().shape({
  // category: yup.string().required('Section-category is required'),
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
  name: yup.object().shape({
    en: yup
      .string()
      .required('Please enter category name in English')
      .min(3, 'Category name in English must be at least 3 characters')
      .max(100, 'Category name in English must be at most 100 characters'),

    pt: yup
      .string()
      .required('Please enter category name in Portugese')
      .min(3, 'Category name in Portuguese must be at least 3 characters')
      .max(100, 'Category name in Portuguese must be at most 100 characters'),

    it: yup
      .string()
      .required('Please enter category name in Italian')
      .min(3, 'Category name in Italian must be at least 3 characters')
      .max(100, 'Category name in Italian must be at most 100 characters'),

    es: yup
      .string()
      .required('Please enter category name in Spanish')
      .min(3, 'Category name in Spanish must be at least 3 characters')
      .max(100, 'Category name in Spanish must be at most 100 characters'),

    de: yup
      .string()
      .required('Please enter category name in German')
      .min(3, 'Category name in German must be at least 3 characters')
      .max(100, 'Category name in German must be at most 100 characters'),
    zh: yup
      .string()
      .required('Please enter category name in Mandrin')
      .max(100, 'Category name in Mandrin must be at most 100 characters'),
  }),
  sequence: yup
    .number()
    .required('Sequence is required')
    .typeError('Sequence should be a number')
    .positive('Sequence should be greater than zero'),
  // tutorialPic: yup.string().required('Tutorial image is required'),
  sections: yup
    .array()
    .test('atLeastOneSelected', 'Please select at least Sections', (value) => {
      return value && value.length > 0 ? true : false;
    }),
});
