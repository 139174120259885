import { createElement, FC, useRef, useState } from 'react';
import { Button, Drawer, message } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ExerciseEquipmentForm, { defaultValues } from '../form';
import validationSchema from '../validation-schema';
import { addExerciseEquipment } from '../../../services/exercise-equipment-service';
import { ExerciseEquipment } from '../../../types/exercise-equipment';

interface IAddExerciseEquipmentProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const AddExerciseEquipment: FC<IAddExerciseEquipmentProps> = ({
  children,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<ExerciseEquipment>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues,
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (data: ExerciseEquipment) => {
    setRequesting(true);
    try {
      await addExerciseEquipment(data);
      message.success('Exercise equipment added successfully');
      handleDrawerVisibility();
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Add Exercise Equipment"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <ExerciseEquipmentForm form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddExerciseEquipment;
