import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AddAdminForm from '../form';
import validationSchema from '../validation-schema';
import { IAllowedSections, editAdmin } from '../../../services/admin-service';
import { TUser } from '../../../services/users-service';
import { IAdminFormValue } from '../add-admin';
import { WorkshopInstructor } from '../../../types/instructor';

interface IEditAdminProps {
  successCallback?: () => void;
  errorCallback?: (e: unknown) => void;
  children: FC<{
    onClick: () => void;
  }>;
  payload: TUser;
  instructor: WorkshopInstructor[];
}

const EditAdmin: FC<IEditAdminProps> = ({
  children,
  successCallback,
  errorCallback,
  payload,
  instructor,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<IAdminFormValue>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmitHandler = async (values: IAdminFormValue) => {
    setRequesting(true);
    try {
      const allowedSections: IAllowedSections['allowedSections'] = [];

      (values?.allowedSections || []).forEach((i) => {
        const { key, subSection } = JSON.parse(i);
        const index = allowedSections.findIndex((item) => item.key === key);

        if (index !== -1) {
          allowedSections[index].subSections.push({
            key: subSection.key,
            label: subSection?.label,
          });
        } else {
          allowedSections.push({
            key,
            subSections: [{ key: subSection.key, label: subSection?.label }],
          });
        }
      });
      const customPaymentShares = Object?.keys(
        values?.customPaymentShares || {}
      )?.map((i: string) => ({
        techniqueSlug: i,
        ownerShare: values?.customPaymentShares?.[i]?.ownerShare,
        hplShare: values?.customPaymentShares?.[i]?.hplShare,
        instructorShare: values?.customPaymentShares?.[i]?.instructorShare,
      }));
      const data = {
        ...values,
        allowedSections,
        customPaymentShares,
      };
      if (payload?._id) {
        await editAdmin({ userID: payload?._id, payload: data });
      }
      setDrawerVisible(false);
      message.success('Admin added successfully');
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      if (errorCallback) {
        errorCallback(err);
      }
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (drawerVisible)
      form.reset({
        fullName: payload?.fullName,
        email: payload?.email,
        adminType: payload?.adminType,
        allowedSections: payload?.allowedSections?.flatMap((section) => {
          return section?.subSections?.map((subSection) => {
            return JSON.stringify({
              key: section?.key,
              subSection: { key: subSection?.key, label: subSection?.label },
            });
          });
        }),
        techniquesAllowed: payload?.techniquesAllowed,
        isUsingDefaultTechniqueShares: payload?.isUsingDefaultTechniqueShares,
        customPaymentShares: payload?.customPaymentShares?.reduce(
          (
            acc: {
              [key: string]: {
                ownerShare?: number;
                hplShare?: number;
                instructorShare?: number;
              };
            },
            cur
          ) => {
            if (cur?.techniqueSlug) {
              acc[cur.techniqueSlug] = {
                ownerShare: cur?.ownerShare,
                hplShare: cur?.hplShare,
                instructorShare: cur?.instructorShare,
              };
            }

            return acc;
          },
          {}
        ),
        assignedInstructor: payload?.assignedInstructor,
      });

    return () => {
      form.reset({});
    };
  }, [payload, drawerVisible]);

  return (
    <>
      <Drawer
        title="Edit Admin"
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddAdminForm form={form} instructor={instructor} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditAdmin;
