import { CERTIFICATE } from '../constants/api';
import { CreateCertificate } from '../types/create-certificate';
import fetchClient from '../utils/fetch-client';

export const getCertificate = (payload: CreateCertificate) => {
  return fetchClient.post(`${CERTIFICATE.CREATE_CERTIFICATE}`, payload);
};

export const generateCertificate = (attendeeId: string) => {
  return fetchClient.post(`${CERTIFICATE.CREATE_CERTIFICATE}/${attendeeId}`);
};

export const generateCertificateForAllAttendee = (workshopId: string) => {
  return fetchClient.post(
    `${CERTIFICATE.CREATE_CERTIFICATE}/download/${workshopId}`
  );
};
