import { createElement, FC, useState, useEffect } from 'react';
import { Button, Drawer } from 'antd';
import { useForm } from 'react-hook-form';
import moment, { Moment } from 'moment';

import { TechniqueDetail } from '../../../../../types/technique';
import {
  UserCertification,
  updateUserCertification,
} from '../../../../../services/users-service';
import CertificationForm from '../form';
import { handleError } from '../../../../../utils/common';

interface IEditCertificationProps {
  userId: string;
  payload: UserCertification;
  techniques: TechniqueDetail[];
  children: FC<{ onClick: () => void }>;
  errorCallback?: (e: Error) => void;
  successCallback?: () => void;
}

interface IFormData {
  assignedCertificationCode: string;
  assignedCertificationLevel: {
    [key: string]: number;
  };
  certificationDate: Moment;
  nextRenewalDueOn: Moment;
}

const EditCertification: FC<IEditCertificationProps> = ({
  children,
  userId,
  techniques,
  payload,
  errorCallback,
  successCallback,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const form = useForm<IFormData>({});

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
    form.reset();
  };

  const onSubmitHandler = async (data: IFormData) => {
    if (requesting) return;
    try {
      setRequesting(true);
      await updateUserCertification(userId, {
        code: data.assignedCertificationCode,
        level: data.assignedCertificationLevel[data.assignedCertificationCode],
        nextRenewalDueOn: data?.nextRenewalDueOn?.toDate(),
      });

      handleDrawerVisibility();
      form.reset({});
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (!payload) return;

    const {
      code,
      level,
      certifiedOn,
      renewalDates = [],
      nextRenewalDueOn,
    } = payload;

    const assignedCertificationLevel = { [code]: level };
    const assignedCertificationCode = code;

    const dates = renewalDates
      .map((date) => new Date(date).getTime())
      .filter(Boolean);

    if (certifiedOn) {
      dates.push(new Date(certifiedOn).getTime());
    }

    const lastRenewedAt = dates.length ? new Date(Math.max(...dates)) : null;

    const currentTechnique = techniques?.find((i) => i?.slug === code);

    const nextRenewalDueOnMoment = nextRenewalDueOn
      ? moment(nextRenewalDueOn)
      : lastRenewedAt
      ? moment(lastRenewedAt).add(
          (currentTechnique?.certificateLifeInYears ?? 1) * 12 + 1,
          'months'
        )
      : undefined;

    const certificationDate = moment(certifiedOn);

    form.reset({
      assignedCertificationCode,
      assignedCertificationLevel,
      nextRenewalDueOn: nextRenewalDueOnMoment,
      certificationDate,
    });

    return () => {
      form.reset({});
    };
  }, [payload]);

  return (
    <>
      <Drawer
        title="Edit Certification"
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
            disabled={requesting}
          >
            Update
          </Button>
        }
      >
        <div style={{ padding: 16 }}>
          <CertificationForm form={form} techniques={techniques} isEditMode />
        </div>
      </Drawer>
      {createElement(children, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditCertification;
