import * as yup from 'yup';

export default yup.object().shape({
  slug: yup
    .string()
    .required('Slug is required')
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers and hyphens("-")'
    ),
  shortName: yup.string().required('Short name is required'),
  primaryLanguages: yup.array().required('Primary languages are required'),
  workshopLanguages: yup.array().required('Workshop languages are required'),
  createdBy: yup.string().required(' Owner is required'),
  ownerShare: yup
    .number()
    .required('Owner share is required')
    .min(0, 'Owner share must be greater or equal to than 0')
    .max(100, 'Owner share must not be greater than 100')
    .typeError('Please enter a valid number between 0 - 100'),
  hplShare: yup
    .number()
    .required('Hpl share is required')
    .min(0, 'Hpl share must be greater or equal to than 0')
    .max(100, 'Hpl share must not be greater than 100')
    .typeError('Please enter a valid number between 0 - 100'),
  instructorShare: yup
    .number()
    .required('Instructor share is required')
    .min(0, 'Instructor share must be greater or equal to than 0')
    .max(100, 'Instructor share must not be greater than 100')
    .typeError('Please enter a valid number between 0 - 100'),

  certificationHighestLevel: yup.number().when('isCertificationMultiLevel', {
    is: true,
    then: yup
      .number()
      .typeError('Please enter a valid certification level between 1 - 3')
      .required('Certification highest level is required')
      .min(1, 'Certification highest level must be greater than 0')
      .max(3, 'Certification highest level must not be greater than 3'),
  }),
  certificateLifeInYears: yup
    .number()
    .typeError('Please enter a valid certification life')
    .required('Certification life is required')
    .min(1, 'Certification life must be greater than 0'),
  name: yup.object().shape({
    en: yup.string().required('Please input technique name in english'),
    pt: yup.string().required('Please input technique name in portugese'),
    it: yup.string().required('Please input technique name in italian'),
    es: yup.string().required('Please input technique name in spanish'),
    de: yup.string().required('Please input technique name in german'),
    zh: yup.string().required('Please input technique name in mandrin'),
  }),
  logoSmallForLightBg: yup
    .string()
    .required('Small logo for  light background is required'),
  logoMediumForLightBg: yup
    .string()
    .required('Medium logo for  light background is required'),
  logoSmallForDarkBg: yup
    .string()
    .required('Small logo for  dark background is required'),
  logoMediumForDarkBg: yup
    .string()
    .required('Medium logo for  dark background is required'),
  certificateTemplates: yup
    .array()
    .required('Certificate templates are required'),
});
