import { createElement, FC, useRef, useState } from 'react';
import { message, Popconfirm } from 'antd';

import { deleteFaq } from '../../../../services/faq-services';

interface IDeleteFaqProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  faqId?: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const DeleteFaq: FC<IDeleteFaqProps> = ({
  children,
  faqId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [popVisible, setPopVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const onDelete = async () => {
    setRequesting(true);
    try {
      await deleteFaq(faqId);
      if (successCallback) {
        successCallback();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      message.error(err?.response?.data?.MESSAGE_KEY || err.message);
      console.log(err);
      if (errorCallback) {
        errorCallback(err as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  const handlePopVisibility = () => {
    setPopVisible(!popVisible);
  };
  return (
    <Popconfirm
      open={popVisible}
      onOpenChange={handlePopVisibility}
      title="Are you sure to remove this Faq?"
      okButtonProps={{ loading: requesting }}
      onConfirm={onDelete}
      onCancel={() => console.log('clicked cancel')}
      okText="Yes"
      cancelText="No"
    >
      {createElement(refChildren.current, { onClick: handlePopVisibility })}
    </Popconfirm>
  );
};

export default DeleteFaq;
