/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, FC, useRef, useState, useEffect } from 'react';
import { Button, Drawer, message } from 'antd';
import { useForm } from 'react-hook-form';

import { TUser, updateUserAwards } from '../../../../../services/users-service';
import { AssessmentCategory } from '../../../../../types/assessment-categories';

import EditAwardsDetailsForm from './form';

interface IEditUserProps {
  userId: string;
  user: TUser;
  assessmentDetails?: AssessmentCategory[];
  children: FC<{ onClick: () => void }>;
  errorCallback?: (e: Error) => void;
  successCallback?: (e: boolean) => void;
}

const EditAwardsDetails: FC<IEditUserProps> = ({
  children,
  user,
  userId,
  assessmentDetails,
  errorCallback,
  successCallback,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const refChildren = useRef(children);

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const getProfileToEdit = (userProfile: TUser) => {
    const awards: { [key: string]: boolean } = {};
    user.l1Awards?.forEach((award) => {
      awards[award?.categoryId] = true;
    });
    user.l2Awards?.forEach((award) => {
      awards[award?.categoryId] = true;
    });
    user.l3Awards?.forEach((award) => {
      awards[award?.categoryId] = true;
    });
    return awards;
  };
  const form = useForm({});

  useEffect(() => {
    form.reset(getProfileToEdit(user));
  }, [user]);

  const onSubmitHandler = async (data: any) => {
    setRequesting(true);

    const badgesIds = Object.entries(data)?.reduce(
      (acc: string[], [key, value]) => {
        if (value) {
          acc.push(key);
        }
        return acc;
      },
      []
    );

    console.log(badgesIds);

    try {
      await updateUserAwards(userId, badgesIds);

      message.success('Updated Successfully');

      handleDrawerVisibility();
      form.reset({});
      if (successCallback) {
        successCallback(true);
      }
    } catch (e: any) {
      if (errorCallback) {
        errorCallback(e?.response?.data?.MESSAGE_KEY || e?.message);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Edit Awards (badges) Details"
        onClose={() => {
          handleDrawerVisibility();
          form.reset({});
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
          >
            Update
          </Button>
        }
      >
        <div style={{ padding: 16 }}>
          <EditAwardsDetailsForm
            form={form}
            assessmentDetails={assessmentDetails}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditAwardsDetails;
