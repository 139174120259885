import * as yup from 'yup';

export default yup.object().shape({
  haveStripeId: yup.boolean(),
  paymentProcessor: yup.string().when('adminType', {
    is: 'workshop',
    then: yup
      .string()
      .oneOf(['stripe', 'paypal', 'ebanx'], 'Invalid option selected')
      .required('Payment processor is required'),
  }),
  accountHolderName: yup.string().when(['paymentProcessor', 'haveStripeId'], {
    is: (paymentProcessor: string, haveStripeId: boolean) =>
      paymentProcessor === 'stripe' && !haveStripeId,
    then: yup.string().required('Account holder name is required'),
    otherwise: yup.string(),
  }),
  accountNumber: yup.string().when(['paymentProcessor', 'haveStripeId'], {
    is: (paymentProcessor: string, haveStripeId: boolean) =>
      paymentProcessor === 'stripe' && !haveStripeId,
    then: yup.string().required('Account number is required'),
    otherwise: yup.string(),
  }),
  routingNumber: yup.string(),
  accountHolderType: yup.string().when(['paymentProcessor', 'haveStripeId'], {
    is: (paymentProcessor: string, haveStripeId: boolean) =>
      paymentProcessor === 'stripe' && !haveStripeId,
    then: yup.string().required('Account type is required'),
    otherwise: yup.string(),
  }),
  currency: yup.string().when(['paymentProcessor', 'haveStripeId'], {
    is: (paymentProcessor: string, haveStripeId: boolean) =>
      paymentProcessor === 'stripe' && !haveStripeId,
    then: yup.string().required('Currency is required'),
    otherwise: yup.string(),
  }),
  country: yup.string().when(['paymentProcessor', 'haveStripeId'], {
    is: (paymentProcessor: string, haveStripeId: boolean) =>
      paymentProcessor === 'stripe' && !haveStripeId,
    then: yup.string().required('Please select a country'),
    otherwise: yup.string(),
  }),
  stripeId: yup.string().when(['paymentProcessor', 'haveStripeId'], {
    is: (paymentProcessor: string, haveStripeId: boolean) =>
      paymentProcessor === 'stripe' && haveStripeId,
    then: yup.string().required('Please enter stripe id'),
    otherwise: yup.string(),
  }),
  paypalEmailId: yup.string().when('paymentProcessor', {
    is: 'paypal',
    then: yup.string().required('Email id is required'),
    otherwise: yup.string(),
  }),
});
