import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  ReloadOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { getCertificationCategory } from '../../services/certification-category-service';
import EditCertificationCategory from '../../components/certification-category/edit-certification-category';
import DeleteCertificationCategory from '../../components/certification-category/delete-certification-category';
import AddCertificationCategory from '../../components/certification-category/add-certification-category';
import { CerificationCategory } from '../../types/certification-categories';

import columns from './columns';

const CertificationCategories = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadCertificationCategory, setReloadCertificationCategory] =
    useState(false);
  const [certificationCategory, setcertificationCategory] = useState<
    CerificationCategory[]
  >([]);

  const loadCertificationCategory = async () => {
    setRequesting(true);
    try {
      const response = await getCertificationCategory();
      setcertificationCategory(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadCertificationCategory(false);
    }
  };
  useEffect(() => {
    if (!requesting && reloadCertificationCategory) {
      loadCertificationCategory();
    }
  }, [reloadCertificationCategory, requesting]);

  useEffect(() => {
    loadCertificationCategory();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: CerificationCategory) => {
        return (
          <Space size="large">
            <EditCertificationCategory
              certificationCategoryId={record._id}
              payload={record}
              successCallback={() => setReloadCertificationCategory(true)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditCertificationCategory>
            <DeleteCertificationCategory
              certificationCategoryId={record._id}
              successCallback={() => setReloadCertificationCategory(true)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteCertificationCategory>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={certificationCategory}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>
                Certification Categories
              </Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadCertificationCategory(true)}
                  />
                </Tooltip>
                <AddCertificationCategory
                  successCallback={() => setReloadCertificationCategory(true)}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add Certification Category
                    </Button>
                  )}
                </AddCertificationCategory>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default CertificationCategories;
