import { Button, Drawer, message } from 'antd';
import { createElement, FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import { ExerciseCategoryDisplay } from '../../../types/exercise-categories';
import { addSectionCategory } from '../../../services/exercise-categories-service';
import CategoryForm from '../form';
import { SectionDetail } from '../../../types/sections';
import { TechniqueDetail } from '../../../types/technique';

interface IAddTCategoryProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  sections: SectionDetail[];
  techniques: TechniqueDetail[];
  children: FC<{
    onClick: () => void;
  }>;
}

const AddCategory: FC<IAddTCategoryProps> = ({
  children,
  successCallback,
  errorCallback,
  sections,
  techniques,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<ExerciseCategoryDisplay>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: ExerciseCategoryDisplay) => {
    setRequesting(true);

    await addSectionCategory({ payload: values })
      .then(() => {
        setDrawerVisible(false);
        message.success('Exercise Category added successfully');
        form.reset();
        setRequesting(false);

        if (successCallback) {
          successCallback();
        }
      })
      .catch((error) => {
        setRequesting(false);

        if (error.response) {
          message.error(error.response.data.MESSAGE_KEY);
        } else {
          message.error('Unknown Error');
        }

        if (errorCallback) {
          errorCallback(error as Error);
        }
      });
  };

  return (
    <>
      <Drawer
        title="Add Exercise Category"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <CategoryForm
            sections={sections}
            techniques={techniques}
            isEditable={false}
            form={form}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddCategory;
