import { NOT_AVAILABLE } from '../../constants/common';
import { WorkshopInstructor } from '../../types/instructor';

const columns = [
  {
    title: '# No',
    render: (value: number, record: WorkshopInstructor) =>
      record?.instructorSequenceNo,
    key: 'slno',
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: WorkshopInstructor) =>
      record?.fullName || NOT_AVAILABLE,
    width: 800,
  },
  {
    title: 'Email',
    render: (value: string, record: WorkshopInstructor) =>
      record?.email || NOT_AVAILABLE,
    width: 800,
  },
];

export default columns;
