import {
  Button,
  Spin,
  Table,
  Tooltip,
  Typography,
  Pagination,
  Input,
  Switch,
  Dropdown,
} from 'antd';
import { useEffect, useState } from 'react';
import { ReloadOutlined, EllipsisOutlined } from '@ant-design/icons';

import './style.scss';

import { handleError } from '../../utils/common';
import { ICreditList } from '../../types/credit-list';
import { getCreditlist } from '../../services/credit-list';

import columns from './columns';
import AddUsertoWorkshop from './add-user-to-workshop';
import EditNote from './edit-note';

const CreditList: React.FC = () => {
  const [creditList, setCreditList] = useState<ICreditList[]>([]);

  const [requesting, setRequesting] = useState(false);
  const [reloadCreditUsers, setReloadCreditUsers] = useState(false);
  const [showTransferedToWorkshop, setShowTransferedToWorkshop] =
    useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');

  const [totalPages, setTotalPages] = useState(1);

  const loadCreditUsers = async () => {
    setRequesting(true);
    try {
      const response = await getCreditlist({
        page,
        limit,
        searchText: debouncedSearchText,
        showTransferedToWorkshop,
      });
      setCreditList(response.data.data.results);
      setTotalPages(response.data.data.totalPages);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
      setReloadCreditUsers(false);
    }
  };

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      width: 150,
      render: (record: ICreditList) => (
        <>
          <Dropdown
            menu={{
              items: [
                {
                  key: 'transfer-to-workshop w-full',
                  label: (
                    <AddUsertoWorkshop
                      creditList={record}
                      successCallback={() => setReloadCreditUsers(true)}
                      errorCallback={handleError}
                    >
                      {({ onClick }) => (
                        <p
                          className="no-margin"
                          onClick={() => {
                            if (!record?.isTransferredToWorkshop) {
                              onClick();
                            }
                          }}
                        >
                          Transfer to workshop
                        </p>
                      )}
                    </AddUsertoWorkshop>
                  ),
                  disabled: record?.isTransferredToWorkshop,
                },
                {
                  key: 'update-note',
                  label: (
                    <EditNote
                      creditList={record}
                      successCallback={() => setReloadCreditUsers(true)}
                      errorCallback={handleError}
                    >
                      {({ onClick }) => (
                        <p className="no-margin w-full" onClick={onClick}>
                          Update note
                        </p>
                      )}
                    </EditNote>
                  ),
                },
              ],
            }}
            arrow
            trigger={['click']}
            placement="bottomRight"
            className="workshop-dropdown"
            overlayClassName="workshop-dropdown-overlay"
          >
            <EllipsisOutlined />
          </Dropdown>
        </>
      ),
      key: 'actions',
    },
  ];

  useEffect(() => {
    if (!requesting && reloadCreditUsers) {
      loadCreditUsers();
    }
  }, [reloadCreditUsers]);

  useEffect(() => {
    if (searchText?.length >= 3 || !searchText) {
      const id = setTimeout(() => {
        setDebouncedSearchText(searchText);
      }, 300);

      return () => {
        clearTimeout(id);
      };
    }
  }, [searchText]);

  useEffect(() => {
    loadCreditUsers();
  }, [page, limit, showTransferedToWorkshop, debouncedSearchText]);

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={creditList}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography.Title level={3}>Credit List</Typography.Title>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginInline: 10 }}>
                    <span>Show Transfered to Workshop Records : </span>

                    <Switch
                      checked={showTransferedToWorkshop}
                      onChange={(e) => setShowTransferedToWorkshop(e)}
                    />
                  </div>

                  <Tooltip title="Refresh" placement="bottom">
                    <Button
                      type="ghost"
                      icon={<ReloadOutlined />}
                      onClick={() => setReloadCreditUsers(true)}
                    />
                  </Tooltip>
                </div>
              </div>
              <Input.Search
                className="userSearchField"
                placeholder="Type at least 3 characters of name or email"
                size="middle"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{ width: 400 }}
                allowClear
              />
            </div>
          );
        }}
      />
      <Pagination
        style={{ float: 'right' }}
        pageSize={limit}
        current={page}
        onChange={(page, limit) => {
          setPage(page);
          setLimit(limit);
        }}
        total={totalPages * limit}
        showSizeChanger
      />
    </Spin>
  );
};

export default CreditList;
