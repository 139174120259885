import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Drawer, message, Row } from 'antd';
import { createElement, FC, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import { addExercise } from '../../../services/exercises';
import { ExerciseVideo, VideoFormData } from '../../../types/exercises';
import ExerciseForm from '../form';
import validationSchema from '../validation-schema';
import { handleError } from '../../../utils/common';
interface IAddExerciseProps {
  successCallback?: () => void;
  videoFormData: VideoFormData;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
  isClassVideo: boolean;
}

const AddExercise = ({
  videoFormData,
  children,
  successCallback,
  isClassVideo,
}: IAddExerciseProps) => {
  const [requesting, setRequesting] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const refChildren = useRef(children);
  const defaultValues = {
    faqs: [{}],
    language: 'en',
    isClassVideo,
  };

  const form = useForm<ExerciseVideo>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const { dirtyFields } = useFormState({
    control: form.control,
  });

  const onSubmitHandler = async (values: ExerciseVideo) => {
    if (values.isPublished) {
      setRequesting(true);
    } else {
      setSavingDraft(true);
    }
    try {
      const dField = Object.keys(dirtyFields).length;
      if (dField !== 0) {
        await addExercise({ payload: values, isClassVideo });
        setDrawerVisible(false);
        values.isPublished
          ? message.success('exercise published successfully')
          : message.success('exercise added to draft successfully');
        if (successCallback) {
          successCallback();
        }
        form.reset({});
      } else {
        message.error('Nothing Changed in form');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setRequesting(false);
      setSavingDraft(false);
    }
  };

  return (
    <>
      <Drawer
        title={isClassVideo ? 'Add Class Video' : 'Add Exercise'}
        placement="bottom"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width="100%"
        height="100%"
        footer={
          <>
            <Row>
              <Col span={12} style={{ padding: '10px' }}>
                <Button
                  danger
                  block
                  type="primary"
                  loading={savingDraft}
                  onClick={(e) => {
                    form.setValue('isPublished', false);
                    form.handleSubmit(onSubmitHandler)(e);
                  }}
                >
                  Save As Draft
                </Button>
              </Col>
              <Col span={12} style={{ padding: '10px' }}>
                <Button
                  block
                  type="primary"
                  loading={requesting}
                  onClick={(e) => {
                    form.setValue('isPublished', true);
                    form.handleSubmit(onSubmitHandler)(e);
                  }}
                >
                  Publish
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <ExerciseForm
            videoFormData={videoFormData}
            isEditable={false}
            form={form}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddExercise;
