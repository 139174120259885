import { ExerciseCategory } from '../../types/exercise-categories';
import { getTechniqueColor } from '../../utils/common';

const columns = [
  {
    title: 'Sequence',
    render: (value: string, record: ExerciseCategory) => record?.sequence,
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: ExerciseCategory) => (
      <>{record?.name?.en}</>
    ),
    width: 250,
  },
  {
    title: 'Technique and Sections',
    render: (value: string, record: ExerciseCategory) => {
      const desiredTechniquesOrder = ['pbt', 'pct', 'pbt-chinese'];

      return (
        <>
          {desiredTechniquesOrder.map((technique) =>
            record?.sections?.some(
              (item) => item.techniqueSlug === technique
            ) ? (
              <div key={technique}>
                <h4 style={getTechniqueColor(technique)}>{technique} :</h4>
                <p>
                  {record.sections
                    .filter((item) => item.techniqueSlug === technique)
                    .map((item) => item.sectionSlug)
                    .join(', ')}
                </p>
              </div>
            ) : null
          )}
        </>
      );
    },
    width: 400,
  },
];

export default columns;
