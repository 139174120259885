import {
  Button,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
  message,
  Switch,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { ReloadOutlined } from '@ant-design/icons';

import { Workshop } from '../../types/workshop';
import {
  changeWorkshopVisibilityStatus,
  getNewWorkshops,
} from '../../services/workshop-service';
import { handleError } from '../../utils/common';

import columns from './columns';

const NewWorkshops: React.FC = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadWorkshops, setReloadWorkshops] = useState(true);
  const [workshops, setWorkshops] = useState<Workshop[]>([]);

  const [isHiddenVisible, setIsHiddenVisible] = useState(false);

  const isMounted = useRef(false);

  const loadWorkshops = async () => {
    setRequesting(true);
    try {
      const response = await getNewWorkshops(isHiddenVisible);
      setWorkshops(response?.data?.data);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
      setReloadWorkshops(false);
    }
  };

  const handleWorkshopVisibility = async ({
    value,
    workshopId,
  }: {
    value: boolean;
    workshopId: string;
  }) => {
    setRequesting(true);
    try {
      await changeWorkshopVisibilityStatus({
        workshopId,
        payload: {
          listedOnBudgetPage: value,
        },
      });
      message.success('Updated successfully');
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
      setReloadWorkshops(true);
    }
  };

  useEffect(() => {
    if (reloadWorkshops && !requesting) {
      loadWorkshops();
    }
  }, [reloadWorkshops]);

  useEffect(() => {
    if (isMounted.current) {
      setReloadWorkshops(true);
    } else {
      isMounted.current = true;
    }
  }, [isHiddenVisible]);

  const columnsWithActions = [
    ...columns,

    {
      title: 'Actions',
      render: (record: Workshop) => (
        <Space>
          <Tooltip placement="top" title="Hide">
            <Switch
              checked={record?.listedOnBudgetPage}
              onChange={(e) =>
                handleWorkshopVisibility({ workshopId: record?._id, value: e })
              }
            />
          </Tooltip>
        </Space>
      ),
      key: 'actions',
    },
  ];
  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={workshops}
        columns={columnsWithActions}
        rowKey="_id"
        scroll={{ x: 'max-content' }}
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Workshops</Typography.Title>
              <Space>
                <div style={{ marginInline: 10 }}>
                  <span>Show Hidden Records : </span>

                  <Switch
                    checked={isHiddenVisible}
                    onChange={(e) => setIsHiddenVisible(e)}
                  />
                </div>

                <div style={{ marginInline: 10 }}>
                  <Tooltip title="Refresh" placement="left">
                    <Button
                      type="ghost"
                      icon={<ReloadOutlined />}
                      onClick={() => setReloadWorkshops(true)}
                    />
                  </Tooltip>
                </div>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};

export default NewWorkshops;
