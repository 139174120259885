/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { Button, Col, Form, Row, Space, Typography } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import {
  FileUploadButton,
  InputField,
  SelectField,
  TextAreaField,
} from '../common/form';
import { TechniqueDetail } from '../../types/technique';
import { getTechniques } from '../../services/technique-service';

interface IAddEndorsementFormProps {
  form: UseFormReturn<any, object>;
  isEditable: boolean;
}

const EndorsementForm: FC<IAddEndorsementFormProps> = ({
  form,
  isEditable,
}) => {
  const [techniques, setTechniques] = useState<TechniqueDetail[]>([]);
  const { control } = form;

  const {
    fields: designations,
    append: addFaq,
    remove: removeFaq,
  } = useFieldArray({
    control,
    name: 'designations',
  });

  const technique = async () => {
    try {
      const response = await getTechniques();
      setTechniques(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    technique();
  }, []);

  const values = form.getValues();
  return (
    <>
      <Form>
        <Row>
          <Col span={24}>
            <InputField
              label="Sequence No."
              name="sequence"
              required
              inputProps={{
                placeholder: 'Sequence No.',
              }}
              form={form}
            />
          </Col>
          <Col span={24}>
            <SelectField
              label="Technique"
              required
              name="technique"
              form={form}
              labelKey="name.en"
              valueKey="slug"
              selectFieldProps={{
                options: techniques,
                loading: techniques.length < 1,
                placeholder:
                  techniques.length < 1 ? 'Loading...' : 'Select Technique',
                onChange: (v) => form.setValue('technique', v),
                showSearch: true,
                allowClear: true,
              }}
            />
          </Col>
          <Col span={24}>
            <InputField
              label="Full Name"
              name="name"
              required
              inputProps={{
                placeholder: 'Fullname',
              }}
              form={form}
            />
          </Col>

          <br />
          <Col span={24}>
            <TextAreaField
              label="Description ( EN )"
              name="description.en"
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <Col span={24}>
            <TextAreaField
              label="Description ( ES )"
              name="description.es"
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <Col span={24}>
            <TextAreaField
              label="Description ( DE )"
              name="description.de"
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <Col span={24}>
            <TextAreaField
              label="Description ( IT )"
              name="description.it"
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <Col span={24}>
            <TextAreaField
              label="Description ( PT )"
              name="description.pt"
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <Col span={24}>
            <TextAreaField
              label="Description ( ZH )"
              name="description.zh"
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <br />
          <Col span={24}>
            <TextAreaField
              label="Message ( EN )"
              name="message.en"
              required
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <Col span={24}>
            <TextAreaField
              label="Message ( ES )"
              name="message.es"
              required
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <Col span={24}>
            <TextAreaField
              label="Message ( DE )"
              name="message.de"
              required
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <Col span={24}>
            <TextAreaField
              label="Message ( IT )"
              name="message.it"
              required
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <Col span={24}>
            <TextAreaField
              label="Message ( PT )"
              name="message.pt"
              required
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <Col span={24}>
            <TextAreaField
              label="Message ( ZH )"
              name="message.zh"
              required
              textAreaProps={{
                placeholder: 'Description',
              }}
              form={form}
            />
          </Col>
          <br />
          <Col span={24}>
            <FileUploadButton
              form={form}
              defaultFileList={
                isEditable && values?.pic
                  ? [
                      {
                        uid: '-1',
                        name: `${values?.name}.png`,
                        status: 'done',
                        url: values?.pic,
                      },
                    ]
                  : []
              }
              label="Picture"
              required
              name="pic"
              buttonLabel="Upload Image"
            />
          </Col>
          <br />
          <Col span={24}>
            <FileUploadButton
              form={form}
              defaultFileList={
                isEditable && values?.sliderPic
                  ? [
                      {
                        uid: '-1',
                        name: `${values?.name}.png`,
                        status: 'done',
                        url: values?.sliderPic,
                      },
                    ]
                  : []
              }
              label="Slider Picture"
              required
              name="sliderPic"
              buttonLabel="Upload Image"
            />
          </Col>
        </Row>
        {designations.map((field, index) => {
          return (
            <Row key={index}>
              <Typography.Title level={4}>
                Designation {index + 1}
              </Typography.Title>
              <Col span={24}>
                <InputField
                  name={`designations[${index}].title`}
                  label="Title"
                  required
                  inputProps={{
                    placeholder: `Designation ${index + 1} title`,
                  }}
                  form={form}
                />
                <InputField
                  name={`designations[${index}].schoolname`}
                  label="School Name"
                  required
                  inputProps={{
                    placeholder: `Designation ${index + 1} school name`,
                  }}
                  form={form}
                />
                <InputField
                  name={`designations[${index}].schoolweb`}
                  label="School's Website Link"
                  required
                  inputProps={{
                    placeholder: `Designation ${index + 1} school website link`,
                  }}
                  form={form}
                />

                <Row>
                  <Col
                    span={24}
                    style={{
                      paddingTop: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    <Space
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {index + 1 === designations.length && (
                        <Button
                          type="primary"
                          onClick={() => {
                            addFaq({
                              title: '',
                              schoolname: '',
                              schoolweb: '',
                            });
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      )}
                      {(index !== 0 || designations.length > 1) && (
                        <Button
                          type="primary"
                          danger
                          onClick={() => {
                            removeFaq(index);
                          }}
                        >
                          <DeleteOutlined style={{ color: 'red' }} />
                        </Button>
                      )}
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </Form>
    </>
  );
};

export default EndorsementForm;
