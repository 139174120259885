import * as yup from 'yup';

export default yup.object().shape({
  renewalYear: yup
    .number()
    .typeError('Renewal Year should be number')
    .required('Renewal Year is required'),
  category: yup.number().required('Question Category is required'),
  video: yup.string().required('Video is required'),
  sequence: yup.number().required('Sequence is required'),
  questions: yup
    .array()
    .of(
      yup.object().shape({
        en: yup
          .string()
          .required('Please input assessment question in english'),
        pt: yup
          .string()
          .required('Please input assessment question in portugese'),
        it: yup
          .string()
          .required('Please input assessment question in italian'),
        es: yup
          .string()
          .required('Please input assessment question in spanish'),
        de: yup.string().required('Please input assessment question in german'),
        zh: yup
          .string()
          .required('Please input assessment question in mandrin'),
      })
    )
    .required('Please input assessment question')
    .min(1, 'Minimum one field is required'),
});
