import { Col, Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { TechniqueDetail } from '../../../types/technique';
import { SectionDetail } from '../../../types/sections';
import {
  InputField,
  SelectField,
  FileUploadButton,
  CheckboxField,
} from '../../common/form';
import SlugNote from '../../common/slug-note';

export type SectionsResArr = {
  value: string;
  label: string;
};
interface ISectionsFormProps {
  form: UseFormReturn<SectionDetail, object>;
  isEditable: boolean;
  techniques: TechniqueDetail[];
}
const SectionsForm = ({
  form,
  techniques,
  isEditable = true,
}: ISectionsFormProps) => {
  return (
    <>
      <Form layout="vertical">
        <Col>
          <InputField
            label="Slug"
            name="slug"
            required
            inputProps={{
              placeholder: 'Slug : eg(advanced-exer)',
            }}
            form={form}
            style={{ marginBottom: '4px' }}
            disabled={isEditable}
          />
          <SlugNote />
        </Col>
        <SelectField
          label="Technique"
          required
          name="techniqueId"
          form={form}
          labelKey="name.en"
          valueKey="slug"
          selectFieldProps={{
            options: techniques,
            placeholder: 'Select technique',
            onChange: (v) => form.setValue('techniqueId', v),
            showSearch: true,
            allowClear: true,
            mode: 'multiple',
          }}
        />
        <InputField
          label="Name EN"
          name="name.en"
          required
          inputProps={{
            placeholder: 'English',
          }}
          form={form}
        />
        <InputField
          label="Name PT"
          name="name.pt"
          required
          inputProps={{
            placeholder: 'Portuguese',
          }}
          form={form}
        />
        <InputField
          label="Name IT"
          name="name.it"
          required
          inputProps={{
            placeholder: 'Italian',
          }}
          form={form}
        />
        <InputField
          label="Name ES"
          name="name.es"
          required
          inputProps={{
            placeholder: 'Spanish',
          }}
          form={form}
        />
        <InputField
          label="Name DE"
          name="name.de"
          required
          inputProps={{
            placeholder: 'German',
          }}
          form={form}
        />
        <InputField
          label="Name ZH"
          name="name.zh"
          required
          inputProps={{
            placeholder: 'Mandrin',
          }}
          form={form}
        />

        <InputField
          label="Min Age"
          name="ageGroup.min"
          required
          inputProps={{
            placeholder: 'Min Age',
            type: 'number',
          }}
          form={form}
        />
        <InputField
          label="Max Age"
          name="ageGroup.max"
          inputProps={{
            placeholder: 'Max Age',
            type: 'number',
          }}
          form={form}
        />
        <InputField
          label="Sequence"
          name="sequence"
          required
          inputProps={{
            placeholder: 'Order in which junior,senior etc appears',
          }}
          form={form}
        />
        <>
          <FileUploadButton
            form={form}
            triggerValidate={() => form.trigger('tutorialPic')}
            defaultFileList={
              isEditable && form.watch('tutorialPic')
                ? [
                    {
                      uid: '-1',
                      name: 'Tutorial Image',
                      status: 'done',
                      url: form.watch('tutorialPic') as string,
                    },
                  ]
                : []
            }
            required
            label="Tutorial Image"
            name="tutorialPic"
            buttonLabel="Click to Upload Pic"
          />

          <FileUploadButton
            form={form}
            triggerValidate={() => form.trigger('classPlanPic')}
            defaultFileList={
              isEditable && form.watch('classPlanPic')
                ? [
                    {
                      uid: '-1',
                      name: 'Class Plan Image',
                      status: 'done',
                      url: form.watch('classPlanPic') as string,
                    },
                  ]
                : []
            }
            required
            label="Class Plan Image"
            name="classPlanPic"
            buttonLabel="Click to Upload Pic"
          />
        </>
        <CheckboxField
          label="Is Class Section Too"
          message="Yes / No"
          name="isClassToo"
          form={form}
          required
          defaultChecked={form.getValues('isClassToo') || false}
        />
      </Form>
    </>
  );
};

export default SectionsForm;
