import { Card, Descriptions, Typography } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { TUser } from '../../../../services/users-service';
import { NOT_AVAILABLE } from '../../../../constants/common';
import { convertStripeAmount } from '../../../../utils/common';
import FormattedDate from '../../../date/FormattedDate';

interface IInvoiceDetails {
  user: TUser;
}

const InvoiceDetails: FC<IInvoiceDetails> = ({ user }) => {
  return (
    <Card title="Latest Invoice on Subscription" className="info-container">
      <Descriptions
        layout="vertical"
        column={2}
        labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
      >
        <Descriptions.Item label="INVOICE ID" className="info-item">
          {user.subscription?.latest_invoice?.id || NOT_AVAILABLE}
          <Typography.Text className="openStatus">OPEN</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="INVOICE NUMBER" className="info-item">
          {user.subscription?.latest_invoice?.number || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="INVOICE ACCOUNT NAME" className="info-item">
          {user.subscription?.latest_invoice?.account_name || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="AMOUNT DUE" className="info-item">
          {user.subscription?.latest_invoice?.amount_due
            ? `${user?.subscription?.latest_invoice?.currency.toUpperCase()}${convertStripeAmount(
                user?.subscription?.latest_invoice?.amount_due,
                user?.subscription?.latest_invoice?.currency
              )}`
            : NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="AMOUNT PAID" className="info-item">
          {user?.subscription?.latest_invoice?.amount_paid
            ? `${user?.subscription?.latest_invoice?.currency?.toUpperCase()} ${convertStripeAmount(
                user?.subscription?.latest_invoice?.amount_paid,
                user?.subscription?.latest_invoice?.currency
              )}`
            : NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="AMOUNT REMAINING" className="info-item">
          {user.subscription?.latest_invoice?.amount_paid
            ? `${user?.subscription?.latest_invoice?.currency.toUpperCase()} ${convertStripeAmount(
                user?.subscription?.latest_invoice?.amount_remaining,
                user?.subscription?.latest_invoice?.currency
              )}`
            : NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="ATTEMPTED" className="info-item">
          {user?.subscription?.latest_invoice?.attempted
            ? `Yes ${user?.subscription?.latest_invoice?.attempt_count} times`
            : 'NO'}
        </Descriptions.Item>
        <Descriptions.Item label="NEXT ATTEMPT" className="info-item">
          {user?.subscription?.latest_invoice?.next_payment_attempt ? (
            <>
              <FormattedDate
                date={user?.subscription?.latest_invoice?.next_payment_attempt}
                fromNow={false}
                ShowTime={false}
              />
              UTC
            </>
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label="AUTO ADVANCE (AUTO COLLECT)"
          className="info-item"
        >
          {user?.subscription?.latest_invoice?.auto_advance === true
            ? `Yes `
            : 'NO'}
        </Descriptions.Item>
        <Descriptions.Item label="BILLING REASON" className="info-item">
          {user?.subscription?.latest_invoice?.billing_reason || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="CHARGE" className="info-item">
          {user?.subscription?.latest_invoice?.charge || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="COLLECTION METHOD" className="info-item">
          {user?.subscription?.latest_invoice?.collection_method ||
            NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="DEFAULT PAYMENT METHOD" className="info-item">
          {user?.subscription?.latest_invoice?.default_payment_method ||
            NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="DESCRIPTION" className="info-item">
          {user?.subscription?.latest_invoice?.description || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="DUE DATE" className="info-item">
          {user?.subscription?.latest_invoice?.due_date ? (
            <FormattedDate
              date={user?.subscription?.latest_invoice?.due_date}
              fromNow={false}
              ShowTime={false}
            />
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label="LINKS (HOSTED ON STRIPE)"
          className="info-item"
        >
          {(user?.subscription?.latest_invoice?.hosted_invoice_url && (
            <Typography.Text>
              <Link
                to={{
                  pathname: `${user?.subscription?.latest_invoice?.hosted_invoice_url}`,
                }}
                target="_blank"
              >
                View
              </Link>
              {' | '}
              <Link
                to={{
                  pathname: `${user?.subscription?.latest_invoice.invoice_pdf}`,
                }}
                target="_blank"
              >
                Download
              </Link>
            </Typography.Text>
          )) ||
            NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="PAID OUT OF BAND" className="info-item">
          {user?.subscription?.latest_invoice?.paid_out_of_band === true
            ? `Yes `
            : 'NO'}
        </Descriptions.Item>
        <Descriptions.Item label="PAYMENT INTENT" className="info-item">
          {user?.subscription?.latest_invoice?.payment_intent || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="FINALIZED AT" className="info-item">
          {user?.subscription?.latest_invoice?.status_transitions
            ?.finalized_at ? (
            <FormattedDate
              date={
                user?.subscription?.latest_invoice?.status_transitions
                  ?.finalized_at
              }
              fromNow={false}
              ShowTime={false}
            />
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label="MARKED UNCOLLECTIBLE AT"
          className="info-item"
        >
          {user?.subscription?.latest_invoice?.status_transitions
            ?.marked_uncollectible_at ? (
            <FormattedDate
              date={
                user?.subscription?.latest_invoice?.status_transitions
                  ?.marked_uncollectible_at
              }
              fromNow={false}
              ShowTime={false}
            />
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item label="PAID AT" className="info-item">
          {user?.subscription?.latest_invoice?.status_transitions?.paid_at ? (
            <FormattedDate
              date={
                user?.subscription?.latest_invoice?.status_transitions.paid_at
              }
              fromNow={false}
              ShowTime={false}
            />
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item label="VOIDED AT" className="info-item">
          {user?.subscription?.latest_invoice?.status_transitions?.voided_at ? (
            <FormattedDate
              date={
                user?.subscription?.latest_invoice?.status_transitions
                  ?.voided_at
              }
              fromNow={false}
              ShowTime={false}
            />
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label="DISCOUNT CHECKOUT SESSION"
          className="info-item"
        >
          {user?.subscription?.latest_invoice?.discount?.id || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="DISCOUNT COUPON ID" className="info-item">
          {user?.subscription?.latest_invoice?.discount?.coupon?.id ||
            NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="DISCOUNT COUPON NAME" className="info-item">
          {user?.subscription?.latest_invoice?.discount?.coupon?.name ||
            NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item
          label="DISCOUNT COUPON DURATION"
          className="info-item"
        >
          {user?.subscription?.latest_invoice?.discount?.coupon?.duration ||
            NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item
          label="DISCOUNT COUPON PERCENT OFF"
          className="info-item"
        >
          {user?.subscription?.latest_invoice?.discount?.coupon?.percent_off
            ? `${user.subscription.latest_invoice.discount.coupon.percent_off}%`
            : NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item
          label="DISCOUNT COUPON DISCOUNT ENDS ON"
          className="info-item"
        >
          {user?.subscription?.latest_invoice?.discount?.end ? (
            <FormattedDate
              date={user?.subscription?.latest_invoice?.discount?.end}
              fromNow={false}
            />
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label="DISCOUNT COUPON APPLIED ON"
          className="info-item"
        >
          {user?.subscription?.latest_invoice?.discount?.start ? (
            <FormattedDate
              date={user?.subscription?.latest_invoice?.discount?.start}
              fromNow={false}
            />
          ) : (
            NOT_AVAILABLE
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label="SUBSCRIPTION ID THE DISCOUNT COUPON IS APPLIED TO"
          className="info-item"
        >
          {user?.subscription?.latest_invoice?.discount?.subscription ||
            NOT_AVAILABLE}
          {user?.subscription?.latest_invoice?.discount?.subscription ||
            NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="DISCOUNT PROMO CODE ID" className="info-item">
          {user?.subscription?.latest_invoice?.discount?.promotion_code?.id ||
            NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="DISCOUNT PROMO CODE" className="info-item">
          {user.subscription?.latest_invoice?.discount?.promotion_code?.code ||
            NOT_AVAILABLE}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default InvoiceDetails;
