import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { ReloadOutlined, EyeOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { PaginationProps } from 'antd/es/pagination';

import { IBlockedTeacherContactEmails } from '../../types/blocked-teacher-contact-emails';
import { getBlockedTeacherContactEmails } from '../../services/blocked-teacher-contat-emails';
import { handleError } from '../../utils/common';

import columns from './columns';
import ViewEmail from './view-email';

const BlockedTeacherContactEmails = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadTemplate, setReloadTemplate] = useState(false);
  const [emails, setEmails] = useState<{
    totalDocuments: number;
    totalPages: number;
    currentPage: string;
    blockedEmails: IBlockedTeacherContactEmails[];
  }>({
    totalDocuments: 0,
    totalPages: 0,
    currentPage: '1',
    blockedEmails: [],
  });
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const page = queryParams.get('page')
    ? parseInt(queryParams.get('page') || '')
    : 1;
  const limit = queryParams.get('limit')
    ? parseInt(queryParams.get('limit') || '')
    : 10;

  const loadEmails = async () => {
    setRequesting(true);
    try {
      const response = await getBlockedTeacherContactEmails(page, limit);
      setEmails(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadTemplate(false);
    }
  };

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: IBlockedTeacherContactEmails) => {
        return (
          <Space size="large">
            <ViewEmail
              payload={record}
              successCallback={() => setReloadTemplate(true)}
              errorCallback={handleError}
            >
              {({ onClick }) => <EyeOutlined onClick={onClick} />}
            </ViewEmail>
          </Space>
        );
      },
    },
  ];

  const handleTableChange: PaginationProps['onChange'] = (pagination, size) => {
    navigate({
      pathname: location.pathname,
      search: `?page=${pagination}&limit=${size}`,
    });
  };

  useEffect(() => {
    if (!requesting && reloadTemplate) {
      loadEmails();
    }
  }, [reloadTemplate, requesting]);

  useEffect(() => {
    setReloadTemplate(true);
  }, [page, limit]);

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={emails?.blockedEmails}
        columns={columnsWithActions}
        rowKey="_id"
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>
                Blocked Teacher Contact Emails
              </Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadTemplate(true)}
                  />
                </Tooltip>
              </Space>
            </div>
          );
        }}
        pagination={{
          position: ['bottomRight'],
          total: emails?.totalDocuments,
          onChange: handleTableChange,
          showSizeChanger: true,
          current: page,
          pageSize: limit,
        }}
      />
    </Spin>
  );
};
export default BlockedTeacherContactEmails;
