import { MARKETING_ASSETS } from '../constants/api';
import { IMarketingAssets } from '../types/marketingAssets';
import fetchClient from '../utils/fetch-client';

export const getMarketingAssets = () => {
  return fetchClient.get(`${MARKETING_ASSETS.MARKETING_ASSETS}/all`);
};
export const addMarketingAssets = (payload: IMarketingAssets) => {
  return fetchClient.post(`${MARKETING_ASSETS.MARKETING_ASSETS}`, payload);
};
export const updateMarketingAssets = (
  payload: IMarketingAssets,
  assetId: string
) => {
  return fetchClient.put(
    `${MARKETING_ASSETS.MARKETING_ASSETS}/${assetId}`,
    payload
  );
};
export const deleteMarketingAssets = (assetId: string) => {
  return fetchClient.delete(`${MARKETING_ASSETS.MARKETING_ASSETS}/${assetId}`);
};
