/* eslint-disable @typescript-eslint/no-explicit-any */

import { Controller, UseFormReturn } from 'react-hook-form';
import './editor.css';
import { Form } from 'antd';
import _ from 'lodash';
import { ReactNode, useEffect, useState } from 'react';

import uploadPlugin from './UploadPlugin';
import BaseEditor from './BaseEditor';

interface CKEditorWrapperProps {
  label?: string | ReactNode;
  required?: boolean;
  name: string;
  readOnly?: boolean;
  form: UseFormReturn<any, any>;
  uploadIdParam?: string;
}

const CKEditorWrapper = ({
  form: {
    formState: { errors },
    control,
  },
  label,
  readOnly = false,
  required,
  name,
}: CKEditorWrapperProps) => {
  const [isInitialRender, setIsInitialRender] = useState(true);

  const errorMessages = _.get(errors, name);
  const hasError = !!(errors && errorMessages);

  useEffect(() => setIsInitialRender(false), []);

  return !isInitialRender ? (
    <Form.Item
      label={label}
      help={errorMessages?.message}
      validateStatus={hasError ? 'error' : 'success'}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <BaseEditor
            readOnly={readOnly}
            field={field}
            extraPlugins={[uploadPlugin]}
          />
        )}
      />
    </Form.Item>
  ) : null;
};

export default CKEditorWrapper;
