import { UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Form } from 'antd';

import { InputField, SelectField } from '../../common/form';
import { getWorkshopsForTransfer } from '../../../services/workshop-service';
import { handleError } from '../../../utils/common';
import { Workshop } from '../../../types/workshop';

interface IAddUsertoWorkshopFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
}
const AddUsertoWorkshopForm = ({ form }: IAddUsertoWorkshopFormProps) => {
  const [loading, setLoading] = useState(false);
  const [workshops, setWorkshops] = useState<Workshop[]>([]);

  const loadWorkshops = async () => {
    setLoading(true);
    try {
      const response = await getWorkshopsForTransfer();
      setWorkshops(response?.data?.data);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadWorkshops();
  }, []);

  return (
    <Form layout="vertical">
      <SelectField
        name="workshopSlug"
        form={form}
        labelKey="label"
        valueKey="value"
        selectFieldProps={{
          options: workshops?.map((workshop) => ({
            label: (
              <p className="no-margin" style={{ textTransform: 'capitalize' }}>
                {workshop?.title} with {workshop?.workshopInstructor?.fullname}{' '}
                -{' '}
                {moment(workshop?.startDate)
                  .tz(workshop.timezone || 'UTC')
                  .format('ll h:mm')}
              </p>
            ),
            value: workshop?.slug,
            loading: loading,
          })),
          placeholder: 'Select the event to transfer in',
        }}
      />

      <InputField
        name="note"
        form={form}
        inputProps={{
          placeholder: 'Note',
        }}
      />
      <p>
        Note: Add transfer note here. Use pipe | as note separator. Eg:
        <strong>
          Attendee is non-dancer | Transfer requested via email to
          myemail@pbt.dance dtd 20/04/2022
        </strong>
      </p>
    </Form>
  );
};

export default AddUsertoWorkshopForm;
