import { Card, Descriptions } from 'antd';
import { FC } from 'react';
import { EditOutlined } from '@ant-design/icons';

import { TUser } from '../../../../services/users-service';
import { NOT_AVAILABLE } from '../../../../constants/common';

import EditBusinessDetails from './EditBusinessDetails';

interface BusinessDetailsProps {
  user: TUser;
  successCallback: (e: boolean) => void;
}
const BusinessDetails: FC<BusinessDetailsProps> = ({
  user,
  successCallback,
}) => {
  return (
    <Card title="Business Details for Invoicing" className="info-container">
      <span className="info-heading">
        <EditBusinessDetails
          userId={user?._id}
          user={{ ...user }}
          successCallback={successCallback}
        >
          {({ onClick }) => (
            <EditOutlined onClick={onClick} style={{ float: 'right' }} />
          )}
        </EditBusinessDetails>
      </span>
      <Descriptions
        layout="vertical"
        column={2}
        labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
      >
        <Descriptions.Item label="NAME (AS ON INVOICE)" className="info-item">
          {user?.invoiceName || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="BUSINESS NAME" className="info-item">
          {user?.businessName || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="BUSINESS ADDRESS" className="info-item">
          {user?.invoiceAddress || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="POSTAL CODE" className="info-item">
          {user?.invoicePostalCode || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="BUSINESS COUNTRY" className="info-item">
          {user?.invoiceCountry || NOT_AVAILABLE}
        </Descriptions.Item>
        <Descriptions.Item label="TAX IDs" className="info-item">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
              marginTop: '5px',
            }}
          >
            {user?.taxIds && user?.taxIds?.length
              ? user?.taxIds?.map((i, index) => {
                  return (
                    <p
                      key={index}
                      style={{ margin: '0', padding: '0' }}
                    >{`${i.taxIdName} : ${i.taxIdNumber}`}</p>
                  );
                })
              : NOT_AVAILABLE}
          </div>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default BusinessDetails;
