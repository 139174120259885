import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { WorkshopPhotos } from '../../../types/workshop';
import { FileUploadButton } from '../../common/form';

interface IMuscleFormProps {
  form: UseFormReturn<WorkshopPhotos, object>;
}
const WorkshopPhotosForm = ({ form }: IMuscleFormProps) => {
  const values = form.getValues();
  return (
    <>
      <Form layout="vertical">
        <>
          <FileUploadButton
            form={form}
            triggerValidate={() => form.trigger(['pic1'])}
            defaultFileList={
              values.pic1
                ? [
                    {
                      uid: '-1',
                      name: 'pic1',
                      status: 'done',
                      url: values.pic1,
                    },
                  ]
                : []
            }
            label="Photo 1 (Required)"
            name="pic1"
            buttonLabel="Click to Upload Photo"
            required
          />
          <FileUploadButton
            form={form}
            defaultFileList={
              values.pic2
                ? [
                    {
                      uid: '-1',
                      name: 'pic2',
                      status: 'done',
                      url: values.pic2,
                    },
                  ]
                : []
            }
            label="Photo 2 (optional)"
            name="pic2"
            buttonLabel="Click to Upload Photo"
          />
          <FileUploadButton
            form={form}
            defaultFileList={
              values.pic3
                ? [
                    {
                      uid: '-1',
                      name: 'pic3',
                      status: 'done',
                      url: values.pic3,
                    },
                  ]
                : []
            }
            label="Photo 3 (optional)"
            name="pic3"
            buttonLabel="Click to Upload Photo"
          />
          <FileUploadButton
            form={form}
            defaultFileList={
              values.pic4
                ? [
                    {
                      uid: '-1',
                      name: 'pic4',
                      status: 'done',
                      url: values.pic4,
                    },
                  ]
                : []
            }
            label="Photo 4 (optional)"
            name="pic4"
            buttonLabel="Click to Upload Photo"
          />
          <FileUploadButton
            form={form}
            defaultFileList={
              values.pic5
                ? [
                    {
                      uid: '-1',
                      name: 'pic5',
                      status: 'done',
                      url: values.pic5,
                    },
                  ]
                : []
            }
            label="Photo 5 (optional)"
            name="pic5"
            buttonLabel="Click to Upload Photo"
          />
          <FileUploadButton
            form={form}
            defaultFileList={
              values.pic6
                ? [
                    {
                      uid: '-1',
                      name: 'pic6',
                      status: 'done',
                      url: values.pic6,
                    },
                  ]
                : []
            }
            label="Photo 6 (optional)"
            name="pic6"
            buttonLabel="Click to Upload Photo"
          />
        </>
      </Form>
    </>
  );
};

export default WorkshopPhotosForm;
