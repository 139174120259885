/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

const certificationSchema = yup.object().shape({
  assignedCertificationCode: yup.string().required('Technique is required'),
  certificationDate: yup
    .date()
    .typeError('Certification date is required')
    .required('Certification date is required'),
  assignedCertificationLevel: yup
    .object()
    .when(
      'assignedCertificationCode',
      (assignedCertificationCode: string, schema: any) => {
        if (assignedCertificationCode) {
          return schema.shape({
            [assignedCertificationCode]: yup
              .number()
              .required('Certification level is required'),
          });
        }
        return schema;
      }
    ),
});

export default certificationSchema;
