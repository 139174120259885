import * as yup from 'yup';

export default yup.object().shape({
  fileType: yup.string().required('Required'),
  techniqueSlug: yup.string().required('Required'),
  url: yup.string().required('Required'),
  previewUrl: yup.string().required('Required'),
  name: yup.string().required('Required'),
  language: yup.string().required('Required'),
});
