import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { getCertificate } from '../../services/create-certificate';
import { getTechniques } from '../../services/technique-service';
import { CreateCertificate } from '../../types/create-certificate';
import { handleError } from '../../utils/common';
import { DatePickerField, InputField, SelectField } from '../common/form';

import validationSchema from './validation-schema';

const CertificateForm = () => {
  const [requesting, setRequesting] = useState(false);
  const [techniques, setTechniques] = useState([]);
  const form = useForm<CreateCertificate>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const onSubmitHandler = async (values: CreateCertificate) => {
    setRequesting(true);
    try {
      const response = await getCertificate(values);
      window.open(response?.data?.data, '_blank');
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
    }
  };

  const getAllTechnique = async () => {
    const response = await getTechniques();
    setTechniques(response?.data?.data);
  };

  useEffect(() => {
    getAllTechnique();
  }, []);

  return (
    <Form layout={'vertical'}>
      <Row>
        <Col span={12}>
          <DatePickerField
            label={'Certification Date'}
            name="certificationDate"
            required
            form={form}
          />
        </Col>
        <Col span={12}>
          <InputField
            label="Name to appear on the certificate"
            name="nameAsOnCertificate"
            required
            inputProps={{
              placeholder: 'John Doe',
            }}
            form={form}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <InputField
            label="Level"
            name="level"
            required
            inputProps={{
              placeholder: '1,2 or 3',
              type: 'number',
            }}
            form={form}
          />
        </Col>
        <Col span={12}>
          <SelectField
            label="Select Technique"
            required
            name="technique"
            form={form}
            labelKey="name.en"
            valueKey="_id"
            selectFieldProps={{
              options: techniques,
              placeholder:
                techniques.length < 1 ? 'Loading...' : 'Select Techniques',
              loading: techniques.length < 1,
              showSearch: true,
              allowClear: true,
              onChange: (v) => form.setValue('technique', v),
            }}
          />
        </Col>
        <Col span={12}>
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CertificateForm;
