import moment from 'moment';

import { ICreditList } from '../../types/credit-list';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 100,
  },
  {
    title: 'User Detail',
    render: (record: ICreditList) => (
      <span>
        {record?.fullName} ({record?.email})
      </span>
    ),
    key: 'name',
    width: 350,
  },
  {
    title: 'From Workshop',
    render: (record: ICreditList) => {
      return (
        <p className="no-margin" style={{ textTransform: 'capitalize' }}>
          {record?.attendeeDetails?.workshopDetails?.title} with{' '}
          {
            record?.attendeeDetails?.workshopDetails?.workshopInstructor
              ?.fullname
          }{' '}
          -{' '}
          {moment(record?.attendeeDetails?.workshopDetails?.startDate)
            .tz(record?.attendeeDetails?.workshopDetails?.timezone || 'UTC')
            .format('ll h:mm')}
        </p>
      );
    },
    key: 'workshop',
    width: 350,
  },
  {
    title: 'Note',
    render: (record: ICreditList) => {
      return <span>{record?.note}</span>;
    },
    key: 'workshop',
    width: 350,
  },
];

export default columns;
