import { Routes, Route } from 'react-router-dom';

import NoMatch from './components/common/no-match';
import AppLayout from './components/layout';
import Auth from './containers/authentication/index';
import RequireAuth from './containers/requires-auth';
import Questions from './containers/questions';
import Equipment from './containers/equipment';
import Workshops from './containers/workshops';
import CreateWorkshop from './components/workshops/create-workshops';
// import Templates from './containers/template';
import Faq from './containers/faq/faq';
import FaqCategry from './containers/faq/faq-category';
import Users from './containers/users';
import UserDetails from './containers/users/user-details';
import Instructor from './containers/instructors';
import Technique from './containers/technique';
import Sections from './containers/video/section';
import MuscleCategory from './containers/muscle/muscle-category';
import Muscle from './containers/muscle/muscle';
import ExerciseCategories from './containers/exercise-categories';
import ExerciseEquipments from './containers/exercise-equipments';
import Exercise from './containers/exercises';
import CertificationCategories from './containers/certification-categories';
import { AuthStateProvider } from './context/auth-state';
import SessionRefresher from './containers/session-refresher';
import AssessmentQuestions from './containers/assessment-questions';
import AssessmentCategories from './containers/assessment-categories';
import ManageWorkshop from './containers/manage-workshop';
import CreateCertificate from './containers/create-certificate';
import FilesDirectory from './containers/files-directory';
import ExerciseClass from './containers/exercise-classes';
import ClassVideos from './containers/class-videos';
import AssessmentResponses from './containers/assessment-response';
import GiftVoucherProducts from './containers/gift-voucher/products';
import GiftVoucherOrders from './containers/gift-voucher/orders';
import MarketingAssets from './containers/marketing-assets';
import CertificationQuestions from './containers/certification-renewal-questions';
import UploadedCertficateResponses from './containers/uploaded-certificates';
import Endorsements from './containers/endorsement-and-testimonials';
import Admins from './containers/admins';
import UserPages from './containers/user-pages';
import NewWorkshops from './containers/new-workshops';
import FilteredWorkshopAttendees from './containers/workshops/search-workshop-attendees';
import BlogCategories from './containers/blog-categories';
import Blog from './containers/blog';
import UserDataDownload from './containers/user-data-download';
import WorkshopDataDownload from './containers/workshop-data-download';
import WorkshopDiscountCouponManager from './components/workshops/workshop-discount-coupons';
import CreditList from './components/credit-list';
import EmailAttachments from './containers/email-attachments';
import CertificationRenewalResponses from './containers/certification-renewal-response';
import UnansweredComments from './containers/unanswered-comments';
import FlagList from './containers/flag-list';
import BlockedTeacherContactEmails from './containers/blocked-teacher-contact-emails';
import Deals from './containers/deals';

const App = () => {
  return (
    <AuthStateProvider>
      <>
        <Routes>
          <Route element={<AppLayout />}>
            <Route path="/" element={<Auth />} />
            <Route
              path="/all-users"
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
            />
            <Route
              path="/all-admins"
              element={
                <RequireAuth>
                  <Admins />
                </RequireAuth>
              }
            />
            <Route
              path="/userdetails/:id"
              element={
                <RequireAuth>
                  <UserDetails />
                </RequireAuth>
              }
            />
            <Route
              path="/equipment"
              element={
                <RequireAuth>
                  <Equipment />
                </RequireAuth>
              }
            />
            <Route
              path="/questions"
              element={
                <RequireAuth>
                  <Questions />
                </RequireAuth>
              }
            />
            <Route
              path="/workshops"
              element={
                <RequireAuth>
                  <Workshops />
                </RequireAuth>
              }
            />
            <Route
              path="/create-workshop"
              element={
                <RequireAuth>
                  <CreateWorkshop />
                </RequireAuth>
              }
            />
            <Route
              path="/new-workshops"
              element={
                <RequireAuth>
                  <NewWorkshops />
                </RequireAuth>
              }
            />
            <Route
              path="/search-attendees"
              element={
                <RequireAuth>
                  <FilteredWorkshopAttendees />
                </RequireAuth>
              }
            />

            <Route
              path="/edit-workshop/:workshopSlug"
              element={
                <RequireAuth>
                  <CreateWorkshop />
                </RequireAuth>
              }
            />
            {/* <Route
              path="/workshop-refund-policy"
              element={
                <RequireAuth>
                  <Templates />
                </RequireAuth>
              }
            /> */}
            <Route
              path="/faq"
              element={
                <RequireAuth>
                  <Faq />
                </RequireAuth>
              }
            />
            <Route
              path="/user-pages"
              element={
                <RequireAuth>
                  <UserPages />
                </RequireAuth>
              }
            />
            <Route
              path="/marketing-assets"
              element={
                <RequireAuth>
                  <MarketingAssets />
                </RequireAuth>
              }
            />
            <Route
              path="/faq-category"
              element={
                <RequireAuth>
                  <FaqCategry />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NoMatch />} />
            <Route
              path="/create-workshop"
              element={
                <RequireAuth>
                  <CreateWorkshop />
                </RequireAuth>
              }
            />
            <Route
              path="/edit-workshop/:workshopSlug"
              element={
                <RequireAuth>
                  <CreateWorkshop />
                </RequireAuth>
              }
            />
            <Route
              path="/instructors"
              element={
                <RequireAuth>
                  <Instructor />
                </RequireAuth>
              }
            />
            <Route
              path="/sections"
              element={
                <RequireAuth>
                  <Sections />
                </RequireAuth>
              }
            />
            <Route
              path="/categories"
              element={
                <RequireAuth>
                  <ExerciseCategories />
                </RequireAuth>
              }
            />
            <Route
              path="/classes"
              element={
                <RequireAuth>
                  <ExerciseClass />
                </RequireAuth>
              }
            />
            <Route
              path="/assessment-categories"
              element={
                <RequireAuth>
                  <AssessmentCategories />
                </RequireAuth>
              }
            />
            <Route
              path="/muscle-categories"
              element={
                <RequireAuth>
                  <MuscleCategory />
                </RequireAuth>
              }
            />
            <Route
              path="/muscle"
              element={
                <RequireAuth>
                  <Muscle />
                </RequireAuth>
              }
            />
            <Route
              path="/exercise-equipment"
              element={
                <RequireAuth>
                  <ExerciseEquipments />
                </RequireAuth>
              }
            />
            <Route
              path="/technique"
              element={
                <RequireAuth>
                  <Technique />
                </RequireAuth>
              }
            />
            <Route
              path="/assessment-questions"
              element={
                <RequireAuth>
                  <AssessmentQuestions />
                </RequireAuth>
              }
            />
            <Route
              path="/assessment-response/certification"
              element={
                <RequireAuth>
                  <AssessmentResponses />
                </RequireAuth>
              }
            />
            <Route
              path="/assessment-response-approved/certification"
              element={
                <RequireAuth>
                  <AssessmentResponses />
                </RequireAuth>
              }
            />
            <Route
              path="/assessment-response/badge"
              element={
                <RequireAuth>
                  <AssessmentResponses />
                </RequireAuth>
              }
            />
            <Route
              path="/assessment-response-approved/badge"
              element={
                <RequireAuth>
                  <AssessmentResponses />
                </RequireAuth>
              }
            />
            <Route
              path="/uploaded-certificate-response"
              element={
                <RequireAuth>
                  <UploadedCertficateResponses />
                </RequireAuth>
              }
            />
            <Route
              path="/uploaded-certificate-response-approved"
              element={
                <RequireAuth>
                  <UploadedCertficateResponses />
                </RequireAuth>
              }
            />
            <Route
              path="/videos"
              element={
                <RequireAuth>
                  <Exercise />
                </RequireAuth>
              }
            />
            <Route
              path="/class-videos"
              element={
                <RequireAuth>
                  <ClassVideos />
                </RequireAuth>
              }
            />
            <Route
              path="/certification-category"
              element={
                <RequireAuth>
                  <CertificationCategories />
                </RequireAuth>
              }
            />
            <Route
              path="/workshop-attendees/:workshopSlug"
              element={
                <RequireAuth>
                  <ManageWorkshop />
                </RequireAuth>
              }
            />
            <Route
              path="/workshop-attendees/:workshopSlug"
              element={
                <RequireAuth>
                  <ManageWorkshop />
                </RequireAuth>
              }
            />
            <Route
              path="/manage-discount-coupons"
              element={
                <RequireAuth>
                  <WorkshopDiscountCouponManager />
                </RequireAuth>
              }
            />
            <Route
              path="/create-certificate"
              element={
                <RequireAuth>
                  <CreateCertificate />
                </RequireAuth>
              }
            />
            <Route
              path="/files-directory"
              element={
                <RequireAuth>
                  <FilesDirectory />
                </RequireAuth>
              }
            />
            <Route
              path="/gift-voucher-products"
              element={
                <RequireAuth>
                  <GiftVoucherProducts />
                </RequireAuth>
              }
            />
            <Route
              path="/gift-voucher-orders"
              element={
                <RequireAuth>
                  <GiftVoucherOrders />
                </RequireAuth>
              }
            />
            <Route
              path="/certification-renewal-questions"
              element={
                <RequireAuth>
                  <CertificationQuestions />
                </RequireAuth>
              }
            />
            <Route
              path="/testimonials-and-endorsements"
              element={
                <RequireAuth>
                  <Endorsements />
                </RequireAuth>
              }
            />
            <Route
              path="/certification-renewal-responses"
              element={
                <RequireAuth>
                  <CertificationRenewalResponses />
                </RequireAuth>
              }
            />
            <Route
              path="/certification-renewal-responses-approved"
              element={
                <RequireAuth>
                  <CertificationRenewalResponses />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NoMatch />} />
            {/* blog routes */}
            <Route
              path="/blog-categories"
              element={
                <RequireAuth>
                  <BlogCategories />
                </RequireAuth>
              }
            />
            <Route
              path="/blog"
              element={
                <RequireAuth>
                  <Blog />
                </RequireAuth>
              }
            />
            {/* Data Download routes */}
            <Route
              path="/user-data-download"
              element={
                <RequireAuth>
                  <UserDataDownload />
                </RequireAuth>
              }
            />
            <Route
              path="/workshop-data-download"
              element={
                <RequireAuth>
                  <WorkshopDataDownload />
                </RequireAuth>
              }
            />
            <Route
              path="/credit-list"
              element={
                <RequireAuth>
                  <CreditList />
                </RequireAuth>
              }
            />
            <Route
              path="/email-attachments"
              element={
                <RequireAuth>
                  <EmailAttachments />
                </RequireAuth>
              }
            />
            <Route
              path="/unanswered-comments"
              element={
                <RequireAuth>
                  <UnansweredComments />
                </RequireAuth>
              }
            />
            <Route
              path="/flag-list"
              element={
                <RequireAuth>
                  <FlagList />
                </RequireAuth>
              }
            />

            <Route
              path="/blocked-teacher-contact-emails"
              element={
                <RequireAuth>
                  <BlockedTeacherContactEmails />
                </RequireAuth>
              }
            />

            {/* // deals route-- */}
            <Route
              path="/deals"
              element={
                <RequireAuth>
                  <Deals />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
        <SessionRefresher />
      </>
    </AuthStateProvider>
  );
};

export default App;
