import { Dropdown, Layout, Menu } from 'antd';
import {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { useAuthState } from '../../../context/auth-state';
import { CLIENT_FRONTEND_URL } from '../../../constants/api';

const { Header } = Layout;

const AppHeader = () => {
  const { onLogout, session, user } = useAuthState();
  const navigator = useNavigate();

  const headerMenu = (
    <Menu>
      <Menu.Item
        key="profile"
        icon={<UserOutlined />}
        onClick={() => {
          navigator(`/userdetails/${user?._id}`);
        }}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        onClick={() => onLogout()}
      >
        Logout
      </Menu.Item>
      <Menu.Item
        key="userSection"
        icon={<UserSwitchOutlined />}
        onClick={() => {
          window.open(CLIENT_FRONTEND_URL, '_self');
        }}
      >
        Go to user's section
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header">
      <div className="brand-logo">PBT Admin</div>
      {session && (
        <div className="header-dropdown">
          <Dropdown overlay={headerMenu}>
            <div>
              {/* <Avatar
                className="profile-avatar"
                size="large"
                src={<Image preview={false} src={user?.pic} width={32} />}
              /> */}
              <span className="welcome-text">
                <small>Welcome</small>
                {/* {user?.name} */}
                <DownOutlined />
              </span>
            </div>
          </Dropdown>
        </div>
      )}
    </Header>
  );
};

export default AppHeader;
