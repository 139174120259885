import { createElement, FC, useRef, useState } from 'react';
import { message, Popconfirm } from 'antd';

import { deleteFaqCategories } from '../../../../services/faq-services';

interface DeleteFaqCategoryProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  faqCategoryId?: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const DeleteFaqCategory: FC<DeleteFaqCategoryProps> = ({
  children,
  faqCategoryId,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [popVisible, setPopVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const onDelete = async () => {
    setRequesting(true);
    try {
      await deleteFaqCategories(faqCategoryId);
      message.success('Faq category deleted successfully');
      if (successCallback) {
        successCallback();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.response) {
        message.error(err.response?.data.MESSAGE_KEY);
      } else {
        message.error(err?.message);
      }
      if (errorCallback) {
        errorCallback(err as Error);
      }
    } finally {
      setRequesting(false);
    }
  };
  const handlePopVisibility = () => {
    setPopVisible(!popVisible);
  };

  return (
    <Popconfirm
      visible={popVisible}
      onVisibleChange={handlePopVisibility}
      title="Are you sure to remove this Faq Category?"
      okButtonProps={{ loading: requesting }}
      onConfirm={onDelete}
      onCancel={() => console.log('clicked cancel')}
      okText="Yes"
      cancelText="No"
    >
      {createElement(refChildren.current, { onClick: handlePopVisibility })}
    </Popconfirm>
  );
};
export default DeleteFaqCategory;
