import { Col, Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { FaqCategory } from '../../../types/faq';
import { InputField } from '../../common/form';
import SlugNote from '../../common/slug-note';

interface IAddFaqCategoryFormProps {
  form: UseFormReturn<FaqCategory, object>;
  isEditable?: boolean;
  edit?: boolean;
}
const FaqCategoryForm = ({
  form,
  isEditable,
  edit,
}: IAddFaqCategoryFormProps) => {
  return (
    <>
      <Form layout="vertical">
        <Col>
          <InputField
            label="Slug"
            name="slug"
            required
            disabled={edit}
            inputProps={{
              placeholder: 'Slug : eg(faq-category-slug)',
              disabled: isEditable === true ? true : false,
            }}
            form={form}
            style={{ marginBottom: '4px' }}
          />
          <SlugNote />
        </Col>

        <InputField
          label="Name (EN)"
          name="category_name.en"
          required
          inputProps={{
            placeholder: 'Name (EN)',
          }}
          form={form}
        />

        <InputField
          label="Name (ES)"
          name="category_name.es"
          required
          inputProps={{
            placeholder: 'Name (ES)',
          }}
          form={form}
        />
        <InputField
          label="Name (DE)"
          name="category_name.de"
          required
          inputProps={{
            placeholder: 'Name (DE)',
          }}
          form={form}
        />
        <InputField
          label="Name (IT)"
          name="category_name.it"
          required
          inputProps={{
            placeholder: 'Name (IT)',
          }}
          form={form}
        />
        <InputField
          label="Name (PT)"
          name="category_name.pt"
          required
          inputProps={{
            placeholder: 'Name (PT)',
          }}
          form={form}
        />

        <InputField
          label="Name (ZH)"
          name="category_name.zh"
          required
          inputProps={{
            placeholder: 'Name (ZH)',
          }}
          form={form}
        />
        <InputField
          label="Name (FR)"
          name="category_name.fr"
          required
          inputProps={{
            placeholder: 'Name (FR)',
          }}
          form={form}
        />
        <InputField
          label="Name (Cantonese)"
          name="category_name[zh-HK]"
          required
          inputProps={{
            placeholder: 'Name (Cantonese)',
          }}
          form={form}
        />

        <br />
      </Form>
    </>
  );
};

export default FaqCategoryForm;
