import { Button, Drawer, message } from 'antd';
import { createElement, FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import { BlogCategory } from '../../../types/blog-categories';
import CategoryForm from '../form';
import { addBlogCategory } from '../../../services/blog-category-service';

interface IAddTCategoryProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const AddCategory: FC<IAddTCategoryProps> = ({
  children,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<BlogCategory>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: BlogCategory) => {
    setRequesting(true);

    await addBlogCategory(values)
      .then(() => {
        setDrawerVisible(false);
        message.success('Blog Category added successfully');
        form.reset();
        setRequesting(false);

        if (successCallback) {
          successCallback();
        }
      })
      .catch((error) => {
        setRequesting(false);

        if (error.response) {
          message.error(error.response.data.MESSAGE_KEY);
        } else {
          message.error('Unknown Error');
        }

        if (errorCallback) {
          errorCallback(error as Error);
        }
      });
  };

  return (
    <>
      <Drawer
        title="Add Blog Category"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <CategoryForm isEditable={false} form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddCategory;
