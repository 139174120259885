import {
  Button,
  Col,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  getDataForExerciseVideoForm,
  getExercises,
} from '../../services/exercises';
import {
  defaultVideoFormDataValue,
  VideoFormData,
} from '../../types/exercises';
import AddExercise from '../../components/exercises/create-exercise';
import { LANGUAGES } from '../../constants/common';
import { MultilingualExerciseVideo } from '../../types/MultiLingual-ExerciseVideo';
import columns from '../exercises/columns';
import '../exercises/style.css';
import MultiUploadModal from '../exercises/multiUploadModal';

export interface ExerciseFilter {
  language: string;
  section: string;
  category: string;
  technique: string;
}

const ClassVideos = () => {
  const [requesting, setRequesting] = useState(false);
  const [isClassVideo] = useState(true);
  const [reloadExercises, setReloadExercises] = useState(false);
  const [exercises, setExercises] = useState<MultilingualExerciseVideo[]>([]);
  const [videoFormData, setVideoFormData] = useState<VideoFormData>(
    defaultVideoFormDataValue
  );

  const location = useLocation();

  const navigate = useNavigate();

  const filter = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return {
      language: queryParams.get('language') || '',
      section: queryParams.get('section') || '',
      category: queryParams.get('category') || '',
      technique: queryParams.get('technique') || '',
    };
  }, [location.search]);

  const loadFilters = async () => {
    setRequesting(true);
    try {
      const response = await getDataForExerciseVideoForm({
        isClassVideo: isClassVideo,
      });
      const data = response?.data?.data;
      setVideoFormData(data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
    }
  };

  const loadExercises = async () => {
    setRequesting(true);
    try {
      const response = await getExercises({
        ...filter,
        isClassVideo: isClassVideo,
      });
      const data = response?.data?.data;
      setExercises(data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadExercises(false);
    }
  };
  useEffect(() => {
    if (!requesting && reloadExercises) {
      loadExercises();
    }
  }, [reloadExercises]);

  useEffect(() => {
    setReloadExercises(true);
  }, [filter]);

  const handleChange = (e: string, value: keyof ExerciseFilter) => {
    const params: Record<string, string> = {
      ...filter,
      [value]: e,
    };
    if (value === 'technique') {
      params.section = '';
      params.category = '';
    }

    if (value === 'section') {
      params.category = '';
    }

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v)
    );

    const searchParams = new URLSearchParams(filteredParams).toString();

    navigate({
      pathname: location.pathname,
      search: `?${searchParams}`,
    });
  };

  useEffect(() => {
    loadFilters();
    loadExercises();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: MultilingualExerciseVideo) => {
        return (
          <Space size="large">
            <MultiUploadModal
              ExerciseVideo={record}
              setReloadExercises={setReloadExercises}
              videoFormData={videoFormData}
              isClassVideo={isClassVideo}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={exercises}
        columns={columnsWithActions}
        pagination={{ position: ['bottomRight'] }}
        title={() => {
          return (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography.Title level={3}>All Classes Video</Typography.Title>
                <Space>
                  <Tooltip title="Refresh" placement="left">
                    <Button
                      type="ghost"
                      icon={<ReloadOutlined />}
                      onClick={() => setReloadExercises(true)}
                    />
                  </Tooltip>
                  <AddExercise
                    videoFormData={videoFormData}
                    successCallback={() => setReloadExercises(true)}
                    isClassVideo={isClassVideo}
                  >
                    {({ onClick }) => (
                      <Button
                        onClick={onClick}
                        type="primary"
                        icon={<PlusCircleOutlined />}
                      >
                        Add Class Video
                      </Button>
                    )}
                  </AddExercise>
                </Space>
              </div>
              <Row>
                <Col span={6}>
                  <Select
                    size="large"
                    onChange={(e) => handleChange(e, 'technique')}
                    style={{ width: '95%' }}
                    defaultValue={filter.technique ?? ''}
                    value={filter.technique ?? ''}
                    placeholder="Please select a Technique"
                    options={[
                      { value: '', label: 'All Technique' },
                      ...videoFormData.techniques.map((t) => {
                        return { value: t?.slug, label: t?.name?.en };
                      }),
                    ]}
                  />
                </Col>
                <Col span={6}>
                  <Select
                    size="large"
                    onChange={(e) => handleChange(e, 'section')}
                    style={{ width: '95%' }}
                    defaultValue={filter.section ?? ''}
                    value={filter.section ?? ''}
                    fieldNames={{ label: 'name', value: 'slug' }}
                    placeholder="Please select a Section"
                    options={[
                      { _id: '', slug: '', name: 'All Sections' },
                      ...videoFormData?.sections?.filter((s) =>
                        s?.techniqueId?.includes(filter?.technique)
                      ),
                    ]}
                  />
                </Col>
                <Col span={6}>
                  <Select
                    size="large"
                    onChange={(e) => handleChange(e, 'category')}
                    style={{ width: '95%' }}
                    defaultValue={filter.category ?? ''}
                    value={filter.category ?? ''}
                    fieldNames={{ label: 'name', value: 'slug' }}
                    placeholder="Please select a Class Category"
                    options={[
                      { _id: '', slug: '', name: 'All Class Category' },
                      ...videoFormData?.categories?.filter((c) => {
                        return !!c?.sections?.filter(
                          (i) =>
                            i?.techniqueSlug === filter?.technique &&
                            i?.sectionSlug === filter?.section
                        )?.length;
                      }),
                    ]}
                  />
                </Col>
                <Col span={6}>
                  <Select
                    size="large"
                    onChange={(e) => handleChange(e, 'language')}
                    style={{ width: '100%' }}
                    defaultValue={filter.language ?? ''}
                    value={filter.language ?? ''}
                    placeholder="Please select a Language"
                    options={[
                      { value: '', label: 'Translation Filter' },
                      ...LANGUAGES.filter((v) => v.value !== 'en'),
                    ]}
                  />
                </Col>
              </Row>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default ClassVideos;
