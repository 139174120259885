import { message, Modal, Button, Tooltip } from 'antd';
import { FC, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';

import { deleteUser } from '../../../services/users-service';
import { handleError } from '../../../utils/common';

import './style.scss';

interface IDeleteUserProps {
  userId?: string;
  loadUsers: () => void;
}

const DeleteAccount: FC<IDeleteUserProps> = ({ userId, loadUsers }) => {
  const [requesting, setRequesting] = useState(false);

  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);

  const handleModalVisibility = () => {
    setIsDeleteAccountModalOpen(!isDeleteAccountModalOpen);
  };

  const handleDeleteAccount = async () => {
    setRequesting(true);
    try {
      await deleteUser({
        _id: userId,
      });
      loadUsers();
      message.success(
        'This account has been successfully marked for deletion.'
      );
      handleModalVisibility();
    } catch (e) {
      handleError(e);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <div>
      <Modal
        open={isDeleteAccountModalOpen}
        onCancel={handleModalVisibility}
        confirmLoading={requesting}
        closable={false}
        width={520}
        footer={false}
        destroyOnClose={true}
        title={false}
      >
        <p className="delete-account-title text-[1.6rem] md:text-[2.4rem] flex justify-between">
          Are you sure you want delete this account?
        </p>
        <div className="delete-account-btn-container">
          <Button onClick={handleModalVisibility} block>
            Cancel
          </Button>
          <Button
            loading={requesting}
            onClick={handleDeleteAccount}
            block
            type="primary"
          >
            Confirm
          </Button>
        </div>
      </Modal>

      <Tooltip title="Delete Account">
        <div
          style={{ cursor: 'pointer', margin: 'auto' }}
          onClick={handleModalVisibility}
        >
          <DeleteOutlined style={{ float: 'right', color: 'red' }} />
        </div>
      </Tooltip>
    </div>
  );
};

export default DeleteAccount;
