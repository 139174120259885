import { CERTIFICATION_CATEGORY } from '../constants/api';
import { CerificationCategory } from '../types/certification-categories';
import fetchClient from '../utils/fetch-client';

export const getCertificationCategory = () => {
  return fetchClient.get(`${CERTIFICATION_CATEGORY.CERTIFICATION_CATEGORY}`);
};

export const addCertificationCategory = (payload: CerificationCategory) => {
  return fetchClient.post(
    `${CERTIFICATION_CATEGORY.CERTIFICATION_CATEGORY}`,
    payload
  );
};

export const updateCertificationCategory = (
  payload: CerificationCategory,
  id: string
) => {
  return fetchClient.put(
    `${CERTIFICATION_CATEGORY.CERTIFICATION_CATEGORY}/${id}`,
    payload
  );
};

export const deleteCertificationCategory = (id?: string) => {
  return fetchClient.delete(
    `${CERTIFICATION_CATEGORY.CERTIFICATION_CATEGORY}/${id}`
  );
};
