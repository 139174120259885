import { Tooltip } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import { MultilingualExerciseVideo } from '../../types/MultiLingual-ExerciseVideo';
// import { getTechniqueColor } from '../../utils/common';

const columns = [
  {
    title: '# No',
    render: (
      value: number,
      record: MultilingualExerciseVideo,
      index: number
    ) => {
      return index + 1;
    },
    key: 'index',
    width: 200,
  },
  {
    title: 'Technique',
    render: (value: number, record: MultilingualExerciseVideo) => {
      const uniqueTechniques = new Set<string>();
      record?.categories?.forEach((item) => {
        if (typeof item !== 'string') {
          uniqueTechniques.add(item?.techniqueSlug);
        }
      });

      return <>{Array.from(uniqueTechniques).join(', ')}</>;
    },
    width: 250,
  },
  {
    title: 'Sections and Categories',
    render: (value: string, record: MultilingualExerciseVideo) => {
      const uniqueSections = new Set<string>();
      const sectionMapping: { [key: string]: string[] } = {};

      record?.categories?.forEach((item) => {
        if (typeof item !== 'string') {
          uniqueSections.add(item?.sectionSlug);
          if (!sectionMapping[item.sectionSlug]) {
            sectionMapping[item.sectionSlug] = [];
          }
          sectionMapping[item.sectionSlug].push(item.categorySlug);
        }
      });

      return (
        <>
          {Array.from(uniqueSections).map((section) => (
            <div key={section}>
              <p style={{ fontWeight: '600' }}>{section}</p>
              <p>{sectionMapping[section].join(', ')}</p>
            </div>
          ))}
        </>
      );
    },
    width: 400,
  },
  {
    title: 'Name',
    render: (value: string, record: MultilingualExerciseVideo) => (
      <>{record?.videoName?.en}</>
    ),
    width: 300,
  },
  {
    title: 'Video link',
    render: (value: string, record: MultilingualExerciseVideo) =>
      record?.categories?.map((category, index) => {
        return (
          <a
            href={`${process.env.REACT_APP_FRONT_END_URL}/${
              typeof category !== 'string' &&
              `library/${category?.techniqueSlug}/${category?.sectionSlug}/${
                record?.isClassVideo ? 'classes' : 'tutorials'
              }/${category?.categorySlug}/${record?.slug}`
            }`}
            target="_blank"
            rel="noreferrer"
          >
            <Tooltip placement="top" title={`Video link ${index + 1}`}>
              <LinkOutlined style={{ marginInline: '5px' }} />
            </Tooltip>
          </a>
        );
      }),
    width: 200,
  },
  // {
  //   title: 'Play Count ( in seconds )',
  //   render: (value: number, record: MultilingualExerciseVideo) =>
  //     record?.playCount || 0,
  //   width: 300,
  // },
  {
    title: 'Status',
    render: (value: number, record: MultilingualExerciseVideo) => {
      const langArray = Object.entries(record?.publishedLang || {});
      return (
        <>
          <div>
            <span style={{ color: 'green' }}>Published: </span>
            <div>
              {langArray.map(
                ([lang, value]) =>
                  value && (
                    <span key={lang} style={{ fontWeight: '600' }}>
                      {`${lang}, `}
                    </span>
                  )
              )}
            </div>
          </div>
          <div>
            <span style={{ color: 'red' }}>Not Published: </span>
            <div>
              {langArray.map(([lang, value], index) => {
                return (
                  value === false && (
                    <span key={lang} style={{ fontWeight: '600' }}>
                      {`${lang}, `}
                    </span>
                  )
                );
              })}
            </div>
          </div>
        </>
      );
    },
    width: 200,
  },
];

export default columns;
