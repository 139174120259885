import { Button, Space, Spin, Tabs, Tooltip, Typography } from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  CopyOutlined,
  EditOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import { ReactNode, useEffect, useState } from 'react';

import DeleteFile from '../../components/files/delete-file';
import UploadFile from '../../components/files/upload-file';
import { getFiles } from '../../services/files-directory-service';
import { FileDetail, Files } from '../../types/file';
import { handleError } from '../../utils/common';

import columns from './columns';
import FilesDataTable from './files-data-table';

type MappedFiles = {
  [key in Files]: FileDetail[];
};

const FilesDirectory = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadFiles, setReloadFiles] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<Files>(Files.banner);
  const [filesData, setFilesData] = useState<MappedFiles>();
  const [copied, setCopied] = useState(false);

  const loadFiles = async () => {
    setRequesting(true);
    try {
      const response = await getFiles();
      setFilesData(_.groupBy(response?.data?.data, 'type') as MappedFiles);
    } catch (e) {
      handleError(e);
    } finally {
      setReloadFiles(false);
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (!requesting && reloadFiles) {
      loadFiles();
    }
  }, [reloadFiles, requesting]);

  useEffect(() => {
    loadFiles();
  }, []);

  useEffect(() => {
    if (copied) {
      const id = setTimeout(() => setCopied(false), 1000);
      return () => {
        clearTimeout(id);
      };
    }
  }, [copied]);

  const onTabChange = (fileType: string) => {
    setSelectedFileType(fileType as Files);
  };

  const handleReload = () => {
    setReloadFiles(true);
  };

  const handleViewFile = (file: string) => {
    window.open(file);
  };

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: FileDetail) => {
        return (
          <Space size="large">
            <Tooltip
              placement="top"
              title={copied ? 'Copied' : 'Copy File Link'}
            >
              <CopyOutlined
                onClick={() => {
                  setCopied(true);
                  navigator.clipboard.writeText(record.file);
                }}
              />
            </Tooltip>
            <Tooltip placement="top" title="View File">
              <EyeOutlined onClick={() => handleViewFile(record.file)} />
            </Tooltip>
            <UploadFile successCallback={handleReload} file={record}>
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </UploadFile>
            <DeleteFile fileId={record._id} successCallback={handleReload}>
              {({ onClick }) => (
                <Tooltip placement="top" title="Delete File">
                  <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
                </Tooltip>
              )}
            </DeleteFile>
          </Space>
        );
      },
    },
  ];

  const items: {
    key: Files;
    label: string;
    children: ReactNode;
  }[] = [
    {
      key: Files.banner,
      label: 'Workshop Banners',
      children: (
        <FilesDataTable
          columns={columnsWithActions}
          data={filesData?.banner || []}
        />
      ),
    },
    {
      key: Files.note,
      label: 'English Notes',
      children: (
        <FilesDataTable
          columns={columnsWithActions}
          data={filesData?.note || []}
        />
      ),
    },
    {
      key: Files.equipment,
      label: 'English Equipments',
      children: (
        <FilesDataTable
          columns={columnsWithActions}
          data={filesData?.equipment || []}
        />
      ),
    },
    {
      key: Files.ne_note,
      label: 'Non-English Notes',
      children: (
        <FilesDataTable
          columns={columnsWithActions}
          data={filesData?.ne_note || []}
        />
      ),
    },
    {
      key: Files.ne_equipment,
      label: 'Non-English Equipments',
      children: (
        <FilesDataTable
          columns={columnsWithActions}
          data={filesData?.ne_equipment || []}
        />
      ),
    },
  ];

  return (
    <Spin spinning={requesting}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Title level={3}>Files Directory</Typography.Title>
        <UploadFile successCallback={handleReload}>
          {({ onClick }) => (
            <Button type="primary" onClick={onClick}>
              Upload file
            </Button>
          )}
        </UploadFile>
      </div>
      <Tabs
        defaultActiveKey={selectedFileType}
        items={items}
        onChange={onTabChange}
      />
    </Spin>
  );
};

export default FilesDirectory;
