import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Descriptions, Modal, Typography, message } from 'antd';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { TUser, removeSchool } from '../../../../services/users-service';
import '../../../../containers/users/style.scss';

import AddSchool from './add-school';
import EditSchool from './edit-school';

interface SchoolFields {
  user: TUser;
  successCallback: (e: boolean) => void;
}

const Schools: FC<SchoolFields> = ({ user, successCallback }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [schooolToDelete, setSchoolToDelete] = useState<string>('');
  const [loadingDelete, setLoadingDelete] = useState(false);

  const deleteSchoolHandler = async () => {
    console.log(schooolToDelete);
    setLoadingDelete(true);
    try {
      const payload = {
        schoolId: schooolToDelete,
      };
      await removeSchool(payload, `${user?._id}`);
      message.success('Deleted Successfully');
      setLoadingDelete(false);
      setPreviewVisible((prev) => !prev);
      successCallback(true);
    } catch (error) {
      message.error(error);
      setLoadingDelete(false);
      setPreviewVisible((prev) => !prev);
    }
  };

  const handlePreview = () => {
    setPreviewVisible((prev) => !prev);
  };

  return (
    <>
      <Modal
        title="Remove School"
        open={previewVisible}
        onCancel={handlePreview}
        footer={[
          <Button key="Cancel" onClick={handlePreview}>
            Cancel
          </Button>,
          <Button
            key="delete"
            type="primary"
            loading={loadingDelete}
            onClick={deleteSchoolHandler}
          >
            Remove
          </Button>,
        ]}
      >
        <p>Are You Sure ?</p>
      </Modal>
      <Card title="Schools" className="info-container">
        <span className="info-heading">
          <AddSchool userId={user?._id} successCallback={successCallback}>
            {({ onClick }) => (
              <PlusOutlined onClick={onClick} style={{ float: 'right' }} />
            )}
          </AddSchool>
        </span>
        <Descriptions
          layout="vertical"
          column={2}
          labelStyle={{ fontSize: '11px', color: '#7e7e7c' }}
        >
          {user.schools && user.schools?.length > 0 ? (
            user?.schools?.map((school) => {
              return (
                <Descriptions.Item
                  label={school.name.toUpperCase()}
                  className="info-item"
                >
                  {!school.website &&
                  !school.facebook &&
                  !school.instagram &&
                  !school.twitter ? (
                    <Typography.Text>No fields added.</Typography.Text>
                  ) : (
                    <>
                      {school.website && (
                        <Typography.Text>
                          <Link
                            className="link"
                            to={{ pathname: `${school.website}` }}
                            target="_blank"
                          >
                            Website
                          </Link>
                        </Typography.Text>
                      )}
                      {school.facebook && (
                        <Typography.Text>
                          {`|`}
                          <Link
                            className="link"
                            to={{ pathname: `${school.facebook}` }}
                            target="_blank"
                          >
                            Facebook
                          </Link>
                        </Typography.Text>
                      )}
                      {school.instagram && (
                        <Typography.Text>
                          {`|`}
                          <Link
                            className="link"
                            to={{ pathname: `${school.instagram}` }}
                            target="_blank"
                          >
                            Instagram
                          </Link>
                        </Typography.Text>
                      )}
                      {school.twitter && (
                        <Typography.Text>
                          {`|`}
                          <Link
                            className="link"
                            to={{ pathname: `${school.twitter}` }}
                            target="_blank"
                          >
                            Twitter
                          </Link>
                        </Typography.Text>
                      )}
                    </>
                  )}
                  <span
                    className="info-heading icon-list"
                    style={{ top: '-15px', right: '25px' }}
                  >
                    <EditSchool
                      userId={user?._id}
                      successCallback={successCallback}
                      school={school}
                    >
                      {({ onClick }) => (
                        <EditOutlined
                          onClick={onClick}
                          style={{ float: 'right', cursor: 'pointer' }}
                        />
                      )}
                    </EditSchool>
                    <DeleteOutlined
                      style={{
                        float: 'right',
                        cursor: 'pointer',
                        color: 'red',
                      }}
                      onClick={() => {
                        setSchoolToDelete(`${school?._id}`);
                        handlePreview();
                      }}
                    />
                  </span>
                </Descriptions.Item>
              );
            })
          ) : (
            <Typography>No Schools.</Typography>
          )}
        </Descriptions>
      </Card>
    </>
  );
};

export default Schools;
