/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { Form, DatePicker, DatePickerProps } from 'antd';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Moment } from 'moment';

const PbtDateRangePicker = DatePicker.RangePicker as any;

interface IInputFieldProps {
  label?: string;
  required?: boolean;
  name: string;
  defaultValue?: Moment;
  form: UseFormReturn<any, any>;
  datePickerProps?: DatePickerProps;
  format?: string;
  size?: string;
}

const DateRangePickerField: FC<IInputFieldProps> = ({
  form: {
    formState: { errors },
    control,
  },
  label,
  required,
  name,
  format = 'MM-DD-YYYY',
  defaultValue,
  datePickerProps,
  size,
}) => {
  return (
    <Form.Item
      label={label}
      help={errors[name]?.message}
      validateStatus={errors[name] ? 'error' : 'success'}
      required={required}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }): React.ReactElement => (
          <PbtDateRangePicker
            style={{ width: '100%' }}
            size={size}
            defaultValue={defaultValue}
            {...field}
            {...datePickerProps}
            format={format}
          />
        )}
      />
    </Form.Item>
  );
};

export default DateRangePickerField;
