export enum IDealsType {
  'popup' = 'popup',
  'notification_bar' = 'notification_bar',
}

export enum IDealsActionType {
  'signup' = 'signup',
  'redirect' = 'redirect',
}

export enum IDealsActionDisplayType {
  'button' = 'button',
  'link' = 'link',
}

export enum IDealsStatus {
  'active' = 'active',
  'expired' = 'expired',
  'upcoming' = 'upcoming',
}

export enum IDealsDiscountedPlans {
  'yearly' = 'yearly',
  'monthly' = 'monthly',
  'all' = 'all',
}

export enum IDealsTargetAudience {
  'members' = 'members',
  'non-members' = 'non-members',
}

export interface IDeals {
  _id: string;
  name: string;
  type: IDealsType;
  bannerUrl?: string;
  headLine?: string;
  action: {
    type: IDealsActionType | null;
    discount?: number | null;
    discountedPlans?: IDealsDiscountedPlans | null;
    url?: string | null;
    actionLabel?: string | null;
    actionDisplayType?: IDealsActionDisplayType | null;
    mailchimpTag?: string | null;
  };
  status: IDealsStatus;
  targetCountry: string[];
  targetAudience: IDealsTargetAudience[];
  views: number;
  clicks: number;
  isDeleted?: boolean;
  deletedOn?: Date;
  deletedBy?: string;
  deletedIp?: string;
  deletedUa?: string;
}

export interface IDealsFormField {
  name: string;
  type: IDealsType;
  bannerUrl?: string;
  headLine?: string;
  action: {
    type?: IDealsActionType | null;
    discount?: number | null;
    discountedPlans?: IDealsDiscountedPlans | null;
    url?: string | null;
    actionLabel?: string | null;
    actionDisplayType?: IDealsActionDisplayType | null;
    mailchimpTag?: string | null;
  };
  targetCountry: string[];
  targetAudience: IDealsTargetAudience[];
}
