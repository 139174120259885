import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import { IMarketingAssets } from '../../../types/marketingAssets';
import AddMarketingAssetsForm from '../form';
import { updateMarketingAssets } from '../../../services/marketing-assets-service';

interface IEditMarketingAssetsProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  payload: IMarketingAssets;
  assetId: string;
  children: FC<{
    onClick: () => void;
  }>;
  isEditable: boolean;
}

const EditMarketingAsset: FC<IEditMarketingAssetsProps> = ({
  children,
  payload,
  assetId,
  isEditable,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<IMarketingAssets>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };
  const onSubmitHandler = async (values: IMarketingAssets) => {
    setRequesting(true);
    try {
      await updateMarketingAssets(values, assetId);
      setDrawerVisible(false);
      message.success('Updated successfully');
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (payload) {
      form.reset(payload);
    }
  }, [payload]);

  return (
    <>
      <Drawer
        title="Update Marketing Asset"
        onClose={() => {
          handleDrawerVisibility();
          // form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddMarketingAssetsForm isEditable={isEditable} form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditMarketingAsset;
