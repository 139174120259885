import { Col, Form } from 'antd';
// import { TreeSelect } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { BlogCategory } from '../../types/blog-categories';
import { InputField } from '../common/form';
import SlugNote from '../common/slug-note';
// import { FileUploadButton} from '../common/form';
// import TreeSelectField from '../common/form/tree-select';

export type CategoryResArr = {
  value: string;
  label: string;
};
interface ICategoryFormProps {
  form: UseFormReturn<BlogCategory, object>;
  isEditable: boolean;
}
const CategoryForm = ({ form, isEditable }: ICategoryFormProps) => {
  return (
    <>
      <Form layout="vertical">
        <Col>
          <InputField
            label="Slug"
            name="slug"
            required
            disabled={isEditable === true ? true : false}
            inputProps={{
              placeholder: 'Slug : eg(category-slug)',
            }}
            form={form}
            style={{ marginBottom: '4px' }}
          />
          <SlugNote />
        </Col>

        <InputField
          label="Name EN"
          name="name.en"
          required
          inputProps={{
            placeholder: 'WARM UP',
          }}
          form={form}
        />
        <InputField
          label="Name PT"
          name="name.pt"
          required
          inputProps={{
            placeholder: 'AQUECER',
          }}
          form={form}
        />
        <InputField
          label="Name IT"
          name="name.it"
          required
          inputProps={{
            placeholder: 'RISCALDARE',
          }}
          form={form}
        />
        <InputField
          label="Name ES"
          name="name.es"
          required
          inputProps={{
            placeholder: 'CALENTAMIENTO',
          }}
          form={form}
        />
        <InputField
          label="Name DE"
          name="name.de"
          required
          inputProps={{
            placeholder: 'AUFWARMEN',
          }}
          form={form}
        />
        <InputField
          label="Name ZH"
          name="name.zh"
          required
          inputProps={{
            placeholder: '暖身',
          }}
          form={form}
        />
        <InputField
          label="Sequence"
          name="sequenceNo"
          required
          inputProps={{
            placeholder: '1',
          }}
          form={form}
        />
      </Form>
    </>
  );
};

export default CategoryForm;
