import { Button, Table } from 'antd';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import moment from 'moment';

import { handleError } from '../../../../utils/common';
import {
  EmailAttendees,
  IAttendeesEmail,
  WorkshopAttendeeWithUserDetail,
} from '../../../../types/workshop';
import {
  deleteEmailAttendees,
  getEmailAttendees,
} from '../../../../services/workshop-service';

import columns from './columns';

const SavedCommunication = ({
  workshopId,
  form,
  attendees,
  workshopTimeZone,
  drawerVisible,
  setMailToUpdate,
}: {
  workshopId: string;
  form: UseFormReturn<EmailAttendees, object>;
  attendees: WorkshopAttendeeWithUserDetail[];
  workshopTimeZone: string;
  drawerVisible: boolean;
  setMailToUpdate: React.Dispatch<React.SetStateAction<IAttendeesEmail | null>>;
}) => {
  const [email, setEmail] = useState<IAttendeesEmail[]>([]);
  const [loading, setLoading] = useState(false);

  const loadEmails = async () => {
    setLoading(true);
    try {
      const response = await getEmailAttendees(workshopId);
      setEmail(response?.data?.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteEmails = async (mailId: string) => {
    setLoading(true);
    try {
      await deleteEmailAttendees(mailId);
      setEmail((prev) => {
        return prev?.filter((i) => i?._id?.toString() !== mailId?.toString());
      });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const populateData = (record: IAttendeesEmail) => {
    form.setValue('body', record?.body);
    form.setValue('subject', record?.subject);
    form.setValue('isScheduled', record?.isScheduled);
    if (record?.isScheduled) {
      form.setValue(
        'emailScheduleDate',
        moment(record?.emailScheduleDate)?.tz(workshopTimeZone)?.toDate()
      );
    }
    if (record?.recipients?.length) {
      form.setValue(
        'selectRecipients',
        record?.recipients?.length === attendees?.length ? 'all' : 'selected'
      );

      form.setValue('recipients', record?.recipients);
    }
  };

  useEffect(() => {
    if (drawerVisible) loadEmails();
  }, [drawerVisible]);

  const dataColumn = [
    ...columns,
    {
      title: 'Date Sent/To Be Sent',
      render: (value: string, record: IAttendeesEmail) => (
        <div>
          {record?.isScheduled
            ? moment(record?.emailScheduleDate)
                .tz(workshopTimeZone)
                .format('ll HH:mm')
            : moment(record?.createdAt).tz(workshopTimeZone).format('ll HH:mm')}
        </div>
      ),
    },
    {
      title: 'Quick link',
      render: (value: string, record: IAttendeesEmail) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button type="default" onClick={() => populateData(record)}>
            Copy
          </Button>
          {record?.isScheduled && (
            <Button
              type="primary"
              onClick={() => {
                populateData(record);
                setMailToUpdate(record);
              }}
            >
              Edit
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              deleteEmails(record?._id);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        title={() => (
          <h4 style={{ fontWeight: 'bold' }}>Saved Communication</h4>
        )}
        dataSource={email}
        columns={dataColumn}
        loading={loading}
      />
    </div>
  );
};

export default SavedCommunication;
