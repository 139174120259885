/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Form, Typography } from 'antd';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { DeleteFilled } from '@ant-design/icons';

import { FileUploadButton, InputField } from '../common/form';
import SlugNote from '../common/slug-note';

interface IEmailAttachmentsFormProps {
  form: UseFormReturn<any, object>;
  isEditable: boolean;
}

const createEmptyAttachment = () => ({
  fileName: {
    en: '',
    es: '',
    it: '',
    de: '',
    pt: '',
    zh: '',
    fr: '',
    'zh-HK': '',
  },
  fileUrl: {
    en: '',
    es: '',
    it: '',
    de: '',
    pt: '',
    zh: '',
    fr: '',
    'zh-HK': '',
  },
});

const AttachmentsForm = ({ form, isEditable }: IEmailAttachmentsFormProps) => {
  const { control } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments',
  });
  const attachments = form.watch('attachments');

  return (
    <>
      <Form layout="vertical">
        <Col>
          <InputField
            label="Slug"
            name="slug"
            required
            inputProps={{
              placeholder: 'Slug : eg(template-slug)',
            }}
            form={form}
            disabled={isEditable}
            style={{ marginBottom: '4px' }}
          />
          <SlugNote />
        </Col>
        <InputField
          label="Name"
          name="name"
          required
          inputProps={{
            placeholder: 'Name',
          }}
          form={form}
        />
        <InputField
          label="Description"
          name="description"
          inputProps={{
            placeholder: 'Description',
          }}
          form={form}
        />

        <Col>
          <p style={{ paddingTop: '20px' }}>Add Attachments</p>
        </Col>
        {fields.map(({ id }, index) => {
          return (
            <div key={id} style={{ paddingBottom: '20px' }}>
              <Typography.Title level={5}>
                Attachment {index + 1}
              </Typography.Title>
              <InputField
                label="Attachment Title (EN)"
                name={`attachments[${index}].fileName.en`}
                inputProps={{
                  placeholder: 'Attachment Title (English)',
                }}
                form={form}
              />
              <FileUploadButton
                form={form}
                triggerValidate={() =>
                  form.trigger(`attachments[${index}].fileUrl.en`)
                }
                defaultFileList={
                  isEditable && attachments?.[index]?.fileUrl?.en
                    ? [
                        {
                          uid: '-1',
                          name: attachments?.[index]?.fileName?.en + '.png',
                          status: 'done',
                          url: attachments?.[index]?.fileUrl?.en,
                        },
                      ]
                    : []
                }
                label=""
                name={`attachments[${index}].fileUrl.en`}
                buttonLabel="Click to Upload Attachment (en)"
              />

              <InputField
                label="Attachment Title (ES)"
                name={`attachments[${index}].fileName.es`}
                inputProps={{
                  placeholder: 'Attachment Title (Spanish)',
                }}
                form={form}
              />
              <FileUploadButton
                form={form}
                triggerValidate={() =>
                  form.trigger(`attachments[${index}].fileUrl.es`)
                }
                defaultFileList={
                  isEditable && attachments?.[index]?.fileUrl?.es
                    ? [
                        {
                          uid: '-1',
                          name: attachments?.[index]?.fileName?.es + '.png',
                          status: 'done',
                          url: attachments?.[index]?.fileUrl?.es,
                        },
                      ]
                    : []
                }
                label=""
                name={`attachments[${index}].fileUrl.es`}
                buttonLabel="Click to Upload Attachment (es)"
              />

              <InputField
                label="Attachment Title (IT)"
                name={`attachments[${index}].fileName.it`}
                inputProps={{
                  placeholder: 'Attachment Title (Italian)',
                }}
                form={form}
              />
              <FileUploadButton
                form={form}
                triggerValidate={() =>
                  form.trigger(`attachments[${index}].fileUrl.it`)
                }
                defaultFileList={
                  isEditable && attachments?.[index]?.fileUrl?.it
                    ? [
                        {
                          uid: '-1',
                          name: attachments?.[index]?.fileName?.it + '.png',
                          status: 'done',
                          url: attachments?.[index]?.fileUrl?.it,
                        },
                      ]
                    : []
                }
                label=""
                name={`attachments[${index}].fileUrl.it`}
                buttonLabel="Click to Upload Attachment (it)"
              />

              <InputField
                label="Attachment Title (DE)"
                name={`attachments[${index}].fileName.de`}
                inputProps={{
                  placeholder: 'Attachment Title (German)',
                }}
                form={form}
              />
              <FileUploadButton
                form={form}
                triggerValidate={() =>
                  form.trigger(`attachments[${index}].fileUrl.de`)
                }
                defaultFileList={
                  isEditable && attachments?.[index]?.fileUrl?.de
                    ? [
                        {
                          uid: '-1',
                          name: attachments?.[index]?.fileName?.de + '.png',
                          status: 'done',
                          url: attachments?.[index]?.fileUrl?.de,
                        },
                      ]
                    : []
                }
                label=""
                name={`attachments[${index}].fileUrl.de`}
                buttonLabel="Click to Upload Attachment (de)"
              />
              <InputField
                label="Attachment Title (PT)"
                name={`attachments[${index}].fileName.pt`}
                inputProps={{
                  placeholder: 'Attachment Title (Portuguese)',
                }}
                form={form}
              />
              <FileUploadButton
                form={form}
                triggerValidate={() =>
                  form.trigger(`attachments[${index}].fileUrl.pt`)
                }
                defaultFileList={
                  isEditable && attachments?.[index]?.fileUrl?.pt
                    ? [
                        {
                          uid: '-1',
                          name: attachments?.[index]?.fileName?.pt + '.png',
                          status: 'done',
                          url: attachments?.[index]?.fileUrl?.pt,
                        },
                      ]
                    : []
                }
                label=""
                name={`attachments[${index}].fileUrl.pt`}
                buttonLabel="Click to Upload Attachment (pt)"
              />
              <InputField
                label="Attachment Title (ZH)"
                name={`attachments[${index}].fileName.zh`}
                inputProps={{
                  placeholder: 'Attachment Title (Mandarin)',
                }}
                form={form}
              />
              <FileUploadButton
                form={form}
                triggerValidate={() =>
                  form.trigger(`attachments[${index}].fileUrl.zh`)
                }
                defaultFileList={
                  isEditable && attachments?.[index]?.fileUrl?.zh
                    ? [
                        {
                          uid: '-1',
                          name: attachments?.[index]?.fileName?.zh + '.png',
                          status: 'done',
                          url: attachments?.[index]?.fileUrl?.zh,
                        },
                      ]
                    : []
                }
                label=""
                name={`attachments[${index}].fileUrl.zh`}
                buttonLabel="Click to Upload Attachment (zh)"
              />
              <InputField
                label="Attachment Title (FR)"
                name={`attachments[${index}].fileName.fr`}
                inputProps={{
                  placeholder: 'Attachment Title (French)',
                }}
                form={form}
              />
              <FileUploadButton
                form={form}
                triggerValidate={() =>
                  form.trigger(`attachments[${index}].fileUrl.fr`)
                }
                defaultFileList={
                  isEditable && attachments?.[index]?.fileUrl?.fr
                    ? [
                        {
                          uid: '-1',
                          name: attachments?.[index]?.fileName?.fr + '.png',
                          status: 'done',
                          url: attachments?.[index]?.fileUrl?.fr,
                        },
                      ]
                    : []
                }
                label=""
                name={`attachments[${index}].fileUrl.fr`}
                buttonLabel="Click to Upload Attachment (fr)"
              />

              <InputField
                label="Attachment Title (ZH-HK)"
                name={`attachments[${index}].fileName.zh-HK`}
                inputProps={{
                  placeholder: 'Attachment Title (Cantonese)',
                }}
                form={form}
              />
              <FileUploadButton
                form={form}
                triggerValidate={() =>
                  form.trigger(`attachments[${index}].fileUrl.zh-HK`)
                }
                defaultFileList={
                  isEditable && attachments?.[index]?.fileUrl?.['zh-HK']
                    ? [
                        {
                          uid: '-1',
                          name:
                            attachments?.[index]?.fileName?.['zh-HK'] + '.png',
                          status: 'done',
                          url: attachments?.[index]?.fileUrl?.['zh-HK'],
                        },
                      ]
                    : []
                }
                label=""
                name={`attachments[${index}].fileUrl.zh-HK`}
                buttonLabel="Click to Upload Attachment (zh-HK)"
              />

              <Button
                style={{ float: 'right' }}
                danger
                onClick={() => {
                  remove(index);
                }}
              >
                <DeleteFilled />
              </Button>
            </div>
          );
        })}
        <Button type="primary" onClick={() => append(createEmptyAttachment())}>
          +
        </Button>
      </Form>
    </>
  );
};

export default AttachmentsForm;
