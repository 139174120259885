import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { ExerciseEquipment } from '../../types/exercise-equipment';
import { InputField, FileUploadButton } from '../common/form';

export const defaultValues: ExerciseEquipment = {
  name: {
    en: '',
    pt: '',
    it: '',
    es: '',
    de: '',
    zh: '',
  },
  imageUrl1: '',
  slug: '',
};

interface IExerciseEquipmentFormProps {
  form: UseFormReturn<ExerciseEquipment, object>;
  isEditable?: boolean;
}

const ExerciseEquipmentForm = ({
  form,
  isEditable,
}: IExerciseEquipmentFormProps) => {
  const values = form.getValues();
  return (
    <>
      <Form
        {...{
          labelCol: { span: 6 },
          wrapperCol: { span: 18 },
        }}
      >
        <InputField
          label="Slug"
          name="slug"
          required
          inputProps={{
            placeholder: 'Slug : eg(yoga-mat)',
            disabled: isEditable === true ? true : false,
          }}
          form={form}
        />
        <InputField
          label="Name EN"
          name="name.en"
          required
          inputProps={{
            placeholder: 'Name (English)',
          }}
          form={form}
        />

        <InputField
          label="Name ES"
          name="name.es"
          required
          inputProps={{
            placeholder: 'Name (Spanish)',
          }}
          form={form}
        />

        <InputField
          label="Name IT"
          name="name.it"
          required
          inputProps={{
            placeholder: 'Name (Italian)',
          }}
          form={form}
        />

        <InputField
          label="Name DE"
          name="name.de"
          required
          inputProps={{
            placeholder: 'Name (German)',
          }}
          form={form}
        />

        <InputField
          label="Name PT"
          name="name.pt"
          required
          inputProps={{
            placeholder: 'Name (Portuguese)',
          }}
          form={form}
        />

        <InputField
          label="Name ZH"
          name="name.zh"
          required
          inputProps={{
            placeholder: 'Name (Mandarin)',
          }}
          form={form}
        />
        <FileUploadButton
          form={form}
          defaultFileList={
            isEditable
              ? [
                  {
                    uid: '-1',
                    name: `${values.name?.en}.png`,
                    status: 'done',
                    url: values.imageUrl1,
                  },
                ]
              : []
          }
          label="Equipment Image"
          required
          name="imageUrl1"
          buttonLabel="Upload Image"
        />
      </Form>
    </>
  );
};

export default ExerciseEquipmentForm;
