import { Col, Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { MuscleCategoryDetail } from '../../../types/muscle';
import { InputField } from '../../common/form';
import SlugNote from '../../common/slug-note';

interface IMuscleCategoryFormProps {
  form: UseFormReturn<MuscleCategoryDetail, object>;
  isEditable: boolean;
}
const MuscleCategoryForm = ({ form, isEditable }: IMuscleCategoryFormProps) => {
  return (
    <>
      <Form layout="vertical">
        <Col>
          <InputField
            label="Slug"
            name="slug"
            required
            inputProps={{
              placeholder: 'eg: word1-word2-word3',
              disabled: isEditable === true ? true : false,
            }}
            form={form}
            style={{ marginBottom: '4px' }}
          />
          <SlugNote />
        </Col>
        <InputField
          label="Category Name (EN)"
          name="name.en"
          required
          inputProps={{
            placeholder: 'Muscles Category Name (EN)',
          }}
          form={form}
        />
        <InputField
          label="Category Name (ES)"
          name="name.es"
          required
          inputProps={{
            placeholder: 'Muscles Category Name (ES)',
          }}
          form={form}
        />
        <InputField
          label="Category Name (IT)"
          name="name.it"
          required
          inputProps={{
            placeholder: 'Muscles Category Name (IT)',
          }}
          form={form}
        />
        <InputField
          label="Category Name (DE)"
          name="name.de"
          required
          inputProps={{
            placeholder: 'Muscles Category Name (DE)',
          }}
          form={form}
        />
        <InputField
          label="Category Name (PT)"
          name="name.pt"
          required
          inputProps={{
            placeholder: 'Muscles Category Name (PT)',
          }}
          form={form}
        />
        <InputField
          label="Category Name (ZH)"
          name="name.zh"
          required
          inputProps={{
            placeholder: 'Muscles Category Name (ZH)',
          }}
          form={form}
        />
      </Form>
    </>
  );
};

export default MuscleCategoryForm;
