import {
  Button,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
  DragOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import { getFaq, updateFaqsSequence } from '../../../services/faq-services';
import AddFaq from '../../../components/faq/faq/create-faq';
import EditFaq from '../../../components/faq/faq/edit-faq';
import DeleteFaq from '../../../components/faq/faq/delete-faq';
import { FaqCategory, FaqDetail } from '../../../types/faq';

import columns from './columns';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

interface DraggableTableProps {
  selectedCategoryFaqs: FaqCategory;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnsWithActions: any[];
  onDragEnd: (event: DragEndEvent) => void;
}

const Row = (props: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

const DraggableTable = ({
  selectedCategoryFaqs,
  columnsWithActions,
  onDragEnd,
}: DraggableTableProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const data = selectedCategoryFaqs?.faqs?.map((faq) => {
    return {
      slug: selectedCategoryFaqs?.slug,
      que: faq?.que,
      ans: faq?.ans,
      _id: faq?._id,
    };
  });

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={data?.map((i) => i?._id) || []}
        strategy={verticalListSortingStrategy}
      >
        <Table
          dataSource={data}
          columns={columnsWithActions}
          rowKey="_id"
          pagination={false}
          components={{
            body: {
              row: Row,
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

const Faq = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadFaq, setReloadFaq] = useState(true);
  const [originalFaqsArray, setOriginalFaqsArray] = useState<FaqCategory[]>([]);

  const [updatingSequence, setUpdatingSequence] = useState(false);
  const [selectedCategoryFaqs, setSelectedCategoryFaqs] =
    useState<FaqCategory>();
  const [selectedCategory, setSelectedCategory] = useState<string>();

  const [dragAndDropEnabled, setDragAndDropEnabled] = useState(false);

  const loadFaqs = async () => {
    setRequesting(true);
    try {
      const response = await getFaq();
      setOriginalFaqsArray(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadFaq(false);
    }
  };

  const toggleDragAndDrop = useCallback(() => {
    setDragAndDropEnabled((prevState) => !prevState);
  }, []);

  const onDragEnd = useCallback((e: DragEndEvent) => {
    const { active, over } = e;

    if (!over || !active || active.id === over.id) {
      return;
    }

    setSelectedCategoryFaqs((previous) => {
      if (!previous || !previous.faqs) {
        return previous;
      }

      const { faqs } = previous;
      const activeIndex = faqs.findIndex((i) => i?._id === active?.id);
      const overIndex = faqs.findIndex((i) => i?._id === over?.id);

      if (activeIndex !== -1 && overIndex !== -1) {
        [faqs[activeIndex], faqs[overIndex]] = [
          faqs[overIndex],
          faqs[activeIndex],
        ];
      }

      return { ...previous, faqs };
    });
  }, []);

  const saveOrderHandler = async () => {
    setUpdatingSequence(true);
    try {
      await updateFaqsSequence({
        faqId: selectedCategoryFaqs?._id || '',
        faqs: selectedCategoryFaqs?.faqs || [],
      });

      message.success('updated Successfully');
      await loadFaqs();
    } catch (error) {
      message.error('Network Error');
      console.log(error);
    } finally {
      setDragAndDropEnabled(false);
      setUpdatingSequence(false);
    }
  };

  const resetSequenceOrder = useCallback(() => {
    const currentFaqs = originalFaqsArray?.find(
      (i) => i?.slug === selectedCategory
    );
    setSelectedCategoryFaqs(JSON.parse(JSON.stringify(currentFaqs)));
    setDragAndDropEnabled(false);
  }, [selectedCategory, originalFaqsArray]);

  const getAllFaqs = useCallback(() => {
    return (
      originalFaqsArray
        ?.filter((faqs) => {
          if (selectedCategory) {
            return faqs?.slug === selectedCategory;
          }
          return true;
        })
        ?.flatMap((faqs) => {
          return faqs?.faqs && faqs?.faqs?.length > 0
            ? faqs?.faqs?.map((faq) => {
                return {
                  slug: faqs?.slug,
                  que: faq?.que,
                  ans: faq?.ans,
                  _id: faq?._id,
                };
              })
            : [];
        }) || []
    );
  }, [selectedCategory, originalFaqsArray]);

  useEffect(() => {
    if (!requesting && reloadFaq) {
      loadFaqs();
    }
  }, [reloadFaq, requesting]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: FaqDetail) => {
        return (
          <Space size="large">
            <EditFaq
              faqId={record._id}
              payload={record}
              successCallback={() => setReloadFaq(true)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditFaq>
            <DeleteFaq
              faqId={record._id}
              successCallback={() => setReloadFaq(true)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteFaq>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Typography.Title level={3}>Faqs</Typography.Title>
        <Space>
          <Tooltip title="Refresh" placement="left">
            <Button
              type="ghost"
              icon={<ReloadOutlined />}
              onClick={() => setReloadFaq(true)}
            />
          </Tooltip>
          <AddFaq successCallback={() => setReloadFaq(true)}>
            {({ onClick }) => (
              <Button
                onClick={onClick}
                type="primary"
                icon={<PlusCircleOutlined />}
              >
                Add Faq
              </Button>
            )}
          </AddFaq>

          <Select
            size="middle"
            onChange={(e) => {
              setSelectedCategory(e);
              const currentFaqs = originalFaqsArray?.find((i) => i?.slug === e);
              setSelectedCategoryFaqs(JSON.parse(JSON.stringify(currentFaqs)));
            }}
            style={{ width: '250px' }}
            placeholder="Select Faq Category"
            options={originalFaqsArray?.map((i) => ({
              label: i?.category_name?.en,
              value: i?.slug,
            }))}
            allowClear
            disabled={dragAndDropEnabled}
          />
          {/* Button to toggle drag and drop */}
          {!dragAndDropEnabled && (
            <Button
              onClick={toggleDragAndDrop}
              type={'default'}
              icon={<DragOutlined />}
              disabled={!selectedCategoryFaqs}
            >
              Edit Sequence Order
            </Button>
          )}
          {dragAndDropEnabled && (
            <Button
              onClick={saveOrderHandler}
              type={'primary'}
              icon={<CheckOutlined />}
              loading={updatingSequence}
            >
              Save
            </Button>
          )}

          {dragAndDropEnabled && (
            <Button onClick={resetSequenceOrder} type="dashed">
              Cancel
            </Button>
          )}
        </Space>
      </div>
      {dragAndDropEnabled && selectedCategoryFaqs ? (
        <DraggableTable
          selectedCategoryFaqs={selectedCategoryFaqs}
          columnsWithActions={columnsWithActions}
          onDragEnd={onDragEnd}
        />
      ) : (
        <Table
          key={selectedCategory}
          dataSource={getAllFaqs()}
          columns={columnsWithActions}
          rowKey="_id"
          pagination={false}
        />
      )}
    </Spin>
  );
};
export default Faq;
