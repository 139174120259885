import { EMAIL_ATTACHMENTS } from '../constants/api';
import { IEmailAttachments } from '../types/email-attachment';
import fetchClient from '../utils/fetch-client';

export const addAttachments = (payload: IEmailAttachments) => {
  return fetchClient.post(`${EMAIL_ATTACHMENTS.ADD}`, payload);
};
export const getAllAttachments = () => {
  return fetchClient.get(EMAIL_ATTACHMENTS.GET_ALL);
};

export const editAttachments = (payload: IEmailAttachments) => {
  return fetchClient.put(`${EMAIL_ATTACHMENTS.EDIT}`, payload);
};

export const deleteAttachments = (id: string) => {
  return fetchClient.delete(`${EMAIL_ATTACHMENTS.DELETE}/${id}`);
};
