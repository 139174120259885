import { Button } from 'antd';

import { Locale } from '../../components/equipment/form';
import { NOT_AVAILABLE } from '../../constants/common';
import { TPayload } from '../../services/equipment-service';

const columns = [
  {
    title: '# No',
    render: (value: number, record: TPayload) => record?.slno,
    key: 'slno',
    width: 200,
  },
  {
    title: 'Product Name',
    render: (value: Locale, record: TPayload) =>
      record?.product?.en || NOT_AVAILABLE,
    key: 'product',
    width: 800,
  },
  {
    title: 'Website',
    render: (value: string, record: TPayload) => {
      return record?.website ? (
        <div>
          <Button
            style={{ color: 'red' }}
            type="link"
            onClick={() => window.open(record.website, '_blank')}
          >
            Website
          </Button>
        </div>
      ) : (
        NOT_AVAILABLE
      );
    },
    key: 'website',
    width: 800,
  },
];

export default columns;
