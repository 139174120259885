import { Form, Input, Button, message } from 'antd';
import { useState } from 'react';

import { CLIENT_FRONTEND_URL } from '../../../constants/api';
import { forgotPassword } from '../../../services/auth-service';
import './style.scss';

const ForgetPassword = () => {
  const [requesting, setRequesting] = useState(false);
  const onFinish = async (values: { email: string }) => {
    setRequesting(true);
    try {
      await forgotPassword({
        email: values.email,
        returnUrl: `${CLIENT_FRONTEND_URL}/setup-new-password`,
      });
      message.success('Password reset link sent successfully on email');
    } catch (err) {
      console.log(err);
      if (err instanceof Error) {
        message.error(err.message);
      }
    } finally {
      setRequesting(false);
    }
  };

  const onFinishFailed = (errorInfo: unknown) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        className="forgot_form"
        size={'large'}
      >
        <Form.Item label="Email" name="email" required>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            loading={requesting}
            className="forgot_btn"
            block
            type="primary"
            htmlType="submit"
          >
            Proceed
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ForgetPassword;
