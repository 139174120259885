import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

// import { FaqCategory } from "../../../containers/faq/faq/columns";
import { FaqCategory, FaqDetail } from '../../../types/faq';
import { getFaqCategories } from '../../../services/faq-services';
import { InputField, SelectField } from '../../common/form';
import ReactQuillField from '../../common/form/react-quill-fields';

export type cateResArr = {
  value: string;
  label: string;
};

interface IAddFaqFormProps {
  form: UseFormReturn<FaqDetail, object>;
  editable?: boolean;
}
const AddFaqForm = ({ form, editable }: IAddFaqFormProps) => {
  const [categories, setCategories] = useState<cateResArr[]>();
  const getCategories = async () => {
    const response = await getFaqCategories();
    const responseArr: cateResArr[] = [];
    response?.data?.data.map((category: FaqCategory) => {
      return responseArr.push({
        value: category?.slug,
        label: category?.category_name?.en,
      });
    });
    setCategories(responseArr);
  };
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <>
      <Form layout="vertical">
        <SelectField
          label="Faq Category"
          required
          name="slug"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: categories,
            placeholder: 'Select Faq Category',
            onChange: (v) => form.setValue('slug', v),
            disabled: editable,
          }}
        />
        English:
        <InputField
          label="Question(English)"
          name="que.en"
          required
          inputProps={{
            placeholder: 'Question',
          }}
          form={form}
        />
        <ReactQuillField
          name="ans.en"
          label="Answer(English)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        Spanish:
        <InputField
          label="Question(Spanish)"
          name="que.es"
          required
          inputProps={{
            placeholder: 'Question',
          }}
          form={form}
        />
        <ReactQuillField
          name="ans.es"
          label="Answer(Spanish)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        Italian:
        <InputField
          label="Question(Italian)"
          name="que.it"
          required
          inputProps={{
            placeholder: 'Question(Italian)',
          }}
          form={form}
        />
        <ReactQuillField
          name="ans.it"
          label="Answer(Italian)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        German:
        <InputField
          label="Question(German)"
          name="que.de"
          required
          inputProps={{
            placeholder: 'Question(German)',
          }}
          form={form}
        />
        <ReactQuillField
          name="ans.de"
          label="Answer(German)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        Portuguese:
        <InputField
          label="Question(Portuguese)"
          name="que.pt"
          required
          inputProps={{
            placeholder: 'Question(Portuguese)',
          }}
          form={form}
        />
        <ReactQuillField
          name="ans.pt"
          label="Answer(Portuguese)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        Mandarin:
        <InputField
          label="Question(Mandarin)"
          name="que.zh"
          required
          inputProps={{
            placeholder: 'Question(Mandarin)',
          }}
          form={form}
        />
        <ReactQuillField
          name="ans.zh"
          label="Answer(Mandarin)"
          required
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        French:
        <InputField
          label="Question(French)"
          name="que.fr"
          inputProps={{
            placeholder: 'Question(French)',
          }}
          form={form}
        />
        <ReactQuillField
          name="ans.fr"
          label="Answer(French)"
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        Cantonese:
        <InputField
          label="Question(Cantonese)"
          name="que[zh-HK]"
          inputProps={{
            placeholder: 'Question(Cantonese)',
          }}
          form={form}
        />
        <ReactQuillField
          name="ans[zh-HK]"
          label="Answer(Cantonese)"
          form={form}
          reactQuillProps={{
            theme: 'snow',
          }}
        />
        <br />
      </Form>
    </>
  );
};

export default AddFaqForm;
