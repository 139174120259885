import { Button, Drawer, message } from 'antd';
import { createElement, FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import AddInstructorForm from '../form';
import {
  InstructorDetail,
  WorkshopInstructor,
} from '../../../types/instructor';
import { addInstructor } from '../../../services/instructor-service';

interface IAddTInstructorProps {
  successCallback?: () => void;
  instructors: WorkshopInstructor[];
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const AddInstructor: FC<IAddTInstructorProps> = ({
  children,
  successCallback,
  instructors,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<InstructorDetail>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: InstructorDetail) => {
    setRequesting(true);
    try {
      const customPaymentShares = Object?.keys(
        values?.customPaymentShares || {}
      )?.map((i: string) => ({
        techniqueSlug: i,
        ownerShare: values?.customPaymentShares?.[i]?.ownerShare,
        hplShare: values?.customPaymentShares?.[i]?.hplShare,
        instructorShare: values?.customPaymentShares?.[i]?.instructorShare,
      }));

      const payload = {
        ...values,
        pic: values?.pic?.pic,
        pic100: values?.pic?.pic100,
        pic35: values?.pic?.pic35,
        customPaymentShares,
      };

      await addInstructor(payload);
      setDrawerVisible(false);
      message.success('Instructor added successfully');
      form.reset();
      if (successCallback) successCallback();
    } catch (e) {
      if (errorCallback) errorCallback(e as Error);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Add Instructor / Admin"
        onClose={() => {
          handleDrawerVisibility();
          form.reset({});
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddInstructorForm
            isEditable={false}
            form={form}
            instructors={instructors}
            readOnly={false}
          />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddInstructor;
