/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, createElement, useEffect, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { Button, Col, Drawer, Row, message } from 'antd';

import validationSchema from '../validation-schema';
import EndorsementForm from '../form';
import { IEndorsement } from '../../../types/endorsements-and-testimonials';
import { updateEndorsement } from '../../../services/endorsements-service';

interface IAddEndorsementProps {
  successCallback?: () => void;
  errorCallback?: (e: any) => void;
  payload?: IEndorsement;
  endorsmentId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditEndorsement: FC<IAddEndorsementProps> = ({
  children,
  successCallback,
  errorCallback,
  payload,
  endorsmentId,
}) => {
  const refChildren = useRef(children);

  const [publishing, setPublishing] = useState(false);

  const [savingDraft, setSavingDraft] = useState(false);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<IEndorsement>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const { dirtyFields } = useFormState({
    control: form.control,
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: IEndorsement) => {
    if (values?.isApproved) {
      setPublishing(true);
    } else {
      setSavingDraft(true);
    }
    try {
      const dField = Object.keys(dirtyFields).length;
      if (dField !== 0 || values?.isApproved) {
        await updateEndorsement(values, endorsmentId);
        setDrawerVisible(false);
        form.reset();
        if (successCallback) {
          successCallback();
        }
      } else {
        message.info('Nothing Changed in form');
      }
    } catch (err) {
      if (errorCallback) {
        errorCallback(err);
      }
    } finally {
      if (values?.isApproved) {
        setPublishing(false);
      } else {
        setSavingDraft(false);
      }
    }
  };

  useEffect(() => {
    form.reset(payload);
  }, [payload]);

  return (
    <>
      <Drawer
        title="Update Endorsement"
        onClose={() => {
          handleDrawerVisibility();
          form.reset({});
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Row>
              <Col span={12} style={{ padding: '10px' }}>
                <Button
                  danger
                  block
                  type="primary"
                  loading={savingDraft}
                  onClick={(e) => {
                    form.setValue('isApproved', false);
                    form.handleSubmit(onSubmitHandler)(e);
                  }}
                >
                  Save As Draft
                </Button>
              </Col>
              <Col span={12} style={{ padding: '10px' }}>
                <Button
                  block
                  type="primary"
                  loading={publishing}
                  onClick={(e) => {
                    form.setValue('isApproved', true);
                    form.handleSubmit(onSubmitHandler)(e);
                  }}
                >
                  {payload?.isApproved ? 'Update' : 'Publish'}
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <EndorsementForm isEditable={true} form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditEndorsement;
