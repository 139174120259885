import { EXERCISE_EQUIPMENTS } from '../constants/api';
import { ExerciseEquipment } from '../types/exercise-equipment';
import fetchClient from '../utils/fetch-client';

export const getExerciseEquipments = () => {
  return fetchClient.get(EXERCISE_EQUIPMENTS.EXERCISE_EQUIPMENT);
};

export const addExerciseEquipment = (payload: ExerciseEquipment) => {
  return fetchClient.post(EXERCISE_EQUIPMENTS.EXERCISE_EQUIPMENT, payload);
};

export const updateExerciseEquipment = (payload: ExerciseEquipment) => {
  // return Promise.resolve(payload);
  return fetchClient.put(EXERCISE_EQUIPMENTS.EXERCISE_EQUIPMENT, payload);
};

export const deleteExerciseEquipment = (payload: { equipmentId: string }) => {
  return fetchClient.delete(EXERCISE_EQUIPMENTS.EXERCISE_EQUIPMENT, {
    data: payload,
  });
};
