import { FC, createElement, useRef, useState } from 'react';
import { Button, Modal, message } from 'antd';

import { removeComplementaryPosition } from '../../services/workshop-service';
import { WorkshopAttendeeWithUserDetail } from '../../types/workshop';

interface RemoveAttendeeProps {
  attendee: WorkshopAttendeeWithUserDetail;
  children: FC<{
    onClick: () => void;
  }>;
  errorCallback?: (e: unknown) => void;
  successCallback?: () => void;
}

const RemoveAttendee: FC<RemoveAttendeeProps> = ({
  attendee,
  children,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);

  const [open, setOpen] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const handleSubmit = async (attendeeId: string) => {
    try {
      setRequesting(true);
      await removeComplementaryPosition({ attendeeId });
      message.success('Removed succesfully successfully.');
      setOpen(false);
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      if (errorCallback) {
        errorCallback(err);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Modal
        title={false}
        open={open}
        onCancel={() => setOpen(false)}
        okText="Yes"
        destroyOnClose
        footer={[
          <Button type="default" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={async () => {
              await handleSubmit(attendee?._id);
            }}
            loading={requesting}
          >
            Yes
          </Button>,
        ]}
      >
        <h3>Are you sure ?</h3>
      </Modal>
      {createElement(refChildren.current, { onClick: () => setOpen(true) })}
    </>
  );
};

export default RemoveAttendee;
