import { FC } from 'react';
import { Card, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { TUser } from '../../../../services/users-service';

import EditAllowedSection from './edit-allowed-sections.tsx';

interface TAllowedSectionProps {
  user: TUser;
  successCallback: () => void;
}

const AllowedSections: FC<TAllowedSectionProps> = ({
  user,
  successCallback,
}) => {
  return (
    <Card title="Allowed Sections" className="info-container">
      <span className="info-heading">
        <EditAllowedSection user={user} successCallback={successCallback}>
          {({ onClick }) => (
            <EditOutlined onClick={onClick} style={{ float: 'right' }} />
          )}
        </EditAllowedSection>
      </span>
      <div>
        {user?.allowedSections?.flatMap((section) => {
          return section?.subSections?.map((subSection) => {
            return (
              <Tag style={{ marginBottom: '8px' }}>{subSection?.label}</Tag>
            );
          });
        })}
      </div>
    </Card>
  );
};

export default AllowedSections;
