import { Card, Radio, RadioChangeEvent } from 'antd';
import { FC, useEffect, useState } from 'react';

import { getAttendeesWorkshops } from '../../../services/workshop-service';
import { AttendeeWorkshops, IFilterWorkshop } from '../../../types/workshop';
import { TUser } from '../../../services/users-service';
import { handleError } from '../../../utils/common';

import WorkshopsList from './WorkshopList';

interface WorkshopAttendedListProps {
  user: TUser;
}

const options = [
  { label: 'Past', value: 'past' },
  { label: 'Upcoming', value: 'upcoming' },
];

const WorkshopAttendedHistory: FC<WorkshopAttendedListProps> = ({ user }) => {
  const [value, setValue] = useState<'past' | 'upcoming'>('past');
  const [workshops, setWorkshops] = useState<IFilterWorkshop>({
    upcoming: [],
    past: [],
  });
  const [filteredWorkshops, setFilteredWorkshops] = useState<
    AttendeeWorkshops[]
  >([]);
  const [requesting, setRequesting] = useState(false);

  const onChange = (value: 'past' | 'upcoming') => {
    setValue(value);
  };

  async function loadWorkshops() {
    try {
      const response = await getAttendeesWorkshops(user?._id || '');
      setWorkshops(response?.data?.data);
      setFilteredWorkshops(response.data?.data.upcoming);

      setRequesting(false);
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  }
  useEffect(() => {
    if (user) {
      setRequesting(true);
      loadWorkshops();
    }
  }, [user]);

  const transferSuccessCallback = () => {
    setRequesting(true);
    loadWorkshops();
  };

  useEffect(() => {
    if (workshops) {
      setFilteredWorkshops(workshops[value]);
    }
  }, [value, workshops]);

  return (
    <>
      <Card bordered={false} style={{ backgroundColor: 'transparent' }}>
        <Radio.Group
          options={options}
          onChange={({ target: { value } }: RadioChangeEvent) =>
            onChange(value)
          }
          value={value}
          optionType="button"
          buttonStyle="solid"
        />
        <br />
        <br />

        <WorkshopsList
          filteredWorkshops={[...filteredWorkshops]}
          requesting={requesting}
          transferSuccessCallback={transferSuccessCallback}
          userId={user?._id}
        />
      </Card>
    </>
  );
};

export default WorkshopAttendedHistory;
