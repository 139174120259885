import { FLAG_LIST } from '../constants/api';
import fetchClient from '../utils/fetch-client';

export const getBlockedTeacherContactEmails = (page: number, limit: number) => {
  return fetchClient.get(
    `${FLAG_LIST.GET_BLOCKED_EMAILS}?page=${page ?? 1}&limit=${limit ?? 10}`
  );
};

export const reviewBlockedEmail = (isApproved: boolean, emailId: string) => {
  return fetchClient.post(`${FLAG_LIST.REVIEW_BLOCKED_EMAIL}/${emailId}`, {
    isApproved,
  });
};
