import { Button, Drawer, message } from 'antd';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import validationSchema from '../validation-schema';
import { ICertificationRenewalQuestion } from '../../../types/CertificationRenewalQuestion';
import AddCertificationRenewalQuestionForm from '../form';
import { updateCertificationRenewalQuestion } from '../../../services/certification-renewal-question-service';

interface IEditCertificationRenewalQuestionProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  payload: ICertificationRenewalQuestion;
  questionId: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const EditCertificationRenewalQuestion: FC<
  IEditCertificationRenewalQuestionProps
> = ({ children, payload, questionId, successCallback, errorCallback }) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<ICertificationRenewalQuestion>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };
  const onSubmitHandler = async (values: ICertificationRenewalQuestion) => {
    setRequesting(true);

    try {
      await updateCertificationRenewalQuestion(values, questionId);
      message.success('Question Updated successfully');
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setDrawerVisible(false);
      setRequesting(false);
    }
  };

  useEffect(() => {
    form.reset({
      _id: payload?._id,
      renewalYear: payload?.renewalYear,
      category: payload?.category,
      video: payload?.video,
      sequence: payload?.sequence,
      questions: payload?.questions,
    });
  }, [payload]);

  return (
    <>
      <Drawer
        title="Update Question"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Update
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddCertificationRenewalQuestionForm isEditable form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditCertificationRenewalQuestion;
