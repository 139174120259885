import { GIFT_VOUCHER_ORDERS, GIFT_VOUCHER_PRODUCT } from '../constants/api';
import fetchClient from '../utils/fetch-client';

type TGiftVoucherPayload = {
  name: string;
  months: number;
  memberType: string;
  defaultCurrency?: string;
  defaultPrice?: number;
  discountInPercent: number;
  imageUrl: string;
  currencyOptionsArray?: [
    {
      currency: string;
      unit_amount: number;
    }
  ];
};

// gift-voucher-product
export const getGiftVoucherProduct = (id?: string) => {
  return fetchClient.get(
    `${GIFT_VOUCHER_PRODUCT.GIFT_VOUCHER_PRODUCT}/${id ? id : ''}`
  );
};

export const createGiftVoucherProduct = (payload: TGiftVoucherPayload) => {
  return fetchClient.post(
    `${GIFT_VOUCHER_PRODUCT.GIFT_VOUCHER_PRODUCT}`,
    payload
  );
};

export const updateGiftVoucherProduct = ({
  payload,
  productId,
}: {
  payload: TGiftVoucherPayload;
  productId: string;
}) => {
  return fetchClient.put(`${GIFT_VOUCHER_PRODUCT.GIFT_VOUCHER_PRODUCT}`, {
    ...payload,
    productId,
  });
};
export const deleteGiftVoucherProduct = ({
  productId,
}: {
  productId: string;
}) => {
  return fetchClient.delete(
    `${GIFT_VOUCHER_PRODUCT.GIFT_VOUCHER_PRODUCT}/${productId ? productId : ''}`
  );
};

export const getGiftVoucherOrders = ({
  page = 1,
  limit = 25,
}: {
  userid?: string;
  page: number;
  limit?: number;
}) => {
  return fetchClient.get(
    `${GIFT_VOUCHER_ORDERS.GIFT_VOUCHER_ORDERS}?page=${page}&limit=${limit}`
  );
};
