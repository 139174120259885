import * as yup from 'yup';

import { AdminType } from '../../services/users-service';

export default yup.object().shape({
  instructorSequenceNo: yup
    .number()
    .typeError('Please input a number')
    .required('Please input name'),
  fullName: yup.string().required('Please input name'),
  nameAsUnderSignature: yup.string().required('Please input name'),
  email: yup
    .string()
    .email('enter a valid email address')
    .required('Please input email'),
  contactEmail: yup
    .string()
    .email('enter a valid contact email address')
    .required('Please input contact email'),
  adminType: yup
    .string()
    .oneOf(['workshop', 'instructor'], 'Invalid option selected')
    .required('admin type field is required'),
  designation: yup.object().shape({
    en: yup.string().required('Please input designation in english'),
    pt: yup.string().required('Please input designation in portugese'),
    it: yup.string().required('Please input designation in italian'),
    es: yup.string().required('Please input designation in spanish'),
    de: yup.string().required('Please input designation in german'),
    zh: yup.string().required('Please input designation in mandrin'),
  }),
  credentials: yup.object().shape({
    en: yup.string(),
    pt: yup.string(),
    it: yup.string(),
    es: yup.string(),
    de: yup.string(),
    zh: yup.string(),
  }),
  facebook: yup
    .string()
    .matches(/^https:\/\/(?:www\.)?facebook\.[a-z]+\/?.*$/i, {
      message: 'Please enter a valid Facebook URL starting with https://',
      excludeEmptyString: true,
    }),
  instagram: yup
    .string()
    .matches(/^https:\/\/(?:www\.)?instagram\.[a-z]+\/?.*$/i, {
      message: 'Please enter a valid Instagram URL starting with https://',
      excludeEmptyString: true,
    }),
  twitter: yup.string().matches(/^https:\/\/(?:www\.)?twitter\.[a-z]+\/?.*$/i, {
    message: 'Please enter a valid Twitter URL starting with https://',
    excludeEmptyString: true,
  }),
  linkedin: yup
    .string()
    .matches(/^https:\/\/(?:www\.)?linkedin\.[a-z]+\/?.*$/i, {
      message: 'Please enter a valid LinkedIn URL starting with https://',
      excludeEmptyString: true,
    }),
  multiLangBio: yup.object().shape({
    en: yup.string().required('Please input bio in english'),
    pt: yup.string().required('Please input bio in portugese'),
    it: yup.string().required('Please input bio in italian'),
    es: yup.string().required('Please input bio in spanish'),
    de: yup.string().required('Please input bio in german'),
    zh: yup.string().required('Please input bio in mandrin'),
  }),
  advanceLaunchDays: yup.number().typeError('Please input a number'),
  techniquesAllowed: yup.array().of(yup.string()),
  signature: yup.string(),
  isUsingDefaultTechniqueShares: yup
    .boolean()
    .when(['techniquesAllowed', 'adminType'], {
      is: (techniquesAllowed: string[], adminType: string) =>
        adminType === AdminType.workshop && techniquesAllowed?.length > 0,
      then: (schema) => schema.required('Please choose any one option'),
      otherwise: (schema) => schema,
    }),

  customPaymentShares: yup.object().when(
    ['isUsingDefaultTechniqueShares', 'techniquesAllowed', 'adminType'],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (isUsingDefaultTechniqueShares, techniquesAllowed, adminType) => {
      if (
        adminType !== AdminType.workshop ||
        isUsingDefaultTechniqueShares ||
        techniquesAllowed?.length === 0
      ) {
        return yup.object().shape({});
      }

      const shape = techniquesAllowed?.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accumulator: any, technique: string) => {
          accumulator[technique] = yup.object().shape({
            ownerShare: yup
              .number()
              .typeError('Please input a number')
              .required(`Owner share (${technique}) is required`),
            hplShare: yup
              .number()
              .typeError('Please input a number')
              .required(`HPL share (${technique}) is required`),
            instructorShare: yup
              .number()
              .typeError('Please input a number')
              .required(`Instructor share (${technique}) is required`),
          });
          return accumulator;
        },
        {}
      );

      return yup.object().shape(shape);
    }
  ),
});
