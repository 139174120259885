/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import { Dropdown, message } from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import {
  UserCertification,
  deleteUserCertification,
} from '../../../../../services/users-service';

interface IDeleteCertificationProps {
  userId: string;
  certification: UserCertification;
  errorCallback?: (e: Error) => void;
  successCallback?: () => void;
}

const DeleteCertification: FC<IDeleteCertificationProps> = ({
  userId,
  certification,
  errorCallback,
  successCallback,
}) => {
  const [requesting, setRequesting] = useState(false);

  const deleteHandler = async (e: any) => {
    setRequesting(true);
    try {
      const data = JSON.parse(e?.key);
      await deleteUserCertification(userId || '', data?.code, data?.action);
      if (successCallback) {
        successCallback();
      }
      message.success(
        data?.action === 'revoke'
          ? 'Revoked certification successfully.'
          : 'Remove certification successfully.'
      );
    } catch (e: any) {
      if (errorCallback) {
        errorCallback(e?.response?.data?.MESSAGE_KEY || e?.message);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              label: 'Revoke Certification',
              key: JSON.stringify({
                code: certification?.code,
                action: 'revoke',
              }),
              onClick: deleteHandler,
            },
            {
              type: 'divider',
            },
            {
              label: 'Remove Certification',
              key: JSON.stringify({
                code: certification?.code,
                action: 'remove',
              }),
              onClick: deleteHandler,
            },
          ],
        }}
        trigger={['click']}
      >
        {!requesting ? (
          <DeleteOutlined
            className="action-buttons-danger"
            style={{ color: 'red' }}
          />
        ) : (
          <LoadingOutlined />
        )}
      </Dropdown>
    </>
  );
};

export default DeleteCertification;
