import { createElement, FC, useEffect, useRef, useState } from 'react';
import { Button, Drawer, message } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import EditUserForm from '../../users/edit-user/form';
import { TUser, updateUser } from '../../../services/users-service';
import { TFieldValues } from '../types';

import validationSchema from './validation-schema';
interface IEditUserProps {
  userId?: string | undefined;
  user: TUser;
  children: FC<{ onClick: () => void }>;
  errorCallback?: (e: Error) => void;
  successCallback?: () => void;
}

const EditUser: FC<IEditUserProps> = ({
  children,
  user,
  userId,
  errorCallback,
  successCallback,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const refChildren = useRef(children);

  const getProfileToEdit = (userProfile: TUser) => {
    return {
      ...(userProfile?.fullName && { fullName: userProfile.fullName }),
      ...(userProfile?.displayName && { displayName: userProfile.displayName }),
      ...(userProfile?.nameAsOnCertificate && {
        nameAsOnCertificate: userProfile.nameAsOnCertificate,
      }),
      ...(userProfile?.emergencyContactName && {
        emergencyContactName: userProfile.emergencyContactName,
      }),
      ...(userProfile?.emergencyContactNumber && {
        emergencyContactNumber: userProfile.emergencyContactNumber,
      }),
      ...(userProfile?.website && { website: userProfile.website }),
      ...(userProfile?.facebook && { facebook: userProfile.facebook }),
      ...(userProfile?.instagram && { instagram: userProfile.instagram }),
      ...(userProfile?.twitter && { twitter: userProfile.twitter }),
      ...(userProfile?.linkedin && { linkedin: userProfile.linkedin }),
      ...(userProfile?.multiLangBio && {
        multiLangBio: userProfile.multiLangBio,
      }),
      ...(userProfile?.language && { language: userProfile.language }),
      ...(userProfile?.profession && { profession: userProfile.profession }),
    };
  };
  const form = useForm<TFieldValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  useEffect(() => {
    form.reset(getProfileToEdit(user));
  }, [user]);

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (data: TUser) => {
    const { ...user } = data;
    setRequesting(true);
    try {
      await updateUser({
        _id: userId,
        user,
      });

      message.success('Personal Details Updated Successfully');

      handleDrawerVisibility();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Edit User"
        onClose={() => {
          handleDrawerVisibility();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <Button
            block
            type="primary"
            loading={requesting}
            onClick={form.handleSubmit(onSubmitHandler)}
          >
            Update
          </Button>
        }
      >
        <div style={{ padding: 16 }}>
          <EditUserForm form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default EditUser;
