import { MUSCLE } from '../constants/api';
import { MuscleCategoryDetail, MuscleDetail } from '../types/muscle';
import fetchClient from '../utils/fetch-client';

//mussle categories
export const getMuscleCategories = () => {
  return fetchClient.get(`${MUSCLE.MUSCLE_CATEGORY}`);
};
export const addMuscleCategory = (payload: MuscleCategoryDetail) => {
  return fetchClient.post(`${MUSCLE.MUSCLE_CATEGORY}`, payload);
};

export const updateMuscleCategory = (
  payload: MuscleCategoryDetail,
  id: string
) => {
  return fetchClient.put(`${MUSCLE.MUSCLE_CATEGORY}/${id}`, payload);
};

export const deleteMuscleCategory = (id?: string) => {
  return fetchClient.delete(`${MUSCLE.MUSCLE_CATEGORY}/${id}`);
};

//mussle
export const getMuscles = () => {
  return fetchClient.get(`${MUSCLE.MUSCLE}`);
};
export const addMuscle = (payload: MuscleDetail) => {
  return fetchClient.post(`${MUSCLE.MUSCLE}`, payload);
};

export const updateMuscle = (payload: MuscleDetail, id: string) => {
  return fetchClient.put(`${MUSCLE.MUSCLE}/${id}`, payload);
};

export const deleteMuscle = (id?: string) => {
  return fetchClient.delete(`${MUSCLE.MUSCLE}/${id}`);
};
