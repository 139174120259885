import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Drawer, Input, message, Select, Spin, Table } from 'antd';
import moment from 'moment';
import { createElement, FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FORMAT_MDY } from '../../../constants/format';
import {
  getWorkshopAttendees,
  updateWorkshopAttendence,
} from '../../../services/workshop-service';
import {
  WorkshopAttendeeList,
  UpdateAttendee,
  AttendeeAttendence,
  WorkshopAttendeeWithUserDetail,
} from '../../../types/workshop';
import validationSchema from '../validationSchema';
import { handleError } from '../../../utils/common';

interface UpdateAttendenceProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  workshopSlug: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const UpdateAttendence: FC<UpdateAttendenceProps> = ({
  children,
  successCallback,
  errorCallback,
  workshopSlug,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [loading, setLoading] = useState(false);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableData, setTableData] = useState<AttendeeAttendence[]>([]);
  const [workshopAttendees, setWorkshopAttendees] =
    useState<WorkshopAttendeeList>();

  const form = useForm<UpdateAttendee>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onInputChange =
    (id: string, index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newData = [...tableData];
      newData[index]['note'] = e.target.value;
      setTableData(newData);
    };

  const handleAttendeeStatus = (id: string, index: number) => (e: string) => {
    const newData = [...tableData];
    newData[index]['attendanceStatus'] = e;
    setTableData(newData);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const newData = [...tableData];
    tableData?.forEach((data, index) => {
      if (newSelectedRowKeys.includes(data._id)) {
        newData[index]['isAttendanceMarked'] = true;
        newData[index]['attendanceStatus'] = 'ATTENDED';
      } else {
        newData[index]['isAttendanceMarked'] = false;
        newData[index]['attendanceStatus'] = undefined;
      }
    });
    setTableData(newData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSubmit = async () => {
    // if (selectedRowKeys?.length !== tableData?.length) {
    //   message.info(
    //     'Select all the attendees along with their attendance status.'
    //   );
    //   return;
    // }
    setRequesting(true);
    try {
      await updateWorkshopAttendence(
        tableData?.map((i) => ({
          ...i,
          attendanceStatus: i?.attendanceStatus || '',
        }))
      );
      setDrawerVisible(false);
      message.success('Attendence marked successfully');
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  const getAttendees = async () => {
    setLoading(true);
    try {
      const attendeesAttendence: AttendeeAttendence[] = [];
      const rowKeys: string[] = [];
      const response = await getWorkshopAttendees(workshopSlug);

      setWorkshopAttendees(response?.data?.data);
      const workshopAttendees = response?.data?.data?.attendees;
      workshopAttendees?.map((data: WorkshopAttendeeWithUserDetail) => {
        attendeesAttendence.push({
          _id: data._id as string,
          note: data?.note || '',
          fullName: data?.fullName,
          email: data?.email,
          isAttendanceMarked: data?.isAttendanceMarked,
          attendanceStatus: data?.attendanceStatus || undefined,
        });
        if (data.isAttendanceMarked) {
          rowKeys.push(data?._id as string);
        }
      });
      setSelectedRowKeys(rowKeys);
      setTableData(attendeesAttendence);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (workshopSlug && drawerVisible) {
      getAttendees();
    }
  }, [drawerVisible]);

  const columns = [
    {
      title: 'Attendee',
      render: (value: string, record: AttendeeAttendence) => (
        <div>
          <h3>{record?.fullName}</h3>
          <h4>{record?.email}</h4>
        </div>
      ),
    },
    {
      title: 'Attendance Status',
      render: (value: string, record: AttendeeAttendence, index: number) => (
        <Select
          style={{ width: '100%' }}
          placeholder="Please select"
          options={[
            {
              label: 'Attended',
              value: 'ATTENDED',
            },
            {
              label: 'Not attended',
              value: 'NOT_ATTENDED',
            },
            {
              label: 'Not certified ',
              value: 'NOT_CERTIFIED',
            },
          ]}
          allowClear
          onChange={handleAttendeeStatus(record?._id as string, index)}
          value={record?.attendanceStatus}
        />
      ),
      width: 200,
    },
    {
      title: 'Note',
      render: (value: string, record: AttendeeAttendence, index: number) => (
        <Input
          value={record?.note}
          onChange={onInputChange(record?._id as string, index)}
        />
      ),
    },
  ];

  return (
    <>
      <Drawer
        title="Update Workshop Attendence"
        onClose={() => {
          handleDrawerVisibility();
          form.reset();
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={800}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={handleSubmit}
            >
              Update Attendence
            </Button>
          </>
        }
      >
        <Spin spinning={loading}>
          <div style={{ paddingLeft: '25px' }}>
            <h2>{workshopAttendees?.title}</h2>
            <h5>
              {workshopAttendees?.workshopMode === 'virtual'
                ? 'Online'
                : workshopAttendees?.city}
              ,{workshopAttendees?.country}
            </h5>
            <small>
              {moment(workshopAttendees?.startDate)
                .tz(workshopAttendees?.timezone || 'UTC')
                .format(FORMAT_MDY)}
              , {workshopAttendees?.timezone}
            </small>
          </div>
          <div style={{ padding: 16 }}>
            <Table
              rowKey="_id"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={tableData}
              pagination={false}
            />
          </div>
        </Spin>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default UpdateAttendence;
