import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Button, Col, Form, Row, message } from 'antd';
import axios from 'axios';

import { ExerciseVideo } from '../../../types/exercises';
import WistiaUploader, { ISetData } from '../wistia-uploader';
import { getTencentVideoData } from '../../../services/exercises';
import { InputField, RadioField } from '../../common/form';
import TencentVideoUploader from '../tencent-uploader';
import Tcplayer from '../tencent-player';
import { handleError } from '../../../utils/common';

interface IVideoUploaderProps {
  form: UseFormReturn<ExerciseVideo, object>;
  isEditMode: boolean;
}

const VideoUploader = ({ form, isEditMode }: IVideoUploaderProps) => {
  const [isValidated, setIsValidated] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [changeMedia, setChangeMedia] = useState(false);
  const [showChangeButton, setShowChangeButton] = useState(false);
  //   const [originalData, setOriginalData] = useState({
  //     videoUrl: '',
  //     thumb: '',
  //     mediaId: '',
  //     duration: 0,
  //   });

  const values = form.watch();

  const setWistiaData = (data: ISetData): void => {
    form.setValue('videoUrl', data?.videoUrl);
    form.setValue('thumb', data?.thumb);
    form.setValue('mediaId', data?.wistiaId);
    form.setValue('duration', data?.duration);
  };

  const validateWistiaId = async (mediaId: string) => {
    if (!mediaId) {
      message.info('Media ID is required');
    }
    setIsValidating(true);
    try {
      let response;
      if (values?.language === 'zh') {
        const response = await getTencentVideoData(mediaId);
        const videoData = response?.data?.data;
        if (videoData?.mediaId) {
          form.setValue('mediaId', videoData?.mediaId);
          form.setValue('videoUrl', videoData?.videoUrl);
          form.setValue('thumb', videoData?.thumb);
          form.setValue('duration', videoData?.duration);

          setIsValidated(true);
        } else {
          message.info('Video not found.');
        }
      } else {
        response = await axios.get(
          `https://api.wistia.com/v1/medias/${mediaId}.json`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_WISTIA_ACCESS_TOKEN}`,
            },
          }
        );

        if (response?.status === 200) {
          const newMedia = response?.data;
          const url = new URL(newMedia.thumbnail.url);
          const thumb = `${url?.origin}${url.pathname}?image_quality=100&ssl=true`;

          const data = {
            duration: newMedia?.duration,
            wistiaId: newMedia.hashed_id,
            thumb,
            videoUrl: `https://pbt.wistia.com/medias/${newMedia.hashed_id}`,
          };
          setWistiaData(data);
          setIsValidated(true);
        } else {
          message.info('Video not found');
        }
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsValidating(false);
    }
  };

  useEffect(() => {
    form.setValue('haveMediaId', true);

    if (values?.mediaId) {
      setIsValidated(true);
      setShowChangeButton(true);
    }

    if (isEditMode && !values?.mediaId) {
      setChangeMedia(true);
    }

    // setOriginalData({
    //   videoUrl: values?.videoUrl,
    //   thumb: values?.thumb,
    //   mediaId: values?.mediaId || '',
    //   duration: values?.duration || 0,
    // });
  }, []);

  return (
    <div>
      {/* video uploder */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {(!isEditMode || (isEditMode && changeMedia)) && (
          <RadioField
            name="haveMediaId"
            required
            label="Do you have Media ID?"
            form={form}
            renderValue={(plan) => {
              return plan.label;
            }}
            radioFieldProps={{
              options: [
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ],
              onChange: (v) => {
                form.setValue('haveMediaId', v?.target?.value);
                form.setValue('videoUrl', '');
                form.setValue('thumb', '');
                form.setValue('mediaId', '');
                form.setValue('duration', undefined);
                setIsValidated(false);
              },
            }}
          />
        )}
        {isEditMode && showChangeButton && changeMedia && (
          <Button
            type="primary"
            onClick={() => {
              setChangeMedia(false);
              //   form.setValue('videoUrl', originalData?.videoUrl);
              //   form.setValue('thumb', originalData?.thumb);
              //   form.setValue('mediaId', originalData?.mediaId);
              //   form.setValue('duration', originalData?.duration);
              form.resetField('videoUrl');
              form.resetField('thumb');
              form.resetField('mediaId');
              form.resetField('duration');
              if (!isValidated) {
                setIsValidated(true);
              }
            }}
          >
            Cancle Change Media
          </Button>
        )}
      </div>
      <div>
        {(!isEditMode || (isEditMode && changeMedia)) && (
          <div>
            {form.watch('haveMediaId') ? (
              <Form layout="horizontal">
                <Row>
                  <Col xs={24} md={12}>
                    <InputField
                      name="mediaId"
                      label="Media ID"
                      required
                      inputProps={{
                        placeholder: 'Enter Media ID (eg. l7gcl80oqe)',
                        onChange: (e) => {
                          form.setValue('mediaId', e.target.value);
                          form.setValue('videoUrl', '');
                          form.setValue('thumb', '');
                          form.setValue('duration', undefined);
                          if (isValidated) {
                            setIsValidated(false);
                          }
                        },
                      }}
                      form={form}
                    />
                  </Col>
                  <Button
                    type="primary"
                    disabled={!values?.mediaId}
                    onClick={() => {
                      values?.mediaId && validateWistiaId(values?.mediaId);
                    }}
                    loading={isValidating}
                    htmlType="submit"
                  >
                    Validate
                  </Button>
                </Row>
              </Form>
            ) : values?.language === 'zh' ? (
              <TencentVideoUploader form={form} />
            ) : (
              <WistiaUploader setData={setWistiaData} />
            )}
            {!form.watch('haveMediaId') && form?.formState?.errors?.mediaId && (
              <h4 style={{ color: 'red' }}>Please Upload a exercise video</h4>
            )}
          </div>
        )}
      </div>
      {/* video player */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          gap: '10px',
        }}
      >
        {isEditMode && showChangeButton && !changeMedia && (
          <Button
            type="primary"
            onClick={() => {
              setChangeMedia(true);
            }}
          >
            Change Media
          </Button>
        )}
        <div style={{ width: '100%' }}>
          {values?.mediaId && isValidated ? (
            values?.language === 'zh' ? (
              <Row key={values?.mediaId}>
                <Tcplayer fileId={values?.mediaId} />
              </Row>
            ) : (
              <div
                id="my_video"
                key={values?.mediaId}
                className={`wistia_embed wistia_async_${values?.mediaId}`}
                style={{ width: '100%', height: '300px' }}
              />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VideoUploader;
