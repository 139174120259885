import { NOT_AVAILABLE } from '../../constants/common';
import { IFlagList } from '../../types/flag-list';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 200,
  },
  {
    title: 'Type',
    render: (value: string, record: IFlagList) => record?.type || NOT_AVAILABLE,
    width: 800,
  },
  {
    title: 'Value',
    render: (value: string, record: IFlagList) =>
      record?.value || NOT_AVAILABLE,
    width: 800,
  },
  {
    title: 'Reason',
    render: (value: string, record: IFlagList) =>
      record?.reason || NOT_AVAILABLE,
    width: 800,
  },
];

export default columns;
