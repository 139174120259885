import { useForm } from 'react-hook-form';
import { Button, message, Drawer, Table, Spin } from 'antd';
import { useState, useRef, FC, createElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';

import { WaitlistAttendee, WaitListEmail } from '../../../types/workshop';
import {
  getWaitListAttendee,
  sendMailToWaitList,
} from '../../../services/workshop-service';
import { handleError } from '../../../utils/common';

import WaitlistForm from './form';
import validationSchema from './validation-schema';

interface EmailAttendeesProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const WaitlistAttendees: FC<EmailAttendeesProps> = ({
  children,
  successCallback,
  errorCallback,
}) => {
  const { workshopSlug } = useParams();

  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [loading, setLoading] = useState(false);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [waitList, setWaitList] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const form = useForm<WaitListEmail>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (values: WaitListEmail) => {
    setRequesting(true);
    try {
      const emailOptions = {
        recipients: selectedRowKeys,
        subject: values.subject,
        body: values.body,
      };
      await sendMailToWaitList(emailOptions);
      setDrawerVisible(false);
      message.success('Email Sent Successfully');
      form.reset();
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  const columns = [
    {
      title: 'Added Date',
      render: (value: string, record: WaitlistAttendee) =>
        moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Email',
      render: (value: string, record: WaitlistAttendee, index: number) =>
        record.email,
    },
    {
      title: 'Mail Sent On',
      render: (value: string, record: WaitlistAttendee, index: number) =>
        record.mailSentOn,
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getWailtlist = async () => {
    setLoading(true);
    try {
      const res = await getWaitListAttendee(workshopSlug as string);
      setWaitList(res?.data?.data);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (workshopSlug && drawerVisible) {
      getWailtlist();
    }
  }, [workshopSlug, drawerVisible]);

  return (
    <>
      <Drawer
        onClose={() => {
          handleDrawerVisibility();
        }}
        title={'Waitlist Attendee'}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={800}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Submit
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <Spin spinning={loading}>
            <div style={{ padding: 16 }}>
              <Table
                rowKey="email"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={waitList}
              />
            </div>
            <WaitlistForm isEditable={false} form={form} />
          </Spin>
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default WaitlistAttendees;
