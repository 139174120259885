import { createElement, FC, useRef, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { deleteWorkshop } from '../../../services/workshop-service';
import { InputField } from '../../common/form';

interface IDeleteWorkshopProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  workshopSlug?: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const DeleteWorkshop: FC<IDeleteWorkshopProps> = ({
  children,
  workshopSlug,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [open, setOpen] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const form = useForm<{ reason: string }>({
    resolver: yupResolver(
      yup.object().shape({ reason: yup.string().required('Required') })
    ),
    mode: 'all',
  });

  const onDelete = async (values: { reason: string }) => {
    setRequesting(true);
    try {
      await deleteWorkshop(workshopSlug, values);
      if (successCallback) {
        successCallback();
      }
      setOpen(false);
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  const handlePopVisibility = () => {
    setOpen(!open);
  };
  return (
    <>
      <Modal
        open={open}
        title="Are you sure to remove this Workshop?"
        onCancel={handlePopVisibility}
        width={500}
        destroyOnClose
        footer={
          <>
            <Button danger type="primary" onClick={handlePopVisibility}>
              Cancel
            </Button>
            <Button
              type="primary"
              loading={requesting}
              onClick={(e) => {
                form.handleSubmit(onDelete)(e);
              }}
            >
              Submit
            </Button>
            ,
          </>
        }
      >
        <Form layout="vertical">
          <InputField
            name="reason"
            label="Reason to delete"
            form={form}
            inputProps={{ placeholder: 'Reason to delete' }}
          />
        </Form>
      </Modal>
      {createElement(refChildren.current, { onClick: handlePopVisibility })}
    </>
  );
};

export default DeleteWorkshop;
