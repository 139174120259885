/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Typography, Segmented } from 'antd';

import { SentEmailStatus } from '../../../types/email-records';
import './styles.scss';

interface EmailHeaderProps {
  alignValue: SentEmailStatus;
  setAlignValue: (value: SentEmailStatus) => void;
}

const { Title } = Typography;

const EmailHeader: React.FC<EmailHeaderProps> = ({
  alignValue,
  setAlignValue,
}) => (
  <div className="email-header">
    <Title level={3} className="no-margin">
      Sent Mails
    </Title>
    {/* @ts-ignore */}
    <Segmented
      value={alignValue}
      options={[
        { value: SentEmailStatus.SENT, label: SentEmailStatus.SENT },
        { value: SentEmailStatus.QUEUED, label: SentEmailStatus.QUEUED },
        { value: SentEmailStatus.FAILED, label: SentEmailStatus.FAILED },
      ]}
      onChange={(value) => setAlignValue(value as SentEmailStatus)}
    />
  </div>
);

export default EmailHeader;
