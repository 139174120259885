import { Form, TreeSelect } from 'antd';
import { UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { InputField, RadioField, SelectField } from '../common/form';
import { MENUS } from '../layout/sidebar';
import TreeSelectField from '../common/form/tree-select';
import { TechniqueDetail } from '../../types/technique';
import { getTechniques } from '../../services/technique-service';
import { handleError } from '../../utils/common';
import { AdminType } from '../../services/users-service';
import { WorkshopInstructor } from '../../types/instructor';

import { IAdminFormValue } from './add-admin';

interface IAddAdminFormProps {
  form: UseFormReturn<IAdminFormValue, object>;
  instructor: WorkshopInstructor[];
}
const AddAdminForm = ({ form, instructor }: IAddAdminFormProps) => {
  const adminType = form.watch('adminType');
  const techniquesAllowed = form.watch('techniquesAllowed');
  const isUsingDefaultTechniqueShares = form.watch(
    'isUsingDefaultTechniqueShares'
  );

  const [techniques, setTechniques] = useState<TechniqueDetail[]>([]);

  const getAllTechnique = async () => {
    try {
      const response = await getTechniques();
      if (response) {
        setTechniques(response?.data?.data);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getAllTechnique();
  }, []);

  return (
    <>
      <Form layout="vertical">
        <InputField
          label="Name"
          name="fullName"
          required
          inputProps={{
            placeholder: 'Name',
            type: 'text',
          }}
          form={form}
        />
        <InputField
          label="Email"
          name="email"
          required
          inputProps={{
            placeholder: 'Name',
            type: 'email',
          }}
          form={form}
        />
        <SelectField
          label="Admin Type"
          required
          name="adminType"
          form={form}
          labelKey="label"
          valueKey="value"
          selectFieldProps={{
            options: [
              { label: 'Root Admin', value: AdminType.root },
              { label: 'General Admin', value: AdminType.general },
              { label: 'Assessment Admin', value: AdminType.assessment },
              // { label: 'Workshop Admin', value: AdminType.workshop },
              // { label: 'Workshop Instructor', value: AdminType.instructor },
            ],
            placeholder: 'Select admin type',
            onChange: (v) => {
              form.setValue('adminType', v);
            },
          }}
        />
        <TreeSelectField
          name="allowedSections"
          label="Allowed Sections"
          form={form}
          treeSelectFieldProps={{
            treeData: MENUS?.map((menu) => {
              return {
                label: menu?.label,
                value: menu?.key,
                key: menu?.key,
                children: menu?.submenus?.map((submenu) => {
                  return {
                    label: submenu?.label,
                    value: JSON.stringify({
                      key: menu?.key,
                      subSection: {
                        key: submenu?.path,
                        label: submenu?.label,
                      },
                    }),
                    key: JSON.stringify({
                      key: menu?.key,
                      subSection: {
                        key: submenu?.path,
                        label: submenu?.label,
                      },
                    }),
                  };
                }),
              };
            }),
            placeholder: 'Select Sections',
            style: {
              width: '100%',
            },
            treeCheckable: true,
            showCheckedStrategy: TreeSelect.SHOW_CHILD,
          }}
        />

        {adminType === AdminType.root ? (
          <SelectField
            label="Techniques allowed"
            name="techniquesAllowed"
            form={form}
            labelKey="label"
            valueKey="value"
            mode="multiple"
            selectFieldProps={{
              options: techniques.map((option) => ({
                label: option?.name?.en,
                value: option?.slug,
              })),
              placeholder: 'Select technique',
              onChange: (v) => {
                form.setValue('techniquesAllowed', v);
                form.setValue('customPaymentShares', undefined);
                form.setValue('isUsingDefaultTechniqueShares', undefined);
              },
              mode: 'multiple',
              allowClear: true,
            }}
          />
        ) : null}

        {adminType === AdminType.root ? (
          <SelectField
            label="Assign Instructors"
            name="assignedInstructor"
            form={form}
            labelKey="label"
            valueKey="value"
            mode="multiple"
            selectFieldProps={{
              options: [
                ...instructor?.map((i) => ({
                  label: `${i?.fullName} (${i?.email})`,
                  value: i?._id,
                })),
              ],
              placeholder: 'Assign Instructor',
              onChange: (v) => {
                form.setValue('assignedInstructor', v);
              },
              mode: 'multiple',
              allowClear: true,
            }}
          />
        ) : null}

        {techniquesAllowed?.length && adminType === AdminType.root ? (
          <RadioField
            label="Do you want to use default technique's payment shares ?"
            name="isUsingDefaultTechniqueShares"
            form={form}
            required
            radioFieldProps={{
              options: [
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ],
            }}
          />
        ) : null}

        {isUsingDefaultTechniqueShares === false &&
        adminType === AdminType.root &&
        techniquesAllowed?.length
          ? techniquesAllowed.map((techiqueSlug: string) => {
              const technique = techiqueSlug?.toUpperCase();
              return (
                <div>
                  <InputField
                    label={`Owner share (${technique})`}
                    name={`customPaymentShares.${techiqueSlug}.ownerShare`}
                    inputProps={{
                      placeholder: 'Owner share',
                      type: 'text',
                    }}
                    form={form}
                    required
                  />
                  <InputField
                    label={`HPL share (${technique})`}
                    name={`customPaymentShares.${techiqueSlug}.hplShare`}
                    inputProps={{
                      placeholder: 'HPL share',
                      type: 'text',
                    }}
                    form={form}
                    required
                  />
                  <InputField
                    label={`Instructor share (${technique})`}
                    name={`customPaymentShares.${techiqueSlug}.instructorShare`}
                    inputProps={{
                      placeholder: 'Instructor share',
                      type: 'text',
                    }}
                    form={form}
                    required
                  />
                </div>
              );
            })
          : null}

        <br />
      </Form>
    </>
  );
};

export default AddAdminForm;
