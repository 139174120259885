import { EQUIPMENTS } from '../constants/api';
import { Locale } from '../types';
import fetchClient from '../utils/fetch-client';

export type TPayload = {
  slug: string;
  slno?: number;
  product: Locale;
  website?: string;
  imageUrl?: string;
  _id?: string;
};

// type TDeleteEquipmentPayload = {
//   // isDeleted: boolean;
//   _id?: string;
// };

export const getEquipments = () => {
  return fetchClient.get(EQUIPMENTS.EQUIPMENTS);
};

export const addEquipment = (payload: TPayload) => {
  return fetchClient.post(EQUIPMENTS.EQUIPMENTS, payload);
};

export const updateEquipment = (payload: TPayload) => {
  return fetchClient.put(EQUIPMENTS.EQUIPMENTS, payload);
};

export const deleteEquipment = (equipmentId: string | undefined) => {
  return fetchClient.delete(`${EQUIPMENTS.EQUIPMENTS}/${equipmentId}`);
};
