import { Form, Input, Button } from 'antd';
import { useState } from 'react';

import { useAuthState } from '../../../context/auth-state';
import { handleError } from '../../../utils/common';

import './style.scss';

type TFieldValues = {
  email: string;
  password: string;
};

const Login = () => {
  const { onLogin } = useAuthState();
  const [requesting, setRequesting] = useState(false);

  const onFinish = async (values: TFieldValues) => {
    setRequesting(true);
    try {
      await onLogin({ email: values.email, password: values.password }, () =>
        setRequesting(false)
      );
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      layout={'vertical'}
      className="login_form"
      size={'large'}
    >
      <Form.Item
        label="Email"
        required
        name="email"
        rules={[{ required: true, message: 'Email is required' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        required
        name="password"
        rules={[{ required: true, message: 'Password is required' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button
          className="login_btn"
          block
          type="primary"
          htmlType="submit"
          loading={requesting}
        >
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};
export default Login;
