import { createElement, FC, useRef, useState } from 'react';
import { Button, Drawer } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AddEquipmentForm, { defaultValues } from '../form';
import validationSchema from '../validation-schema';
import { addEquipment, TPayload } from '../../../services/equipment-service';
interface IAddEquipmentProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  children: FC<{
    onClick: () => void;
  }>;
}

const AddEquipment: FC<IAddEquipmentProps> = ({
  children,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const form = useForm<TPayload>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues,
  });

  const handleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onSubmitHandler = async (data: TPayload) => {
    setRequesting(true);
    try {
      await addEquipment(data);
      handleDrawerVisibility();
      form.reset({});
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Drawer
        title="Add Equipment"
        onClose={() => {
          handleDrawerVisibility();
          form.reset({});
        }}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable={true}
        destroyOnClose={true}
        width={600}
        footer={
          <>
            <Button
              block
              type="primary"
              loading={requesting}
              onClick={form.handleSubmit(onSubmitHandler)}
            >
              Save
            </Button>
          </>
        }
      >
        <div style={{ padding: 16 }}>
          <AddEquipmentForm isEditable={false} form={form} />
        </div>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default AddEquipment;
