import { Button, message, Modal, Select } from 'antd';
import { createElement, FC, useRef, useState } from 'react';

import { handleError } from '../../../utils/common';
import { updateDealStatus } from '../../../services/deals';
import { IDealsStatus } from '../../../types/deals';

interface IManageDealStatusProps {
  successCallback?: () => void;
  errorCallback?: (e: string) => void;
  dealId: string;
  currentStatus: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const ManageDealStatus: FC<IManageDealStatusProps> = ({
  children,
  dealId,
  successCallback,
  currentStatus,
}) => {
  const refChildren = useRef(children);
  const [requesting, setRequesting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState('');

  const handleModalVisibility = () => {
    setOpenModal(!openModal);
    setStatus(currentStatus);
  };

  const onSubmitHandler = async () => {
    setRequesting(true);
    try {
      await updateDealStatus({ dealId, status });
      setOpenModal(false);
      message.success(`Deal Status Updated Successfully, moved to ${status}`);
      if (successCallback) {
        successCallback();
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        width={300}
        destroyOnClose
        onCancel={handleModalVisibility}
        title={'Manage Deal Status'}
        footer={
          <div>
            <Button key="cancel" onClick={handleModalVisibility}>
              Cancel
            </Button>
            <Button
              key="delete"
              type="primary"
              loading={requesting}
              onClick={onSubmitHandler}
            >
              Save
            </Button>
          </div>
        }
      >
        <Select
          style={{ width: '100%' }}
          className="w-full rounded-[0.6rem]  py-[0.7rem] md:py-[2.5rem]   border-[0.1rem] border-[#d9d9d9]"
          value={status}
          onChange={(value) => setStatus(value)}
          options={[
            { label: 'Active', value: IDealsStatus.active },
            { label: 'Expired', value: IDealsStatus.expired },
            { label: 'Upcoming', value: IDealsStatus.upcoming },
          ]}
        />
      </Modal>

      {createElement(refChildren.current, { onClick: handleModalVisibility })}
    </>
  );
};

export default ManageDealStatus;
