import { Form } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { CerificationCategory } from '../../types/certification-categories';
import { InputField } from '../common/form';

export type CertificationCategoryResArr = {
  value: string;
  label: string;
};
interface ICategoryFormProps {
  form: UseFormReturn<CerificationCategory, object>;
  isEditable: boolean;
}
const CertificationCategoryForm = ({
  form,
  isEditable = true,
}: ICategoryFormProps) => {
  return (
    <>
      <Form
        {...{
          labelCol: { span: 6 },
          wrapperCol: { span: 18 },
        }}
      >
        <InputField
          label="Slug"
          name="slug"
          required
          inputProps={{
            placeholder: 'Slug : eg(category-name)',
            disabled: isEditable === true,
          }}
          form={form}
        />
        <InputField
          label="Sequence"
          name="serialno"
          required
          inputProps={{
            placeholder: 'Sequence Number',
            type: 'number',
            onChange: (e) => {
              form.setValue('serialno', parseInt(e.target.value));
            },
          }}
          form={form}
        />
        <InputField
          label="Name EN"
          name="category.en"
          required
          inputProps={{
            placeholder: 'English',
          }}
          form={form}
        />
        <InputField
          label="Name PT"
          name="category.pt"
          required
          inputProps={{
            placeholder: 'Portuguese',
          }}
          form={form}
        />
        <InputField
          label="Name IT"
          name="category.it"
          required
          inputProps={{
            placeholder: 'Italian',
          }}
          form={form}
        />
        <InputField
          label="Name ES"
          name="category.es"
          required
          inputProps={{
            placeholder: 'Spanish',
          }}
          form={form}
        />
        <InputField
          label="Name DE"
          name="category.de"
          required
          inputProps={{
            placeholder: 'German',
          }}
          form={form}
        />
        <InputField
          label="Name ZH"
          name="category.zh"
          required
          inputProps={{
            placeholder: 'Mandrin',
          }}
          form={form}
        />
      </Form>
    </>
  );
};

export default CertificationCategoryForm;
