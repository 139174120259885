import { Button, Space, Spin, Table, Tooltip, Typography, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
  DragOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import AddClass from '../../components/exercise-classes/create-class';
import EditClass from '../../components/exercise-classes/edit-class';
import DeleteClass from '../../components/exercise-classes/delete-class';
import { getTechniques } from '../../services/technique-service';
import { TechniqueDetail } from '../../types/technique';
import { SectionDetail } from '../../types/sections';
import { getSections } from '../../services/sections-service';
import {
  getCategoriesBySection,
  updateBulkCategories,
} from '../../services/exercise-categories-service';
import { ExerciseClasses } from '../../types/exercise-classes';

import columns from './columns';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

interface DraggableTableProps {
  classes: ExerciseClasses[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnsWithActions: any[];
  onDragEnd: (event: DragEndEvent) => void;
}

const DraggableTable = ({
  classes,
  columnsWithActions,
  onDragEnd,
}: DraggableTableProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={classes.map((i) => i._id)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          dataSource={classes}
          columns={columnsWithActions}
          pagination={{ position: ['bottomRight'] }}
          rowKey="_id"
          components={{
            body: {
              row: Row,
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

const ExerciseClass = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadClasses, setReloadClasses] = useState(false);
  const [sections, setSections] = useState<SectionDetail[]>([]);
  const [Classes, setClasses] = useState<ExerciseClasses[]>([]);
  const [techniques, setTechniques] = useState<TechniqueDetail[]>([]);
  const [dragAndDropEnabled, setDragAndDropEnabled] = useState(false);
  const [originalClasses, setOriginalClasses] = useState<ExerciseClasses[]>([]);

  const loadSections = async () => {
    setRequesting(true);
    try {
      const response = await getSections();
      setSections(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
    }
  };

  const loadTechniques = async () => {
    setRequesting(true);
    try {
      const response = await getTechniques();
      setTechniques(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
    }
  };

  const loadClasses = async () => {
    setRequesting(true);
    try {
      const response = await getCategoriesBySection({ isClassVideo: true });
      setClasses(response?.data?.data);
      setOriginalClasses(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setReloadClasses(false);
      setRequesting(false);
    }
  };

  const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setClasses((previous) => {
        const activeIndex = previous.findIndex((i) => i._id === active.id);
        const overIndex = previous.findIndex((i) => i._id === over?.id);

        const newClasses = [...previous];

        // Remove the dragged class from the array
        const [draggedClass] = newClasses.splice(activeIndex, 1);

        // Insert the dragged class back to the array at the target position
        newClasses.splice(overIndex, 0, draggedClass);

        // Update the sequence order for all classes based on their index
        newClasses.forEach((item, index) => {
          item.sequence = index + 1;
        });

        return newClasses;
      });
    }
  }, []);

  useEffect(() => {
    if (!requesting && reloadClasses) {
      loadClasses();
    }
  }, [reloadClasses, requesting]);

  useEffect(() => {
    loadClasses();
    loadTechniques();
    loadSections();
  }, []);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: ExerciseClasses) => {
        return (
          <Space size="large">
            <EditClass
              _id={record._id}
              payload={record as unknown as ExerciseClasses}
              sections={sections}
              techniques={techniques}
              successCallback={() => setReloadClasses(true)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditClass>
            <DeleteClass
              _id={record._id}
              successCallback={() => setReloadClasses(true)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteClass>
          </Space>
        );
      },
    },
  ];

  const toggleDragAndDrop = useCallback(() => {
    setDragAndDropEnabled((prevState) => !prevState);
  }, []);

  const saveOrderHandler = async () => {
    try {
      if (Classes === originalClasses) {
        message.info('No Changes Found');
        setDragAndDropEnabled(false);
      } else {
        await updateBulkCategories(
          Classes.map((cls) => ({ _id: cls._id, sequence: cls.sequence }))
        );
        message.success('Updated Successfully');
        setDragAndDropEnabled(false);
        setReloadClasses(true);
      }
    } catch (error) {
      message.error('Network Error');
      console.log(error);
    }
  };

  // Function to reset order to its original state
  const resetSequenceOrder = useCallback(() => {
    setDragAndDropEnabled(false);
    setClasses(
      originalClasses.map((cls, index) => {
        cls.sequence = index + 1;
        return cls;
      })
    );
  }, [originalClasses]);

  return (
    <Spin spinning={requesting}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={3}>Exercise Classes</Typography.Title>
          <Space>
            <Tooltip title="Refresh" placement="left">
              <Button
                type="ghost"
                icon={<ReloadOutlined />}
                onClick={() => setReloadClasses(true)}
              />
            </Tooltip>
            <AddClass
              techniques={techniques}
              sections={sections}
              successCallback={() => setReloadClasses(true)}
            >
              {({ onClick }) => (
                <Button
                  onClick={onClick}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add Exercise Classes
                </Button>
              )}
            </AddClass>
            {/* Button to toggle drag and drop */}
            {!dragAndDropEnabled && (
              <Button
                onClick={toggleDragAndDrop}
                type={'default'}
                icon={<DragOutlined />}
              >
                Edit Sequence Order
              </Button>
            )}
            {dragAndDropEnabled && (
              <Button
                onClick={saveOrderHandler}
                type={'primary'}
                icon={<CheckOutlined />}
              >
                Save
              </Button>
            )}
            {dragAndDropEnabled && (
              <Button onClick={resetSequenceOrder} type="dashed">
                Cancel
              </Button>
            )}
          </Space>
        </div>

        {/* Conditionally render the draggable table if dragAndDropEnabled is true */}
        {dragAndDropEnabled ? (
          <DraggableTable
            classes={Classes}
            columnsWithActions={columnsWithActions}
            onDragEnd={onDragEnd}
          />
        ) : (
          // Render a regular table without drag and drop functionality
          <Table
            dataSource={Classes}
            columns={columnsWithActions}
            pagination={{ position: ['bottomRight'] }}
          />
        )}
      </div>
    </Spin>
  );
};

export default ExerciseClass;
