import { Layout } from 'antd';
const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer className="footer">
      吴中经济技术开发区岭云网络技术工作室版权所有{' '}
      <a href="https://beian.miit.gov.cn/" rel="noreferrer" target="_blank">
        苏ICP备18034268号-8
      </a>{' '}
      | Copyright © 2022 Progressing Ballet Technique. All Rights Reserved.
    </Footer>
  );
};

export default AppFooter;
