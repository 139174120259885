import { Language, WorkshopLanguage } from '../../constants/common';
import { TechniqueDetail } from '../../types/technique';
import {
  getFormattedLanguages,
  getFormattedWorkshopLanguages,
} from '../../utils/common';

const columns = [
  {
    title: 'Sequence',
    render: (value: string, record: TechniqueDetail) => record?.sequence,
    width: 100,
  },
  {
    title: 'Short Name',
    render: (value: string, record: TechniqueDetail) => record?.shortName,
    width: 200,
  },
  {
    title: 'Name',
    render: (value: string, record: TechniqueDetail) => record?.name?.en,
    width: 400,
  },
  {
    title: 'Certificate Life',
    render: (value: string, record: TechniqueDetail) =>
      `${record?.certificateLifeInYears} Years`,
    width: 200,
  },
  {
    title: 'Certification Highest Level',
    render: (value: string, record: TechniqueDetail) =>
      record?.certificationHighestLevel,
    width: 200,
  },
  {
    title: 'Primary Language',
    render: (value: string, record: TechniqueDetail) =>
      `${getFormattedLanguages(record.primaryLanguages as Language[]).join(
        ', '
      )}`,
    width: 300,
  },
  {
    title: 'Workshop Languages',
    render: (value: string, record: TechniqueDetail) =>
      `${getFormattedWorkshopLanguages(
        record.workshopLanguages as WorkshopLanguage[]
      ).join(', ')}`,
    width: 400,
  },
];

export default columns;
