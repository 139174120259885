import { useEffect, useState, useCallback } from 'react';

import {
  ISentEmailsRecords,
  SentEmailStatus,
} from '../../../types/email-records';
import { handleError } from '../../../utils/common';
import { getSentEmailsByRecipientEmail } from '../../../services/sent-email-records';

import './styles.scss';
import EmailHeader from './EmailHeader';
import EmailList from './EmailList';
import EmailContent from './EmailContent';

interface IEmailReceivedProps {
  userId: string;
  pic?: string;
}

const limit = 10;

const EmailReceived: React.FC<IEmailReceivedProps> = ({ userId, pic }) => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const [selectedEmail, setSelectedEmail] = useState<ISentEmailsRecords | null>(
    null
  );

  const [emailRecords, setEmailRecords] = useState<ISentEmailsRecords[]>([]);
  const [alignValue, setAlignValue] = useState<SentEmailStatus>(
    SentEmailStatus.SENT
  );

  const loadSentEmails = useCallback(
    async (reset = false, page: number) => {
      if (!hasMore && !reset) return;
      if (reset) {
        setHasMore(true);
      }

      try {
        setLoading(true);
        const response = await getSentEmailsByRecipientEmail({
          userId,
          page,
          status: alignValue,
        });
        const { emailRecords: newEmails = [], currentPage = 1 } =
          response?.data?.data ?? {};

        setEmailRecords((prevEmails) =>
          reset ? newEmails : [...prevEmails, ...newEmails]
        );
        setHasMore(newEmails.length >= limit);
        setPage(currentPage + 1);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [userId, page, hasMore, alignValue]
  );

  useEffect(() => {
    loadSentEmails(true, 1);
  }, [alignValue]);

  useEffect(() => {
    setSelectedEmail(emailRecords?.[0] || null);
  }, [emailRecords]);

  return (
    <div className="email-container">
      <div className="email-list">
        <EmailHeader alignValue={alignValue} setAlignValue={setAlignValue} />
        <EmailList
          emailRecords={emailRecords}
          hasMore={hasMore}
          selectedEmailId={selectedEmail?._id || null}
          loadMoreEmails={() => loadSentEmails(false, page)}
          handleEmailSelect={setSelectedEmail}
          pic={pic || '/profile-demo.jpg'}
          loading={loading}
        />
      </div>
      <EmailContent
        selectedEmail={selectedEmail}
        pic={pic || '/profile-demo.jpg'}
      />
    </div>
  );
};

export default EmailReceived;
