import { IGiftVoucherProduct } from '../../../types/gift-voucher';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 70,
  },
  {
    title: 'Name',
    render: (value: number, record: IGiftVoucherProduct, index: number) => {
      return record?.name?.en;
    },
    key: 'name',
    width: 500,
  },
  {
    title: 'Discount (%)',
    render: (value: number, record: IGiftVoucherProduct, index: number) => {
      return record?.discountInPercent;
    },
    key: 'name',
    width: 250,
  },
];

export default columns;
