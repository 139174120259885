import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { IMarketingAssets } from '../../types/marketingAssets';
import { getMarketingAssets } from '../../services/marketing-assets-service';
import EditMarketingAssets from '../../components/marketing-assets/edit-marketing-asset';
import AddMarketingAsset from '../../components/marketing-assets/create-marketing-asset';
import DeleteMarketingAsset from '../../components/marketing-assets/delete-marketing-asset';

import columns from './columns';

const MarketingAssets = () => {
  const [requesting, setRequesting] = useState(false);
  const [reloadAssets, setReloadAssets] = useState(true);
  const [marketingAssets, setMarketingAssets] = useState<IMarketingAssets[]>(
    []
  );

  const loadMarketingAssets = async () => {
    setRequesting(true);
    try {
      const response = await getMarketingAssets();
      setMarketingAssets(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
      setReloadAssets(false);
    }
  };
  useEffect(() => {
    if (!requesting && reloadAssets) {
      loadMarketingAssets();
    }
  }, [reloadAssets, requesting]);

  const columnsWithActions = [
    ...columns,
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (value: string, record: IMarketingAssets) => {
        return (
          <Space size="large">
            <EditMarketingAssets
              assetId={record._id}
              payload={record}
              isEditable={true}
              successCallback={() => setReloadAssets(true)}
            >
              {({ onClick }) => <EditOutlined onClick={onClick} />}
            </EditMarketingAssets>
            <DeleteMarketingAsset
              assetId={record._id}
              successCallback={() => setReloadAssets(true)}
            >
              {({ onClick }) => (
                <DeleteOutlined onClick={onClick} style={{ color: 'red' }} />
              )}
            </DeleteMarketingAsset>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={requesting}>
      <Table
        dataSource={marketingAssets}
        columns={columnsWithActions}
        rowKey="_id"
        pagination={false}
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={3}>Marketing Assets</Typography.Title>
              <Space>
                <Tooltip title="Refresh" placement="left">
                  <Button
                    type="ghost"
                    icon={<ReloadOutlined />}
                    onClick={() => setReloadAssets(true)}
                  />
                </Tooltip>
                <AddMarketingAsset
                  isEditable={false}
                  successCallback={() => setReloadAssets(true)}
                >
                  {({ onClick }) => (
                    <Button
                      onClick={onClick}
                      type="primary"
                      icon={<PlusCircleOutlined />}
                    >
                      Add
                    </Button>
                  )}
                </AddMarketingAsset>
              </Space>
            </div>
          );
        }}
      />
    </Spin>
  );
};
export default MarketingAssets;
