/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Form, Modal, message, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { IAssignee } from '../../types/assesment-response';
import { SelectField } from '../../components/common/form';
import { IUploadedCertificate } from '../../types/uploaded-certificate-response';
import { addAssignee } from '../../services/uploaded-certifcate-response';

interface IAssigneeModel {
  response?: IUploadedCertificate;
  assignees?: IAssignee[];
  loadingAssignees?: boolean;
  successCallback?: () => void;
}

const AssigneeModel: FC<IAssigneeModel> = ({
  response,
  assignees,
  loadingAssignees,
  successCallback,
}) => {
  const [open, setOpen] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const form = useForm<{
    assignee: string;
  }>({
    resolver: yupResolver(
      yup.object().shape({
        assignee: yup.string().required('Required'),
      })
    ),
  });

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const onSubmitHandler = async (values: { assignee: string }) => {
    setRequesting(true);
    try {
      if (response?.assignee?.id === values?.assignee) {
        message.info('No change found');
        return;
      }
      await addAssignee({
        assigneeId: values?.assignee,
        responseId: response?._id || '',
      });
      notification.success({
        message: 'Successfull',
      });
      if (successCallback) {
        successCallback();
      }
    } catch (err: any) {
      notification.error({
        message: 'Error',
        description: err?.MESSAGE_KEY || err.message,
      });
    } finally {
      setRequesting(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    form.reset({
      assignee: response?.assignee?.id,
    });
  }, []);

  return (
    <>
      <Modal
        title={false}
        onCancel={closeModal}
        open={open}
        footer={[
          <Button key={1} onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key={2}
            type="primary"
            onClick={form.handleSubmit(onSubmitHandler)}
            loading={requesting}
          >
            Submit
          </Button>,
        ]}
        bodyStyle={{ padding: '40px 30px 0px 30px' }}
        destroyOnClose
      >
        <Form layout="vertical">
          <SelectField
            label="Assignees"
            required
            name="assignee"
            form={form}
            labelKey="label"
            valueKey="value"
            selectFieldProps={{
              options: assignees?.map((i) => {
                return {
                  label: i?.name,
                  value: i?.id,
                };
              }),
              placeholder: 'Select Assignee',
              onChange: (v) => form.setValue('assignee', v),
              loading: loadingAssignees,
            }}
          />
        </Form>
      </Modal>
      <Button
        type="dashed"
        onClick={() => {
          !response?.isApproved && openModal();
        }}
      >
        {response?.assignee?.name ? response?.assignee?.name : 'Not Assigned'}
      </Button>
    </>
  );
};

export default AssigneeModel;
