import { createElement, FC, useRef, useState } from 'react';
import { Button, Modal } from 'antd';

import { deleteWorkshopCoupon } from '../../../../services/workshop-service';

interface IDeleteCouponProps {
  successCallback?: () => void;
  errorCallback?: (e: Error) => void;
  id: string;
  children: FC<{
    onClick: () => void;
  }>;
}

const DeleteCoupon: FC<IDeleteCouponProps> = ({
  children,
  id,
  successCallback,
  errorCallback,
}) => {
  const refChildren = useRef(children);
  const [popVisible, setPopVisible] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const onDelete = async () => {
    setRequesting(true);
    try {
      await deleteWorkshopCoupon(id);
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (errorCallback) {
        errorCallback(e as Error);
      }
    } finally {
      setRequesting(false);
      handlePopVisibility();
    }
  };

  const handlePopVisibility = () => {
    setPopVisible(!popVisible);
  };
  return (
    <>
      <Modal
        open={popVisible}
        onCancel={handlePopVisibility}
        title="Are you sure you want to delete this coupon?"
        footer={[
          <Button key="cancel" onClick={handlePopVisibility}>
            Cancel
          </Button>,
          <Button
            key="delete"
            type="primary"
            loading={requesting}
            onClick={onDelete}
          >
            Delete
          </Button>,
        ]}
      >
        <p>
          <span style={{ color: 'red' }}>NOTE:</span> This coupon will be
          deleted for all the workshops, it applies on!
        </p>
      </Modal>
      {createElement(refChildren.current, { onClick: handlePopVisibility })}
    </>
  );
};

export default DeleteCoupon;
