import { Space, Typography } from 'antd';
import moment from 'moment';

import { Workshop } from '../../../types/workshop';
import { getCountryByCode } from '../../../constants/countries';

const { Text, Title } = Typography;

interface WorkshopProfileProps {
  workshop?: Workshop;
}

const WorkshopProfile = ({ workshop }: WorkshopProfileProps) => {
  return (
    <Space direction="vertical">
      <Title level={3}>{workshop?.title}</Title>
      <Text>
        {workshop?.workshopMode === 'virtual' ? 'Online' : workshop?.city}
        {workshop?.country && `, ${getCountryByCode(workshop?.country)?.name}`}
      </Text>
      <Text>{`${moment(workshop?.startDate)
        .tz(workshop?.timezone || 'UTC')
        .format('MMM Do YYYY, h:mm:ss A')}, (${workshop?.timezone})`}</Text>
    </Space>
  );
};

export default WorkshopProfile;
