import moment from 'moment';
import { Link } from 'react-router-dom';

import { IGiftVoucherOrders } from '../../../types/gift-voucher';
import { convertStripeAmount } from '../../../utils/common';

const columns = [
  {
    title: '# No',
    render: (value: number, record: unknown, index: number) => index + 1,
    key: 'slno',
    width: 60,
  },
  {
    title: 'Created At',
    render: (value: number, record: IGiftVoucherOrders, index: number) => {
      return (
        <>
          <p>{moment(record?.createdAt).format('DD MMM YYYY ( HH:MM )')}</p>
          <p>
            Purchased :{' '}
            {record?.months === 1 ? '1 Month' : `${record?.months} Months`}{' '}
            {record?.memberType} Membership
          </p>
          <p>
            Purchased At :{' '}
            {`${record?.saleCurrency.toUpperCase()} ${convertStripeAmount(
              record?.salePrice,
              record?.saleCurrency
            )}`}
          </p>
        </>
      );
    },
    key: 'createdAt',
    width: 170,
  },
  {
    title: 'Code',
    render: (value: number, record: IGiftVoucherOrders, index: number) => {
      return record?.code;
    },
    key: 'code',
    width: 100,
  },
  {
    title: 'Sender',
    render: (value: number, record: IGiftVoucherOrders, index: number) => {
      return `${record?.sender?.name} (${record?.sender?.email})`;
    },
    key: 'sender',
    width: 120,
  },
  {
    title: 'Recipient',
    render: (value: number, record: IGiftVoucherOrders, index: number) => {
      return `${record?.recipient?.name} (${record?.recipient?.email})`;
    },
    key: 'name',
    width: 120,
  },
  {
    title: 'Is Redeemed',
    render: (value: number, record: IGiftVoucherOrders, index: number) => {
      return record?.isRedeemed ? (
        <>
          <p>Yes</p>
          <p>
            {'( '}By{' '}
            <Link
              to={{ pathname: `/userdetails/${record?.remedeemedBy?._id}` }}
            >
              {record?.remedeemedBy?.email}
            </Link>{' '}
            {' )'}
          </p>
          <p>{`Credits Given : ${record?.redeemCurrency?.toUpperCase()} ${convertStripeAmount(
            record?.listedPrice as number,
            record?.redeemCurrency as string
          )} `}</p>
        </>
      ) : (
        'No'
      );
    },
    key: 'name',
    width: 170,
  },
];

export default columns;
