import { Col, Form, Row } from 'antd';
import { UseFormReturn } from 'react-hook-form';

import { InputField } from '../../../../common/form';

interface IAddSchoolFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
}
const EditSchoolForm = ({ form }: IAddSchoolFormProps) => {
  return (
    <Form layout="vertical">
      <Row>
        <Col span={24} className="pbt-pr">
          <InputField
            label={'School Name'}
            name="name"
            required
            form={form}
            inputProps={{ placeholder: 'Enter School Name' }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'Website'}
            name="website"
            form={form}
            inputProps={{ placeholder: "Add School's Website" }}
          />
        </Col>
        <Col span={12}>
          <InputField
            label={'Facebook'}
            name="facebook"
            form={form}
            inputProps={{ placeholder: "Add School's  Facebook" }}
          />
        </Col>
        <Col span={12} className="pbt-pr">
          <InputField
            label={'Instagram'}
            name="instagram"
            form={form}
            inputProps={{ placeholder: "Add School's  Instagram" }}
          />
        </Col>
        <Col span={12}>
          <InputField
            label={'Twitter'}
            name="twitter"
            form={form}
            inputProps={{ placeholder: "Add School's  Twitter" }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default EditSchoolForm;
